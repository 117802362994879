import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Button, Col, Dropdown, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faDownload,
  faGear,
  faPlusCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useLocation, useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate'; 
import { formulaIvorySheet,IvorySheethightwidth } from "./TableFormula";
import ReactHTMLTableToExcel from "react-html-table-to-excel";



const Report = () => {
  const location = useLocation();
  
  const { 
    projectId,
    jsquareTotalNosState,
    jsquareTotalState,
    GlasslistTotalNosState,
    TandembottomTotalNosState,
    TandembackTotalNosState ,
    prjName,
    singleTotalNosState,
    ShelfTotalNosState,
    DepthTotalNosState,
    BkTotalNosState,
    OnecrossOneTotalNosState,
    OnepointtwoTotalNosState,
    OnepointtwolipTotalNosState,
    mmTotalNosState
  } = location.state || {};

  console.log("Project ID:", projectId);
  console.log("Jsquare Total Nos:", jsquareTotalNosState);
  console.log("Jsquare Total:", jsquareTotalState);
  console.log("Glasslist Total Nos:", GlasslistTotalNosState);
  console.log("Tandembottom Total Nos:", TandembottomTotalNosState);
  console.log("Tandemback Total Nos:", TandembackTotalNosState);
  console.log("prjName",prjName);

  console.log("singleTotalNosState",singleTotalNosState);
  console.log("ShelfTotalNosState",ShelfTotalNosState);
  console.log("DepthTotalNosState",DepthTotalNosState);
  console.log("BkTotalNosState",BkTotalNosState);
 console.log(" OnecrossOneTotalNosState",OnecrossOneTotalNosState);
  console.log("OnepointtwoTotalNosState",OnepointtwoTotalNosState);
  console.log("OnepointtwolipTotalNosState",OnepointtwolipTotalNosState);
  console.log("mmTotalNosState",mmTotalNosState);
    
  const navigate = useNavigate();
  const [DataSheet, setDataSheet] = useState<any>({});
  const [LeftReport, setLeftReport] = useState<any>({});
 
  useEffect(() => {
    FormulaDataSheetSelect();
    FormulaLeftReportSelect();
    console.log("useeffect");
    
}, []);

  const FormulaDataSheetSelect = () => {
    console.log("setDataSheetcoming");
    setDataSheet({});
    let req = {
      projectId: projectId,
      jsquareTotalNos:jsquareTotalNosState ? jsquareTotalNosState : "0",
      jsquareTotal:jsquareTotalState ? jsquareTotalState : "0",
      GlasslistTotalNos:GlasslistTotalNosState ? GlasslistTotalNosState : "0",
      TandembottomTotalNos:TandembottomTotalNosState ? TandembottomTotalNosState :"0",
      TandembackTotalNos:TandembackTotalNosState ? TandembackTotalNosState :"0" ,
      singleTotalNosState:singleTotalNosState ? singleTotalNosState : "0",
      ShelfTotalNosState:ShelfTotalNosState? ShelfTotalNosState :"0",
      DepthTotalNosState: DepthTotalNosState ? DepthTotalNosState :"0",
      BkTotalNosState:BkTotalNosState ? BkTotalNosState : "0",
      OnecrossOneTotalNosState : OnecrossOneTotalNosState ? OnecrossOneTotalNosState :"0",
      OnepointtwoTotalNosState : OnepointtwoTotalNosState ? OnepointtwoTotalNosState : "0",
      OnepointtwolipTotalNosState : OnepointtwolipTotalNosState ? OnepointtwolipTotalNosState :"0",
      mmTotalNosState : mmTotalNosState ? mmTotalNosState : "0"

    };
    console.log("Datasheetrequest====>",req);
    
    callApi("POST", "data_sheet.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setDataSheet(response.responseJson);
        }
        console.log("setDataSheet.setDataSheet.php",DataSheet);
      })
      .catch((err: any) => {
        console.log(err);
      });
      console.log("DataSheetDataSheet",DataSheet);
      
  };

  const FormulaLeftReportSelect = () => {
    console.log("FormulaLeftReportSelect");
    setLeftReport({});
    let req = {
      projectId: projectId,
    };
    callApi("POST", "leftpage_report.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setLeftReport(response.responseJson);
          console.log("responseJson",response.responseJson);
          
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const back = () => {
    navigate(`/projectdetails?id=${projectId}`);
  };
  const logout = () => {
    showSuccess("Success!", "Logout Successfully!");
    navigate(`/`);
  };
  const showSections =
  Object.keys(DataSheet).length > 0
  return (
    <main>
      <header>
        <div className="">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="icon backicon"
            onClick={back}
          />
          <h1 className="heading">Report Page</h1>

          <Dropdown className="d-inline mx-2 ddrop">
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon icon={faSignOutAlt} className="icon printicon" onClick={logout} />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </header>
<br />
<Row>
  <Col lg="12" md="12" sm="12" xs="12">
  <h3 style={{textAlign:"center",paddingBottom:"15px"}}>{prjName}</h3>
  </Col>
</Row>
      <div className="cutlistmain" style={{paddingLeft:"15% !important",paddingRight:"15% !important"}}>
                <div className="cutlistcontainer" style={{ padding: "1%" }}>
                  <style>{`th {background-color: burlywood !important;}`}</style>
 {/*Doorsheet cutlist Table*/}
 <Row className="scrollRow">

          <Col lg="12" md="12" className="">
          <div style={{ textAlign: "end" }}>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xls"
            filename={`${prjName}-Quantity Report`}
            sheet={`tablexls`}
            buttonText={<FontAwesomeIcon icon={faDownload} />
          }
          />

                          </div>
          <h4 style={{textAlign: "center",borderRadius: "5px",backgroundColor: "yellow",padding: "5px",marginBottom: "0px",}}>
          Quantity Report  
                          </h4>
            <Table
              responsive="md lg"
              striped
              bordered
              hover
               id="table-to-xls"
              style={{ width: "100% !important" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Code</th>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>Label</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {LeftReport &&
                  LeftReport.length > 0 &&
                  LeftReport.map((details: any, index: any) => {
                    return (
                      <>
                        <tr>
                        <td>{index+1}</td>
                          <td>{details.code}</td>
                          <td>{details.item}</td>
                          <td>{details.qty}</td>
                          <td>{details.label}</td>  
                          <td>{details.note}</td>
                         
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <br/>
        {showSections && (
            <>
        <Row className="scrollRow">

          <Col lg="12" md="12" className="">
          <div style={{ textAlign: "end" }}>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="table-to-xlss"
            filename={`${prjName}-Datasheet`}
            sheet={`tablexls`}
            buttonText={<FontAwesomeIcon icon={faDownload} />
          }
          />

                          </div>
          <h4 style={{textAlign: "center",borderRadius: "5px",backgroundColor: "yellow",padding: "5px",marginBottom: "0px",}}>
                         Data Sheet  
                          </h4>
            <Table
              responsive="md lg"
              striped
              bordered
              hover
                id="table-to-xlss"
              style={{ width: "100% !important" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>Label</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
                {DataSheet &&
                  DataSheet.length > 0 &&
                  DataSheet.map((details: any, index: any) => {
                    return (
                      <>
                        <tr>
                          <td>{details.SlNo}</td>
                          <td>{details.item}</td>
                          <td>{(details.qty)}</td>
                          <td>{details.label}</td>  
                          <td>{details.note}</td>
                         
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
          </Col>
        </Row>
        </>
        )}
                  </div>
                  </div>
      
    </main>
  );
};

export default Report;
