import React, { Component, FormEvent, useEffect, useState } from "react";
import { callApi } from "../../utills/api";
import { Col, Row, Table } from "react-bootstrap";


// const [formulatabledetails,setFormulatabledetails] = useState<any>({});


export default function FormulaTable() {

    const [formulatabledetails, setFormulatabledetails] = useState<any>({});
    const [formulausingtable, setUpdatedformula] = useState<any>({});
    const [categoryList, setCategoryList] = useState<any>(['']);




    useEffect(() => {
        formulatableselect();
    }, []);

    const formulatableselect = () => {
        // const searchParams = new URLSearchParams(window.location.search);
        // const projectId = searchParams.get("id");
        // if (!projectId) {
        //   console.log("Project ID is null");
        //   return;
        // }
        let req = {
            projectId: "5",
        };
        callApi("POST", "formula_boxtableselect.php", req)
            .then((res) => res.data)
            .then((response) => {
                if (response.statusCode === 200) {
                    console.log("formula_boxtableselect", response.responseJson.resultUser);
                    setFormulatabledetails(response.responseJson.resultUser);
                    console.log("formulatableset", formulatabledetails);
                }
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    useEffect(() => {
        console.log("formula stores updated:", formulatabledetails);
        FormulatableHW();
    }, [formulatabledetails]);

    const FormulatableHW = () => {
        if (Object.keys(formulatabledetails).length > 0) {
            let categoryLists: any[] = [];
            Object.keys(formulatabledetails).forEach((category) => {
                categoryLists.push(category);
                setCategoryList(categoryLists);
                console.log("categorysList----", categoryList);
            });
            for (const category in formulatabledetails) {
                const colors = formulatabledetails[category];
                for (const color in colors) {
                    const detailsArray = colors[color];
                    detailsArray.forEach((details: any, index: number) => {
                        colors[color][index] = updateDetails(details, category);
                    });
                }
            }
            setUpdatedformula(formulatabledetails);
            console.log("formuladetails", formulatabledetails);
        }

    }

    function updateDetails(details: any, category: any) {
        const height = parseInt(details.boxheightcat) - 40;
        const width = parseInt(details.boxwidthcat) - 40;
        return {
            ...details,
            updatedcategory: category,
            updatedboxheightcat: height,
            updatedboxwidthcat: width,
        };
    }

    return (
        <main>
            <div className="main">
                <Row className="scrollRow">
                    <Col lg="12" md="12">
                        <div className="container">
                            <h1>Haii</h1>


                            <div className="container" style={{ padding: "2%" }}>
                                <div>


                                </div>
                                <br />
                                <div>
                                    {/* {Object.keys(formulatabledetails).length > 0 ?  Object.keys(formulatabledetails).forEach((category) => { : ""} */}
                                    <p className="tablepara">FF Single lip BLACK 15nos</p>
                                    <Table responsive="md" striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Height</th>
                                                <th>Width</th>
                                                <th>Quantity</th>
                                                <th>Label</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>100</td>
                                                <td>500</td>
                                                <td>4</td>
                                                <td>DR</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <br />
                                <div>
                                    <p className="tablepara">BK IVORY 15nos</p>
                                    <Table responsive="md" striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Height</th>
                                                <th>Width</th>
                                                <th>Quantity</th>
                                                <th>Label</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>100</td>
                                                <td>500</td>
                                                <td>4</td>
                                                <td>DR</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>


                        </div>
                    </Col>
                </Row>

            </div>
        </main>
    )

}