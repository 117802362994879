import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faGear, faPlusCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useNavigate } from "react-router-dom";

interface Project {
  prjdesc: string;
  id: number;
  prjname: string;
}

const Dashboard = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [prjname, setPrjName] = useState("");
  const [prjdescription, setPrjDescription] = useState("");
  const [color, setColor] = useState("");
  const [nameError, setNameError] = useState("");
  const [descError, setDescError] = useState("");
  const [totalProjects, setTotalProjects] = useState<Project[]>([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "prjname") setPrjName(value);
    if (name === "prjdescription") setPrjDescription(value);
    if (name === "color") setColor(value);
  };

  const handleClick = () => {
    let nameError = "";
    if (!prjname) {
      nameError = "Project name is required";
    } 

    if (nameError) {
      setNameError(nameError);
      return;
    }

    const req = {
      prjname: prjname,
      prjdescription: prjdescription,
    };

    callApi("POST", "projectadd.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] === true) {
          showSuccess("Success!", "Project Added Successfully!");
          setShow(false);
          setPrjName("");
          setPrjDescription("");
          getProjectDetails();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const getProjectDetails = () => {
    callApi("GET", "projectselect.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] === 200) {
          setTotalProjects(response.responseJson.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  const cardhandleClick = (project: any) => {
    navigate(`/projectdetails?id=${project.id}`);
  };

  const logout = () => {
    navigate(`/`);
  };

  const coloradd = () => {
    navigate(`/coloradd`);
  }

  const categery = () =>  {
    navigate(`/Categery`); 
  }

  const handleDuplicate = (projectid:any) =>{
console.log("projectid",projectid);
const req = {
  projectid: projectid,
};

callApi("POST", "project_duplicate.php", req)
  .then((res) => res.data)
  .then((response) => {
    if (response["success"] === true) {
      showSuccess("Success!", "Project Duplicate Successfully!");
      getProjectDetails();
    } else {
      showError("Failed", "Not Inserted !");
    }
  })
  .catch((err) => {
    console.log(err);
  });

  }
  return (
    <main>
      <header>
        <div className="">
          <h1>Merlok Interiors</h1>

          <Dropdown className="d-inline mx-2 ddrop"  >
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon icon={faGear} className="icon printicon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="padd" onClick={coloradd}>       <FontAwesomeIcon icon={faPlusCircle} className="icon" />Add Color</Dropdown.Item>
              <Dropdown.Item className="padd" onClick={categery}>       <FontAwesomeIcon icon={faPlusCircle} className="icon" />Category</Dropdown.Item>
              <Dropdown.Item className="padd" onClick={logout}> <FontAwesomeIcon icon={faSignOutAlt} className="icon" />  Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <div className="dashcontiner">
        <Row className="scrollRow">
          <Col lg="3" md="3" className="flexbtn">
            <Button variant="outline-primary subbutton" onClick={handleShow}>
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Project
            </Button>
          </Col>
          <Col lg="6" md="6" className="flex"></Col>
          <Col lg="3" md="3" className="flexbtn">
          
          </Col>
        </Row>
        <Row style={{ padding: "0% 3%" }} className="scrollRow">
          {totalProjects &&
            totalProjects.length > 0 &&
            totalProjects.map((project, index) => {
              return (
                <Col lg="3" md="3" key={project.id} className="flex">
                  <div
                    className="card"
                   
                  >
                    <div className="card-body">
                                        <Row className="">
                      <Col lg="10" md="10" sm="10" xs="10"  onClick={() => cardhandleClick(project)}>
                      <h5 className="card-title">{project.prjname}</h5>
                      </Col>
                      <Col lg="2" md="2" sm="2" xs="2">
                      <h5 onClick={() => handleDuplicate(project.id)}>
                      <FontAwesomeIcon
                            icon={faCopy}
                            className="icon"
                          />
                          </h5>
</Col>
<Col lg="12" md="12" sm="12" xs="12"  onClick={() => cardhandleClick(project)}>
                      <p className="card-text">{project.prjdesc}</p>
                      </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              );
            })}
        </Row>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Project Name </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter project Name"
                name="prjname"
                minLength={5}
                maxLength={50}
                required
                value={prjname}
                onChange={handleChange}
                onFocus={() => setNameError("")}
              />
              <div className="text-danger">{nameError}</div>
            </div>
            <div className="labeldiv">
              <label className="label">Description</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Description"
                name="prjdescription"
                minLength={5}
                maxLength={100}
                required
                value={prjdescription}
                onChange={handleChange}
                onFocus={() => setDescError("")}
              />
              <div className="text-danger">{descError}</div>
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={handleClick}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </main>
  );
};

export default Dashboard;
