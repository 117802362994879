import React, { useState, useEffect } from "react";
import "./dashboard.css";
import { Button, Col, Dropdown, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faGear,
  faPlusCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate'; 

interface Project {
  prjdesc: string;
  id: number;
  prjname: string;
}

const Coloradd = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [colormodal, setColorModal] = useState(false);
  const [colorselectmodal, setcolorselectmodal] = useState(false);
  const [prjname, setPrjName] = useState("");
  const [prjdescription, setPrjDescription] = useState("");
  const [color, setColor] = useState("");
  const [nameError, setNameError] = useState("");
  const [descError, setDescError] = useState("");
  const [colorError, setColorError] = useState("");
  const [totalProjects, setTotalProjects] = useState<Project[]>([]);
  const [state, setState] = useState({
    Boxdetailstable: [],
  });
  const [selectcolor, setselectcolor] = useState({ id: "", color: ""});
  const handleClose = () => setShow(false);
  const handleColorShow = () => setColorModal(true);
  const handleColorClose = () => {
    setColorModal(false);
  };
  const handleselectColorShow = () => setcolorselectmodal(true);
  const handleselectColorClose = () => {
    setcolorselectmodal(false);
    setselectcolor({id: "", color: ""});
  };
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "prjname") setPrjName(value);
    if (name === "prjdescription") setPrjDescription(value);
    if (name === "color") setColor(value);
  };
  const getProjectDetails = () => {
    callApi("POST", "colorselect.php", "")
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log("colorresponse", res.data.responseJson.data);
          setState({ Boxdetailstable: res.data.responseJson.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const colorhandleClick = () => {
    let colorError = "";
    if (!color) {
      colorError = "Color is required";
    } else if (!/^[a-zA-Z\s]+$/.test(color)) {
      colorError = "Color must contain only alphabets and spaces";
    }
    if (colorError) {
      setColorError(colorError);
      return;
    }
    const req = {
      color: color,
    };
    callApi("POST", "coloradd.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Color Added Successfully!");
          getProjectDetails();
          setColorModal(false);
          setColor("");
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const logout = () => {
    showSuccess("Success!", "Logout Successfully!");
    navigate(`/`);
  };
  const coloradd = () => {
    navigate(`/coloradd`);
  };
  useEffect(() => {
    getProjectDetails();
    console.log("selectcolor:", selectcolor);
  }, [selectcolor.color]);
  const handleShow = (id: any) => {
    setcolorselectmodal(true);
    console.log("boxid", id);
    let req = {
      colorId: id,
    };

    callApi("POST", "color_idselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          console.log("color_idselect", response.responseJson.data);
          let res = response.responseJson.data[0];
          
          setselectcolor(res);
          console.log("setselectcolor", selectcolor);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const back = () => {
    navigate(`/dashboard`);
  };
  //   const [colorstate, setcolorState] = useState({ updatecolor:"" });
  const handleChangecolor = (event: any) => {
    console.log("type", event);
    setselectcolor({"color": event.target.value, "id": selectcolor.id});
    console.log(" event.target.value", event.target.value);
  };
  const updatecolor = (id: any) => {
    let req = {
      colorId: selectcolor.id,
      color_name: selectcolor.color,
    };
    console.log("req", req);
    
    callApi("POST", "color_idupdate.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Color Added Successfully!");
        getProjectDetails();
        setcolorselectmodal(false);
        setselectcolor({id: "", color: ""});
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });

  };
  const deletecolor = (id: any) => {
    let req = {
      colorId:id,
    };
    console.log("req", req);
    
    callApi("POST", "color_iddelete.php", req)
    .then((res) => res.data)
    .then((response) => {
      console.log("response", response);
      if (response["success"] === true) {
        showSuccess("Success!", "Color Deleted Successfully!");
        getProjectDetails();
        setselectcolor({id: "", color: ""});
      } else {
        showError("Failed", "Not Inserted !");
      }
    })
    .catch((err) => {
      console.log(err);
    });

  };
  const { Boxdetailstable } = state;

  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems =  Boxdetailstable.slice(itemOffset, endOffset);
  const pageCount = Math.ceil( Boxdetailstable.length / itemsPerPage);

  const handlePageClick = (event:any) => {
    const newOffset = (event.selected * itemsPerPage) % Boxdetailstable.length;
    setItemOffset(newOffset);
  };

  
  return (
    <main>
      <header>
        <div className="">
          <FontAwesomeIcon
            icon={faArrowLeft}
            className="icon backicon"
            onClick={back}
          />
          <h1 className="heading">Manage Color</h1>

          <Dropdown className="d-inline mx-2 ddrop">
            <Dropdown.Toggle id="autoclose-true" className="dbutton">
              <FontAwesomeIcon icon={faSignOutAlt} className="icon printicon" onClick={logout} />
            </Dropdown.Toggle>
          </Dropdown>
        </div>
      </header>

      <div className="dashcontiner">
        <Row className="scrollRow">
          <Col lg="1" md="1" className=""></Col>

          <Col lg="3" md="3" className="">
            <Button
              variant="outline-primary subbutton newbtn"
              onClick={handleColorShow}
            >
              <FontAwesomeIcon icon={faPlusCircle} className="icon" />
              Add Color
            </Button>
          </Col>
          <Col lg="6" md="6" className="flex"></Col>
        </Row>
        <Row style={{ padding: "0% 3%" }} className="scrollRow">
          <Col lg="1" md="1" className=""></Col>

          <Col lg="10" md="10" className="">
            <Table
              responsive="md lg"
              striped
              bordered
              hover
              style={{ width: "100% !important" }}
            >
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Color</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentItems &&
                  currentItems.length > 0 &&
                  currentItems.map((details: any, index: any) => {
                    return (
                      <>
                        <tr>
                          <td>{details.index}</td>
                          <td>{details.colorname}</td>
                          <td>
                            <Dropdown className="d-inline mx-2">
                              <Dropdown.Toggle
                                id="dropdown-autoclose-true"
                                className="actionbutton"
                              >
                                Action
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleShow(details.id)}
                                >
                                  Edit
                                </Dropdown.Item>

                                <Dropdown.Item
                                  onClick={() =>deletecolor(details.id)}
                                >
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      </>
                    );
                  })}
              </tbody>
            </Table>
            <ReactPaginate
      breakLabel="..."
      nextLabel="Next"
      onPageChange={handlePageClick}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel="Previous"
      renderOnZeroPageCount={null}
      containerClassName={'pagination'} 
      activeClassName={'selected'}
      disabledClassName={'disabled'}
      breakClassName={'break'}
    />
          </Col>
          <Col lg="1" md="1" className=""></Col>
        </Row>
      </div>

      <Modal show={colormodal} onHide={handleColorClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Color</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label"> Add Color </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Color"
                name="color"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChange}
                onFocus={() => setColorError("")}
              />
              <div className="text-danger">{colorError}</div>
            </div>

            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={colorhandleClick}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={colorselectmodal} onHide={handleselectColorClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Color</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="loginform">
            <div className="labeldiv">
              <label className="label">Color </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Color"
                name="color"
                minLength={5}
                maxLength={50}
                required
                onChange={handleChangecolor}
                onFocus={() => setColorError("")}
                value={selectcolor.color}
              />
              <div className="text-danger">{colorError}</div>
            </div>

            <div className="d-grid">
              <button
                className="btn btn-primary modalsubbutton"
                onClick={() => updatecolor(selectcolor.id)}
              >
                Submit
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default Coloradd;
