import React, { Component, FormEvent } from "react";
import { Card } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import "./product.css";

interface ProductState {
  width: number | string;
  height: number | string;
  depth: number | string;
  area: number | null;
  lines: number;
}

export default class Product extends Component<{}, ProductState> {
  state: ProductState = {
    width: "",
    height: "",
    depth: "",
    lines: 0,
    area: null,
  };

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { width, height, depth } = this.state;
    const area = Number(width) * Number(height);
    this.setState({ area });
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<
      ProductState,
      keyof ProductState
    >);
  };

  handleChangeone = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as unknown as Pick<
      ProductState,
      keyof ProductState
    >);
  };

  render() {
    const { area, depth, lines } = this.state;

    let horizontalLines = [];
    if (area && depth && lines) {
      const lineGap = Number(this.state.height) / lines;
      for (let i = 0; i < lines; i++) {
        const y = lineGap * (i + 1) - 1;
        horizontalLines.push(
          <line
            key={i}
            x1="0"
            y1={y}
            x2={this.state.width}
            y2={y}
            stroke="#000"
            strokeWidth="1"
          />
        );
      }
    }

    return (
      <main>
        
        <div className="">
          <div className="">
            <p className="text-center p-4">
              <span className="styled-heading">Square Box</span>
            </p>
            <Row>
              <Col lg="12" md="12" className="text-center bg-blue p-4 colcard">
                <Card className="card">
                  <form onSubmit={this.handleSubmit}>
                    <div className="divlabel">
                      <label className="label">Width :</label>
                      <input
                        name="width"
                        value={this.state.width}
                        onChange={this.handleChange}
                        type="number"
                        className="form-control"
                        placeholder="Enter Width"
                      />
                    </div>
                    <div className="divlabel">
                      <label className="label">Height :</label>
                      <input
                        name="height"
                        value={this.state.height}
                        onChange={this.handleChange}
                        type="number"
                        className="form-control"
                        placeholder="Enter Height"
                      />
                    </div>
                    <div className="divlabel">
                      <label className="label">Depth :</label>
                      <input
                        name="depth"
                        value={this.state.depth}
                        onChange={this.handleChange}
                        type="number"
                        className="form-control"
                        placeholder="Enter depth"
                      />
                    </div>

                    <div className="divlabel">
                      <label className="label">Lines :</label>
                      <select
                        name="lines"
                        className="form-select"
                        value={this.state.lines}
                        onChange={this.handleChangeone}
                      >
                        <option value="">Select Lines</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                      </select>
                    </div>
                    <div className="d-grid">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </Card>
              </Col>
            </Row>

            {area && depth && (
              <><div
                className="square-box"
                style={{ marginLeft: `${depth}px`, marginBottom: `${depth}px` }}
              >

                <svg
                  className="shape rectangle"
                  width={`${this.state.width}px`}
                  height={`${this.state.height}px`}
                >
                  <rect
                    width={`${this.state.width}px`}
                    height={`${this.state.height}px`}
                    fill="lightgray" />
                  <line
                    x1="0"
                    y1={Number(this.state.height) - Number(depth)}
                    x2="20"
                    y2={Number(this.state.height) - Number(depth) - 30}
                    stroke="#000"
                    strokeWidth="1" />
                  {horizontalLines}
                </svg>

              </div><div>
              <h3 className="dimension-info text-center">Width: {this.state.width}</h3>
                <h3 className="dimension-info text-center">Height: {this.state.height}</h3>
                </div></>
            )}
          </div>
        </div>
      </main>
    );
  }
}
