import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import * as XLSX from 'xlsx';

interface FormulatabledetailsItem {
    formulaupdatedboxheightcat: number;
    formulaupdatedboxwidthcat: number;
    formulaupdatedboxdepthcat: number;
    quantitySum: number;
    Label: string;
    Boxtype: string;
  }

type JsquareItem = {
    RightRk: any;
    LeftRk: any;
    formulaupdatedboxheightcat: any;
    formulaupdatedboxwidthcat: any;
    quantitySum: any;
    frametypecat: string;
    boxdoor_typecat: string;
    framedoortypecat: string;
    Boxtype: string;
    boxdoor_selectcat: string;
    framedoortypeselectcat: string;
    formulaupdatedboxdepthcat: any;
    Label: string;
    boxshelf_countcat: string;
    updatedframeheightcat: any;
    updatedframewidthcat: any;
    SftSum: any;
    SFTValue: any;
  };

const handleExportAllcutlist = (faciatandemdrawer:any,jsquare:any,formulatabledetails:any,formulaframedetails:any,glassList:any,faciammdrawer:any,faciainternaldrawer:any,doorsheet:any,IvorySheet:any, prjName:any) => {
  console.log("faciammdrawerfaciammdrawer===>",faciammdrawer);
  const workbook = XLSX.utils.book_new();
  const allData: any[][] = [];
  const addTableData = (
    data: any,
    headers: string[],
    category: string,
    color: string,
    Nostotalcount: any
  ) => {
    const categoryColorHeading = `${
      category.charAt(0).toUpperCase() + category.slice(1)
    } ${color.charAt(0).toUpperCase() + color.slice(1)} NOs ${Nostotalcount}`;
    allData.push([categoryColorHeading]);
    allData.push(headers);
    allData.push(...data);
    const totalQuantity = data.reduce(
      (sum: any, row: any[]) => sum + row[2],
      0
    );
    allData.push(["", "Total", totalQuantity, "", ""]);
    allData.push([]);
  };
  const addIvorySheetData = (IvorySheet:any) => {
    const headers = ["Height", "Width", "Qty", "Label", "Note"];
    const ivorySheetData = IvorySheet.Ivory_Sheet.map((details: { height: any; width: any; qty: any; Label: any; Note: any; }) => [
      details.height, details.width, details.qty, details.Label, details.Note
    ]);
    const shelfData = IvorySheet.shelf.map((details: { depth: any; width: any; qty: any; Label: any; Note: any; }) => [
      details.depth, details.width, details.qty, details.Label, details.Note
    ]);
    allData.push(["Ivory Sheet"]);
    allData.push(headers);
    allData.push(...ivorySheetData);
    allData.push(...shelfData);
    const totalQuantity = ivorySheetData.reduce((sum: any, row: any[]) => sum + row[2], 0) + shelfData.reduce((sum: any, row: any[]) => sum + row[2], 0);
    // allData.push(["", "Total", totalQuantity, "", ""]);
    allData.push([]);
  };
  const GlassTableData = (
    data: any,
    headers: string[],
    category: string,
    color: string,
    Nostotalcount: any
  ) => {
    const categoryColorHeading = `${
      category.charAt(0).toUpperCase() + category.slice(1)
    }`;
    allData.push([categoryColorHeading]);
    allData.push(headers);
    allData.push(...data);
    const totalQuantity = data.reduce(
      (sum: any, row: any[]) => sum + row[2],
      0
    );
    allData.push(["", "Total", totalQuantity, "", ""]);
    allData.push([]);
  };
  const FaciaMMdrawer = (
    data: any,
    headers: string[],
    category: string,
    color: string,
    Nostotalcount: any,
    // Facia100mmDrawertotalnos:any
  ) => {
    const categoryColorHeading = `${ 
      category.charAt(0).toUpperCase() + category.slice(1)
    } NOs ${Nostotalcount}`;
    allData.push([categoryColorHeading]);
    allData.push(headers);
    allData.push(...data);
    const totalQuantity = data.reduce(
      (sum: any, row: any[]) => sum + row[2],
      0
    );
    allData.push(["", "Total", totalQuantity, "", ""]);
    allData.push([]);
  };
  // Process J Square tables cutlist csv
  Object.keys(jsquare).forEach((category) => {
    Object.keys(jsquare[category]).forEach((color) => {
      const Nostotalcount = jsquare[category][color][0].jsquaretotalnos;
      const reducedData: JsquareItem[] = Object.values(
        jsquare[category][color].reduce(
          (acc: { [key: string]: JsquareItem }, item: JsquareItem) => {
            const key = `${item.formulaupdatedboxheightcat}-${item.formulaupdatedboxwidthcat}-${item.frametypecat}-${item.Boxtype}`;
            if (!acc[key]) {
              acc[key] = { ...item, quantitySum: 0 };
            }
            acc[key].quantitySum +=
              item.frametypecat === "EP"
                ? 1
                : item.boxdoor_selectcat === "3 drawer"
                ? 3
                : item.boxdoor_selectcat === "2 drawer"
                ? 2
                : item.boxdoor_selectcat === "3 drawer 2:1"
                ? 1
                : item.boxdoor_selectcat === "2 drawer 2:1"
                ? 1
                : item.boxdoor_selectcat ===
                                                      "Single Drawer"
                                                      ? 2
                                                      : item.boxdoor_selectcat ===
                                                        "Double Drawer"
                                                        ? 2
                : item.boxdoor_selectcat === "Double" ||
                  item.framedoortypeselectcat === "Double"
                ? 2
                : 1;
            return acc;
          },
          {}
        )
      );
      const data = reducedData.map((item) => [
        item.formulaupdatedboxheightcat,
        item.formulaupdatedboxwidthcat,
        item.quantitySum,
        item.frametypecat === "EP"
          ? "EP"
          : item.boxdoor_typecat === "Glass Door" ||
            item.framedoortypecat === "Glass Door"
          ? "Glass DR"
          : item.boxdoor_typecat === "Facia"
          ? "FA"
          : item.frametypecat === "1.25 lip" ||
            item.frametypecat === "1.25" ||
            item.frametypecat === "1x1" ||
            item.frametypecat === "EP"
          ? "DR"
          : item.boxdoor_typecat === "Sheet Door" ||
            item.framedoortypecat === "Sheet Door"
          ? "DR"
          : item.frametypecat === "ES"
          ? "ES"
          : "",
        item.Boxtype === "Regular Box"
          ? ""
          : item.Boxtype === "Open Box"
          ? "Open Box"
          : item.Boxtype === "Pa Box"
          ? "Pa Box"
          : "",
      ]);
      const headers = ["Height", "Width", "Quantity", "Label", "Note"];
      addTableData(data, headers, category, color, Nostotalcount);
    });
  });
  // Process Box tables cutlist csv
  Object.keys(formulatabledetails)
    .filter((category) => category !== "J Square")
    .forEach((category) => {
      Object.keys(formulatabledetails[category]).forEach((color) => {
        if (!color) return;
        let Nostotalcount;
        if (category === "depth") {
          Nostotalcount = formulatabledetails[category][color][0].totalnos;
        } else {
          Nostotalcount =
            formulatabledetails[category][color][0].updatetotalnos;
        }
        const BoxreducedData: JsquareItem[] = Object.values(
          formulatabledetails[category][color].reduce(
            (acc: { [key: string]: JsquareItem }, item: JsquareItem) => {
              const key = `${
                category === "depth"
                  ? Math.floor(item.formulaupdatedboxdepthcat)
                  : category === "shelf"
                  ? Math.floor(item.formulaupdatedboxdepthcat)
                  : Math.floor(item.formulaupdatedboxheightcat)
              }-${
                category === "depth"
                  ? Math.floor(item.formulaupdatedboxdepthcat)
                  : category === "shelf"
                  ? Math.floor(item.formulaupdatedboxwidthcat)
                  : Math.floor(item.formulaupdatedboxwidthcat)
              }-${item.Label}-${item.Boxtype}`;
              if (!acc[key]) {
                acc[key] = {
                  ...item,
                  quantitySum:
                    category === "depth"
                      ? 4
                      : category === "shelf"
                      ? item.boxshelf_countcat !== ""
                        ? parseInt(item.boxshelf_countcat)
                        : 1
                      : 1,
                };
              } else {
                acc[key].quantitySum +=
                  category === "depth"
                    ? 4
                    : category === "shelf"
                    ? item.boxshelf_countcat !== ""
                      ? parseInt(item.boxshelf_countcat)
                      : 1
                    : 1;
              }
              return acc;
            },
            {}
          )
        );
        const data = BoxreducedData.map((item) => [
          category === "depth"
            ? Math.floor(item.formulaupdatedboxdepthcat)
            : category === "shelf"
            ? Math.floor(item.formulaupdatedboxdepthcat)
            : Math.floor(item.formulaupdatedboxheightcat),
          category === "depth"
            ? ""
            : ""
            ? Math.floor(item.formulaupdatedboxwidthcat)
            : Math.floor(item.formulaupdatedboxwidthcat),
          item.quantitySum,
          item.Label,
          item.Boxtype === "Regular Box"
            ? ""
            : item.Boxtype === "Open Box"
            ? "Open Box"
            : item.Boxtype === "Pa Box"
            ? "Pa Box"
            : "",
        ]);
        const headers =
          category === "depth"
            ? ["Depth", "Label", "Quantity", "Note"]
            : category === "shelf"
            ? ["Depth", "Width", "Quantity", "Label", "Note"]
            : ["Height", "Width", "Quantity", "Label", "Note"];
        addTableData(data, headers, category, color, Nostotalcount);
      });
    });
  // / Process frame tables cutlist csv //
  Object.keys(formulaframedetails)
    .filter((category) => category !== "EP")
    .forEach((category) => {
      Object.keys(formulaframedetails[category]).forEach((color) => {
        if (!formulaframedetails[category]) {
          console.error(`'${category}' is undefined in formulaframedetails`);
          return;
        }
        if (!formulaframedetails[category][color]) {
          console.error(
            `'${color}' is undefined for category '${category}' in formulaframedetails`
          );
          return;
        }
        const Nostotalcount =
          formulaframedetails[category][color][0].updatetotalnos;
        const FramereducedData: JsquareItem[] = Object.values(
          formulaframedetails[category][color].reduce(
            (acc: { [key: string]: JsquareItem }, item: JsquareItem) => {
              const key = `${Math.floor(
                item.updatedframeheightcat
              )}-${Math.floor(item.updatedframewidthcat)}-${item.Label}`;
              if (!acc[key]) {
                acc[key] = { ...item, quantitySum: 0 };
              }
              acc[key].quantitySum +=
                item.boxdoor_selectcat === "Double" ? 2 : 1;
              return acc;
            },
            {}
          )
        );
        const data = FramereducedData.map((item) => [
          Math.floor(item.updatedframeheightcat),
          Math.floor(item.updatedframewidthcat),
          item.quantitySum,
          item.Label,
          "",
        ]);
        const headers = ["Height", "Width", "Quantity", "Label", "Note"];
        addTableData(data, headers, category, color, Nostotalcount);
      });
    });
  // Process GlassList tables
  Object.keys(glassList).forEach((category) => {
    Object.keys(glassList[category]).forEach((color) => {
      const GlassreducedData: JsquareItem[] = Object.values(
        glassList[category][color].reduce(
          (acc: { [key: string]: JsquareItem }, item: JsquareItem) => {
            const key = `${item.formulaupdatedboxheightcat}-${item.formulaupdatedboxwidthcat}-${item.frametypecat}-${item.SFTValue}`;
            if (!acc[key]) {
              acc[key] = { ...item, quantitySum: 0, SftSum: 0 }; // Initialize SftSum
            }
            acc[key].quantitySum +=
              item.frametypecat === "EP"
                ? 1
                : item.boxdoor_selectcat === "3 drawer"
                ? 3
                : item.boxdoor_selectcat === "2 drawer"
                ? 2
                : item.boxdoor_selectcat === "Double" ||
                  item.framedoortypeselectcat === "Double"
                ? 2
                : 1;
            acc[key].SftSum += parseFloat(item.SFTValue);
            return acc;
          },
          {}
        )
      );
      const Nostotalcount = glassList[category][color].reduce(
        (acc: number, item: JsquareItem) => acc + parseFloat(item.SFTValue),
        0
      );
      const data = GlassreducedData.map((item) => [
        item.formulaupdatedboxheightcat,
        item.formulaupdatedboxwidthcat,
        item.quantitySum,
        "",
        item.SftSum,
      ]);
      const headers = ["Height", "Width", "Quantity", "Label", "SFT"];
      GlassTableData(data, headers, "Glass List", color, Nostotalcount);
    });
  });
  // Process facia 1oomm tables cutlist csv
  Object.keys(faciammdrawer).forEach((category) => {
    Object.keys(faciammdrawer[category]).forEach((color) => {
      const Nostotalcount =
        faciammdrawer[category][color][0].totalFaciaDrawerquantity;
      const reducedData: JsquareItem[] = Object.values(
        faciammdrawer[category][color].reduce(
          (acc: { [key: string]: JsquareItem }, item: JsquareItem) => {
            const key = `${item.formulaupdatedboxdepthcat}-${item.formulaupdatedboxwidthcat}-${item.frametypecat}-${item.Boxtype}`;
            if (!acc[key]) {
              acc[key] = { ...item, quantitySum: 0 };
            }
            acc[key].quantitySum +=
              item.frametypecat === "EP"
                ? 1
                : item.boxdoor_selectcat === "3 drawer"
                ? 3
                : item.boxdoor_selectcat === "2 drawer"
                ? 2
                : item.boxdoor_selectcat === "3 drawer 2:1"
                ? 3
                : item.boxdoor_selectcat === "2 drawer 2:1"
                ? 2
                : item.boxdoor_selectcat === "Double" ||
                  item.framedoortypeselectcat === "Double"
                ? 2
                : 1;
            return acc;
          },
          {}
        )
      );
      const data = reducedData.map((item) => [
        item.formulaupdatedboxdepthcat,
        item.formulaupdatedboxwidthcat,
        item.quantitySum,
        item.frametypecat === "EP"
          ? "EP"
          : item.boxdoor_typecat === "Glass Door" ||
            item.framedoortypecat === "Glass Door"
          ? "Glass DR"
          : item.boxdoor_typecat === "Facia"
          ? "FA"
          : item.frametypecat === "1.25 lip" ||
            item.frametypecat === "1.25" ||
            item.frametypecat === "1x1" ||
            item.frametypecat === "EP"
          ? "DR"
          : item.boxdoor_typecat === "Sheet Door" ||
            item.framedoortypecat === "Sheet Door"
          ? "DR"
          : item.frametypecat === "ES"
          ? "ES"
          : "",
        item.Boxtype === "Regular Box"
          ? ""
          : item.Boxtype === "Open Box"
          ? "Open Box"
          : item.Boxtype === "Pa Box"
          ? "Pa Box"
          : "",
      ]);
      const headers = ["Depth", "Width", "Quantity", "Label", "Note"];
      FaciaMMdrawer(data, headers, category, color, Nostotalcount);
    });
  });

  allData.push([]);
// Process faciatandemdrawer tables cutlist csv
  const addDataToSheet = (data: any[], headers: any[], detailType: string) => {
    allData.push([{ v: detailType.charAt(0).toUpperCase() + detailType.slice(1), s: { fill: { fgColor: { rgb: "00FF00" } } } }]);
    allData.push(headers.map((header: any) => ({ v: header, s: { fill: { fgColor: { rgb: "00FF00" } } } })));
    allData.push(...data);
    const totalQuantity = data.reduce((sum: any, row: any[]) => sum + row[2], 0);
    allData.push(["", "", totalQuantity, "", ""]);
    allData.push([]);
  };
  let isFirstCategory = true;
  Object.keys(faciatandemdrawer).forEach((category) => {
    Object.keys(faciatandemdrawer[category]).forEach((color) => {
      ['bottom panel', 'back panel'].forEach((detailType) => {
        if (Array.isArray(faciatandemdrawer[category][color][detailType])) {
          const TandemData = Object.values(
            faciatandemdrawer[category][color][detailType].reduce((acc:any, item:any) => {
              const key = `${item.formulaupdatedboxdepthcat}-${item.formulaupdatedboxwidthcat}-${item.TandemSftValue}-${item.frametypecat}-${item.Boxtype}`;
              if (!acc[key]) {
                acc[key] = { ...item, quantitySum: 0 ,FaciaSft:0 };
              }
              acc[key].quantitySum += item.boxdoor_selectcat === "3 drawer"
                ? 3
                : item.boxdoor_selectcat === "3 drawer"
                ? 3
                : item.boxdoor_selectcat === "2 drawer"
                ? 2
                : item.boxdoor_selectcat === "3 drawer 2:1"
                ? 3
                : item.boxdoor_selectcat === "2 drawer 2:1"
                ? 2
                : item.boxdoor_selectcat === "Double" || item.framedoortypeselectcat === "Double"
                ? 2
                : 1;
            acc[key].FaciaSft += parseFloat(item.TandemSftValue);

              return acc;
            }, {})
          );

          const data = TandemData.map((item:any) => detailType === 'bottom panel' ? [
            Math.floor(item.formulaupdatedboxdepthcat),
            Math.floor(item.formulaupdatedboxwidthcat),
            item.quantitySum,
            "",
            item.FaciaSft
          ] : [
            Math.floor(item.formulaupdatedboxheightcat),
            Math.floor(item.formulaupdatedboxwidthcat),
            item.quantitySum,
            "",
            item.FaciaSft,

          ]);

          const headers = detailType === 'bottom panel'
            ? ["Depth", "Width", "Quantity", "Label", "SFT"]
            : ["Height", "Width", "Quantity", "Label", "SFT"];

            if (isFirstCategory) {
                // Add the main category heading "Tandem panels" with yellow background once
                allData.push([{ v: "Tandem panels", s: { fill: { fgColor: { rgb: "FFFF00" } } } }]);
                isFirstCategory = false;
              }

          addDataToSheet(data, headers, detailType);

        }
      });
    });
  });
// Process faciainternaldrawer tables cutlist csv
const addInDataToSheet = (data: any[], headers: any[], detailType: string, totalNos: number) => {
  allData.push([{ v: detailType.charAt(0).toUpperCase() + detailType.slice(1) + ` NOs ${totalNos}` }]);
    allData.push(headers.map((header: any) => ({ v: header,  })));
    allData.push(...data);
    const totalQuantity = data.reduce((sum: any, row: any[]) => sum + row[2], 0);
    allData.push(["", "", totalQuantity, "", ""]);
    allData.push([]);
  };
  Object.keys(faciainternaldrawer).forEach((category) => {
    allData.push([{ v: category, s: { fill: { fgColor: { rgb: "FFFF00" } } } }]);
    Object.keys(faciainternaldrawer[category]).forEach((color) => {
      ['Single lip ivory', '100mm drawer ivory', 'Internal Facia steel'].forEach((detailType) => {
        if (Array.isArray(faciainternaldrawer[category][color][detailType])) {
          const totalNos = faciainternaldrawer[category][color][detailType][0].FaciaInDrawertotalnos;
          const reducedData = Object.values(
            faciainternaldrawer[category][color][detailType].reduce((acc:any, item:any) => {
              const key = `${item.formulaupdatedboxdepthcat}-${item.formulaupdatedboxwidthcat}-${item.frametypecat}-${item.Boxtype}`;
              if (!acc[key]) {
                acc[key] = { ...item, quantitySum: 0 };
              }
              acc[key].quantitySum += item.frametypecat === "EP"
                ? 1
                : item.boxdoor_selectcat === "3 drawer"
                  ? 3
                  : item.boxdoor_selectcat === "2 drawer"
                    ? 2
                    : item.boxdoor_selectcat === "3 drawer 2:1"
                      ? 3
                      : item.boxdoor_selectcat === "2 drawer 2:1"
                        ? 2
                        : item.boxdoor_selectcat === "Single Drawer"
                          ? 1
                          : item.boxdoor_selectcat === "Double Drawer"
                            ? 2
                            : item.boxdoor_selectcat === "Double" || item.framedoortypeselectcat === "Double"
                              ? 2
                              : 1;
              return acc;
            }, {})
          );

          const data = reducedData.map((item: any) => detailType === 'Single lip ivory' ? [
            Math.floor(item.formulaupdatedboxheightcat),
            Math.floor(item.formulaupdatedboxwidthcat),
            item.quantitySum,
            item.frametypecat === "Regular Box"
            ? ""
            : item.Boxtype === "Open Box"
              ? "Open Box"
              : item.Boxtype === "Pa Box"
                ? "Pa Box"
                : "",
            item.Boxtype === "Regular Box"
              ? ""
              : item.Boxtype === "Open Box"
                ? "Open Box"
                : item.Boxtype === "Pa Box"
                  ? "Pa Box"
                  : ""
          ] : detailType === '100mm drawer ivory' ? [
            Math.floor(item.formulaupdatedboxdepthcat),
            Math.floor(item.formulaupdatedboxwidthcat),
            item.quantitySum,
            // item.frametypecat === "",
            item.frametypecat === "Regular Box"
              ? ""
              : item.Boxtype === "Open Box"
                ? "Open Box"
                : item.Boxtype === "Pa Box"
                  ? "Pa Box"
                  : "",
            item.Boxtype === "Regular Box"
              ? ""
              : item.Boxtype === "Open Box"
                ? "Open Box"
                : item.Boxtype === "Pa Box"
                  ? "Pa Box"
                  : ""
          ] :
            detailType === 'Internal Facia steel' ? [
              Math.floor(item.formulaupdatedboxheightcat),
              Math.floor(item.formulaupdatedboxwidthcat),
              item.quantitySum,
              item.frametypecat === "Regular Box"
              ? ""
              : item.Boxtype === "Open Box"
                ? "Open Box"
                : item.Boxtype === "Pa Box"
                  ? "Pa Box"
                  : "",
              item.Boxtype === "Regular Box"
                ? ""
                : item.Boxtype === "Open Box"
                  ? "Open Box"
                  : item.Boxtype === "Pa Box"
                    ? "Pa Box"
                    : ""
            ] : [""]);

          const headers = detailType === 'Single lip ivory'
            ? ["Height", "Width", "Quantity", "Label", "Note"]
            : detailType === '100mm drawer ivory'
              ? ["Depth", "Width", "Quantity", "Label", "Note"]
              : detailType === 'Internal Facia steel'
                ? ["Height", "Width", "Quantity", "Label", "Note"]
                : [""];

          addInDataToSheet(data, headers, detailType,totalNos);
        }
      });
    });
  });
   // Process GlassList tables
   Object.keys(doorsheet).forEach((category) => {
    Object.keys(doorsheet[category]).forEach((color) => {
      const DoorsheetreducedData: JsquareItem[] = Object.values(
        doorsheet[category][color].reduce(
          (acc: { [key: string]: JsquareItem }, item: JsquareItem) => {
            const key = `${item.formulaupdatedboxheightcat}-${item.formulaupdatedboxwidthcat}-${item.frametypecat}-${item.SFTValue}`;
            if (!acc[key]) {
              acc[key] = { ...item, quantitySum: 0, SftSum: 0 }; // Initialize SftSum
            }
            acc[key].quantitySum +=
              item.frametypecat === "EP"
                ? 1
                : item.boxdoor_selectcat === "3 drawer"
                ? 3
                : item.boxdoor_selectcat === "2 drawer"
                ? 2
                : item.boxdoor_selectcat === "Double" ||
                  item.framedoortypeselectcat === "Double"
                ? 2
                : 1;
            acc[key].SftSum += parseFloat(item.SFTValue);
            return acc;
          },
          {}
        )
      );
      const Nostotalcount = doorsheet[category][color].reduce(
        (acc: number, item: JsquareItem) => acc + parseFloat(item.SFTValue),
        0
      );
      const data = DoorsheetreducedData.map((item) => [
        item.formulaupdatedboxheightcat,
        item.formulaupdatedboxwidthcat,
        item.quantitySum,
        item.frametypecat === "EP"
                                          ? "EP"
                                          : item.boxdoor_typecat ===
                                            "Glass Door" ||
                                            item.framedoortypecat ===
                                            "Glass Door"
                                            ? "Glass DR"
                                            : item.boxdoor_typecat === "Facia"
                                              ? "FA"
                                              : item.frametypecat === "1.25 lip" ||
                                                item.frametypecat === "1.25" ||
                                                item.frametypecat === "1x1" ||
                                                item.frametypecat === "EP"
                                                ? "DR"
                                                : item.boxdoor_typecat ===
                                                  "Sheet Door" ||
                                                  item.framedoortypecat ===
                                                  "Sheet Door"
                                                  ? "DR"
                                                  : item.frametypecat === "ES"
                                                    ? "ES"
                                                    : "",
                                                    item.LeftRk,
                                                    item.RightRk,
                                                    ""
      ]);
      const headers = ["Height", "Width", "Quantity", "Label","LeftRemark","RightRemark","Note"];
      GlassTableData(data, headers, "Door Sheet", color, Nostotalcount);
    });
  });
  addIvorySheetData(IvorySheet);
  const worksheet = XLSX.utils.aoa_to_sheet(allData);
  XLSX.utils.book_append_sheet(workbook, worksheet, "All Cutlist");
  XLSX.writeFile(workbook, `${prjName}-All Cutlist.xlsx`);
};

export default handleExportAllcutlist;
