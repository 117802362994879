import {
  faArrowLeft,
  faDownload,
  faPlusCircle,
  faTimes,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "./prj_details.css";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import { callApi } from "../../utills/api";
import { showError, showSuccess } from "../../utills/sweetAlert";
import { useNavigate } from "react-router-dom";
import "./prj_details.css";
import {
  bkcolor,
  single_lip,
  depth,
  onecrossone,
  formulajsquare,
  formulaglasslist,
  totaldepth,
  totalhightwidth,
  framehightwidth,
  jsquarehightwidth,
  shelf,
  GlassListhightwidth,
  FaciaDrawerhightwidth,
  FormulaFaciaDrawer,
  FormulaFaciaTendamDrawer,
  FaciatandemDrawerhightwidth,
  FaciaInternalDrawerhightwidth,
  FormulaFaciaInternalDrawer,
  formuladoorsheet,
  doorsheethightwidth,
} from "./TableFormula";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Hourglass } from "react-loader-spinner";
import handleExportAllcutlist from "./cutlistall";
interface StateType {
  projectId: string;
  modelshow: boolean;
  framemodelshow: boolean;
  prjName: string;
  prjDesc: string;
  colors: { id: string; colorname: string }[];
  category: { id: string; category: string }[];
  lines: number;
  [key: string]: any;
}
interface FormulatabledetailsItem {
  formulaupdatedboxheightcat: number;
  formulaupdatedboxwidthcat: number;
  formulaupdatedboxdepthcat: number;
  TandemSftValue: number;
  quantitySum: number;
  Label: string;
  Boxtype: string;
}

type CheckboxStates = {
  [key: string]: boolean;
};
const Projectdetails = () => {
  const navigate = useNavigate();
  const [Remarkstate, setRemarkState] = useState<StateType>({
    projectId: "",
    modelshow: false,
    framemodelshow: false,
    prjName: "",
    prjDesc: "",
    colors: [],
    category: [],
    lines: 0,
  });

  const [RightRemarkstate, setRightRemarkState] = useState<StateType>({
    projectId: "",
    modelshow: false,
    framemodelshow: false,
    prjName: "",
    prjDesc: "",
    colors: [],
    category: [],
    lines: 0,
  });

  const [state, setState] = useState({
    loader: false,
    projectId: "",
    modelshow: false,
    framemodelshow: false,
    prjName: "",
    prjDesc: "",
    colors: [
      {
        id: "",
        colorname: "",
      },
    ],
    categery: [
      {
        id: "",
        categery: "",
      },
    ],
    lines: 0,
    area: 0,
    remarks: {},
    boxdoortype: "Sheet Door",
    boxdoorselect: "Double",
    boxdoorposition: "",
    FaciaValue: "1",
    boxfaciadrawerselect: "2 drawer",
    shelf: "Regular self",
    shelfcolor: "Ivory",
    selfEntercolor: "",
    shelfcount: "1",
    boxg: "",
    boxgvalue: false,
    asvalue: false,
    FaciaInnodoor: false,
    boxgposition: "",
    boxintdummyposition: "",
    boxfixedposition: "",
    intdummy: "",
    intdummyvalue: false,
    boxfixed: false,
    bkcolor: "Ivory",
    bkentercolor: "",
    depthcolor: "Ivory",
    singlelipcolor: "Black",
    jsquarecolor: "Black",
    JsquareEntercolor: "",
    categeryselect: "",
    framecategeryselect: "",
    boxheight: "600",
    boxwidth: "600",
    boxdepth: "550",
    BoxCount: "1",
    boxtypecategery: "",
    BoxType: "Regular Box",
    depthentercolor: "",
    singlelipentercolor: "",
    BoxRemarks: "Remark",
    RightBoxRemarks: "Remark",
    ThirdRemarks: "Remark",
    FixedHeight: "-51",
    FixedWidth: "",
    //framevalue//
    type: "1.25",
    frametypecolor: "Black",
    FrameEntercolor: "",
    framedoortype: "Sheet Door",
    framedoorselect: "Double",
    frameg: "",
    framegvalue: false,
    frameintdummy: "",
    frameintdummyvalue: false,
    frameheight: "900",
    framewidth: "900",
    framedoorposition: "",
    frameboxgposition: "",
    FrameCount: "1",
    Frametypecategery: "",
    FrameRemarks: "Remark",
    RightFrameRemarks: "Remark",
    FrameFixedHeight: "-51",
    FrameFixedWidth: "",
    //error//
    heightError: "",
    BoxcountError: "",
    FramecountError: "",
    BoxTypecatError: "",
    frameintdummyposition: "",
    framefixed: false,
    framefixedposition: "",
    framedoorcolor: "Black",
    FrameJsclrEnter: "",
    widthError: "",
    depthError: "",
    frameheightError: "",
    framewidthError: "",
    boxId: "",
    frameId: "",
    //updateboxvalues//
    updateboxdoortype: "Sheet Door",
    updateboxdoorselect: "Double",
    updateboxdoorposition: "",
    updateshelf: "Regular self",
    updateshelfcount: "1",
    updateshelfcolor: "Ivory",
    updateSelfEnterclr: "",
    updateboxg: "30",
    updateboxgvalue: false,
    Upasvalue: false,
    UpdateFaciaInnodoor: false,
    updateboxgposition: "",
    updateintdummy: "25",
    updateintdummyvalue: false,
    updatebkcolor: "Ivory",
    updatedepthcolor: "Ivory",
    updatesinglelipcolor: "Black",
    updatejsquarecolor: "",
    UpdateJsquareEntercolor: "",
    updateboxcategery: "",
    updateCatstatus: "",
    updateboxheight: "600",
    updateboxwidth: "600",
    updateboxdepth: "550",
    updateboxintdummyposition: "",
    updateboxfixedposition: "",
    UpdateFixedHeight: "-51",
    UpdateFixedWidth: "",
    updateboxfixed: false,
    Updateboxtypecategery: "",
    UpdateBoxType: "",
    updateJsquareclrStatus: "",
    updateSelfClrStatus: "",
    updateBkclrenter: "",
    updateBkClrStatus: "",
    updateDepthclrenter: "",
    updateDepthClrstatus: "",
    updateSingleclrenter: "",
    updateSingleclrSts: "",
    UpdateBoxRemarks: "Remark",
    UpRightRemark: "",
    UpThirdRemark: "",
    UpdateFaciaValue: "1",
    //updateframemodalvalue//
    updatetype: "1.25",
    updateframedoortype: "Sheet Door",
    updateframedoorselect: "Double",
    updateframeg: "",
    updateframegvalue: false,
    updateframeintdummy: "",
    updateframeintdummyvalue: false,
    updateframecategery: "",
    UpdateFrametypecategery: "",
    updateframeheight: "900",
    updateframewidth: "900",
    staticboxdepth: "0",
    updateframetypecolor: "Black",
    UpdateFrameEntercolor: "",
    updateframedoorcolor: "Black",
    updateframedoorposition: "left",
    updateframegposition: "top",
    updateframeintdummyposition: "left",
    updateframefixed: false,
    updateframefixedposition: "left",
    updateFrameFixedWidth: "",
    UpdateFrameFixedHeight: "-51",
    updateFrameCatstatus: "",
    UpFrameclrSts: "",
    UpdateFrameJsEntercolor: "",
    UpFrameJsclrSts: "",
    UpdateFrameRemarks: "Remark",
    UpdateRightFrameRemarks: "Remark",
  });
  const [IvorySheet, setIvorysheet] = useState<any>({});
  const [formulatabledetails, setFormulatabledetails] = useState<any>({});
  const [formulaframedetails, setFormulaframedetails] = useState<any>({});
  const [updatedformula, setUpdatedformula] = useState<any>({});
  const [categoryList, setCategoryList] = useState<any>([""]);
  const [jsquare, setjsquare] = useState<any>({});
  const [glassList, setGlassList] = useState<any>({});
  const [doorsheet, setDoorsheet] = useState<any>({});
  const [faciammdrawer, setFaciammdrawer] = useState<any>({});
  const [faciatandemdrawer, setFaciaTendamdrawer] = useState<any>({});
  const [faciainternaldrawer, setFaciaInternaldrawer] = useState<any>({});
  const [dragDetails, setDragDetails] = useState<any>({
    dragStart: "",
    dragOver: "",
  });
  const [categoryImages, setCategoryImages] = useState<any>({});
  const [updatedCategoryImages, setUpdatedCategoryImages] = useState<any>({});
  const handleTableDragStart = (index: number, value: any) => {
    setDragDetails({ dragStart: { ...value, index } });
  };
  const handleTableDragOver = (index: number, value: any) => {
    setDragDetails((prevState: any) => ({
      ...prevState,
      dragOver: { ...value, index },
    }));
  };
  const handleTableDragEnd = (category: string) => {
    const items = [...updatedCategoryImages[category].diagramimages];
    const { dragStart, dragOver } = dragDetails;
    if (dragOver && dragStart.index !== dragOver.index) {
      items.splice(dragStart.index, 1);
      items.splice(dragOver.index, 0, dragStart);
      const finalRearranged = items.map((item, index) => ({
        ...item,
        position: index + 1,
      }));
      setUpdatedCategoryImages((prevState: any) => ({
        ...prevState,
        [category]: {
          ...prevState[category],
          diagramimages: finalRearranged,
        },
      }));
      reOrderBox(finalRearranged);
    }
  };
  const reOrderBox = (finalRearranged: any) => {
    let req = {
      box_details: finalRearranged,
    };
    callApi("POST", "reorder_box.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success === true) {
          Categeryimagesselect();
          formulatableselect();
          TableFormulaselect();
          formulaframeselect();
          formulajsquareselect();
          FormulaGlasslistSelect();
          FormulaDoorSheetSelect();
          Formula100mmDrawerSelect();
          FormulaTandemDrawerSelect();
          FormulaInternalDrawerSelect();
          FormulaIvorySheetSelect();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getProjectDetails();
    Categeryimagesselect();
    formulatableselect();
    TableFormulaselect();
    formulaframeselect();
    formulajsquareselect();
    FormulaGlasslistSelect();
    FormulaDoorSheetSelect();
    Formula100mmDrawerSelect();
    FormulaTandemDrawerSelect();
    FormulaInternalDrawerSelect();
    FormulaIvorySheetSelect();
  }, []);
  const anyTableDetailsHasData = Object.values(formulatabledetails).some(
    (array: any) =>
      Object.values(array).some((innerArray: any) => innerArray.length > 0)
  );
  const showSections =
    Object.keys(jsquare).length > 0 ||
    anyTableDetailsHasData ||
    Object.keys(formulaframedetails).length > 0;
  //2d height width caluclation//
  const FormulaHW = () => {
    if (Object.keys(categoryImages).length > 0) {
      const updatedImages = { ...categoryImages };
      Object.keys(categoryImages).forEach((category) => {
        updatedImages[category].diagramimages = categoryImages[
          category
        ].diagramimages.map((details: any) => {
          const height = parseInt(details.boxheightcat) + 400;
          const width = parseInt(details.boxwidthcat) + 400;
          const frameheight = parseInt(details.frameheightcat) + 400;
          const framewidth = parseInt(details.framewidthcat) + 400;
          const higherDimension = Math.max(height, width);
          const x = Math.max(Math.max(higherDimension / 400) + 1);
          const framehigherDimension = Math.max(frameheight, framewidth);
          const framex = Math.max(Math.max(framehigherDimension / 400) + 1);
          const dynamicHeightValue = height / x;
          const dynamicWidthValue = width / x;
          const framedynamicHeightValue = frameheight / framex;
          const framedynamicWidthValue = framewidth / framex;
          return {
            ...details,
            updatedboxheightcat: dynamicHeightValue,
            updatedboxwidthcat: dynamicWidthValue,
            updatedframeheightcat: framedynamicHeightValue,
            updatedframewidthcat: framedynamicWidthValue,
          };
        });
      });
      setUpdatedCategoryImages(updatedImages);
    }
  };
  //cutlist api select //
  const TableFormulaselect = () => {
    setFormulatabledetails({});
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "formula_boxtableselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setFormulatabledetails(response.responseJson.resultUser);
          FormulatableHW();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const formulaframeselect = () => {
    setFormulaframedetails({});
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "formula_frameselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setFormulaframedetails(response.responseJson.resultUser);
          FormulaframeHW();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const formulajsquareselect = () => {
    setjsquare({});
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "formula_jsquare.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setjsquare(response.responseJson.resultUser);
          FormulajsquareHW();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
      console.log("setjsquare--->1111",jsquare);
      
  };
  const FormulaGlasslistSelect = () => {
    setGlassList({});
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "formula_glasslist.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setGlassList(response.responseJson.resultUser);
          FormulaGlasslistHW();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const FormulaDoorSheetSelect = () => {
    setDoorsheet({});
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "door_sheet.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setDoorsheet(response.responseJson.resultUser);
          FormulaDoorsheetHW();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const Formula100mmDrawerSelect = () => {
    setFaciammdrawer({});
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "formula_faciadrawer.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setFaciammdrawer(response.responseJson.resultUser);
          FormulaFaciammdrawerHW();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const FormulaTandemDrawerSelect = () => {
    setFaciaTendamdrawer({});
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "formula_faciaTendamdrawer.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setFaciaTendamdrawer(response.responseJson.resultUser);
          FormulaFaciaTendamdrawerHW();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const FormulaInternalDrawerSelect = () => {
    setFaciaInternaldrawer({});
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "formula_faciaInternalDr.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setFaciaInternaldrawer(response.responseJson.resultUser);
          FormulaFaciaInternaldrawerHW();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const asyncFunctions = async () => {
      await FormulatableHW();
      await FormulaframeHW();
      await FormulajsquareHW();
      await FormulaGlasslistHW();
      await FormulaFaciammdrawerHW();
      await FormulaFaciaTendamdrawerHW();
      await FormulaFaciaInternaldrawerHW();
      await formulatableupdate();
    };
    asyncFunctions();
  }, [formulatabledetails, formulaframedetails, jsquare, glassList]);
  const formulatableupdate = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const box: any[] = [];
    for (const category in jsquare) {
      for (const color in jsquare[category]) {
        jsquare[category][color].forEach((item: any) => {
          const projectId = searchParams.get("id");
          if (item.id !== undefined && item.id !== null) {
            const data = {
              project_id: projectId,
              box_id: item.id,
              updated_height: item.formulaupdatedboxheightcat,
              updated_width: item.formulaupdatedboxwidthcat,
              quantity: item.quantity,
            };
            console.log("data---->0000",data);
            box.push(data);
          }
        });
      }
    }
    // for (const category in glassList) {
    //   for (const color in glassList[category]) {
    //     glassList[category][color].forEach((item: any) => {
    //       const projectId = searchParams.get("id");
    //       if (item.id !== undefined && item.id !== null) {
    //         const data = {
    //           project_id: projectId,
    //           box_id: item.id,
    //           updated_height: item.formulaupdatedboxheightcat,
    //           updated_width: item.formulaupdatedboxwidthcat,
    //           quantity: item.quantity,
    //           sft: item.NewSftValue,
    //         };
    //         console.log("glassdoordata ---->1111 ",data);
            
    //         box.push(data);
    //       }
    //     });
    //   }
    // }
    const frame: any[] = [];
    for (const category in jsquare) {
      for (const color in jsquare[category]) {
        jsquare[category][color].forEach((item: any) => {
          const projectId = searchParams.get("id");
          if (item.idcat !== undefined && item.idcat !== null) {
            const data = {
              project_id: projectId,
              frame_id: item.idcat,
              updated_height: item.formulaupdatedboxheightcat,
              updated_width: item.formulaupdatedboxwidthcat,
              quantity: item.quantity,
            };
            frame.push(data);
          }
        });
      }
    }
    for (const category in glassList) {
      for (const color in glassList[category]) {
        glassList[category][color].forEach((item: any) => {
          const projectId = searchParams.get("id");
          if (item.idcat !== undefined && item.idcat !== null) {
            const data = {
              project_id: projectId,
              frame_id: item.idcat,
              updated_height: item.formulaupdatedboxheightcat,
              updated_width: item.formulaupdatedboxwidthcat,
              quantity: item.quantity,
            };
            frame.push(data);
          }
        });
      }
    }
    const req = {
      box,
      frame,
    };
    callApi("POST", "jsquare_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.responseCode === 200) {
          Categeryimagesselect();
          FormulaHW();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  //store api total//
  const [jsquareTotalNosState, setJsquareTotalNosState] = useState("");
  const [jsquareTotalState, setJsquareTotalState] = useState("");
  const [GlasslistTotalNosState, setGlasslistTotalNosState] = useState("");
  const [TandembottomTotalNosState, setTandembottomTotalNosState] =
    useState("");
  const [TandembackTotalNosState, setTandembackTotalNosState] = useState("");
  const [singleTotalNosState, setSingleTotalNosState] = useState("");
  const [ShelfTotalNosState, setShelfTotalNosState] = useState("");
  const [DepthTotalNosState, setDepthTotalNosState] = useState("");
  const [BkTotalNosState, setBkTotalNosState] = useState("");
  const [OnecrossOneTotalNosState, setOnecrossOneTotalNosState] = useState("");
  const [OnepointtwoTotalNosState, setonepointtwoTotalNosState] = useState("");
  const [OnepointtwolipTotalNosState, setonepointtwolipTotalNosState] =
    useState("");
  const [mmTotalNosState, setmmTotalNosState] = useState("");
  //formula using table//
  const FormulatableHW = () => {
    let bktotalNos = 0;
    let depthtotalNos = 0;
    let singleliptotalNos = 0;
    let ShelftotalNos = 0;
    if (Object.keys(formulatabledetails).length > 0) {
      let categoryLists: any[] = [];
      Object.keys(formulatabledetails).forEach((category) => {
        categoryLists.push(category);
        setCategoryList(categoryLists);
      });
      for (const category in formulatabledetails) {
        const colors = formulatabledetails[category];
        for (const color in colors) {
          const detailsArray = colors[color];
          let filteredArray: any;
          detailsArray.forEach((details: any, index: number) => {
            if (category === "single lip") {
              colors[color][index] = single_lip(details, category);
            } else if (category === "depth") {
              colors[color][index] = depth(details, category);
            } else if (category === "shelf") {
              colors[color][index] = shelf(details, category);
            } else if (category === "bk") {
              colors[color][index] = bkcolor(details, category);
            }
            filteredArray = detailsArray.filter((obj: any) => {
              if (category === "shelf") {
                return obj.boxdoor_typecat !== "Facia";
              } else {
                return obj;
              }
            });
          });
          // Calculate the sum of all boxheightcat values
          const totalBoxHeight = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.heightquantity),
            0
          );
          const totalBoxwidth = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.widthquantity),
            0
          );
          const totalBoxDepth = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.depthquantity),
            0
          );
          const totalquantity = filteredArray.reduce((sum: any, obj: any) => {
            return sum + parseInt(obj.quantity);
          }, 0);
          detailsArray.forEach((obj: any) => {
            obj.totalBoxHeight = totalBoxHeight;
            obj.totalBoxwidth = totalBoxwidth;
            obj.totalBoxDepth = totalBoxDepth;
            obj.totalquantity = totalquantity;
          });
          detailsArray.forEach((details: any, index: number) => {
            if (category === "depth") {
              const updatedDetails = totaldepth(details, category);
              colors[color][index] = updatedDetails;
              if (index === 0) {
                depthtotalNos += updatedDetails.totalnos;
              }
            } else {
              const updatedDetails = totalhightwidth(details, category);
              colors[color][index] = updatedDetails;
              if (category === "single lip") {
                if (index === 0) {
                  singleliptotalNos += updatedDetails.updatetotalnos;
                }
              }
              if (category === "bk") {
                if (index === 0) {
                  bktotalNos += updatedDetails.updatetotalnos;
                }
              }
              if (category === "shelf") {
                if (index === 0) {
                  ShelftotalNos += updatedDetails.updatetotalnos;
                }
              }
            }
          });
        }
      }
      setSingleTotalNosState(singleliptotalNos.toString());
      setShelfTotalNosState(ShelftotalNos.toString());
      setDepthTotalNosState(depthtotalNos.toString());
      setBkTotalNosState(bktotalNos.toString());
      setUpdatedformula(formulatabledetails);
    }
  };
  const FormulaframeHW = () => {
    let onecrossoneNos = 0;
    let oneNos = 0;
    let lipNos = 0;
    if (Object.keys(formulaframedetails).length > 0) {
      let categoryLists: any[] = [];
      Object.keys(formulaframedetails).forEach((category) => {
        categoryLists.push(category);
        setCategoryList(categoryLists);
      });
      for (const category in formulaframedetails) {
        const colors = formulaframedetails[category];
        for (const color in colors) {
          const detailsArray = colors[color];
          detailsArray.forEach((details: any, index: number) => {
            colors[color][index] = onecrossone(details, category);
          });
          const totalframeHeight = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.heightquantity),
            0
          );
          const totalframewidth = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.widthquantity),
            0
          );
          const totalframequantity = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.quantity),
            0
          );
          detailsArray.forEach((obj: any) => {
            obj.totalframeHeight = totalframeHeight;
            obj.totalframewidth = totalframewidth;
            obj.totalframequantity = totalframequantity;
          });
          detailsArray.forEach((details: any, index: number) => {
            const updatedDetails = framehightwidth(details, category);
            colors[color][index] = updatedDetails;
            if (category === "1 x 1") {
              if (index === 0) {
                onecrossoneNos += updatedDetails.updatetotalnos;
              }
            }
            if (category === "1.25") {
              if (index === 0) {
                oneNos += updatedDetails.updatetotalnos;
              }
            }
            if (category === "1.25 lip") {
              if (index === 0) {
                lipNos += updatedDetails.updatetotalnos;
              }
            }
          });
        }
      }
      setUpdatedformula(formulaframedetails);
      setOnecrossOneTotalNosState(onecrossoneNos.toString());
      setonepointtwoTotalNosState(oneNos.toString());
      setonepointtwolipTotalNosState(lipNos.toString());
    }
  };
  const FormulajsquareHW = () => {
    let jsNostotalCount = 0;
    let jscutlisttotalCount = 0;
    if (Object.keys(jsquare).length > 0) {
      for (const category in jsquare) {
        const colors = jsquare[category];
        for (const color in colors) {
          let detailsArray = colors[color];
          if (!Array.isArray(detailsArray)) {
            detailsArray = [detailsArray];
          }
          detailsArray.forEach((details: any, index: number) => {
            colors[color][index] = formulajsquare(details, category);
          });
          const totaljsquareHeight = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.heightquantity),
            0
          );
          const totaljsquarewidth = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.widthquantity),
            0
          );
          const totalsquarequantity = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.quantity),
            0
          );
          detailsArray.forEach((obj: any) => {
            obj.totaljsquareHeight = totaljsquareHeight;
            obj.totaljsquarewidth = totaljsquarewidth;
            obj.totalsquarequantity = totalsquarequantity;
          });
          detailsArray.forEach((details: any, index: number) => {
            const updatedDetails = jsquarehightwidth(details, category);
            colors[color][index] = updatedDetails;
            if (index == 0) {
              jsNostotalCount += parseInt(updatedDetails.jsquaretotalnos);
              setJsquareTotalNosState(jsNostotalCount.toString());
            }
            if (index == 0) {
              jscutlisttotalCount += parseInt(totalsquarequantity);
              setJsquareTotalState(jscutlisttotalCount.toString());
            }
          });
        }
      }
    }
  };
  useEffect(() => {
    FormulaGlasslistHW();
  }, [glassList]);
  const FormulaGlasslistHW = () => {
    if (Object.keys(glassList).length > 0) {
      for (const category in glassList) {
        const colors = glassList[category];
        for (const color in colors) {
          let detailsArray = colors[color];
          if (!Array.isArray(detailsArray)) {
            detailsArray = [detailsArray];
          }
          detailsArray.forEach((details: any, index: number) => {
            colors[color][index] = formulaglasslist(details, category);
          });
          const totalGlasslistHeight = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.heightquantity),
            0
          );
          const totalGlasslistwidth = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.widthquantity),
            0
          );
          const totalGlasslistquantity = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.quantity),
            0
          );

          const totalGlasslistSFTquantity = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseFloat(obj.SFTValue),
            0
          );
          setGlasslistTotalNosState(totalGlasslistSFTquantity);
          detailsArray.forEach((obj: any) => {
            obj.totalGlasslistHeight = totalGlasslistHeight;
            obj.totalGlasslistwidth = totalGlasslistwidth;
            obj.totalGlasslistquantity = totalGlasslistquantity;
            obj.totalGlasslistSFTquantity = totalGlasslistSFTquantity;
          });

          detailsArray.forEach((details: any, index: number) => {
            colors[color][index] = GlassListhightwidth(details, category);
          });
        }
      }
    }
  };
  useEffect(() => {
    FormulaDoorsheetHW();
  }, [doorsheet]);
  const FormulaDoorsheetHW = () => {
    if (Object.keys(doorsheet).length > 0) {
      for (const category in doorsheet) {
        const colors = doorsheet[category];
        for (const color in colors) {
          let detailsArray = colors[color];
          if (!Array.isArray(detailsArray)) {
            detailsArray = [detailsArray];
          }
          detailsArray.forEach((details: any, index: number) => {
            colors[color][index] = formuladoorsheet(details, category);
          });
          const totaldoorsheetHeight = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.heightquantity),
            0
          );
          const totaldoorsheetwidth = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.widthquantity),
            0
          );
          const totaldoorsheetquantity = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.quantity),
            0
          );
          detailsArray.forEach((obj: any) => {
            obj.totaldoorsheetHeight = totaldoorsheetHeight;
            obj.totaldoorsheetwidth = totaldoorsheetwidth;
            obj.totaldoorsheetquantity = totaldoorsheetquantity;
          });
          detailsArray.forEach((details: any, index: number) => {
            colors[color][index] = doorsheethightwidth(details, category);
          });
        }
      }
    }
  };
  //facia 100 mm drawer //
  useEffect(() => {
    FormulaFaciammdrawerHW();
  }, [faciammdrawer]);
  const FormulaFaciammdrawerHW = () => {
    let mmdrawertotalNos = 0;
    if (Object.keys(faciammdrawer).length > 0) {
      for (const category in faciammdrawer) {
        const colors = faciammdrawer[category];
        for (const color in colors) {
          let detailsArray = colors[color];
          if (!Array.isArray(detailsArray)) {
            detailsArray = [detailsArray];
          }
          detailsArray.forEach((details: any, index: number) => {
            colors[color][index] = FormulaFaciaDrawer(details, category);
            if (index === 0) {
              mmdrawertotalNos += parseInt(details.Facia100mmDrawertotalnos);
            }
          });
          const totalFaciaDrawerHeight = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.heightquantity),
            0
          );
          const totalFaciaDrawerwidth = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.widthquantity),
            0
          );
          const totalFaciaDrawerquantity = detailsArray.reduce(
            (sum: any, obj: any) => sum + parseInt(obj.quantity),
            0
          );

          detailsArray.forEach((obj: any) => {
            obj.totalFaciaDrawerHeight = totalFaciaDrawerHeight;
            obj.totalFaciaDrawerwidth = totalFaciaDrawerwidth;
            obj.totalFaciaDrawerquantity = totalFaciaDrawerquantity;
          });

          detailsArray.forEach((details: any, index: number) => {
            colors[color][index] = FaciaDrawerhightwidth(details, category);
          });
        }
      }
      setmmTotalNosState(mmdrawertotalNos.toString());
    }
  };
  //facia tendem drawer //
  useEffect(() => {
    FormulaFaciaTendamdrawerHW();
  }, [faciatandemdrawer]);
  const FormulaFaciaTendamdrawerHW = () => {
    if (Object.keys(faciatandemdrawer).length > 0) {
      for (const category in faciatandemdrawer) {
        const colors = faciatandemdrawer[category];
        for (const color in colors) {
          const detailTypes = ["bottom panel", "back panel"];
          detailTypes.forEach((detailType) => {
            if (colors[color][detailType]) {
              let detailsArray = colors[color][detailType];
              if (!Array.isArray(detailsArray)) {
                detailsArray = [detailsArray];
              }
              detailsArray.forEach((details: any, index: number) => {
                colors[color][detailType]![index] = FormulaFaciaTendamDrawer(
                  details,
                  category,
                  detailType
                );
              });
              const totalFaciaTandemDrawerHeight = detailsArray.reduce(
                (sum: any, obj: any) =>
                  sum + (parseInt(obj.heightquantity!) || 0),
                0
              );
              const totalFaciaTandemDrawerWidth = detailsArray.reduce(
                (sum: any, obj: any) =>
                  sum + (parseInt(obj.widthquantity!) || 0),
                0
              );
              const totalFaciaTandemDrawerQuantity = detailsArray.reduce(
                (sum: any, obj: any) => sum + (parseInt(obj.quantity!) || 0),
                0
              );
              const totalFaciaSft = detailsArray.reduce(
                (sum: any, obj: any) => sum + parseFloat(obj.TandemSftValue),
                0
              );
              if (detailType == "bottom panel") {
                setTandembottomTotalNosState(totalFaciaSft);
              } else {
                setTandembackTotalNosState(totalFaciaSft);
              }
              detailsArray.forEach((obj: any) => {
                obj.totalFaciaTandemDrawerHeight = totalFaciaTandemDrawerHeight;
                obj.totalFaciaTandemDrawerWidth = totalFaciaTandemDrawerWidth;
                obj.totalFaciaTandemDrawerQuantity =
                  totalFaciaTandemDrawerQuantity;
                obj.totalFaciaSft = totalFaciaSft;
              });
              detailsArray.forEach((details: any, index: number) => {
                colors[color][detailType]![index] = FaciatandemDrawerhightwidth(
                  details,
                  category
                );
              });
            } else {
              console.warn(
                `No ${detailType} found for color ${color} in category ${category}`
              );
            }
          });
        }
      }
    }
  };
  //facia internal drawer //
  useEffect(() => {
    FormulaFaciaInternaldrawerHW();
  }, [faciainternaldrawer]);
  const FormulaFaciaInternaldrawerHW = () => {
    if (Object.keys(faciainternaldrawer).length > 0) {
      for (const category in faciainternaldrawer) {
        const colors = faciainternaldrawer[category];
        for (const color in colors) {
          const detailTypes = [
            "Single lip ivory",
            "100mm drawer ivory",
            "Internal Facia steel",
          ];
          detailTypes.forEach((detailType) => {
            if (colors[color][detailType]) {
              let detailsArray = colors[color][detailType];
              if (!Array.isArray(detailsArray)) {
                detailsArray = [detailsArray];
              }
              detailsArray.forEach((details: any, index: number) => {
                colors[color][detailType]![index] = FormulaFaciaInternalDrawer(
                  details,
                  category,
                  detailType
                );
              });
              const totalFaciaInternalDrawerHeight = detailsArray.reduce(
                (sum: any, obj: any) =>
                  sum + (parseInt(obj.heightquantity!) || 0),
                0
              );
              const totalFaciaInternalDrawerWidth = detailsArray.reduce(
                (sum: any, obj: any) =>
                  sum + (parseInt(obj.widthquantity!) || 0),
                0
              );
              const totalFaciaInternalDrawerQuantity = detailsArray.reduce(
                (sum: any, obj: any) => sum + (parseInt(obj.quantity!) || 0),
                0
              );
              detailsArray.forEach((obj: any) => {
                obj.totalFaciaInternalDrawerHeight =
                  totalFaciaInternalDrawerHeight;
                obj.totalFaciaInternalDrawerWidth =
                  totalFaciaInternalDrawerWidth;
                obj.totalFaciaInternalDrawerQuantity =
                  totalFaciaInternalDrawerQuantity;
              });
              detailsArray.forEach((details: any, index: number) => {
                colors[color][detailType]![index] =
                  FaciaInternalDrawerhightwidth(details, category);
              });
            } else {
              console.warn(
                `No ${detailType} found for color ${color} in category ${category}`
              );
            }
          });
        }
      }
    }
  };
  const getProjectDetails = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    setState((prevState: any) => ({ ...prevState, projectId: projectId }));
    let req = {
      id: projectId,
    };
    callApi("POST", "project_detailsselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          setState((prevState: any) => ({
            ...prevState,
            prjName: res.prjname,
            prjDesc: res.prjdesc,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    callApi("POST", "colorselect.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          setState((prevState: any) => ({
            ...prevState,
            colors: response.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
    callApi("POST", "categery_select.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          setState((prevState: any) => ({
            ...prevState,
            categery: response.responseJson.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    FormulaHW();
  }, [categoryImages]);
  const Categeryimagesselect = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "formula_categery.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setCategoryImages(response.responseJson.resultUser);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const formulatableselect = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "formula_boxtableselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setFormulatabledetails(response.responseJson.resultUser);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const handleClose = () => {
    setState((prevState) => ({ ...prevState, modelshow: false }));
  };
  const handleShow = (id: any) => {
    setState((prevState) => ({ ...prevState, modelshow: true, boxId: id }));
    let req = {
      projectId: state.projectId,
      boxid: id,
    };
    callApi("POST", "box_modalselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          setState((prevState) => ({
            ...prevState,
            UpdateBoxType: res.Box,
            updateboxdoortype: res.boxdoor_type,
            updateboxdoorselect: res.boxdoor_select,
            updateshelf: res.boxshelf,
            updateshelfcount: res.boxshelf_count,
            updateboxg: res.boxg,
            updateboxgvalue: res.boxg_value,
            updateshelfcolor: res.shelf_color,
            updateSelfEnterclr: res.shelf_color,
            updateintdummy: res.boxint_dummy,
            updateintdummyvalue: res.boxint_dummy_value,
            updatebkcolor: res.boxbk_color,
            updatedepthcolor: res.boxdepth_color,
            updateDepthclrenter: res.boxdepth_color,
            updatesinglelipcolor: res.boxsingle_lipcolor,
            updatejsquarecolor: res.jsquarecolor,
            UpdateJsquareEntercolor: res.jsquarecolor,
            updateJsquareclrStatus: res.JsquareclrStatus,
            updateboxcategery: res.boxcategery,
            Updateboxtypecategery: res.boxcategery,
            updateCatstatus: res.catstatus,
            updateboxheight: res.boxheight,
            updateboxwidth: res.boxwidth,
            updateboxdepth: res.boxdepth,
            updateboxdoorposition: res.boxdoorposition,
            updateboxgposition: res.boxgposition,
            updateboxintdummyposition: res.boxintposition,
            updateboxfixed: res.boxfixed,
            updateboxfixedposition: res.boxfixedposition,
            UpdateFixedHeight: res.boxfixedheight,
            UpdateFixedWidth: res.boxfixedwidth,
            updateSelfClrStatus: res.selfcolorstatus,
            updateBkclrenter: res.boxbk_color,
            updateBkClrStatus: res.bkcolorsts,
            updateDepthClrstatus: res.depthclrsts,
            updateSingleclrenter: res.boxsingle_lipcolor,
            updateSingleclrSts: res.Singleclrsts,
            UpdateBoxRemarks: res.boxremark,
            UpdateFaciaValue: res.FaciaValue,
            Upasvalue: res.asvalue,
            UpdateFaciaInnodoor: res.faciaIndoorsts,
            UpRightRemark: res.RightRemark,
            UpThirdRemark: res.ThirdRemark,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const framehandleClose = () => {
    setState((prevState) => ({ ...prevState, framemodelshow: false }));
  };
  const framehandleShow = (id: any) => {
    setState((prevState) => ({
      ...prevState,
      framemodelshow: true,
      frameId: id,
    }));
    let req = {
      projectId: state.projectId,
      frameId: id,
    };
    callApi("POST", "frame_modalselect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          setState((prevState) => ({
            ...prevState,
            updatetype: res.frametype,
            updateframedoortype: res.framedoor_type,
            updateframedoorselect: res.framedoor_type_select,
            updateframeg: res.frameg,
            updateframegvalue: res.frameg_value,
            updateframeintdummy: res.frameint_dummy,
            updateframeintdummyvalue: res.frameint_dummy_value,
            updateframecategery: res.framecategery,
            updateframeheight: res.frameheight,
            updateframewidth: res.framewidth,
            updateframetypecolor: res.frametypecolor,
            updateframedoorcolor: res.framedoorcolor,
            updateframedoorposition: res.framedoorposition,
            updateframegposition: res.frameboxgposition,
            updateframeintdummyposition: res.frameintdummyposition,
            updateframefixed: res.framefixed,
            updateframefixedposition: res.framefixedposition,
            updateFrameFixedWidth: res.framefixedwidth,
            UpdateFrameFixedHeight: res.framefixedheight,
            updateFrameCatstatus: res.catstatus,
            UpdateFrametypecategery: res.framecategery,
            UpdateFrameEntercolor: res.frametypecolor,
            UpFrameclrSts: res.frameclrsts,
            UpdateFrameJsEntercolor: res.framedoorcolor,
            UpFrameJsclrSts: res.frameJsclrSts,
            UpdateFrameRemarks: res.frameRemarks,
            UpdateRightFrameRemarks: res.Rightremark,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleChangename = (event: any) => {
    setState((prevState) => ({ ...prevState, prjName: event.target.value }));
  };
  const handleChangedesc = (event: any) => {
    setState((prevState) => ({ ...prevState, prjDesc: event.target.value }));
  };
  //box onchange//
  const BoxChange = (event: any) => {
    if (event.target.value == "Open Box") {
      setState((prevState) => ({
        ...prevState,
        boxdoortype: "No Door",
        boxdoorselect: "",
        jsquarecolor: "",
        boxgvalue: false,
        boxg: "",
        boxgposition: "",
        shelf: "Regular self",
        shelfcolor: "Ivory",
        shelfcount: "1",
        BoxType: event.target.value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        BoxType: event.target.value,
      }));
    }
  };
  const doortypechange = (event: any) => {
    if (event.target.value == "No Door") {
      setState((prevState) => ({
        ...prevState,
        boxdoorselect: "",
        jsquarecolor: "",
        boxgvalue: false,
        boxg: "",
        boxgposition: "",
        shelf: "Regular self",
        shelfcolor: "Ivory",
        shelfcount: "1",
      }));
    } else if (event.target.value == "Sheet Door") {
      setState((prevState) => ({
        ...prevState,
        boxdoorselect: "Double",
        jsquarecolor: "Black",
        boxgvalue: false,
        boxg: "",
        boxgposition: "",
        shelf: "Regular self",
        shelfcolor: "Ivory",
        shelfcount: "1",
      }));
    } else if (event.target.value == "Glass Door") {
      setState((prevState) => ({
        ...prevState,
        boxdoorselect: "Double",
        jsquarecolor: "Black",
        boxgvalue: true,
        boxg: "30",
        boxgposition: "top",
        shelf: "Regular self",
        shelfcolor: "Ivory",
        shelfcount: "1",
      }));
    } else if (event.target.value == "Facia") {
      setState((prevState) => ({
        ...prevState,
        boxdoorselect: "2 drawer",
        jsquarecolor: "Black",
        boxgvalue: false,
        boxg: "",
        boxgposition: "",
        shelf: "",
        shelfcolor: "",
        shelfcount: "",
        FaciaValue: "1",
      }));
    }
    setState((prevState) => ({
      ...prevState,
      boxdoortype: event.target.value,
    }));
  };
  const doorselectchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      boxdoorselect: event.target.value,
    }));

    if (event.target.value == "Single") {
      setState((prevState) => ({
        ...prevState,
        boxdoorposition: "left",
        RightBoxRemarks: "",
      }));
    } else if (event.target.value == "Double") {
      setState((prevState) => ({
        ...prevState,
        boxdoorposition: "",
      }));
    } else if (event.target.value == "3 drawer") {
      setState((prevState) => ({
        ...prevState,
        ThirdRemarks: "Remark",
        RightBoxRemarks: "Remark",
      }));
    } else if (event.target.value == "3 drawer 2:1") {
      setState((prevState) => ({
        ...prevState,
        ThirdRemarks: "Remark",
        RightBoxRemarks: "Remark",
      }));
    } else if (event.target.value == "2 drawer") {
      setState((prevState) => ({
        ...prevState,
        ThirdRemarks: "",
        RightBoxRemarks: "Remark",
      }));
    } else if (event.target.value == "2 drawer 2:1") {
      setState((prevState) => ({
        ...prevState,
        ThirdRemarks: "",
        RightBoxRemarks: "Remark",
      }));
    }
  };
  const FaciavalueOnchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      FaciaValue: event.target.value,
    }));
    if (event.target.value == "0") {
      setState((prevState) => ({
        ...prevState,
        boxdoorselect: "",
      }));
    } else if (event.target.value == "3") {
      setState((prevState) => ({
        ...prevState,
        boxdoorselect: "Single Drawer",
        FaciaInnodoor: true,
      }));
    }
  };
  const doorselectpositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      boxdoorposition: event.target.value,
    }));
  };
  const shelfchange = (event: any) => {
    setState((prevState) => ({ ...prevState, shelf: event.target.value }));
    if (event.target.value == "No Self") {
      setState((prevState) => ({
        ...prevState,
        shelfcolor: "",
        shelfcount: "0",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        shelfcolor: "Ivory",
        shelfcount: "1",
      }));
    }
  };
  const shelfcountchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    const currentValue = parseInt(state.shelfcount);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        shelfcount: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, shelfcount: "0" }));
    }
  };
  const gchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({ ...prevState, boxg: inputValue.toString() }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, boxg: "0" }));
    }
  };
  const gvaluechange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      boxgvalue: isChecked,
      boxg: "30",
    }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        boxg: "30",
        boxgposition: "top",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        boxg: "",
        boxgposition: "",
      }));
    }
  };
  const asvalueOnchange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      asvalue: isChecked,
    }));
  };
  const FaciaInternalnodoor = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      FaciaInnodoor: isChecked,
    }));
  };
  const gvaluepositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      boxgposition: event.target.value,
    }));
  };
  const intdummychange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        intdummy: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, intdummy: "0" }));
    }
  };
  const Fixedheightonchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        FixedHeight: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, FixedHeight: "0" }));
    }
  };
  const Fixedwidthonchange = (event: any) => {
    let inputValue: any = parseInt(event.target.value);
    if (state.boxwidth && inputValue > state.boxwidth) {
      inputValue = state.boxwidth;
      event.target.value = state.boxwidth.toString();
    } else {
      console.log("within bounds or less");
    }
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        FixedWidth: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, FixedWidth: "0" }));
    }
  };
  const intdummyvaluechange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({ ...prevState, intdummyvalue: isChecked }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        intdummy: "25",
        boxintdummyposition: "left",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        intdummy: "",
        boxintdummyposition: "",
      }));
    }
  };
  const intdummypositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      boxintdummyposition: event.target.value,
    }));
  };
  const boxfixedchange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({ ...prevState, boxfixed: isChecked }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        boxfixedposition: "left",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        boxfixedposition: "",
      }));
    }
  };
  const boxfixedpositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      boxfixedposition: event.target.value,
    }));
  };
  const bkcolorchange = (event: any) => {
    setState((prevState) => ({ ...prevState, bkcolor: event.target.value }));
  };
  const bkEntercolorchange = (event: any) => {
    if (event.target.value) {
      setState((prevState) => ({
        ...prevState,
        bkcolor: "",
        bkentercolor: event.target.value,
      }));
    } else if (event.target.value == "") {
      setState((prevState) => ({
        ...prevState,
        bkcolor: "Ivory",
        bkentercolor: "",
      }));
    }
  };
  const depthcolorchange = (event: any) => {
    setState((prevState) => ({ ...prevState, depthcolor: event.target.value }));
  };
  const depthEntercolorchange = (event: any) => {
    if (event.target.value) {
      setState((prevState) => ({
        ...prevState,
        depthcolor: "",
        depthentercolor: event.target.value,
      }));
    } else if (event.target.value == "") {
      setState((prevState) => ({
        ...prevState,
        depthcolor: "Ivory",
        depthentercolor: "",
      }));
    }
  };
  const singlelipcolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      singlelipcolor: event.target.value,
    }));
  };
  const SinglelipEntercolorchange = (event: any) => {
    if (event.target.value) {
      setState((prevState) => ({
        ...prevState,
        singlelipcolor: "",
        singlelipentercolor: event.target.value,
      }));
    } else if (event.target.value == "") {
      setState((prevState) => ({
        ...prevState,
        singlelipcolor: "Black",
        singlelipentercolor: "",
      }));
    }
  };
  const jsquarecolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      jsquarecolor: event.target.value,
    }));
  };
  const JsquareEnterColor = (event: any) => {
    if (event.target.value) {
      setState((prevState) => ({
        ...prevState,
        jsquarecolor: "",
        JsquareEntercolor: event.target.value,
      }));
    } else if (event.target.value == "") {
      setState((prevState) => ({
        ...prevState,
        jsquarecolor: "Black",
        JsquareEntercolor: "",
      }));
    }
  };
  const shelfcolorchange = (event: any) => {
    setState((prevState) => ({ ...prevState, shelfcolor: event.target.value }));
  };
  const SelfEnterColor = (event: any) => {
    if (event.target.value) {
      setState((prevState) => ({
        ...prevState,
        shelfcolor: "",
        selfEntercolor: event.target.value,
      }));
    } else if (event.target.value == "") {
      setState((prevState) => ({
        ...prevState,
        shelfcolor: "Ivory",
        selfEntercolor: "",
      }));
    }
  };
  const selectcategerychange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      categeryselect: event.target.value,
    }));
  };
  const Boxcategrtytype = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      boxtypecategery: event.target.value,
    }));
  };
  const heightchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        boxheight: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, boxheight: "0" }));
    }
  };
  const widthchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        boxwidth: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, boxwidth: "0" }));
    }
  };
  const depthchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        boxdepth: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, boxdepth: "0" }));
    }
  };
  const BoxRemarksonChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      BoxRemarks: event.target.value,
    }));
  };
  const RightRemarkonChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      RightBoxRemarks: event.target.value,
    }));
  };
  const ThirdRemarkonChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      ThirdRemarks: event.target.value,
    }));
  };
  const boxhandelclick = () => {
    let BoxcountError = "";
    if (!state.BoxCount) {
      BoxcountError = "BoxCount is required";
    }
    if (state.BoxCount == "0") {
      BoxcountError = "BoxCount should be greater than 0";
    }
    if (BoxcountError) {
      setState((prevState) => ({ ...prevState, BoxcountError }));
      return;
    }
    let BoxTypeSelectCat;
    if (state.categeryselect) {
      BoxTypeSelectCat = state.categeryselect;
    } else if (state.boxtypecategery) {
      BoxTypeSelectCat = state.boxtypecategery;
    }

    let BoxCategory;
    if (BoxTypeSelectCat) {
      BoxCategory = BoxTypeSelectCat;
    } else {
      BoxCategory = "Select Categery";
    }

    let BoxTypeSelectCatStatus;
    if (state.categeryselect) {
      BoxTypeSelectCatStatus = "0";
    } else if (state.boxtypecategery) {
      BoxTypeSelectCatStatus = "1";
    }
    let JsquareclrEnter;
    if (state.jsquarecolor) {
      JsquareclrEnter = state.jsquarecolor;
    } else if (state.JsquareEntercolor) {
      JsquareclrEnter = state.JsquareEntercolor;
    }
    let SelfclrEnter;
    if (state.shelfcolor) {
      SelfclrEnter = state.shelfcolor;
    } else if (state.selfEntercolor) {
      SelfclrEnter = state.selfEntercolor;
    }
    let JsquareclrStatus;
    if (state.jsquarecolor) {
      JsquareclrStatus = "0";
    } else if (state.JsquareEntercolor) {
      JsquareclrStatus = "1";
    }
    let SelfclrStatus;
    if (state.shelfcolor) {
      SelfclrStatus = "0";
    } else if (state.selfEntercolor) {
      SelfclrStatus = "1";
    }
    let BkclrEnter;
    if (state.bkcolor) {
      BkclrEnter = state.bkcolor;
    } else if (state.bkentercolor) {
      BkclrEnter = state.bkentercolor;
    }
    let BkclrStatus;
    if (state.bkcolor) {
      BkclrStatus = "0";
    } else if (state.bkentercolor) {
      BkclrStatus = "1";
    }
    let DepthclrEnter;
    if (state.depthcolor) {
      DepthclrEnter = state.depthcolor;
    } else if (state.depthentercolor) {
      DepthclrEnter = state.depthentercolor;
    }
    let DepthclrStatus;
    if (state.depthcolor) {
      DepthclrStatus = "0";
    } else if (state.depthentercolor) {
      DepthclrStatus = "1";
    }
    let SingleLipclrEnter;
    if (state.singlelipcolor) {
      SingleLipclrEnter = state.singlelipcolor;
    } else if (state.singlelipentercolor) {
      SingleLipclrEnter = state.singlelipentercolor;
    }
    let SingleLipclrStatus;
    if (state.singlelipcolor) {
      SingleLipclrStatus = "0";
    } else if (state.singlelipentercolor) {
      SingleLipclrStatus = "1";
    }
    let FixedHeight;
    if (state.boxheight) {
      FixedHeight = parseInt(state.boxheight) - 51;
    }

    let req = {
      prjid: state.projectId,
      Box: state.BoxType,
      jsquarecolor: JsquareclrEnter,
      jsquareclrstatus: JsquareclrStatus,
      door_type: state.boxdoortype,
      door_select: state.boxdoorselect,
      singledoorposition: state.boxdoorposition,
      shelf: state.shelf,
      shelf_count: state.shelfcount,
      shelf_color: SelfclrEnter,
      SelfclrStatus: SelfclrStatus,
      g: state.boxg,
      g_value: state.boxgvalue,
      g_position: state.boxgposition,
      int_dummy: state.intdummy,
      int_dummy_value: state.intdummyvalue,
      int_position: state.boxintdummyposition,
      fixed: state.boxfixed,
      fixed_position: state.boxfixedposition,
      bk_color: BkclrEnter,
      depth_color: DepthclrEnter,
      single_lipcolor: SingleLipclrEnter,
      categery: BoxCategory,
      height: state.boxheight,
      width: state.boxwidth,
      depth: state.boxdepth,
      BoxCount: state.BoxCount,
      CatStatus: BoxTypeSelectCatStatus,
      BkclrStatus: BkclrStatus,
      DepthclrStatus: DepthclrStatus,
      SingleLipclrStatus: SingleLipclrStatus,
      BoxRemarks: state.BoxRemarks,
      Asvalue: state.asvalue,
      FaciaValue: state.FaciaValue,
      RightBoxRemarks: state.RightBoxRemarks,
      ThirdRemarks: state.ThirdRemarks,
      FixedHeight: FixedHeight,
      FixedWidth: state.FixedWidth,
      FaciaInnodoor: state.FaciaInnodoor,
      accsts: "true",
    };
    console.log("request", req);

    callApi("POST", "projectboxinsert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] == true) {
          setState((prevState) => ({
            ...prevState,
            boxdoortype: "Sheet Door",
            boxdoorselect: "Double",
            boxdoorposition: "",
            shelf: "Regular self",
            shelfcount: "1",
            shelfcolor: "Ivory",
            boxg: "",
            boxgposition: "",
            boxgvalue: false,
            asvalue: false,
            FaciaInnodoor: false,
            intdummy: "",
            intdummyvalue: false,
            boxintdummyposition: "",
            boxfixed: false,
            boxfixedposition: "",
            bkcolor: "Ivory",
            depthcolor: "Ivory",
            singlelipcolor: "Black",
            JsquareEntercolor: "",
            jsquarecolor: "Black",
            categeryselect: "",
            boxheight: "600",
            boxwidth: "600",
            boxdepth: "550",
            boxtypecategery: "",
            BoxCount: "1",
            BoxType: "Regular Box",
            BoxRemarks: "Remark",
            RightBoxRemarks: "Remark",
            ThirdRemarks: "",
            FixedWidth: "",
          }));
          showSuccess("Success!", "Box Added Successfully!");
          Categeryimagesselect();
          FormulaHW();
          Categeryimagesselect();
          TableFormulaselect();
          formulaframeselect();
          formulajsquareselect();
          FormulaGlasslistSelect();
          FormulaDoorSheetSelect();
          Formula100mmDrawerSelect();
          FormulaTandemDrawerSelect();
          FormulaInternalDrawerSelect();
          FormulaIvorySheetSelect();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const BoxCountChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      BoxCount: event.target.value,
    }));
  };
  //frame onchange //
  const frametypechange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      type: event.target.value,
    }));
    if (event.target.value == "EP") {
      setState((prevState) => ({
        ...prevState,
        framedoortype: "",
        framedoorcolor: "",
        framedoorselect: "",
        frametypecolor: "Black",
        FrameRemarks: "Remark",
        RightFrameRemarks: "",
      }));
    } else if (event.target.value == "ES") {
      setState((prevState) => ({
        ...prevState,
        framedoortype: "",
        framedoorcolor: "",
        framedoorselect: "",
        frametypecolor: "",
        FrameRemarks: "Remark",
        RightFrameRemarks: "",
      }));
    } else if (event.target.value == "1.25 lip") {
      setState((prevState) => ({
        ...prevState,
        framedoortype: "",
        framedoorcolor: "",
        framedoorselect: "",
        frametypecolor: "Black",
        FrameRemarks: "Remark",
        RightFrameRemarks: "",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        framedoortype: "Sheet Door",
        framedoorcolor: "Black",
        framedoorselect: "Double",
        frametypecolor: "Black",
      }));
    }
  };
  const frametypecolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      frametypecolor: event.target.value,
    }));
  };
  const FrameEnterColor = (event: any) => {
    if (event.target.value) {
      setState((prevState) => ({
        ...prevState,
        frametypecolor: "",
        FrameEntercolor: event.target.value,
      }));
    } else if (event.target.value == "" && state.type == "EP") {
      setState((prevState) => ({
        ...prevState,
        frametypecolor: "Black",
        FrameEntercolor: "",
      }));
    } else if (event.target.value == "" && state.type == "ES") {
      setState((prevState) => ({
        ...prevState,
        frametypecolor: "Ivory",
        FrameEntercolor: "",
      }));
    } else if (event.target.value == "" && state.type == "1.25 lip") {
      setState((prevState) => ({
        ...prevState,
        frametypecolor: "Black",
        FrameEntercolor: "",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        frametypecolor: "Black",
        FrameEntercolor: "",
      }));
    }
  };
  const framedoortypechange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      framedoortype: event.target.value,
    }));
    if (event.target.value == "No Door") {
      setState((prevState) => ({
        ...prevState,
        framedoorselect: "",
        framedoorcolor: "",
        framegvalue: false,
        frameg: "",
        frameboxgposition: "",
      }));
    } else if (event.target.value == "Sheet Door") {
      setState((prevState) => ({
        ...prevState,
        framedoorselect: "Double",
        framedoorcolor: "Black",
        framegvalue: false,
        frameg: "",
        frameboxgposition: "",
      }));
    } else if (event.target.value == "Glass Door") {
      setState((prevState) => ({
        ...prevState,
        framedoorselect: "Double",
        framedoorcolor: "Black",
        framegvalue: true,
        frameg: "30",
        frameboxgposition: "top",
      }));
    }
  };
  const framedoorselectchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      framedoorselect: event.target.value,
    }));
    if (event.target.value == "Single") {
      setState((prevState) => ({
        ...prevState,
        framedoorposition: "left",
        FrameRemarks: "Remark",
        RightFrameRemarks: "",
      }));
    } else if (event.target.value == "Double") {
      setState((prevState) => ({
        ...prevState,
        framedoorposition: "",
        FrameRemarks: "Remark",
        RightFrameRemarks: "Remark",
      }));
    }
  };
  const framedoorcolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      framedoorcolor: event.target.value,
    }));
  };
  const FrameJsclrChange = (event: any) => {
    if (event.target.value) {
      setState((prevState) => ({
        ...prevState,
        framedoorcolor: "",
        FrameJsclrEnter: event.target.value,
      }));
    } else if (event.target.value == "") {
      setState((prevState) => ({
        ...prevState,
        framedoorcolor: "Black",
        FrameJsclrEnter: "",
      }));
    }
  };
  const frameselectpositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      framedoorposition: event.target.value,
    }));
  };
  const framegchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        frameg: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, frameg: "0" }));
    }
  };
  const framegvaluepositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      frameboxgposition: event.target.value,
    }));
  };
  const framegvaluechange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      framegvalue: isChecked,
    }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        frameg: "30",
        frameboxgposition: "top",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        frameg: "",
        frameboxgposition: "",
      }));
    }
  };
  const frameintdummychange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        frameintdummy: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, frameintdummy: "0" }));
    }
  };
  const frameintdummyvaluechange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      frameintdummyvalue: isChecked,
    }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        frameintdummy: "25",
        frameintdummyposition: "left",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        frameintdummy: "",
        frameintdummyposition: "",
      }));
    }
  };
  const frameintdummypositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      frameintdummyposition: event.target.value,
    }));
  };
  const framefixedchange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({ ...prevState, framefixed: isChecked }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        framefixedposition: "left",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        framefixedposition: "",
      }));
    }
  };
  const framefixedpositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      framefixedposition: event.target.value,
    }));
  };
  const FrameFixedheightonchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        FrameFixedHeight: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, FrameFixedHeight: "0" }));
    }
  };
  const FrameFixedwidthonchange = (event: any) => {
    let inputValue: any = parseInt(event.target.value);
    if (state.framewidth && inputValue > state.framewidth) {
      inputValue = state.framewidth;
      event.target.value = state.framewidth.toString();
    } else {
      console.log("within bounds or less");
    }
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        FrameFixedWidth: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, FrameFixedWidth: "0" }));
    }
  };
  const frameheightchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        frameheight: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, frameheight: "0" }));
    }
  };
  const framewidthchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        framewidth: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, framewidth: "0" }));
    }
  };
  const selectframecategerychange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      framecategeryselect: event.target.value,
    }));
  };
  const Framecategerytype = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      Frametypecategery: event.target.value,
    }));
  };
  const FrameRemarksonChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      FrameRemarks: event.target.value,
    }));
  };
  const RightRemarkFrameChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      RightFrameRemarks: event.target.value,
    }));
  };
  const framehandleclick = () => {
    let FramecountError = "";

    if (!state.FrameCount) {
      FramecountError = "FrameCount is required";
    }
    if (state.FrameCount == "0") {
      FramecountError = "FrameCount should be greater than 0";
    }

    if (FramecountError) {
      setState((prevState) => ({ ...prevState, FramecountError }));
      return;
    }
    let FrameTypeCatSet;
    if (state.framecategeryselect) {
      FrameTypeCatSet = state.framecategeryselect;
    } else if (state.Frametypecategery) {
      FrameTypeCatSet = state.Frametypecategery;
    }

    let FrameCategory;
    if (FrameTypeCatSet) {
      FrameCategory = FrameTypeCatSet;
    } else {
      FrameCategory = "Select Categery";
    }

    let FrameTypeSelectCatStatus;
    if (state.framecategeryselect) {
      FrameTypeSelectCatStatus = "0";
    } else if (state.Frametypecategery) {
      FrameTypeSelectCatStatus = "1";
    }

    let FrameclrEnter;
    if (state.frametypecolor) {
      FrameclrEnter = state.frametypecolor;
    } else if (state.FrameEntercolor) {
      FrameclrEnter = state.FrameEntercolor;
    }
    let FrameclrStatus;
    if (state.frametypecolor) {
      FrameclrStatus = "0";
    } else if (state.FrameEntercolor) {
      FrameclrStatus = "1";
    }

    let FrameJsclrEnter;
    if (state.framedoorcolor) {
      FrameJsclrEnter = state.framedoorcolor;
    } else if (state.FrameJsclrEnter) {
      FrameJsclrEnter = state.FrameJsclrEnter;
    }

    let FrameJsclrStatus;
    if (state.framedoorcolor) {
      FrameJsclrStatus = "0";
    } else if (state.FrameJsclrEnter) {
      FrameJsclrStatus = "1";
    }

    let FrameFixedHeight;
    if (state.frameheight) {
      FrameFixedHeight = parseInt(state.frameheight) - 51;
    }

    let req = {
      frameprjid: state.projectId,
      frametype: state.type,
      framedoor_type: state.framedoortype,
      framedoor_type_select: state.framedoorselect,
      frameg: state.frameg,
      frameg_value: state.framegvalue,
      frameint_dummy: state.frameintdummy,
      frameint_dummy_value: state.frameintdummyvalue,
      framecategery: FrameCategory,
      frameheight: state.frameheight,
      framewidth: state.framewidth,
      framedoorposition: state.framedoorposition,
      frameboxgposition: state.frameboxgposition,
      frameintdummyposition: state.frameintdummyposition,
      framefixed: state.framefixed,
      framefixedposition: state.framefixedposition,
      frametypecolor: FrameclrEnter,
      framedoorcolor: FrameJsclrEnter,
      FrameCount: state.FrameCount,
      FrameCatstatus: FrameTypeSelectCatStatus,
      FrameclrStatus: FrameclrStatus,
      FrameJsclrStatus: FrameJsclrStatus,
      Remarks: state.FrameRemarks,
      RightRemarks: state.RightFrameRemarks,
      FrameFixedHeight: FrameFixedHeight,
      FrameFixedWidth: state.FrameFixedWidth,
      accsts: "true",
    };
    console.log("request", req);

    callApi("POST", "project_frameinsert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] == true) {
          setState({
            ...state,
            type: "1.25",
            framedoortype: "Sheet Door",
            framedoorselect: "Double",
            frameg: "",
            framecategeryselect: "",
            framegvalue: false,
            frameintdummy: "",
            frameintdummyvalue: false,
            frameheight: "900",
            framewidth: "900",
            frameintdummyposition: "",
            framefixed: false,
            framefixedposition: "",
            FrameJsclrEnter: "",
            framedoorcolor: "Black",
            framedoorposition: "",
            frameboxgposition: "",
            FrameEntercolor: "",
            frametypecolor: "Black",
            FrameCount: "1",
            Frametypecategery: "",
            RightFrameRemarks: "Remark",
            FrameFixedWidth: "",
          });
          showSuccess("Success!", "Frame Added Successfully!");
          Categeryimagesselect();
          FormulaHW();
          TableFormulaselect();
          formulaframeselect();
          formulajsquareselect();
          FormulaGlasslistSelect();
          FormulaDoorSheetSelect();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const FrameCountChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      FrameCount: event.target.value,
    }));
  };
  //updatebox onchange//
  const UpdateBoxChange = (event: any) => {
    if (event.target.value == "Open Box") {
      setState((prevState) => ({
        ...prevState,
        updateboxdoortype: "No Door",
        updateboxdoorselect: "",
        updatejsquarecolor: "",
        updateboxgvalue: false,
        updateboxg: "",
        updateboxgposition: "",
        updateshelf: "Regular self",
        updateshelfcolor: "Ivory",
        updateshelfcount: "1",
        UpdateBoxType: event.target.value,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        UpdateBoxType: event.target.value,
      }));
    }
  };
  const updatedoortypechange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateboxdoortype: event.target.value,
    }));
    if (event.target.value == "No Door") {
      setState((prevState) => ({
        ...prevState,
        updateboxdoorselect: "",
        updatejsquarecolor: "",
        updateboxgvalue: false,
        updateboxg: "",
        updateboxgposition: "",
        updateshelf: "Regular self",
        updateshelfcolor: "Ivory",
        updateshelfcount: "1",
      }));
    } else if (event.target.value == "Sheet Door") {
      setState((prevState) => ({
        ...prevState,
        updateboxdoorselect: "Double",
        updatejsquarecolor: "Black",
        updateboxgvalue: false,
        updateboxg: "",
        updateboxgposition: "",
        updateshelf: "Regular self",
        updateshelfcolor: "Ivory",
        updateshelfcount: "1",
      }));
    } else if (event.target.value == "Glass Door") {
      setState((prevState) => ({
        ...prevState,
        updateboxdoorselect: "Double",
        updatejsquarecolor: "Black",
        updateboxgvalue: true,
        updateboxg: "30",
        updateboxgposition: "top",
        updateshelf: "Regular self",
        updateshelfcolor: "Ivory",
        updateshelfcount: "1",
      }));
    } else if (event.target.value == "Facia") {
      setState((prevState) => ({
        ...prevState,
        updateboxdoorselect: "2 drawer",
        updatejsquarecolor: "Black",
        updateboxgvalue: false,
        updateboxg: "",
        updateboxgposition: "",
        updateshelf: "",
        updateshelfcolor: "",
        updateshelfcount: "",
      }));
    }
  };
  const updatedoorselectchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateboxdoorselect: event.target.value,
    }));
    if (event.target.value == "Single") {
      setState((prevState) => ({
        ...prevState,
        updateboxdoorposition: "left",
        UpRightRemark: "",
        UpThirdRemark: "",
      }));
    } else if (event.target.value == "Double") {
      setState((prevState) => ({
        ...prevState,
        updateboxdoorposition: "",
        UpThirdRemark: "",
      }));
    } else if (event.target.value == "3 drawer") {
      setState((prevState) => ({
        ...prevState,
        UpThirdRemark: "Remark",
        UpRightRemark: "Remark",
      }));
    } else if (event.target.value == "3 drawer 2:1") {
      setState((prevState) => ({
        ...prevState,
        UpThirdRemark: "Remark",
        UpRightRemark: "Remark",
      }));
    } else if (event.target.value == "2 drawer") {
      setState((prevState) => ({
        ...prevState,
        UpThirdRemark: "",
        UpRightRemark: "Remark",
      }));
    } else if (event.target.value == "2 drawer 2:1") {
      setState((prevState) => ({
        ...prevState,
        UpThirdRemark: "",
        UpRightRemark: "Remark",
      }));
    }
  };
  const updatedoorselectpositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateboxdoorposition: event.target.value,
    }));
  };
  const updateshelfchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateshelf: event.target.value,
    }));
    if (event.target.value == "No Self") {
      setState((prevState) => ({
        ...prevState,
        updateshelfcolor: "",
        updateshelfcount: "0",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        updateshelfcolor: "Ivory",
        updateshelfcount: "1",
      }));
    }
  };
  const updateshelfcountchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateshelfcount: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateshelfcount: "0" }));
    }
  };
  const updateshelfcolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateshelfcolor: event.target.value,
    }));
  };
  const updateSelfEnter = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateSelfEnterclr: event.target.value,
    }));
  };
  const updategchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateboxg: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateboxg: "0" }));
    }
  };
  const UpdateasvalueOnchange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      Upasvalue: isChecked,
    }));
  };
  const UpdateFaciaInternalnodoor = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      UpdateFaciaInnodoor: isChecked,
    }));
  };
  const updategvaluechange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      updateboxgvalue: isChecked,
    }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        updateboxg: "30",
        updateboxgposition: "top",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        updateboxg: "",
        updateboxgposition: "",
      }));
    }
  };
  const updategvaluepositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateboxgposition: event.target.value,
    }));
  };
  const updateintdummychange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateintdummy: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateintdummy: "0" }));
    }
  };
  const updateintdummyvaluechange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      updateintdummyvalue: isChecked,
    }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        updateintdummy: "25",
        updateboxintdummyposition: "left",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        updateintdummy: "",
        updateboxintdummyposition: "",
      }));
    }
  };
  const updateintdummypositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateboxintdummyposition: event.target.value,
    }));
  };
  const updateboxfixedchange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({ ...prevState, updateboxfixed: isChecked }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        updateboxfixedposition: "left",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        updateboxfixedposition: "",
      }));
    }
  };
  const UpdateFixedheightonchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        UpdateFixedHeight: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, UpdateFixedHeight: "0" }));
    }
  };
  const UpdateFixedwidthonchange = (event: any) => {
    let inputValue: any = parseInt(event.target.value);

    if (state.updateboxwidth && inputValue > state.updateboxwidth) {
      inputValue = state.updateboxwidth;
      event.target.value = state.updateboxwidth.toString();
    } else {
      console.log("within bounds or less");
    }

    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        UpdateFixedWidth: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, UpdateFixedWidth: "0" }));
    }
  };
  const updateboxfixedpositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateboxfixedposition: event.target.value,
    }));
  };
  const updatebkcolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updatebkcolor: event.target.value,
    }));
  };
  const updateBkEnter = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateBkclrenter: event.target.value,
    }));
  };
  const updatedepthcolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updatedepthcolor: event.target.value,
    }));
  };
  const updateSingleclrEnter = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateSingleclrenter: event.target.value,
    }));
  };
  const updatesinglelipcolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updatesinglelipcolor: event.target.value,
    }));
  };
  const updateDepthEnter = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateDepthclrenter: event.target.value,
    }));
  };
  const updatejsquarecolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updatejsquarecolor: event.target.value,
    }));
  };
  const UpdateJsquareEnterColor = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      UpdateJsquareEntercolor: event.target.value,
    }));
  };
  const updatecategerychange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateboxcategery: event.target.value,
    }));
  };
  const BoxUpdatecategerytype = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      Updateboxtypecategery: event.target.value,
    }));
  };
  const updateheightchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateboxheight: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateboxheight: "0" }));
    }
  };
  const updatewidthchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateboxwidth: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateboxwidth: "0" }));
    }
  };
  const updatedepthchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateboxdepth: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateboxdepth: "0" }));
    }
  };
  const UpdateBoxRemarksonChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      UpdateBoxRemarks: event.target.value,
    }));
  };
  const UpdateRightRemarkonChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      UpRightRemark: event.target.value,
    }));
  };
  const UpdateThirdRemarkonChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      UpThirdRemark: event.target.value,
    }));
  };
  const UpdatedFaciavalueChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      UpdateFaciaValue: event.target.value,
    }));
    if (event.target.value == "0") {
      setState((prevState) => ({
        ...prevState,
        updateboxdoorselect: "",
      }));
    } else if (event.target.value == "3") {
      setState((prevState) => ({
        ...prevState,
        updateboxdoorselect: "Single Drawer",
      }));
    }
  };
  const updateboxhandelclick = () => {
    let UpBoxTypeSelectCat;
    if (state.updateCatstatus == "0") {
      UpBoxTypeSelectCat = state.updateboxcategery;
    } else if (state.updateCatstatus == "1") {
      UpBoxTypeSelectCat = state.Updateboxtypecategery;
    }
    let UpBoxTypeSelectCatStatus;
    if (state.updateCatstatus == "0") {
      UpBoxTypeSelectCatStatus = "0";
    } else if (state.updateCatstatus == "1") {
      UpBoxTypeSelectCatStatus = "1";
    }
    let UpJsquareClrEnter;
    if (state.updateJsquareclrStatus == "0") {
      UpJsquareClrEnter = state.updatejsquarecolor;
    } else if (state.updateJsquareclrStatus == "1") {
      UpJsquareClrEnter = state.UpdateJsquareEntercolor;
    }
    let UpJsquareclrStatus;
    if (state.updateJsquareclrStatus == "0") {
      UpJsquareclrStatus = "0";
    } else if (state.updateJsquareclrStatus == "1") {
      UpJsquareclrStatus = "1";
    }
    let UpSelfClrEnter;
    if (state.updateSelfClrStatus == "0") {
      UpSelfClrEnter = state.updateshelfcolor;
    } else if (state.updateSelfClrStatus == "1") {
      UpSelfClrEnter = state.updateSelfEnterclr;
    }
    let UpSelfclrStatus;
    if (state.updateSelfClrStatus == "0") {
      UpSelfclrStatus = "0";
    } else if (state.updateSelfClrStatus == "1") {
      UpSelfclrStatus = "1";
    }
    let UpBkClrEnter;
    if (state.updateBkClrStatus == "0") {
      UpBkClrEnter = state.updatebkcolor;
    } else if (state.updateBkClrStatus == "1") {
      UpBkClrEnter = state.updateBkclrenter;
    }
    let UpBkclrStatus;
    if (state.updateBkClrStatus == "0") {
      UpBkclrStatus = "0";
    } else if (state.updateBkClrStatus == "1") {
      UpBkclrStatus = "1";
    }
    let UpDepthClrEnter;
    if (state.updateDepthClrstatus == "0") {
      UpDepthClrEnter = state.updatedepthcolor;
    } else if (state.updateDepthClrstatus == "1") {
      UpDepthClrEnter = state.updateDepthclrenter;
    }
    let UpDepthclrStatus;
    if (state.updateDepthClrstatus == "0") {
      UpDepthclrStatus = "0";
    } else if (state.updateDepthClrstatus == "1") {
      UpDepthclrStatus = "1";
    }
    let UpSingleClrEnter;
    if (state.updateSingleclrSts == "0") {
      UpSingleClrEnter = state.updatesinglelipcolor;
    } else if (state.updateSingleclrSts == "1") {
      UpSingleClrEnter = state.updateSingleclrenter;
    }
    let UpSingleclrStatus;
    if (state.updateSingleclrSts == "0") {
      UpSingleclrStatus = "0";
    } else if (state.updateSingleclrSts == "1") {
      UpSingleclrStatus = "1";
    }

    let UpdateFixedHeight;
    if (state.updateboxheight) {
      UpdateFixedHeight = parseInt(state.updateboxheight) - 51;
    }

    let req = {
      boxId: state.boxId,
      prjid: state.projectId,
      Box: state.UpdateBoxType,
      door_type: state.updateboxdoortype,
      door_select: state.updateboxdoorselect,
      single_dr_position: state.updateboxdoorposition,
      shelf: state.updateshelf,
      shelf_count: state.updateshelfcount,
      shelf_color: UpSelfClrEnter,
      g: state.updateboxg,
      g_value: state.updateboxgvalue,
      g_position: state.updateboxgposition,
      int_dummy: state.updateintdummy,
      int_dummy_value: state.updateintdummyvalue,
      int_position: state.updateboxintdummyposition,
      fixed: state.updateboxfixed,
      fixed_position: state.updateboxfixedposition,
      bk_color: UpBkClrEnter,
      depth_color: UpDepthClrEnter,
      single_lipcolor: UpSingleClrEnter,
      jsquarecolor: UpJsquareClrEnter,
      boxcategery: UpBoxTypeSelectCat,
      height: state.updateboxheight,
      width: state.updateboxwidth,
      depth: state.updateboxdepth,
      CatStatus: UpBoxTypeSelectCatStatus,
      JsquareclrStatus: UpJsquareclrStatus,
      SelfclrStatus: UpSelfclrStatus,
      UpBkclrStatus: UpBkclrStatus,
      UpDepthclrStatus: UpDepthclrStatus,
      UpSingleclrStatus: UpSingleclrStatus,
      BoxRemarks: state.UpdateBoxRemarks,
      Asvalue: state.Upasvalue,
      forHeight: "",
      forWidth: "",
      FaciaValue: state.UpdateFaciaValue,
      RightRemark: state.UpRightRemark,
      ThirdRemark: state.UpThirdRemark,
      FixedHeight: UpdateFixedHeight,
      FixedWidth: state.UpdateFixedWidth,
      faciaIndoorsts: state.UpdateFaciaInnodoor,
    };
    callApi("POST", "boxmodalupdate.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] == true) {
          setState((prevState) => ({
            ...prevState,
            modelshow: false,
          }));
          showSuccess("Success!", "Updated  Successfully!");
          Categeryimagesselect();
          FormulaHW();
          TableFormulaselect();
          formulaframeselect();
          formulajsquareselect();
          FormulaDoorSheetSelect();
          FormulaGlasslistSelect();
          Formula100mmDrawerSelect();
          FormulaTandemDrawerSelect();
          FormulaInternalDrawerSelect();
          FormulaIvorySheetSelect();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  //updateframemodal//
  const updateframetypechange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updatetype: event.target.value,
    }));
    if (event.target.value == "EP") {
      setState((prevState) => ({
        ...prevState,
        updateframedoortype: "",
        updateframedoorcolor: "",
        updateframedoorselect: "",
        updateframetypecolor: "Black",
      }));
    } else if (event.target.value == "ES") {
      setState((prevState) => ({
        ...prevState,
        updateframedoortype: "",
        updateframedoorcolor: "",
        updateframedoorselect: "",
        updateframetypecolor: "Ivory",
      }));
    } else if (event.target.value == "1.25 lip") {
      setState((prevState) => ({
        ...prevState,
        updateframedoortype: "",
        updateframedoorcolor: "",
        updateframedoorselect: "",
        updateframetypecolor: "Black",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        updateframedoortype: "Sheet Door",
        updateframedoorcolor: "Black",
        updateframedoorselect: "Double",
        updateframetypecolor: "Black",
      }));
    }
  };
  const updateframetypecolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateframetypecolor: event.target.value,
    }));
  };
  const UpdateFrameEnterColor = (event: any) => {
    if (event.target.value) {
      setState((prevState) => ({
        ...prevState,
        updateframetypecolor: "",
        UpdateFrameEntercolor: event.target.value,
      }));
    } else if (event.target.value == "" && state.updatetype == "EP") {
      setState((prevState) => ({
        ...prevState,
        updateframetypecolor: "Black",
        UpdateFrameEntercolor: "",
      }));
    } else if (event.target.value == "" && state.updatetype == "ES") {
      setState((prevState) => ({
        ...prevState,
        updateframetypecolor: "Ivory",
        UpdateFrameEntercolor: "",
      }));
    } else if (event.target.value == "" && state.updatetype == "1.25 lip") {
      setState((prevState) => ({
        ...prevState,
        updateframetypecolor: "Black",
        UpdateFrameEntercolor: "",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        updateframetypecolor: "Black",
        UpdateFrameEntercolor: "",
      }));
    }
  };
  const updateframedoortypechange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateframedoortype: event.target.value,
    }));
    if (event.target.value == "No Door") {
      setState((prevState) => ({
        ...prevState,
        updateframedoorselect: "",
        updateframedoorcolor: "",
        updateframegvalue: false,
        updateframeg: "",
        updateframegposition: "",
      }));
    } else if (event.target.value == "Sheet Door") {
      setState((prevState) => ({
        ...prevState,
        updateframedoorselect: "Double",
        updateframedoorcolor: "Black",
        updateframegvalue: false,
        updateframeg: "",
        updateframegposition: "",
      }));
    } else if (event.target.value == "Glass Door") {
      setState((prevState) => ({
        ...prevState,
        updateframedoorselect: "Double",
        updateframedoorcolor: "Black",
        updateframegvalue: true,
        updateframeg: "30",
        updateframegposition: "top",
      }));
    }
  };
  const updateframedoorselectchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateframedoorselect: event.target.value,
    }));
    if (event.target.value == "Single") {
      setState((prevState) => ({
        ...prevState,
        updateframedoorposition: "left",
        UpdateRightFrameRemarks: "",
      }));
    } else if (event.target.value == "Double") {
      setState((prevState) => ({
        ...prevState,
        updateframedoorposition: "",
      }));
    }
  };
  const updateframedoorcolorchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateframedoorcolor: event.target.value,
    }));
  };
  const UpdateFrameJsEnter = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      UpdateFrameJsEntercolor: event.target.value,
    }));
  };
  const updateframeselectpositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateframedoorposition: event.target.value,
    }));
  };
  const updateframegchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateframeg: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateframeg: "0" }));
    }
  };
  const updateframegvaluepositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateframegposition: event.target.value,
    }));
  };
  const updateframegvaluechange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      updateframegvalue: isChecked,
    }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        updateframeg: "30",
        updateframegposition: "top",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        updateframeg: "",
        updateframegposition: "",
      }));
    }
  };
  const updateframeintdummychange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateframeintdummy: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateframeintdummy: "0" }));
    }
  };
  const updateframeintdummypositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateframeintdummyposition: event.target.value,
    }));
  };
  const updateframefixedchange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({ ...prevState, updateframefixed: isChecked }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        updateframefixedposition: "left",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        updateframefixedposition: "",
      }));
    }
  };
  const updateframefixedpositionchange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateframefixedposition: event.target.value,
    }));
  };
  const updateFrameFixedheightonchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        UpdateFrameFixedHeight: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, UpdateFrameFixedHeight: "0" }));
    }
  };
  const UpdateFrameFixedwidthonchange = (event: any) => {
    let inputValue: any = parseInt(event.target.value);
    if (state.updateframewidth && inputValue > state.updateframewidth) {
      inputValue = state.updateframewidth;
      event.target.value = state.updateframewidth.toString();
    } else {
      console.log("within bounds or less");
    }
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateFrameFixedWidth: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateFrameFixedWidth: "0" }));
    }
  };
  const updateframeintdummyvaluechange = (event: any) => {
    const isChecked = event.target.checked;
    setState((prevState) => ({
      ...prevState,
      updateframeintdummyvalue: isChecked,
    }));
    if (isChecked == true) {
      setState((prevState) => ({
        ...prevState,
        updateframeintdummy: "25",
        updateframeintdummyposition: "left",
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        updateframeintdummy: "",
        updateframeintdummyposition: "",
      }));
    }
  };
  const updateframecategerychange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      updateframecategery: event.target.value,
    }));
  };
  const FrameUpdatecategerytype = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      UpdateFrametypecategery: event.target.value,
    }));
  };
  const updateframeheightchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateframeheight: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateframeheight: "0" }));
    }
  };
  const updateframewidthchange = (event: any) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 0) {
      setState((prevState) => ({
        ...prevState,
        updateframewidth: inputValue.toString(),
      }));
    } else {
      event.target.value = "0";
      setState((prevState) => ({ ...prevState, updateframewidth: "0" }));
    }
  };
  const UpdateFrameRemarksonChange = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      UpdateFrameRemarks: event.target.value,
    }));
  };
  const UpdateFrameRightRemark = (event: any) => {
    setState((prevState) => ({
      ...prevState,
      UpdateRightFrameRemarks: event.target.value,
    }));
  };
  const updateframehandelclick = () => {
    let UpFrameTypeSelectCat;
    if (state.updateFrameCatstatus == "0") {
      UpFrameTypeSelectCat = state.updateframecategery;
    } else if (state.updateFrameCatstatus == "1") {
      UpFrameTypeSelectCat = state.UpdateFrametypecategery;
    }
    let UpFrameTypeSelectCatStatus;
    if (state.updateFrameCatstatus == "0") {
      UpFrameTypeSelectCatStatus = "0";
    } else if (state.updateFrameCatstatus == "1") {
      UpFrameTypeSelectCatStatus = "1";
    }

    let UpFrameTypeClr;
    if (state.UpFrameclrSts == "0") {
      UpFrameTypeClr = state.updateframetypecolor;
    } else if (state.UpFrameclrSts == "1") {
      UpFrameTypeClr = state.UpdateFrameEntercolor;
    }
    let UpFrameTypeClrSts;
    if (state.UpFrameclrSts == "0") {
      UpFrameTypeClrSts = "0";
    } else if (state.UpFrameclrSts == "1") {
      UpFrameTypeClrSts = "1";
    }

    let UpFrameTypeJsClr;
    if (state.UpFrameJsclrSts == "0") {
      UpFrameTypeJsClr = state.updateframedoorcolor;
    } else if (state.UpFrameJsclrSts == "1") {
      UpFrameTypeJsClr = state.UpdateFrameJsEntercolor;
    }

    let UpFrameTypeJsClrSts;
    if (state.UpFrameJsclrSts == "0") {
      UpFrameTypeJsClrSts = "0";
    } else if (state.UpFrameJsclrSts == "1") {
      UpFrameTypeJsClrSts = "1";
    }

    let updatefixedheight;
    if (state.updateframeheight) {
      updatefixedheight = parseInt(state.updateframeheight) - 51;
    }
    let req = {
      frameId: state.frameId,
      prjid: state.projectId,
      frametype: state.updatetype,
      framedoor_type: state.updateframedoortype,
      framedoor_type_select: state.updateframedoorselect,
      frameg: state.updateframeg,
      frameg_value: state.updateframegvalue,
      frameint_dummy: state.updateframeintdummy,
      frameint_dummy_value: state.updateframeintdummyvalue,
      framecategery: UpFrameTypeSelectCat,
      frameheight: state.updateframeheight,
      framewidth: state.updateframewidth,
      frametypecolor: UpFrameTypeClr,
      framedoorcolor: UpFrameTypeJsClr,
      framedoorposition: state.updateframedoorposition,
      frameboxgposition: state.updateframegposition,
      frameintdummyposition: state.updateframeintdummyposition,
      framefixed: state.updateframefixed,
      framefixedposition: state.updateframefixedposition,
      frameCatStatus: UpFrameTypeSelectCatStatus,
      FrameTypeClrSts: UpFrameTypeClrSts,
      FrameJsClrSts: UpFrameTypeJsClrSts,
      Frameremark: state.UpdateFrameRemarks,
      RightFrameRemarks: state.UpdateRightFrameRemarks,
      FrameFixedheight: updatefixedheight,
      FrameFixedwidth: state.updateFrameFixedWidth,
    };
    callApi("POST", "frame_modalupdate.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] == true) {
          setState((prevState) => ({
            ...prevState,
            framemodelshow: false,
          }));
          showSuccess("Success!", "Updated  Successfully!");
          Categeryimagesselect();
          FormulaHW();
          TableFormulaselect();
          formulaframeselect();
          formulajsquareselect();
          FormulaDoorSheetSelect();
          FormulaGlasslistSelect();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  //boxdupliactecreate//
  const handleDuplicate = async (id: any) => {
    setState((prevState) => ({ ...prevState, boxId: id }));
    let req = {
      projectId: state.projectId,
      boxid: id,
    };
    callApi("POST", "box_modalselect.php", req)
      .then((res) => res.data)
      .then(async (response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          state.UpdateBoxType = res.Box;
          state.updateboxdoortype = res.boxdoor_type;
          state.updateboxdoorselect = res.boxdoor_select;
          state.updateshelf = res.boxshelf;
          state.updateshelfcount = res.boxshelf_count;
          state.updateshelfcolor = res.shelf_color;
          state.updateboxg = res.boxg;
          state.updateboxgvalue = res.boxg_value;
          state.updateintdummy = res.boxint_dummy;
          state.updateintdummyvalue = res.boxint_dummy_value;
          state.updatebkcolor = res.boxbk_color;
          state.updatedepthcolor = res.boxdepth_color;
          state.updatesinglelipcolor = res.boxsingle_lipcolor;
          state.updatejsquarecolor = res.jsquarecolor;
          state.updateboxcategery = res.boxcategery;
          state.updateboxheight = res.boxheight;
          state.updateboxwidth = res.boxwidth;
          state.updateboxdepth = res.boxdepth;
          state.updateboxdoorposition = res.boxdoorposition;
          state.updateboxgposition = res.boxgposition;
          state.updateboxintdummyposition = res.boxintposition;
          state.updateboxfixed = res.boxfixed;
          state.updateboxfixedposition = res.boxfixedposition;
          state.UpdateFixedHeight = res.boxfixedheight;
          state.UpdateFixedWidth = res.boxfixedwidth;
          state.updateCatstatus = res.catstatus;
          state.updateJsquareclrStatus = res.JsquareclrStatus;
          state.updateSelfClrStatus = res.selfcolorstatus;
          state.updateBkClrStatus = res.bkcolorsts;
          state.updateDepthClrstatus = res.depthclrsts;
          state.updateSingleclrSts = res.Singleclrsts;
          state.UpdateBoxRemarks = res.boxremark;
          state.Upasvalue = res.asvalue;
          state.UpdateFaciaValue = res.FaciaValue;
          state.UpRightRemark = res.RightRemark;
          state.UpThirdRemark = res.ThirdRemark;
          state.UpdateFaciaInnodoor = res.faciaIndoorsts;

          duplicateCreate();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const duplicateCreate = () => {
    let request = {
      prjid: state.projectId,
      Box: state.UpdateBoxType,
      jsquarecolor: state.updatejsquarecolor,
      door_type: state.updateboxdoortype,
      door_select: state.updateboxdoorselect,
      shelf: state.updateshelf,
      shelf_count: state.updateshelfcount,
      shelf_color: state.updateshelfcolor,
      g: state.updateboxg,
      g_value: state.updateboxgvalue,
      int_dummy: state.updateintdummy,
      int_dummy_value: state.updateintdummyvalue,
      bk_color: state.updatebkcolor,
      depth_color: state.updatedepthcolor,
      single_lipcolor: state.updatesinglelipcolor,
      categery: state.updateboxcategery,
      height: state.updateboxheight,
      width: state.updateboxwidth,
      depth: state.updateboxdepth,
      singledoorposition: state.updateboxdoorposition,
      g_position: state.updateboxgposition,
      int_position: state.updateboxintdummyposition,
      fixed: state.updateboxfixed,
      fixed_position: state.updateboxfixedposition,
      CatStatus: state.updateCatstatus,
      BoxCount: state.BoxCount,
      jsquareclrstatus: state.updateJsquareclrStatus,
      SelfclrStatus: state.updateSelfClrStatus,
      BkclrStatus: state.updateBkClrStatus,
      DepthclrStatus: state.updateDepthClrstatus,
      SingleLipclrStatus: state.updateSingleclrSts,
      BoxRemarks: state.UpdateBoxRemarks,
      Asvalue: state.Upasvalue,
      FaciaValue: state.UpdateFaciaValue,
      RightBoxRemarks: state.UpRightRemark,
      ThirdRemarks: state.UpThirdRemark,
      FixedHeight: state.UpdateFixedHeight,
      FixedWidth: state.UpdateFixedWidth,
      FaciaInnodoor: state.UpdateFaciaInnodoor,
    };
    callApi("POST", "projectboxinsert.php", request)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] == true) {
          showSuccess("Success!", "Box Duplicate Added Successfully!");
          Categeryimagesselect();
          FormulaHW();
          TableFormulaselect();
          formulaframeselect();
          formulajsquareselect();
          FormulaDoorSheetSelect();
          FormulaGlasslistSelect();
          Formula100mmDrawerSelect();
          FormulaTandemDrawerSelect();
          FormulaInternalDrawerSelect();
          FormulaIvorySheetSelect();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  //frameduplicate create
  const frameDuplicate = async (id: any) => {
    setState((prevState) => ({ ...prevState, frameId: id }));
    let req = {
      projectId: state.projectId,
      frameId: id,
    };
    callApi("POST", "frame_modalselect.php", req)
      .then((res) => res.data)
      .then(async (response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          state.updatetype = res.frametype;
          state.updateframedoortype = res.framedoor_type;
          state.updateframedoorselect = res.framedoor_type_select;
          state.updateframeg = res.frameg;
          state.updateframegvalue = res.frameg_value;
          state.updateframeintdummy = res.frameint_dummy;
          state.updateframeintdummyvalue = res.frameint_dummy_value;
          state.updateframecategery = res.framecategery;
          state.updateframeheight = res.frameheight;
          state.updateframewidth = res.framewidth;
          state.updateframetypecolor = res.frametypecolor;
          state.updateframedoorcolor = res.framedoorcolor;
          state.updateframedoorposition = res.framedoorposition;
          state.updateframegposition = res.frameboxgposition;
          state.updateframeintdummyposition = res.frameintdummyposition;
          state.updateframefixed = res.framefixed;
          state.updateframefixedposition = res.framefixedposition;
          state.updateFrameCatstatus = res.catstatus;
          state.UpFrameclrSts = res.frameclrsts;
          state.UpdateFrameRemarks = res.frameRemarks;
          state.UpdateRightFrameRemarks = res.Rightremark;
          state.updateFrameFixedWidth = res.framefixedwidth;
          state.UpdateFrameFixedHeight = res.framefixedheight;
          frameduplicateCreate();
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const frameduplicateCreate = () => {
    let req = {
      frameprjid: state.projectId,
      frametype: state.updatetype,
      framedoor_type: state.updateframedoortype,
      framedoor_type_select: state.updateframedoorselect,
      frameg: state.updateframeg,
      frameg_value: state.updateframegvalue,
      frameint_dummy: state.updateframeintdummy,
      frameint_dummy_value: state.updateframeintdummyvalue,
      framecategery: state.updateframecategery,
      frameheight: state.updateframeheight,
      framewidth: state.updateframewidth,
      framedoorposition: state.updateframedoorposition,
      frameboxgposition: state.updateframegposition,
      frameintdummyposition: state.updateframeintdummyposition,
      framefixed: state.updateframefixed,
      framefixedposition: state.updateframefixedposition,
      frametypecolor: state.updateframetypecolor,
      framedoorcolor: state.updateframedoorcolor,
      FrameCatstatus: state.updateFrameCatstatus,
      FrameCount: state.FrameCount,
      FrameclrStatus: state.UpFrameclrSts,
      Remarks: state.UpdateFrameRemarks,
      RightRemarks: state.UpdateRightFrameRemarks,
      FrameFixedHeight: state.UpdateFrameFixedHeight,
      FrameFixedWidth: state.updateFrameFixedWidth,
    };
    callApi("POST", "project_frameinsert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["success"] == true) {
          showSuccess("Success!", "Frame Duplicate Added Successfully!");
          Categeryimagesselect();
          FormulaHW();
          TableFormulaselect();
          formulaframeselect();
          formulajsquareselect();
          FormulaDoorSheetSelect();
          FormulaGlasslistSelect();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  //boxtabledelete//
  const boxtableDelete = (id: any,wardrope_ids:any) => {
    console.log("wardrope_ids--->",wardrope_ids);
    
    if (window.confirm("Are you sure you want to delete?")) {
      confirmDelete(id,wardrope_ids);
    }
  };
  const confirmDelete = async (id: any,wardrope_ids:any) => {
    setState((prevState) => ({ ...prevState, boxId: id }));
    let req = {
      prjid: state.projectId,
      boxId: id,
      wardrope_ids:wardrope_ids,
    };
    callApi("POST", "box_tabledelete.php", req)
      .then((res) => res.data)
      .then(async (response) => {
        showSuccess("Success!", "Box Delete  Successfully!");
        Categeryimagesselect();
        FormulaHW();
        TableFormulaselect();
        formulaframeselect();
        formulajsquareselect();
        FormulaDoorSheetSelect();
        FormulaGlasslistSelect();
        Formula100mmDrawerSelect();
        FormulaTandemDrawerSelect();
        FormulaInternalDrawerSelect();
        FormulaIvorySheetSelect();
        setCategoryImages({});
        setUpdatedCategoryImages({});
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };
  //frametabledelete//
  const frametableDelete = (id: any) => {
    if (window.confirm("Are you sure you want to delete?")) {
      confirmframetableDelete(id);
    }
  };
  const confirmframetableDelete = async (id: any) => {
    setState((prevState) => ({ ...prevState, frameId: id }));
    let req = {
      prjid: state.projectId,
      frameId: id,
    };
    callApi("POST", "frame_tabledelete.php", req)
      .then((res) => res.data)
      .then(async (response) => {
        showSuccess("Success!", "Frame Delete  Successfully!");
        Categeryimagesselect();
        setCategoryImages({});
        setUpdatedCategoryImages({});
        FormulaHW();
        TableFormulaselect();
        formulaframeselect();
        formulajsquareselect();
        FormulaDoorSheetSelect();
        FormulaGlasslistSelect();
        Formula100mmDrawerSelect();
        FormulaTandemDrawerSelect();
        FormulaInternalDrawerSelect();
        FormulaIvorySheetSelect();
        setCategoryImages({});
        setUpdatedCategoryImages({});
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };
  //fullprojectdelete//
  const confirmDeleteAndDownloadPDF = () => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      alertdownloadPDF();
    }
  };
  const deleteprojectfun = async () => {
    let req = {
      Prj_id: state.projectId,
    };
    try {
      const response = await callApi("POST", "project_delete.php", req);
      showSuccess("Success!", "Project Delete Successfully!");
      navigate("/dashboard");
    } catch (err) {
      console.log("Error:", err);
    }
  };
  const alertdownloadPDF = () => {
    deleteprojectfun();
    generatePDF();
  };
  const handleBlurname = () => {
    let req = {
      id: state.projectId,
      prjname: state.prjName,
      prjdescription: state.prjDesc,
    };
    callApi("POST", "project_detailsupdate.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["statusCode"] == 200) {
          let res = response.responseJson.data[0];
          setState((prevState) => ({
            ...prevState,
            prjName: res.prjname,
            prjDesc: res.prjdesc,
          }));
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const handleKeyDownname = (event: any) => {
    if (event.key === "Enter") {
      let req = {
        id: state.projectId,
        prjname: state.prjName,
        prjdescription: state.prjDesc,
      };
      callApi("POST", "project_detailsupdate.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response["statusCode"] == 200) {
            let res = response.responseJson.data[0];
            setState((prevState) => ({
              ...prevState,
              prjName: res.prjname,
              prjDesc: res.prjdesc,
            }));
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };
  const back = () => {
    navigate("/dashboard");
  };
  const handleRemarkChange = (event: any, category: any) => {
    const { value } = event.target;
    setRemarkState((prevState) => ({
      ...prevState,
      [category]: value,
    }));
  };
  const handleRemarkRightChange = (event: any, category: any) => {
    const { value } = event.target;
    setRightRemarkState((prevState) => ({
      ...prevState,
      [category]: value,
    }));
  };
  const BlurUpdateRemark = (category: any, updatedCategoryImages: any) => {
    let req = {
      id: state.projectId,
      categery: category,
      remark: Remarkstate[category],
      rightremark: RightRemarkstate[category],
    };
    callApi("POST", "categery_remarkupdate.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response["response Code"] == 200) {
          Categeryimagesselect();
          showSuccess("Success!", "Remark Updated Successfully!");
          setRemarkState((prevState) => ({
            ...prevState,
            [category]: "",
          }));
          setRightRemarkState((prevState) => ({
            ...prevState,
            [category]: "",
          }));
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const generatePDF = async () => {
    const pdfPage = document.querySelector(".pdf-page") as HTMLElement | null;
    if (pdfPage) {
      pdfPage.style.display = "block";
    } else {
      console.error("Element with class 'pdf-page' not found.");
      return;
    }
    setState((prevState) => ({
      ...prevState,
      loader: true,
    }));
    const doc = new jsPDF("p", "mm", "a4");
    const elementsToInclude = document.querySelectorAll(".pdf-page");
    const promises: Promise<void>[] = [];

    elementsToInclude.forEach((element, index) => {
      const htmlElement = element as HTMLElement;
      promises.push(
        html2canvas(htmlElement).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          if (index !== 0) {
            doc.addPage();
          }
          const pageHeight = doc.internal.pageSize.height;
          const imgHeight = (canvas.height * 255) / canvas.width;
          if (imgHeight > pageHeight) {
            let heightLeft = imgHeight;
            let position = 0;
            while (heightLeft >= 0) {
              doc.addImage(imgData, "PNG", 10, position, 200, imgHeight);
              heightLeft -= pageHeight;
              position -= pageHeight;
              if (heightLeft > 0) {
                doc.addPage();
              }
            }
          } else {
            doc.addImage(imgData, "PNG", 10, 10, 190, imgHeight);
          }
        })
      );
    });
    await Promise.all(promises);
    doc.save(`${state.prjName}.pdf`);
    if (pdfPage) {
      pdfPage.style.display = "none";
    }
    setState((prevState) => ({
      ...prevState,
      loader: false,
    }));
  };
  //ivory sheet //
  const FormulaIvorySheetSelect = () => {
    setIvorysheet({});
    const searchParams = new URLSearchParams(window.location.search);
    const projectId = searchParams.get("id");
    if (!projectId) {
      return;
    }
    let req = {
      projectId: projectId,
    };
    callApi("POST", "ivory_sheet.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.statusCode === 200) {
          setIvorysheet(response.responseJson);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  //category edit modal//
  const [categorymodal, setCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [EditselectedCategory, setEditSelectedCategory] = useState("");
  const [TypeselectedCategory, setTypeSelectedCategory] = useState("");

  const handleChangeCategory = (category: any) => {
    setSelectedCategory(category);
    setCategoryModal(true);
  };
  const handleCategoryClose = () => {
    setCategoryModal(false);
  };
  const EditCategorychange = (event: any) => {
    setEditSelectedCategory(event.target.value);
    if (EditselectedCategory) {
      setTypeSelectedCategory("");
    }
  };
  const EditTypeCategorychange = (event: any) => {
    setTypeSelectedCategory(event.target.value);
    if (TypeselectedCategory) {
      setEditSelectedCategory("");
    }
  };

  const updateCategory = () => {
    let NewUpdateCategory;
    if (EditselectedCategory) {
      NewUpdateCategory = EditselectedCategory;
    } else if (TypeselectedCategory) {
      NewUpdateCategory = TypeselectedCategory;
    }
    const req = {
      ProjectId: state.projectId,
      OldCategery: selectedCategory,
      NewUpdateCategory: NewUpdateCategory,
    };
    console.log("UpdateCategory Request", req);

    callApi("POST", "category_update.php", req)
      .then((res) => res.data)
      .then((response) => {
        console.log("response", response);
        if (response["success"] === true) {
          showSuccess("Success!", "Category Updated Successfully!");
          setEditSelectedCategory("");
          setTypeSelectedCategory("");
          setCategoryModal(false);
          getProjectDetails();
          Categeryimagesselect();
          formulatableselect();
          TableFormulaselect();
          formulaframeselect();
          formulajsquareselect();
          FormulaGlasslistSelect();
          FormulaDoorSheetSelect();
          Formula100mmDrawerSelect();
          FormulaTandemDrawerSelect();
          FormulaInternalDrawerSelect();
          FormulaIvorySheetSelect();
        } else {
          showError("Failed", "Not Inserted !");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [expandedCategories, setExpandedCategories] = useState<
    Record<string, boolean>
  >({});
  const handleToggle = (category: string) => {
    setExpandedCategories((prevExpandedCategories) => {
      const newExpandedCategories = {
        ...prevExpandedCategories,
        [category]: !prevExpandedCategories[category],
      };
      let AccordianSts;
      if (updatedCategoryImages[category].accordianSts == "true") {
        console.log(`Sending 1 true and category to API: ${category}`);
        AccordianSts = "false";
      } else {
        console.log(`Sending 0 false and category to API: ${category}`);
        AccordianSts = "true";
      }
      const req = {
        ProjectId: state.projectId,
        Categery: category,
        AccordianSts: AccordianSts,
      };
      console.log("AccordianSts Request", req);
      callApi("POST", "category_accordian.php", req)
        .then((res) => res.data)
        .then((response) => {
          console.log("response", response);
          if (response["success"] === true) {
            Categeryimagesselect();
          } else {
            showError("Failed", "Not Inserted !");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      return newExpandedCategories;
    });
  };
  const Reportpage = (
    projectId: string,
    jsquareTotalNosState: string,
    jsquareTotalState: string,
    GlasslistTotalNosState: string,
    TandembottomTotalNosState: string,
    TandembackTotalNosState: string,
    prjName: string,
    singleTotalNosState: string,
    ShelfTotalNosState: string,
    DepthTotalNosState: string,
    BkTotalNosState: string,
    OnecrossOneTotalNosState: string,
    OnepointtwoTotalNosState: string,
    OnepointtwolipTotalNosState: string,
    mmTotalNosState: string
  ) => {
    navigate("/Reportpage", {
      state: {
        projectId,
        jsquareTotalNosState,
        jsquareTotalState,
        GlasslistTotalNosState,
        TandembottomTotalNosState,
        TandembackTotalNosState,
        prjName,
        singleTotalNosState,
        ShelfTotalNosState,
        DepthTotalNosState,
        BkTotalNosState,
        OnecrossOneTotalNosState,
        OnepointtwoTotalNosState,
        OnepointtwolipTotalNosState,
        mmTotalNosState,
      },
    });
  };
  let productCounter = 1;
  let imagesCounter = 1;
  let pdfimagecounter = 1;

  // const handleCheckboxChange = (e: any, idx: any, details: any) => {
  //   const boxdoor_typecat = details.boxdoor_typecat;
  //   const categoryImages = updatedCategoryImages;
  //   const width = details.boxwidthcat;
  //   const depth = details.boxdepthcat;
  //   const { checked } = e.target;

  //   console.log("idx", idx);
  //   console.log("boxdoor_typecat", boxdoor_typecat);
  //   console.log("checked", checked);
  //   console.log("width",width);
  //   console.log("depth",depth);

  //   let isAllowedToCheck = true;
  //   let checkedCount = 0;
  //   let firstCheckedType:any = null;

  //   // Count current checked items and check existing selections
  //   Object.keys(categoryImages).forEach((category) => {
  //     updatedCategoryImages[category].diagramimages.forEach(
  //       (categoryItem: any) => {
  //         if (categoryItem.checked) {
  //           checkedCount++;
  //           if (!firstCheckedType) {
  //             firstCheckedType = categoryItem.boxdoor_typecat;
  //           }
  //         }else{
  //           checkedCount--;
  //         }
  //       }
  //     );
  //   });

  //   if (checked) {
  //     if (checkedCount >= 2) {
  //       isAllowedToCheck = false;
  //     } else if (firstCheckedType) {
  //       if (
  //         (firstCheckedType === "Sheet Door" &&
  //           boxdoor_typecat === "Sheet Door") ||
  //         (firstCheckedType === "Glass Door" &&
  //           boxdoor_typecat === "Glass Door") ||
  //         (firstCheckedType === "No Door" && boxdoor_typecat === "No Door") ||
  //         (firstCheckedType !== "No Door" && boxdoor_typecat !== "No Door")||
  //         (firstCheckedType === "Facia" &&
  //           boxdoor_typecat === "Facia") ||
  //           (firstCheckedType === "Facia" &&
  //             (boxdoor_typecat === "Sheet Door" || boxdoor_typecat === "Glass Door" ))
  //       ) {
  //         isAllowedToCheck = false;
  //       }
  //     }
  //   }

  //   if (isAllowedToCheck) {
  //     Object.keys(categoryImages).forEach((category) => {
  //       updatedCategoryImages[category].diagramimages.map(
  //         (categoryItem: any, categoryIndex: any) => {
  //           if (idx == categoryIndex) {
  //             updatedCategoryImages[category].diagramimages[idx].checked =
  //               checked;
  //           }
  //           console.log("category,", categoryItem, categoryIndex);
  //         }
  //       );
  //     });
  //   } else {
  //     // Revert the checkbox to its previous state
  //     e.target.checked = !checked;
  //     console.log(
  //       "Check not allowed based on existing conditions or limit exceeded."
  //     );
  //   }
  // };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const [firstBoxId, setFirstBoxId] = useState("");/
  const [secondBoxId, setSecondBoxId] = useState("");

  let BtnSts: string;

  //   const handleCheckboxChange = (e: any, index: any, idx: any, details: any) => {
  //   const boxdoor_typecat = details.boxdoor_typecat;
  //   const categoryImages = updatedCategoryImages;
  //   const width = details.boxwidthcat;
  //   const depth = details.boxdepthcat;
  //   const Facianodoor = details.Facianodoor;
  //   const { checked } = e.target;
  //   const PrjId = details.prjidcat;
  //   const BoxId = details.boxid;

  //   console.log("index--->",index);
  //   console.log("Facianodoor",Facianodoor);
  //   console.log("categoryImages",categoryImages);
  //   console.log("idx", idx);
  //   console.log("boxdoor_typecat", boxdoor_typecat);
  //   console.log("checked", checked);
  //   console.log("width",width);
  //   console.log("depth",depth);

  //   let isAllowedToCheck = true;
  //   let checkedCount = 0;
  //   let firstCheckedType:any = null;
  //   let firstCheckedWidth = null;
  //   let firstCheckedDepth = null;
  //   let firstFaciaNodoor = null;

  //   // Count current checked items and check existing selections
  //   Object.keys(categoryImages).forEach((category) => {
  //     updatedCategoryImages[category].diagramimages.forEach(
  //       (categoryItem: any) => {
  //         if (categoryItem.checked) {
  //           checkedCount++;
  //           if (!firstCheckedType) {
  //             firstCheckedType = categoryItem.boxdoor_typecat;
  //             firstCheckedWidth = categoryItem.boxwidthcat;
  //             firstCheckedDepth = categoryItem.boxdepthcat;
  //             firstFaciaNodoor = categoryItem.Facianodoor;
  //           }
  //         }else{
  //           checkedCount--;
  //         }
  //       }
  //     );
  //   });

  //   if (checked) {
  //     if (checkedCount >= 2) {
  //       isAllowedToCheck = false;
  //       alert("You can only select up to two items.");
  //     } else if (firstCheckedType) {
  //       if (
  //         (firstCheckedType === "Sheet Door" && boxdoor_typecat === "Sheet Door") ||
  //         (firstCheckedType === "Glass Door" && boxdoor_typecat === "Glass Door") ||
  //         (firstCheckedType === "No Door" && boxdoor_typecat === "No Door") ||
  //         (firstCheckedType !== "No Door" && boxdoor_typecat !== "No Door") ||
  //         (firstCheckedType === "Facia" &&  Facianodoor === "0") ||
  //         ((firstCheckedType === "Facia" && firstFaciaNodoor ==="1") &&  boxdoor_typecat === "No Door") ||
  //         (firstCheckedType === "No Door" && Facianodoor ==="1") ||
  //         (firstCheckedType === "Facia" && boxdoor_typecat !== "No Door")
  //       ) {
  //         console.log("if====>,boxdoor_typecat,Facianodoor",firstCheckedType,boxdoor_typecat,Facianodoor);
  //         if( (firstCheckedType === "Facia" &&  Facianodoor === "1") ){
  //     console.log("muthu------>");
  //     isAllowedToCheck = true;
  //         } else if (firstFaciaNodoor ==="1" &&  boxdoor_typecat ==="Facia") {
  //           isAllowedToCheck = true;
  //         }else {
  //         isAllowedToCheck = false;
  //         alert("Selection not allowed based on door type.");
  //         }
  //       }  else if (width !== firstCheckedWidth || depth !== firstCheckedDepth) {
  //         console.log("elseifsurya");
  //         isAllowedToCheck = false;
  //         console.log("Width or depth does not match with the first checked item.");
  //         alert(`Width or depth mismatch.\nFirst checked item - Width: ${firstCheckedWidth}, Depth: ${firstCheckedDepth}\nCurrent item - Width: ${width}, Depth: ${depth}`);
  //       }else if(firstCheckedType === "Facia" && boxdoor_typecat === "Facia" && Facianodoor === 1){
  //         console.log("elseifsakthi");
  //         isAllowedToCheck = true;
  //       }
  //     }
  //   }

  //   if (isAllowedToCheck) {
  //     let newCheckedCount = 0;
  //     Object.keys(categoryImages).forEach((category) => {
  //       updatedCategoryImages[category].diagramimages.map(
  //         (categoryItem: any, categoryIndex: any) => {
  //           if (idx == categoryIndex) {
  //             updatedCategoryImages[category].diagramimages[idx].checked =
  //               checked;
  //           }
  //           if (updatedCategoryImages[category].diagramimages[categoryIndex].checked) {
  //             newCheckedCount++;
  //           }
  //           console.log("category,", categoryItem, categoryIndex);
  //         }
  //       );
  //     });
  //     if (newCheckedCount === 2) {
  //       console.log("Two checkboxes checked successfully.");
  //       BtnSts = "1";
  //     }
  //       if (!firstBoxId) {
  //       // setFirstBoxId(BoxId);
  //       console.log("First box checked:", BoxId);
  //     } else if (!secondBoxId) {
  //       // setSecondBoxId(BoxId);
  //       console.log("Second box checked:", BoxId);
  //     }
  //   } else {
  //     // Revert the checkbox to its previous state
  //     e.target.checked = !checked;
  //     console.log(
  //       "Check not allowed based on existing conditions or limit exceeded."
  //     );
  //   }
  //   if(BtnSts==="1"){
  //     console.log("finally");
  //     // setIsButtonDisabled(true);
  //   }
  // };

  let firstCheckedItem: any = null;
  let secondCheckedItem: any = null;

  const handleCheckboxChange = (e: any, index: any, idx: any, details: any) => {
    const boxdoor_typecat = details.boxdoor_typecat;
    const categoryImages = updatedCategoryImages;
    const width = details.boxwidthcat;
    const depth = details.boxdepthcat;
    const Facianodoor = details.Facianodoor;
    const { checked } = e.target;
    const PrjId = details.prjidcat;
    const BoxId = details.boxid;
    const boxIdsArray: any = [];
    let isAllowedToCheck = true;
    let checkedCount = 0;
    let firstCheckedType: any = null;
    let firstCheckedWidth = null;
    let firstCheckedDepth = null;
    let firstFaciaNodoor = null;
    Object.keys(categoryImages).forEach((category) => {
      categoryImages[category].diagramimages.forEach((categoryItem: any) => {
        if (categoryItem.checked) {
          boxIdsArray.push(categoryItem.id);
          checkedCount++;
          if (!firstCheckedType) {
            firstCheckedType = categoryItem.boxdoor_typecat;
            firstCheckedWidth = categoryItem.boxwidthcat;
            firstCheckedDepth = categoryItem.boxdepthcat;
            firstFaciaNodoor = categoryItem.Facianodoor;
            if (!firstCheckedItem) {
              firstCheckedItem = categoryItem;
            } else if (firstCheckedItem && !secondCheckedItem) {
              secondCheckedItem = categoryItem;
            }
          }
        } else {
          checkedCount--;
        }
      });
    });

    if (checked) {
      if (checkedCount >= 2) {
        isAllowedToCheck = false;
        alert("You can only select up to two items.");
      } else if (firstCheckedType) {
        console.log("firstCheckedType--->",firstCheckedType);
        if (
          (firstCheckedType === "Sheet Door" &&
            boxdoor_typecat === "Sheet Door") ||
          (firstCheckedType === "Glass Door" &&
            boxdoor_typecat === "Glass Door") ||
          (firstCheckedType === "No Door" && boxdoor_typecat === "No Door") ||
          (firstCheckedType !== "No Door" && boxdoor_typecat !== "No Door") ||
          (firstCheckedType === "Facia" && Facianodoor === "0") ||
          (firstCheckedType === "Facia" &&
            firstFaciaNodoor === "1" &&
            boxdoor_typecat === "No Door") ||
          (firstCheckedType === "No Door" && Facianodoor === "1") 
        ) {
          if (firstCheckedType === "Facia" && Facianodoor === "1") {
            isAllowedToCheck = true;
          } else if (firstFaciaNodoor === "1" && boxdoor_typecat === "Facia") {
            isAllowedToCheck = true;
          } else if (firstCheckedType != "No Door"  && boxdoor_typecat === "Facia") {
            console.log("000-->");
            isAllowedToCheck = true;
          }else if (firstCheckedType === "Facia" && boxdoor_typecat != "No Door") {
            console.log("111-->");
            isAllowedToCheck = true;
          }
          else {
            console.log("222-->firstCheckedType,boxdoor_typecat",firstCheckedType,boxdoor_typecat);
            isAllowedToCheck = false;
            alert("Selection not allowed based on door type.");
          }
        } else if (width !== firstCheckedWidth || depth !== firstCheckedDepth) {
          isAllowedToCheck = false;
          console.log(
            "Width or depth does not match with the first checked item."
          );
          alert(
            `Width or depth mismatch.\nFirst checked item - Width: ${firstCheckedWidth}, Depth: ${firstCheckedDepth}\nCurrent item - Width: ${width}, Depth: ${depth}`
          );
        } else if (
          firstCheckedType === "Facia" &&
          boxdoor_typecat === "Facia" &&
          Facianodoor === 1
        ) {
          isAllowedToCheck = true;
        }
      }
    }
    if (isAllowedToCheck && boxIdsArray.length < 2) {
      Object.keys(categoryImages).forEach((category) => {
        categoryImages[category].diagramimages.map(
          (categoryItem: any, categoryIndex: any) => {
            if (BoxId == categoryItem.boxid) {
              categoryImages[category].diagramimages[idx].checked = checked;
            }
            if (checked == false) {
              if (BoxId == categoryItem.boxid) {
                categoryImages[category].diagramimages[idx].checked = false;
              }
            }
            // console.log("category,", categoryItem, categoryItem.boxid);
          }
        );
      });
      if (!firstCheckedItem) {
        firstCheckedItem = details;
      } else if (firstCheckedItem && !secondCheckedItem) {
        secondCheckedItem = details;
      }
    } else if (isAllowedToCheck && checked == false) {
      Object.keys(categoryImages).forEach((category) => {
        categoryImages[category].diagramimages.map(
          (categoryItem: any, categoryIndex: any) => {
            if (checked == false) {
              if (BoxId == categoryItem.boxid) {
                categoryImages[category].diagramimages[idx].checked = false;
              }
            }
            console.log("category,", categoryItem, categoryItem.boxid);
          }
        );
      });
    } else {
      e.target.checked = !checked;
      console.log(
        "Check not allowed based on existing conditions or limit exceeded."
      );
    }
    setUpdatedCategoryImages(categoryImages);
    // console.log("updatedCategoryImages________11111111", updatedCategoryImages);
  };

  const CreateWardrope = () => {
    const categoryImages = updatedCategoryImages;
    const boxIdsArray: any = [];
    Object.keys(categoryImages).forEach((category) => {
      categoryImages[category].diagramimages.forEach((categoryItem: any) => {
        if (categoryItem.checked === true) {
          console.log("item checked----", categoryItem);
          boxIdsArray.push(categoryItem.id);
        }
      });
    });
    const boxIds = boxIdsArray.join(",");
    console.log("Boxids:", boxIds);

    if (boxIdsArray.length == 2) {
      console.log("sending request====>");
      let req = {
        prj_idfk: state.projectId,
        Boxids: boxIds,
      };
      console.log("request====>", req);
      callApi("POST", "wardrope_update.php", req)
        .then((res) => res.data)
        .then((response) => {
          console.log("response--->", response);
          showSuccess("Success!", "Wardrope Created Successfully!");

          Categeryimagesselect();
          formulajsquareselect();
          console.log("success----->");
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      alert("Please select both checkbox");
    }
  };

  return (
    <>
      {state.loader == true ? (
        <>
          <div className="loader-container">
            <Hourglass
              visible={true}
              height="80"
              width="80"
              ariaLabel="hourglass-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["#306cce", "#72a1ed"]}
            />
          </div>
        </>
      ) : (
        <main>
          <header>
            <div className="">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="icon backicon"
                onClick={back}
              />
              <h1 className="heading">Merlok Interiors</h1>
              <div>
                <FontAwesomeIcon
                  icon={faDownload}
                  className="icon printicon"
                  onClick={generatePDF}
                />
              </div>
              <div>
                <Button
                  className="reportbutton"
                  onClick={() =>
                    Reportpage(
                      state.projectId,
                      jsquareTotalNosState,
                      jsquareTotalState,
                      GlasslistTotalNosState,
                      TandembottomTotalNosState,
                      TandembackTotalNosState,
                      state.prjName,
                      singleTotalNosState,
                      ShelfTotalNosState,
                      DepthTotalNosState,
                      BkTotalNosState,
                      OnecrossOneTotalNosState,
                      OnepointtwoTotalNosState,
                      OnepointtwolipTotalNosState,
                      mmTotalNosState
                    )
                  }
                >
                  Report
                </Button>
              </div>
            </div>
          </header>
          <Row className="scrollRow">
            <Col lg="12" md="12">
              <h3 className="text-center" style={{ padding: "2%" }}>
                {" "}
                {state.prjName}
              </h3>
            </Col>
          </Row>
          <div className="container prjcon">
            <Row className="mb-3">
              <Col lg="2" md="2" sm="2" className="colprjname">
                <label>Project Name</label>
              </Col>
              <Col lg="4" md="4" sm="4" className="colprjname">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  name="prjname"
                  minLength={5}
                  maxLength={150}
                  value={state.prjName}
                  onChange={handleChangename}
                  onBlur={handleBlurname}
                  onKeyDown={handleKeyDownname}
                  required
                />
              </Col>
              <Col lg="2" md="2" sm="2" className="colprjname">
                <label>Description</label>
              </Col>
              <Col lg="4" md="4" sm="4" className="colprjname">
                <textarea
                  className="form-control"
                  placeholder=""
                  name="prjname"
                  value={state.prjDesc}
                  onChange={handleChangedesc}
                  onBlur={handleBlurname}
                  onKeyDown={handleKeyDownname}
                  required
                />
              </Col>
            </Row>
          </div>
          {/* //box form// */}
          <div className="container prjcon">
            <Row>
              <Col lg="6" md="6" className="flexbtn">
                <div className="card">
                  <div className="card-body">
                    <Row className="mb-3">
                      <Col lg="3" md="3" sm="3" xs="3" className="boxcardcol">
                        <label className="label"> Categery</label>
                      </Col>
                      <Col
                        lg="5"
                        md="5"
                        sm="5"
                        xs="5"
                        className="flexbtn mblviewgap"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          value={state.categeryselect}
                          onChange={selectcategerychange}
                        >
                          <option value="">Select Categery</option>
                          {state.categery.map((categery) => (
                            <option key={categery.id} value={categery.categery}>
                              {categery.categery}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>

                      {state.categeryselect == "" && (
                        <>
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="flexbtn mblviewgap"
                          >
                            <input
                              type="text"
                              className="form-control colorinput"
                              placeholder="Enter categery"
                              minLength={5}
                              maxLength={50}
                              value={state.boxtypecategery}
                              onChange={Boxcategrtytype}
                              onFocus={() =>
                                setState((prevState) => ({
                                  ...prevState,
                                  BoxTypecatError: "",
                                }))
                              }
                            />
                          </Col>
                          <div className="text-danger">
                            {state.BoxTypecatError}
                          </div>
                        </>
                      )}
                    </Row>

                    <Row className="mb-3">
                      <Col lg="3" md="3" sm="3" xs="3" className="mblviewgap">
                        <label>Height</label>
                        <input
                          type="number"
                          name="boxheight"
                          value={state.boxheight}
                          onChange={heightchange}
                          className="form-control"
                          placeholder=""
                          minLength={5}
                          maxLength={50}
                          onFocus={() =>
                            setState((prevState) => ({
                              ...prevState,
                              heightError: "",
                            }))
                          }
                          required
                        />
                      </Col>

                      <Col lg="3" md="3" sm="3" xs="3" className="mblviewgap">
                        <label>Width</label>
                        <input
                          type="number"
                          name="boxwidth"
                          value={state.boxwidth}
                          onChange={widthchange}
                          className="form-control"
                          placeholder=""
                          minLength={5}
                          maxLength={50}
                          onFocus={() =>
                            setState((prevState) => ({
                              ...prevState,
                              widthError: "",
                            }))
                          }
                          required
                        />
                      </Col>

                      <Col lg="3" md="3" sm="3" xs="3" className="mblviewgap">
                        <label>Depth</label>
                        <input
                          type="number"
                          name="boxdepth"
                          value={state.boxdepth}
                          onChange={depthchange}
                          className="form-control"
                          placeholder=""
                          minLength={5}
                          maxLength={50}
                          onFocus={() =>
                            setState((prevState) => ({
                              ...prevState,
                              depthError: "",
                            }))
                          }
                          required
                        />
                      </Col>
                      <Col lg="3" md="3" sm="3" xs="3" className="mblviewgap">
                        <label>Count</label>
                        <input
                          type="text"
                          name="BoxCount"
                          value={state.BoxCount}
                          onChange={BoxCountChange}
                          className="form-control"
                          placeholder=""
                          minLength={5}
                          maxLength={50}
                          onKeyPress={(e) => {
                            const isNumber = /[0-9]/.test(e.key);
                            if (!isNumber) {
                              e.preventDefault();
                            }
                          }}
                          onFocus={() =>
                            setState((prevState) => ({
                              ...prevState,
                              BoxcountError: "",
                            }))
                          }
                          required
                        />
                      </Col>
                      <div className="text-danger">{state.BoxcountError}</div>
                    </Row>

                    <Row className="mb-3">
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        <label className="">Box</label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={BoxChange}
                          value={state.BoxType}
                          required
                        >
                          <option value="Regular Box">Regular Box</option>
                          <option value="Open Box">Open Box</option>
                          <option value="Pa Box">Pa Box </option>
                        </Form.Select>
                      </Col>
                      {(state.BoxType === "Regular Box" ||
                        state.BoxType === "Pa Box") && (
                        <>
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="mblviewgap"
                          >
                            <label className="">Door Type</label>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={doortypechange}
                              value={state.boxdoortype}
                              required
                            >
                              <option value="No Door">No Door</option>
                              <option value="Sheet Door">Sheet Door</option>
                              <option value="Glass Door">Glass Door </option>
                              <option value="Facia">Facia</option>
                            </Form.Select>
                          </Col>

                          {(state.boxdoortype === "Sheet Door" ||
                            state.boxdoortype === "Glass Door") && (
                            <>
                              <Col
                                lg="4"
                                md="4"
                                sm="4"
                                xs="4"
                                className="mblviewgap"
                              >
                                <label className="">Quantity</label>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={doorselectchange}
                                  value={state.boxdoorselect}
                                >
                                  <option value="Single">Single Door</option>
                                  <option value="Double">Double Door</option>
                                </Form.Select>
                              </Col>
                            </>
                          )}
                          {state.boxdoortype === "Facia" && (
                            <>
                              <Col
                                lg="4"
                                md="4"
                                sm="4"
                                xs="4"
                                className="mblviewgap"
                              >
                                <label className=" whitetxt">type</label>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={FaciavalueOnchange}
                                  value={state.FaciaValue}
                                >
                                  <option value="0">BPO drawer</option>
                                  <option value="1">100mm drawer</option>
                                  <option value="2">Tandem drawer</option>
                                  <option value="3">Internal drawer</option>
                                </Form.Select>
                              </Col>
                              {(state.FaciaValue === "1" ||
                                state.FaciaValue === "2") && (
                                <>
                                  <Col
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    xs="4"
                                    className="mblviewgap"
                                  >
                                    <label className="label"></label>
                                    <Form.Select
                                      aria-label="Default select example"
                                      onChange={doorselectchange}
                                      value={state.boxdoorselect}
                                    >
                                      <option value="2 drawer">
                                        2 Drawer 1:1
                                      </option>
                                      <option value="2 drawer 2:1">
                                        2 Drawer 2:1
                                      </option>
                                      <option value="3 drawer">
                                        3 Drawer 1:1:1
                                      </option>
                                      <option value="3 drawer 2:1">
                                        3 Drawer 2:1
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </>
                              )}
                              {state.FaciaValue == "3" && (
                                <>
                                  <Col
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    xs="4"
                                    className="mblviewgap"
                                  >
                                    <label className="label"></label>
                                    <Form.Select
                                      aria-label="Default select example"
                                      onChange={doorselectchange}
                                      value={state.boxdoorselect}
                                    >
                                      <option value="Single Drawer">
                                        Single Drawer
                                      </option>
                                      <option value="Double Drawer">
                                        Double Drawer
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </>
                              )}
                              {state.FaciaValue == "3" &&
                                (state.boxdoorselect == "Single Drawer" ||
                                  state.boxdoorselect == "Double Drawer") && (
                                  <>
                                    <Col
                                      lg="4"
                                      md="4"
                                      sm="4"
                                      xs="4"
                                      className="nodoorcheck "
                                    >
                                      <label className="label">No Door </label>
                                      <input
                                        name="#value"
                                        type="checkbox"
                                        className="form-check-input "
                                        id="exampleCheck1"
                                        checked={state.FaciaInnodoor}
                                        onChange={FaciaInternalnodoor}
                                      />
                                    </Col>
                                  </>
                                )}
                            </>
                          )}
                        </>
                      )}
                    </Row>

                    {state.boxdoorselect === "Single" && (
                      <>
                        <Row className="mb-3">
                          <Col lg="6" md="6" sm="6" xs="6" className="">
                            <label className="">Door Side</label>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={doorselectpositionchange}
                              value={state.boxdoorposition}
                            >
                              <option value="left">Left</option>
                              <option value="right">Right</option>
                              <option value="top">Top</option>
                              <option value="bottom">Bottom</option>
                            </Form.Select>
                          </Col>
                        </Row>
                      </>
                    )}

                    {(state.boxdoortype === "Sheet Door" ||
                      state.boxdoortype === "Glass Door" ||
                      state.boxdoortype === "No Door") && (
                      <>
                        <Row className="mb-3">
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="mblviewgap"
                          >
                            <label className="">Shelf</label>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={shelfchange}
                              value={state.shelf}
                            >
                              <option value="Regular self">Regular Self</option>
                              <option value="Neck to Neck">
                                Neck to Neck{" "}
                              </option>
                              <option value="No Self">No Self </option>
                            </Form.Select>
                          </Col>
                          {(state.shelf === "Regular self" ||
                            state.shelf === "Neck to Neck") && (
                            <>
                              <Col
                                lg="2"
                                md="2"
                                sm="2"
                                xs="2"
                                className="mblviewgap"
                              >
                                <label className=" whitetxt">type</label>
                                <input
                                  type="number"
                                  name="shelf_count"
                                  onChange={shelfcountchange}
                                  value={state.shelfcount}
                                  className="form-control"
                                  placeholder=""
                                  minLength={5}
                                  maxLength={50}
                                  required
                                />
                              </Col>
                            </>
                          )}
                          {(state.shelf === "Regular self" ||
                            state.shelf === "Neck to Neck") && (
                            <>
                              <Col
                                lg="3"
                                md="3"
                                sm="3"
                                xs="3"
                                className="mblviewgap"
                              >
                                <label className="">Self Color</label>
                                {state.selfEntercolor == "" && (
                                  <>
                                    <Form.Select
                                      aria-label="Default select example"
                                      value={state.shelfcolor}
                                      onChange={shelfcolorchange}
                                    >
                                      {state.colors.map((color) => (
                                        <option
                                          key={color.id}
                                          value={color.colorname}
                                        >
                                          {color.colorname}
                                        </option>
                                      ))}
                                    </Form.Select>
                                  </>
                                )}
                              </Col>

                              <Col
                                lg="3"
                                md="3"
                                sm="3"
                                xs="3"
                                className="mblviewgap"
                              >
                                <label className=" whitetxt">type</label>
                                <input
                                  type="text"
                                  className="form-control colorinput"
                                  placeholder="Enter Color"
                                  minLength={5}
                                  maxLength={50}
                                  value={state.selfEntercolor}
                                  onChange={SelfEnterColor}
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    )}

                    <Row>
                      <Col lg="2" md="2" sm="2" xs="2" className=" ">
                        <label>#</label>
                      </Col>
                      <Col lg="3" md="3" sm="3" xs="3" className="">
                        <label>G</label>
                      </Col>
                      <Col lg="4" md="4" sm="4" xs="4" className="">
                        <label>IntDummy</label>
                      </Col>
                      <Col lg="3" md="3" sm="3" xs="3" className=" ">
                        <label>Fixed</label>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="2" md="2" sm="2" xs="2" className=" ">
                        <input
                          name="#value"
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          checked={state.asvalue}
                          onChange={asvalueOnchange}
                        />
                      </Col>

                      {(state.boxdoortype === "Sheet Door" ||
                        state.boxdoortype === "Glass Door" ||
                        state.boxdoortype === "Facia") && (
                        <>
                          <Col lg="3" md="3" sm="3" xs="3" className="">
                            <input
                              name="g_value"
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck1"
                              checked={state.boxgvalue}
                              onChange={gvaluechange}
                            />
                          </Col>
                          <Col lg="4" md="4" sm="4" xs="4" className="">
                            <input
                              type="checkbox"
                              className="form-check-input "
                              id="exampleCheck1"
                              checked={state.intdummyvalue}
                              onChange={intdummyvaluechange}
                            />
                          </Col>
                          <Col lg="3" md="3" sm="3" xs="3" className="">
                            <input
                              type="checkbox"
                              className="form-check-input "
                              id="exampleCheck1"
                              checked={state.boxfixed}
                              onChange={boxfixedchange}
                            />
                          </Col>

                          {state.boxgvalue === true && (
                            <>
                              <Col
                                lg="6"
                                md="6"
                                sm="6"
                                xs="6"
                                className="mblviewgap"
                              >
                                <label>G</label>
                                <input
                                  className="form-control"
                                  placeholder=""
                                  required
                                  name="g"
                                  minLength={5}
                                  maxLength={50}
                                  type="number"
                                  value={state.boxg}
                                  onChange={gchange}
                                />
                              </Col>
                            </>
                          )}
                          {state.boxgvalue === true && (
                            <>
                              <Col
                                lg="6"
                                md="6"
                                sm="6"
                                xs="6"
                                className="mblviewgap"
                              >
                                <label className=" whitetxt">type</label>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={gvaluepositionchange}
                                  value={state.boxgposition}
                                >
                                  <option value="top">Top</option>
                                  <option value="bottom">Bottom</option>
                                </Form.Select>
                              </Col>
                            </>
                          )}

                          {/* //intdummy// */}
                          {state.intdummyvalue === true && (
                            <>
                              <Col
                                lg="6"
                                md="6"
                                sm="6"
                                xs="6"
                                className="mblviewgap"
                              >
                                <label> Intdummy</label>
                                <input
                                  className="form-control"
                                  placeholder=""
                                  required
                                  name="boxID"
                                  minLength={5}
                                  maxLength={50}
                                  type="number"
                                  value={state.intdummy}
                                  onChange={intdummychange}
                                />
                              </Col>
                            </>
                          )}
                          {state.intdummyvalue === true && (
                            <>
                              <Col
                                lg="6"
                                md="6"
                                sm="6"
                                xs="6"
                                className="mblviewgap"
                              >
                                <label className=" whitetxt">type</label>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={intdummypositionchange}
                                  value={state.boxintdummyposition}
                                >
                                  <option value="left">left</option>
                                  <option value="right">right</option>
                                </Form.Select>
                              </Col>
                            </>
                          )}
                        </>
                      )}
                      {/* //fixed// */}
                      {state.boxfixed === true && (
                        <>
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="mblviewgap"
                          >
                            <label> Fixed</label>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={boxfixedpositionchange}
                              value={state.boxfixedposition}
                            >
                              <option value="left">left</option>
                              <option value="right">right</option>
                            </Form.Select>
                          </Col>
                        </>
                      )}
                      {(state.boxfixedposition === "left" ||
                        state.boxfixedposition === "right") && (
                        <>
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="mblviewgap"
                          >
                            <label className=" whitetxt">type</label>
                            <input
                              className="form-control"
                              placeholder=""
                              required
                              name="boxID"
                              minLength={5}
                              maxLength={50}
                              type="number"
                              value={parseInt(state.boxheight) - 51}
                              onChange={Fixedheightonchange}
                              readOnly
                            />
                          </Col>
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="mblviewgap"
                          >
                            <label className=" whitetxt">type</label>
                            <input
                              className="form-control"
                              placeholder="Width"
                              required
                              name="boxID"
                              minLength={5}
                              maxLength={50}
                              type="number"
                              value={state.FixedWidth}
                              onChange={Fixedwidthonchange}
                            />
                          </Col>
                        </>
                      )}
                    </Row>

                    <Row className="mb-3">
                      {state.boxdoortype !== "No Door" && (
                        <>
                          <Col lg="6" md="6" sm="6" xs="6" className="">
                            <label> J Square Color</label>
                          </Col>
                        </>
                      )}
                      <Col lg="6" md="6" sm="6" xs="6" className="">
                        <label className="">Single Lip Color</label>
                      </Col>
                    </Row>
                    <Row className="mb-3 colorrow">
                      {(state.boxdoortype === "Sheet Door" ||
                        state.boxdoortype === "Glass Door" ||
                        state.boxdoortype === "Facia") && (
                        <>
                          <Col
                            lg="3"
                            md="3"
                            sm="3"
                            xs="3"
                            className=" mblviewgap"
                          >
                            {state.JsquareEntercolor == "" && (
                              <>
                                <Form.Select
                                  aria-label="Default select example"
                                  value={state.jsquarecolor}
                                  onChange={jsquarecolorchange}
                                >
                                  {state.colors.map((color) => (
                                    <option
                                      key={color.id}
                                      value={color.colorname}
                                    >
                                      {color.colorname}
                                    </option>
                                  ))}
                                </Form.Select>
                              </>
                            )}
                          </Col>
                          <Col
                            lg="3"
                            md="3"
                            sm="3"
                            xs="3"
                            className=" mblviewgap"
                          >
                            <input
                              type="text"
                              className="form-control colorinput"
                              placeholder="Enter Color"
                              minLength={5}
                              maxLength={50}
                              value={state.JsquareEntercolor}
                              onChange={JsquareEnterColor}
                            />
                          </Col>
                        </>
                      )}

                      <Col lg="3" md="3" sm="3" xs="3" className="mblviewgap">
                        {state.singlelipentercolor == "" && (
                          <>
                            <Form.Select
                              aria-label="Default select example"
                              value={state.singlelipcolor}
                              onChange={singlelipcolorchange}
                            >
                              {state.colors.map((color) => (
                                <option key={color.id} value={color.colorname}>
                                  {color.colorname}
                                </option>
                              ))}
                            </Form.Select>
                          </>
                        )}
                      </Col>

                      <Col lg="3" md="3" sm="3" xs="3" className="mblviewgap">
                        <input
                          type="text"
                          className="form-control colorinput"
                          placeholder="Enter Color"
                          minLength={5}
                          maxLength={50}
                          value={state.singlelipentercolor}
                          onChange={SinglelipEntercolorchange}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col lg="6" md="6" sm="6" xs="6" className="">
                        <label>Bk Color</label>
                      </Col>
                      <Col lg="6" md="6" sm="6" xs="6" className="">
                        <label className="">Depth Color</label>
                      </Col>
                    </Row>
                    <Row className="mb-3 colorrow">
                      <Col lg="3" md="3" sm="3" xs="3" className="mblviewgap">
                        {state.bkentercolor == "" && (
                          <>
                            <Form.Select
                              aria-label="Default select example"
                              value={state.bkcolor}
                              onChange={bkcolorchange}
                            >
                              {state.colors.map((color) => (
                                <option key={color.id} value={color.colorname}>
                                  {color.colorname}
                                </option>
                              ))}
                            </Form.Select>
                          </>
                        )}
                      </Col>
                      <Col lg="3" md="3" sm="3" xs="3" className="mblviewgap">
                        <input
                          type="text"
                          className="form-control colorinput"
                          placeholder="Enter Color"
                          minLength={5}
                          maxLength={50}
                          value={state.bkentercolor}
                          onChange={bkEntercolorchange}
                        />
                      </Col>
                      <Col lg="3" md="3" sm="3" xs="3" className="mblviewgap">
                        {state.depthentercolor == "" && (
                          <>
                            <Form.Select
                              aria-label="Default select example"
                              value={state.depthcolor}
                              onChange={depthcolorchange}
                            >
                              {state.colors.map((color) => (
                                <option key={color.id} value={color.colorname}>
                                  {color.colorname}
                                </option>
                              ))}
                            </Form.Select>
                          </>
                        )}
                      </Col>

                      <Col lg="3" md="3" sm="3" xs="3" className="mblviewgap">
                        <input
                          type="text"
                          className="form-control colorinput"
                          placeholder="Enter Color"
                          minLength={5}
                          maxLength={50}
                          value={state.depthentercolor}
                          onChange={depthEntercolorchange}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        {state.boxdoorselect === "Single" ||
                        state.boxdoorselect === "Single Drawer" ||
                        state.boxdoorselect === "Double Drawer" ||
                        state.boxdoorselect === "" ? (
                          <>
                            <label className="">Remark</label>
                          </>
                        ) : (
                          <></>
                        )}
                        {state.boxdoorselect === "Double" && (
                          <>
                            <label className="">Left Remark</label>
                          </>
                        )}
                        {state.boxdoorselect === "2 drawer" ||
                        state.boxdoorselect === "2 drawer 2:1" ||
                        state.boxdoorselect === "3 drawer" ||
                        state.boxdoorselect === "3 drawer 2:1" ? (
                          <>
                            <label className="">First Remark</label>
                          </>
                        ) : (
                          <></>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Remark"
                          minLength={5}
                          maxLength={50}
                          value={state.BoxRemarks}
                          onChange={BoxRemarksonChange}
                        />
                      </Col>
                      {(state.boxdoorselect === "Double" ||
                        state.boxdoorselect === "2 drawer" ||
                        state.boxdoorselect === "2 drawer 2:1" ||
                        state.boxdoorselect === "3 drawer" ||
                        state.boxdoorselect === "3 drawer 2:1") && (
                        <>
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="mblviewgap"
                          >
                            {(state.boxdoorselect === "2 drawer" ||
                              state.boxdoorselect === "2 drawer 2:1" ||
                              state.boxdoorselect === "3 drawer" ||
                              state.boxdoorselect === "3 drawer 2:1") && (
                              <>
                                <label className="">Second Remark</label>
                              </>
                            )}
                            {state.boxdoorselect === "Double" && (
                              <>
                                <label className="">Right Remark</label>
                              </>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Remark"
                              minLength={5}
                              maxLength={50}
                              value={state.RightBoxRemarks}
                              onChange={RightRemarkonChange}
                            />
                          </Col>
                        </>
                      )}
                      {(state.boxdoorselect === "3 drawer" ||
                        state.boxdoorselect === "3 drawer 2:1") && (
                        <>
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="mblviewgap"
                          >
                            {(state.boxdoorselect === "3 drawer" ||
                              state.boxdoorselect === "3 drawer 2:1") && (
                              <>
                                <label className="">Third Remark</label>
                              </>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Remark"
                              minLength={5}
                              maxLength={50}
                              value={state.ThirdRemarks}
                              onChange={ThirdRemarkonChange}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      <Col lg="12" md="12" className="flexbtn mt-3">
                        <Button
                          variant="outline-primary subbutton"
                          type="submit"
                          onClick={boxhandelclick}
                        >
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="icon"
                          />
                          Add Box
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              {/* frame form// */}
              <Col lg="6" md="6" className="flexbtn">
                <div
                  className="card framecard"
                  style={{ background: "#e5e5e5" }}
                >
                  <div className="card-body">
                    <Row className="mb-3">
                      <Col lg="3" md="3" sm="3" xs="3" className="boxcardcol">
                        <label className="label"> Categery</label>
                      </Col>
                      <Col
                        lg="5"
                        md="5"
                        sm="5"
                        xs="5"
                        className="flexbtn mblviewgap"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          value={state.framecategeryselect}
                          onChange={selectframecategerychange}
                        >
                          <option value="">Select Categery</option>
                          {state.categery.map((categery) => (
                            <option key={categery.id} value={categery.categery}>
                              {categery.categery}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                      {state.framecategeryselect == "" && (
                        <>
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="flexbtn mblviewgap"
                          >
                            <input
                              type="text"
                              className="form-control colorinput"
                              placeholder="Enter categery"
                              minLength={5}
                              maxLength={50}
                              value={state.Frametypecategery}
                              onChange={Framecategerytype}
                              onFocus={() =>
                                setState((prevState) => ({
                                  ...prevState,
                                  BoxTypecatError: "",
                                }))
                              }
                            />
                          </Col>
                          <div className="text-danger">
                            {state.BoxTypecatError}
                          </div>
                        </>
                      )}
                    </Row>
                    <Row className="mb-3">
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        <label>Height</label>
                        <input
                          type="number"
                          name="frameheight"
                          value={state.frameheight}
                          onChange={frameheightchange}
                          className="form-control"
                          onFocus={() =>
                            setState((prevState) => ({
                              ...prevState,
                              frameheightError: "",
                            }))
                          }
                          placeholder=""
                          minLength={5}
                          maxLength={50}
                          required
                        />
                      </Col>
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        <label>Width</label>
                        <input
                          type="number"
                          name="framewidth"
                          value={state.framewidth}
                          onChange={framewidthchange}
                          onFocus={() =>
                            setState((prevState) => ({
                              ...prevState,
                              framewidthError: "",
                            }))
                          }
                          className="form-control"
                          placeholder=""
                          minLength={5}
                          maxLength={50}
                          required
                        />
                      </Col>
                      <br />
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        <label>Count</label>
                        <input
                          type="text"
                          name="FrameCount"
                          value={state.FrameCount}
                          onChange={FrameCountChange}
                          className="form-control"
                          placeholder=""
                          minLength={5}
                          maxLength={50}
                          onKeyPress={(e) => {
                            const isNumber = /[0-9]/.test(e.key);
                            if (!isNumber) {
                              e.preventDefault();
                            }
                          }}
                          onFocus={() =>
                            setState((prevState) => ({
                              ...prevState,
                              FramecountError: "",
                            }))
                          }
                          required
                        />
                      </Col>
                      <div className="text-danger">{state.FramecountError}</div>
                    </Row>

                    <Row className="mb-3">
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        <label className="">Frame</label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={frametypechange}
                          value={state.type}
                        >
                          <option value="1 x 1">1 x 1 </option>
                          <option value="1.25">1.25</option>
                          <option value="1.25 lip">1.25 lip</option>
                          <option value="EP">EP</option>
                          <option value="ES">ES</option>
                          <option value="1 x 1 Pa Frame">1 x 1 Pa Frame</option>
                          <option value="1.25 Pa Frame">1.25 Pa Frame</option>
                        </Form.Select>
                      </Col>

                      {state.type !== "EP" &&
                        state.type !== "ES" &&
                        state.type !== "1.25 lip" && (
                          <>
                            <Col
                              lg="4"
                              md="4"
                              sm="4"
                              xs="4"
                              className="mblviewgap"
                            >
                              <label className="">Door Type </label>
                              <Form.Select
                                aria-label="Default select example"
                                name="framedoor_type"
                                onChange={framedoortypechange}
                                value={state.framedoortype}
                              >
                                <option value="No Door">No Door</option>
                                <option value="Glass Door">Glass Door </option>
                                <option value="Sheet Door">Sheet Door</option>
                              </Form.Select>
                            </Col>
                            {state.type !== "EP" &&
                              state.type !== "ES" &&
                              state.type !== "1.25 lip" && (
                                <>
                                  {(state.framedoortype === "Glass Door" ||
                                    state.framedoortype === "Sheet Door") && (
                                    <>
                                      <Col
                                        lg="4"
                                        md="4"
                                        sm="4"
                                        xs="4"
                                        className="mblviewgap"
                                      >
                                        <label className="">Quantity</label>
                                        <Form.Select
                                          aria-label="Default select example"
                                          onChange={framedoorselectchange}
                                          value={state.framedoorselect}
                                        >
                                          <option value="Single">
                                            Single Door
                                          </option>
                                          <option value="Double">
                                            Double Door
                                          </option>
                                        </Form.Select>
                                      </Col>
                                    </>
                                  )}
                                </>
                              )}
                          </>
                        )}
                    </Row>
                    {state.type !== "EP" &&
                      state.type !== "ES" &&
                      state.type !== "1.25 lip" && (
                        <>
                          {state.framedoorselect === "Single" && (
                            <>
                              <Row className="mb-3">
                                <Col
                                  lg="6"
                                  md="6"
                                  sm="6"
                                  xs="6"
                                  className="mblviewgap"
                                >
                                  <label className=" "> Door Side</label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    onChange={frameselectpositionchange}
                                    value={state.framedoorposition}
                                  >
                                    <option value="left">Left</option>
                                    <option value="right">Right</option>
                                    <option value="top">Top</option>
                                    <option value="bottom">Bottom</option>
                                  </Form.Select>
                                </Col>
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    <Row className="mb-3">
                      {state.type !== "ES" && (
                        <>
                          <Col lg="6" md="6" sm="6" xs="6" className="">
                            <label>Frame Color</label>
                          </Col>
                        </>
                      )}
                      {state.type !== "EP" &&
                        state.type !== "ES" &&
                        state.type !== "1.25 lip" &&
                        state.framedoortype !== "No Door" && (
                          <>
                            <Col lg="6" md="6" sm="6" xs="6" className="">
                              <label className="">J Square Color</label>
                            </Col>
                          </>
                        )}
                    </Row>

                    <Row>
                      {state.type !== "ES" && (
                        <>
                          <Col
                            lg="3"
                            md="3"
                            sm="3"
                            xs="3"
                            className="mblviewgap"
                          >
                            {state.FrameEntercolor == "" && (
                              <>
                                <Form.Select
                                  aria-label="Default select example"
                                  value={state.frametypecolor}
                                  onChange={frametypecolorchange}
                                >
                                  {state.colors.map((color) => (
                                    <option
                                      key={color.id}
                                      value={color.colorname}
                                    >
                                      {color.colorname}
                                    </option>
                                  ))}
                                </Form.Select>
                              </>
                            )}
                          </Col>

                          <Col
                            lg="3"
                            md="3"
                            sm="3"
                            xs="3"
                            className="mblviewgap"
                          >
                            <input
                              type="text"
                              className="form-control colorinput"
                              placeholder="Enter Color"
                              minLength={5}
                              maxLength={50}
                              value={state.FrameEntercolor}
                              onChange={FrameEnterColor}
                            />
                          </Col>
                        </>
                      )}
                      {state.type !== "EP" &&
                        state.type !== "ES" &&
                        state.type !== "1.25 lip" && (
                          <>
                            {(state.framedoortype === "Glass Door" ||
                              state.framedoortype === "Sheet Door") && (
                              <>
                                <Col
                                  lg="3"
                                  md="3"
                                  sm="3"
                                  xs="3"
                                  className="mblviewgap"
                                >
                                  {state.FrameJsclrEnter == "" && (
                                    <>
                                      <Form.Select
                                        aria-label="Default select example"
                                        value={state.framedoorcolor}
                                        onChange={framedoorcolorchange}
                                      >
                                        {state.colors.map((color) => (
                                          <option
                                            key={color.id}
                                            value={color.colorname}
                                          >
                                            {color.colorname}
                                          </option>
                                        ))}
                                      </Form.Select>
                                    </>
                                  )}
                                </Col>
                                <Col
                                  lg="3"
                                  md="3"
                                  sm="3"
                                  xs="3"
                                  className="mblviewgap"
                                >
                                  <input
                                    type="text"
                                    className="form-control colorinput"
                                    placeholder="Enter Color"
                                    minLength={5}
                                    maxLength={50}
                                    value={state.FrameJsclrEnter}
                                    onChange={FrameJsclrChange}
                                  />
                                </Col>
                              </>
                            )}
                          </>
                        )}
                    </Row>

                    {state.type !== "EP" &&
                      state.type !== "ES" &&
                      state.type !== "1.25 lip" && (
                        <>
                          {(state.framedoortype === "Glass Door" ||
                            state.framedoortype === "Sheet Door") && (
                            <>
                              <Row className="mb-3">
                                <Col lg="4" md="4" sm="4" xs="4" className="">
                                  <label>G</label>
                                </Col>
                                <Col lg="4" md="4" sm="4" xs="4" className="">
                                  <label>IntDummy</label>
                                </Col>
                                <Col lg="4" md="4" sm="4" xs="4" className=" ">
                                  <label>Fixed</label>
                                </Col>
                              </Row>
                              <Row className="colorrow">
                                <Col lg="4" md="4" sm="4" xs="4" className=" ">
                                  <input
                                    type="checkbox"
                                    className="form-check-input "
                                    id="exampleCheck1"
                                    onChange={framegvaluechange}
                                    checked={state.framegvalue}
                                  />
                                </Col>
                                <Col lg="4" md="4" sm="4" xs="4" className="">
                                  <input
                                    type="checkbox"
                                    className="form-check-input "
                                    id="exampleCheck1"
                                    onChange={frameintdummyvaluechange}
                                    checked={state.frameintdummyvalue}
                                  />
                                </Col>
                                <Col lg="4" md="4" sm="4" xs="4" className="">
                                  <input
                                    type="checkbox"
                                    className="form-check-input "
                                    id="exampleCheck1"
                                    checked={state.framefixed}
                                    onChange={framefixedchange}
                                  />
                                </Col>
                                {state.framegvalue === true && (
                                  <>
                                    <Col
                                      lg="6"
                                      md="6"
                                      sm="6"
                                      xs="6"
                                      className="mblviewgap"
                                    >
                                      <label>G</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                        name="frameg"
                                        onChange={framegchange}
                                        value={state.frameg}
                                        minLength={5}
                                        maxLength={50}
                                        required
                                      />
                                    </Col>
                                  </>
                                )}
                                {state.framegvalue === true && (
                                  <>
                                    <Col
                                      lg="6"
                                      md="6"
                                      sm="6"
                                      xs="6"
                                      className="mblviewgap"
                                    >
                                      <label className=" greytxt">type</label>
                                      <Form.Select
                                        aria-label="Default select example"
                                        onChange={framegvaluepositionchange}
                                        value={state.frameboxgposition}
                                      >
                                        <option value="top">Top</option>
                                        <option value="bottom">Bottom</option>
                                      </Form.Select>
                                    </Col>
                                  </>
                                )}
                                {state.frameintdummyvalue === true && (
                                  <>
                                    <Col
                                      lg="6"
                                      md="6"
                                      sm="6"
                                      xs="6"
                                      className="mblviewgap"
                                    >
                                      <label> Intdummy</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        placeholder=""
                                        name="frameID"
                                        onChange={frameintdummychange}
                                        value={state.frameintdummy}
                                        minLength={5}
                                        maxLength={50}
                                        required
                                      />
                                    </Col>
                                  </>
                                )}

                                {state.frameintdummyvalue === true && (
                                  <>
                                    <Col
                                      lg="6"
                                      md="6"
                                      sm="6"
                                      xs="6"
                                      className="mblviewgap"
                                    >
                                      <label className=" greytxt">type</label>
                                      <Form.Select
                                        aria-label="Default select example"
                                        onChange={frameintdummypositionchange}
                                        value={state.frameintdummyposition}
                                      >
                                        <option value="left">left</option>
                                        <option value="right">right</option>
                                      </Form.Select>
                                    </Col>
                                    {state.framefixed === true && (
                                      <>
                                        <Col
                                          lg="4"
                                          md="4"
                                          sm="4"
                                          xs="4"
                                          className="mblviewgap"
                                        >
                                          <label> Fixed</label>
                                          <Form.Select
                                            aria-label="Default select example"
                                            onChange={framefixedpositionchange}
                                            value={state.framefixedposition}
                                          >
                                            <option value="left">left</option>
                                            <option value="right">right</option>
                                          </Form.Select>
                                        </Col>
                                      </>
                                    )}
                                    {(state.framefixedposition === "left" ||
                                      state.framefixedposition === "right") && (
                                      <>
                                        <Col
                                          lg="4"
                                          md="4"
                                          sm="4"
                                          xs="4"
                                          className="mblviewgap"
                                        >
                                          <label className=" greytxt">
                                            type
                                          </label>
                                          <input
                                            className="form-control"
                                            placeholder=""
                                            required
                                            name="boxID"
                                            minLength={5}
                                            maxLength={50}
                                            type="number"
                                            value={
                                              parseInt(state.frameheight) - 51
                                            }
                                            onChange={FrameFixedheightonchange}
                                            readOnly
                                          />
                                        </Col>
                                        <Col
                                          lg="4"
                                          md="4"
                                          sm="4"
                                          xs="4"
                                          className="mblviewgap"
                                        >
                                          <label className=" greytxt">
                                            type
                                          </label>
                                          <input
                                            className="form-control"
                                            placeholder="Width"
                                            required
                                            name="boxID"
                                            minLength={5}
                                            maxLength={50}
                                            type="number"
                                            value={state.FrameFixedWidth}
                                            onChange={FrameFixedwidthonchange}
                                          />
                                        </Col>
                                      </>
                                    )}
                                  </>
                                )}
                              </Row>
                            </>
                          )}
                        </>
                      )}

                    <Row className="mb-3">
                      <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                        {(state.type === "1.25 lip" ||
                          state.type === "EP" ||
                          state.type === "ES" ||
                          state.framedoorselect === "Single" ||
                          state.framedoortype === "No Door") && (
                          <>
                            <label className="">Remark</label>
                          </>
                        )}

                        {state.framedoorselect === "Double" && (
                          <>
                            <label className="">Left Remark</label>
                          </>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Remark"
                          minLength={5}
                          maxLength={50}
                          value={state.FrameRemarks}
                          onChange={FrameRemarksonChange}
                        />
                      </Col>
                      {state.framedoorselect === "Double" && (
                        <>
                          <Col
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mblviewgap"
                          >
                            {state.framedoorselect === "Double" && (
                              <>
                                <label className="">Right Remark</label>
                              </>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Remark"
                              minLength={5}
                              maxLength={50}
                              value={state.RightFrameRemarks}
                              onChange={RightRemarkFrameChange}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      <Col lg="12" md="12" className="flexbtn mt-3">
                        <Button
                          variant="outline-primary subbutton"
                          type="submit"
                          onClick={framehandleclick}
                        >
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            className="icon"
                          />
                          Add Frame
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br />
          {/* //boxframetable// */}
          {Object.keys(updatedCategoryImages).map((category, mainindex) => (
            <React.Fragment key={mainindex}>
              <div
                className=""
                style={{ paddingLeft: "2%", paddingRight: "2%" }}
              >
                <Row className="mblrow">
                  <Col lg="6" md="6" sm="6" xs="6" style={{ display: "flex" }}>
                    <h2>{category}</h2>
                    <Button
                      className="btn btn-outline-primary wardropebtn"
                      onClick={() => CreateWardrope()}
                    >
                      Create Wardrobe
                    </Button>
                  </Col>
                  <Col lg="5" md="5" sm="5" xs="5"></Col>
                  <Col lg="1" md="1" sm="1" xs="1" style={{ textAlign: "end" }}>
                    <Button
                      className="btn btn-outline-primary submitbutton"
                      onClick={() => handleToggle(category)}
                    >
                      {updatedCategoryImages[category].accordianSts == "true"
                        ? "-"
                        : "+"}
                    </Button>
                  </Col>
                </Row>
                {updatedCategoryImages[category].accordianSts == "true" && (
                  <Table responsive="md lg" striped bordered hover>
                    <thead>
                      <tr>
                        <th className="edittd">Wardrope</th>
                        <th className="edittd">S.No</th>
                        <th className="edittd"> Name</th>
                        <th className="edittd">Height</th>
                        <th className="edittd">Width</th>
                        <th className="edittd">Depth</th>
                        <th className="edittd">Edit</th>
                        <th className="edittd">Duplicate</th>
                        <th className="edittd">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {updatedCategoryImages[category].diagramimages.map(
                        (details: any, idx: number, index: number) => (
                          <>
                            <tr
                              key={details.id || details.idcat}
                              draggable
                              onDragStart={() =>
                                handleTableDragStart(idx, details)
                              }
                              onDragOver={() =>
                                handleTableDragOver(idx, details)
                              }
                              onDragEnd={() => handleTableDragEnd(category)}
                            >
                              <td className="edittd">
                                {details.boxheightcat &&
                                  details.boxwidthcat &&
                                  details.boxdepthcat && (
                                    <>
                                      <input
                                        name={`#value${idx}`}
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`exampleCheck${idx}`}
                                        checked={
                                          updatedCategoryImages[category]
                                            .diagramimages[idx].checked
                                        }
                                        disabled={
                                          updatedCategoryImages[category]
                                            .diagramimages[idx]
                                            .Wardropestatus == "1"
                                        }
                                        onChange={(e: any) => {
                                          handleCheckboxChange(
                                            e,
                                            index,
                                            idx,
                                            details
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                              </td>
                              <td className="edittd">{idx + 1}</td>
                              <td className="edittd">No-{productCounter++}</td>
                              <td className="edittd">
                                {details.boxheightcat ? (
                                  <>{details.boxheightcat}</>
                                ) : (
                                  <>{details.frameheightcat}</>
                                )}
                              </td>
                              <td className="edittd">
                                {details.boxwidthcat ? (
                                  <>{details.boxwidthcat}</>
                                ) : (
                                  <>{details.framewidthcat}</>
                                )}
                              </td>
                              <td className="edittd">{details.boxdepthcat}</td>
                              <td className="edittd">
                                {details.boxheightcat ? (
                                  <>
                                    <Button
                                      className="editbutton"
                                      onClick={() => handleShow(details.id)}
                                    >
                                      Edit
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      className="editbutton"
                                      onClick={() =>
                                        framehandleShow(details.idcat)
                                      }
                                    >
                                      Edit
                                    </Button>
                                  </>
                                )}
                              </td>
                              <td className="edittd">
                                {details.boxheightcat ? (
                                  <>
                                    <Button
                                      className="dublicatebutton"
                                      onClick={() =>
                                        handleDuplicate(details.id)
                                      }
                                    >
                                      Duplicate
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      className="dublicatebutton"
                                      onClick={() =>
                                        frameDuplicate(details.idcat)
                                      }
                                    >
                                      Duplicate
                                    </Button>
                                  </>
                                )}
                              </td>
                              <td className="edittd">
                                {details.boxheightcat ? (
                                  <>
                                    <Button
                                      className="deletebtn"
                                      onClick={() => boxtableDelete(details.id,details.wardrope_ids)}
                                    >
                                      Delete
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      className="deletebtn"
                                      onClick={() =>
                                        frametableDelete(details.idcat)
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        )
                      )}
                    </tbody>
                  </Table>
                )}
              </div>
            </React.Fragment>
          ))}
          {/* //boxframeimages// */}
          <div className="pdf-section">
            <div className="" style={{ paddingLeft: "2%", paddingRight: "2%" }}>
              <Row className="print-row">
                <div>
                  {(updatedCategoryImages?.length > 0 ||
                    updatedCategoryImages?.length > 0) && (
                    <>
                      <div className="all2dimage">
                        <button
                          onClick={generatePDF}
                          className="all2ddwnlbutton"
                        >
                          <FontAwesomeIcon icon={faDownload} /> Download All 2D
                        </button>
                      </div>
                    </>
                  )}
                  <br />
                  {Object.keys(updatedCategoryImages).map((category, index) => (
                    <React.Fragment key={index}>
                      <Row>
                        <Col lg="6" md="6" sm="12" xs="12">
                          <h2 onClick={() => handleChangeCategory(category)}>
                            {category}
                          </h2>
                        </Col>
                        <Col
                          lg="2"
                          md="2"
                          sm="4"
                          xs="4"
                          className="remarkcol mblviewgap"
                        >
                          <input
                            type="text"
                            name={category}
                            className="form-control"
                            placeholder="Left Remark"
                            minLength={5}
                            maxLength={50}
                            value={Remarkstate[category]}
                            onChange={(event) =>
                              handleRemarkChange(event, category)
                            }
                          />
                        </Col>
                        <Col
                          lg="2"
                          md="2"
                          sm="4"
                          xs="4"
                          className="remarkcol mblviewgap"
                        >
                          <input
                            type="text"
                            name={category}
                            className="form-control"
                            placeholder="Right Remark"
                            minLength={5}
                            maxLength={50}
                            value={RightRemarkstate[category]}
                            onChange={(event) =>
                              handleRemarkRightChange(event, category)
                            }
                          />
                        </Col>
                        <Col
                          lg="1"
                          md="1"
                          sm="2"
                          xs="2"
                          className="remarkcol mblviewgap"
                        >
                          <Button
                            variant="outline-primary submitbutton"
                            type="submit"
                            onClick={() =>
                              BlurUpdateRemark(category, updatedCategoryImages)
                            }
                          >
                            Submit
                          </Button>
                        </Col>
                        <Col lg="1" md="1" sm="2" xs="2" className="remarkcol">
                          <Button
                            className="btn btn-outline-primary submitbutton"
                            onClick={() => handleToggle(category)}
                          >
                            {updatedCategoryImages[category].accordianSts ==
                            "true"
                              ? "-"
                              : "+"}
                          </Button>
                        </Col>
                      </Row>
                      {updatedCategoryImages[category].accordianSts ==
                        "true" && (
                        <div className="category-images">
                          <Row className="print-row">
                            {updatedCategoryImages[category].diagramimages.map(
                              (details: any, idx: number) => (
                                <Col md={4} key={idx}>
                                  <div key={idx} className="category-image">
                                    {/* boximage// */}
                                    {details.id && (
                                      <>
                                        <p className="svgletter svgtext">டு</p>
                                        <div className="square-box">
                                          <div
                                            style={{
                                              transform: "rotate(272deg)",
                                            }}
                                          >
                                              <span>
                                                      {details.boxheightcat}
                                                    </span>
                                            {/* {details.Wardropestatus == "1" &&
                                            details.boxdoor_typecat !=
                                              "No Door" ? (
                                              <>
                                                <span>
                                                  {details.WardropeHeight}
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                {details.Wardropestatus ==
                                                  "0" && (
                                                  <>
                                                    <span>
                                                      {details.boxheightcat}
                                                    </span>
                                                  </>
                                                )}
                                              </>
                                            )} */}
                                          </div>
                                          {details.AsSymbol && (
                                            <>
                                              <div
                                                style={{
                                                  marginTop: `${
                                                    details.updatedboxheightcat +
                                                    12
                                                  }px`,
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontSize: "20px",
                                                    fontWeight: "700",
                                                  }}
                                                >
                                                  #
                                                </span>
                                              </div>
                                            </>
                                          )}
                                          <svg
                                            className="shape rectangle"
                                            width={`${
                                              details.updatedboxwidthcat + 35
                                            }px`}
                                            height={`${
                                              details.updatedboxheightcat + 35
                                            }px`}
                                          >
                                            {/* box svg square line border & Pa Box// */}
                                            {details.BoxType == "Pa Box" ? (
                                              <>
                                                <svg
                                                  className="shape rectangle"
                                                  width={`${
                                                    details.updatedboxwidthcat +
                                                    35
                                                  }px`}
                                                  height={`${
                                                    details.updatedboxheightcat +
                                                    7
                                                  }px`}
                                                >
                                                  <rect
                                                    width={`${details.updatedboxwidthcat}px`}
                                                    height={`${details.updatedboxheightcat}px`}
                                                    fill="#ffffff"
                                                    stroke="black"
                                                    x="10"
                                                    y="10"
                                                  />
                                                </svg>
                                              </>
                                            ) : (
                                              <>
                                                <rect
                                                  width={`${details.updatedboxwidthcat}px`}
                                                  height={`${details.updatedboxheightcat}px`}
                                                  fill="#ffffff"
                                                  stroke="black"
                                                  x="10"
                                                  y="10"
                                                />
                                              </>
                                            )}

                                            {/* //Fixed Posistion left right // */}
                                            {details.boxfixed == "1" ? (
                                              <>
                                                {details.boxfixed_position ==
                                                  "left" && (
                                                  <>
                                                    <rect
                                                      width={`${
                                                        details.updatedboxheightcat /
                                                          2 +
                                                        5
                                                      }px`}
                                                      height={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        90
                                                      }px`}
                                                      fill="#ffffff"
                                                      stroke="black"
                                                      x={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                          7 +
                                                        15
                                                      }`}
                                                      y={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        10
                                                      }`}
                                                      style={{
                                                        border:
                                                          "3px solid #000000",
                                                      }}
                                                    />
                                                  </>
                                                )}
                                                {details.boxfixed_position ==
                                                  "right" && (
                                                  <>
                                                    <rect
                                                      width={`${
                                                        details.updatedboxheightcat /
                                                          2 +
                                                        5
                                                      }px`}
                                                      height={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        90
                                                      }px`}
                                                      fill="#ffffff"
                                                      stroke="black"
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                        2.5
                                                      }`}
                                                      y={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        10
                                                      }`}
                                                      style={{
                                                        border:
                                                          "3px solid #000000",
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            <svg>
                                              {/* box top x border  */}
                                              <rect
                                                fill="#ffffff"
                                                stroke="black"
                                                strokeWidth="2"
                                                x={`${
                                                  (details.updatedboxwidthcat /
                                                    100) *
                                                  9
                                                }`}
                                                y="1"
                                                width="20"
                                                height="20"
                                              />
                                              <text
                                                x={`${
                                                  (details.updatedboxwidthcat /
                                                    100) *
                                                    9 +
                                                  4
                                                }`}
                                                y="17"
                                                fill="black"
                                                font-size="25"
                                              >
                                                x
                                              </text>
                                            </svg>
                                            {/* //open box circle // */}
                                            {details.BoxType == "Open Box" && (
                                              <>
                                                <circle
                                                  r="8"
                                                  cx={`${
                                                    (details.updatedboxwidthcat /
                                                      100) *
                                                    102
                                                  }`}
                                                  cy={`${10}`}
                                                  stroke="black"
                                                  stroke-width="2"
                                                  fill="white"
                                                />
                                              </>
                                            )}
                                            {details.BoxType == "Pa Box" && (
                                              <>
                                                <svg
                                                  width="30px"
                                                  height="20px"
                                                  x={`${
                                                    details.updatedboxwidthcat -
                                                    20
                                                  }`}
                                                >
                                                  <rect
                                                    fill="#ffffff"
                                                    stroke="black"
                                                    strokeWidth="2"
                                                    y="1"
                                                    width="20"
                                                    height="20"
                                                  />
                                                </svg>
                                              </>
                                            )}

                                            {/* //boxdepth // */}
                                            {details.boxheightcat &&
                                              state.staticboxdepth && (
                                                <>
                                                  <line
                                                    x1="10"
                                                    y1={
                                                      Number(
                                                        details.updatedboxheightcat +
                                                          10
                                                      ) -
                                                      Number(
                                                        state.staticboxdepth
                                                      )
                                                    }
                                                    x2="35"
                                                    y2={
                                                      Number(
                                                        details.updatedboxheightcat
                                                      ) -
                                                      Number(
                                                        state.staticboxdepth
                                                      ) -
                                                      25
                                                    }
                                                    stroke="#000"
                                                    strokeWidth="4"
                                                  />
                                                  <text
                                                    x="35"
                                                    y={
                                                      Number(
                                                        details.updatedboxheightcat
                                                      ) -
                                                      Number(
                                                        state.staticboxdepth
                                                      ) -
                                                      30
                                                    }
                                                    fill="#000000"
                                                  >
                                                    {details.boxdepthcat}
                                                  </text>
                                                  {/* //shelfline// */}
                                                  {Array.from(
                                                    {
                                                      length:
                                                        details.boxshelf_countcat,
                                                    },
                                                    (_, i) => {
                                                      const lineCount =
                                                        Number(
                                                          details.boxshelf_countcat
                                                        ) + 1;
                                                      const lineGap =
                                                        Number(
                                                          details.updatedboxheightcat
                                                        ) / lineCount;
                                                      const y =
                                                        lineGap * (i + 1);
                                                      return (
                                                        <line
                                                          key={i}
                                                          x1="10"
                                                          y1={y}
                                                          x2={
                                                            details.updatedboxwidthcat +
                                                            10
                                                          }
                                                          y2={y}
                                                          stroke="#000"
                                                          strokeWidth="1"
                                                          strokeDasharray="3,3"
                                                        />
                                                      );
                                                    }
                                                  )}
                                                </>
                                              )}

                                            {/* //facia internaldrawer 2d single // */}
                                            {details.boxdoor_typecat ==
                                              "Facia" &&
                                              details.Drawerselect == "3" &&
                                              details.boxdoor_selectcat ==
                                                "Single Drawer" && (
                                                <>
                                                  <line
                                                    x1="10"
                                                    y1="90"
                                                    x2={
                                                      details.updatedboxwidthcat +
                                                      10
                                                    }
                                                    y2="90"
                                                    stroke="#000"
                                                    strokeWidth="1"
                                                  />
                                                  <text
                                                    x={`${
                                                      details.updatedboxwidthcat /
                                                        2 -
                                                      10
                                                    }`}
                                                    y={
                                                      details.updatedboxheightcat /
                                                        4 -
                                                      5
                                                    }
                                                    fill="black"
                                                    font-size="60"
                                                  >
                                                    -
                                                  </text>
                                                </>
                                              )}
                                            {/* //facia internaldrawer 2d double // */}
                                            {details.boxdoor_typecat ==
                                              "Facia" &&
                                              details.Drawerselect == "3" &&
                                              details.boxdoor_selectcat ==
                                                "Double Drawer" && (
                                                <>
                                                  <line
                                                    x1="10"
                                                    y1="90"
                                                    x2={
                                                      details.updatedboxwidthcat +
                                                      10
                                                    }
                                                    y2="90"
                                                    stroke="#000"
                                                    strokeWidth="1"
                                                  />
                                                  <line
                                                    x1={
                                                      details.updatedboxwidthcat /
                                                        2 +
                                                      5
                                                    }
                                                    y1="10"
                                                    x2={
                                                      details.updatedboxwidthcat /
                                                        2 +
                                                      5
                                                    }
                                                    y2="90"
                                                    stroke="#000"
                                                    strokeWidth="1"
                                                  />
                                                  <text
                                                    x={`${
                                                      details.updatedboxwidthcat /
                                                        3.5 -
                                                      10
                                                    }`}
                                                    y={
                                                      details.updatedboxheightcat /
                                                        4 -
                                                      5
                                                    }
                                                    fill="black"
                                                    font-size="60"
                                                  >
                                                    -
                                                  </text>
                                                  <text
                                                    x={`${
                                                      details.updatedboxwidthcat /
                                                        1.3 -
                                                      10
                                                    }`}
                                                    y={
                                                      details.updatedboxheightcat /
                                                        4 -
                                                      5
                                                    }
                                                    fill="black"
                                                    font-size="60"
                                                  >
                                                    -
                                                  </text>
                                                </>
                                              )}
                                            {/* //jsquare box center height width // */}
                                            {details.jsquareboxheight != "" &&
                                            details.jsquareboxwidth != "" ? (
                                              <>
                                                {details.boxdoor_typecat ==
                                                  "Facia" &&
                                                details.boxdoor_selectcat ==
                                                  "3 drawer" ? (
                                                  <>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                        2
                                                      }px`}
                                                      y={`${
                                                        details.updatedboxheightcat /
                                                          2 +
                                                        30
                                                      }px`}
                                                      textAnchor="middle"
                                                      fill="#000000"
                                                      dominantBaseline="middle"
                                                      fontSize="16px"
                                                    >
                                                      {details.boxdoor_typecat ===
                                                        "Glass Door" &&
                                                      details.boxdoor_selectcat ===
                                                        "Double" ? (
                                                        <>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              3
                                                            }px`}
                                                            dy="-0.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {details.updatedboxheightcat +
                                                              2}
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              3
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {details.updatedboxwidthcat +
                                                              3}
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 -
                                                              18
                                                            }px`}
                                                            y={`${
                                                              details.updatedboxheightcat /
                                                                3 +
                                                              40
                                                            }px`}
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.jsquareboxheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 -
                                                              18
                                                            }px`}
                                                            y={`${
                                                              details.updatedboxheightcat /
                                                                3 +
                                                              55
                                                            }px`}
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 +
                                                              5
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      )}
                                                    </text>
                                                  </>
                                                ) : (
                                                  //facia 2 drawer glass door if //
                                                  <>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                        2
                                                      }px`}
                                                      y={`${
                                                        details.updatedboxheightcat /
                                                          2 +
                                                        30
                                                      }px`}
                                                      textAnchor="middle"
                                                      fill="#000000"
                                                      dominantBaseline="middle"
                                                      fontSize="16px"
                                                    >
                                                      {details.boxdoor_typecat ===
                                                        "Glass Door" &&
                                                      details.boxdoor_selectcat ===
                                                        "Double" ? (
                                                        <>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            dy="-0.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {parseInt(
                                                              details.jsquareboxheight
                                                            ) }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {parseInt(
                                                              details.jsquareboxwidth
                                                            ) 
                                                               +
                                                              "G"}
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      ) : details.boxfixed ==
                                                          "1" &&
                                                        details.boxfixed_position ==
                                                          "left" &&
                                                        details.boxdoor_selectcat ===
                                                          "Single" ? (
                                                        <>
                                                          {/* //fixed left fixed value // */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2.7
                                                            }px`}
                                                            dy="-4.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.boxfixedheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2.7
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.boxfixedwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2.7 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            - 1
                                                          </tspan>
                                                          {/* //fixed jsquare value // */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              1.2
                                                            }px`}
                                                            dy="-0.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.jsquareboxheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              1.2
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                1.2 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      ) : details.boxfixed ==
                                                          "1" &&
                                                        details.boxfixed_position ==
                                                          "right" &&
                                                        details.boxdoor_selectcat ===
                                                          "Single" ? (
                                                        <>
                                                          {/* //fixed right fixed value single door // */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              1.8
                                                            }px`}
                                                            dy="-4.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.boxfixedheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              1.8
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.boxfixedwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                1.8 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            - 1
                                                          </tspan>
                                                          {/* //fixed jsquare value // */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              4.2
                                                            }px`}
                                                            dy="-0.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.jsquareboxheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              4.2
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                4.2 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      ) : details.boxdoor_selectcat ===
                                                        "Single" ? (
                                                        <>
                                                          {/* // glass door else // */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            dy="-0.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {parseInt(
                                                              details.jsquareboxheight
                                                            )}
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {parseInt(
                                                              details.jsquareboxwidth
                                                            ) + "G"}
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      ) : details.boxdoor_typecat ==
                                                          "Facia" &&
                                                        details.boxdoor_selectcat ==
                                                          "2 drawer 2:1" ? (
                                                        <>
                                                          {/* //facia 2 drawer 2:1 1st value// */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            dy="-0.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.jsquareboxheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                          {/* //facia 2 drawer 2:1 2st value// */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            dy="-0.5em"
                                                            y={`${
                                                              details.updatedboxheightcat /
                                                                3 +
                                                              22
                                                            }px`}
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {details.jsquareboxheight /
                                                              2}
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            dy="1.1em"
                                                            y={`${
                                                              details.updatedboxheightcat /
                                                                3 +
                                                              15
                                                            }px`}
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                            y={`${
                                                              details.updatedboxheightcat /
                                                                3 +
                                                              30
                                                            }px`}
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      ) : details.boxdoor_typecat ==
                                                          "Facia" &&
                                                        details.boxdoor_selectcat ==
                                                          "3 drawer 2:1" ? (
                                                        <>
                                                          {/* //facia 3 drawer 2:1 1st value// */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 -
                                                              18
                                                            }px`}
                                                            dy="2.2em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.jsquareboxheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 -
                                                              18
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 +
                                                              10
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>

                                                          {/* //facia 3 drawer 2:1 2st value// */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 -
                                                              18
                                                            }px`}
                                                            y={`${
                                                              details.updatedboxheightcat /
                                                                3 +
                                                              40
                                                            }px`}
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {details.jsquareboxheight /
                                                              2}
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 -
                                                              18
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 +
                                                              10
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                          {/* //facia 3 drawer 2:1 3st value// */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 -
                                                              18
                                                            }px`}
                                                            y={`${
                                                              details.updatedboxheightcat /
                                                                5 -
                                                              10
                                                            }px`}
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {details.jsquareboxheight /
                                                              2}
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 -
                                                              18
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                3 +
                                                              10
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      ) : details.boxfixed ==
                                                          "1" &&
                                                        details.boxfixed_position ==
                                                          "left" ? (
                                                        <>
                                                          {/* //fixed left fixed value // */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2.4
                                                            }px`}
                                                            dy="-5.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.boxfixedheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2.4
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.boxfixedwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2.4 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            - 1
                                                          </tspan>
                                                          {/* //fixed jsquare value // */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              1.2
                                                            }px`}
                                                            dy="-0.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.jsquareboxheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              1.2
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                1.2 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      ) : details.boxfixed ==
                                                          "1" &&
                                                        details.boxfixed_position ==
                                                          "right" ? (
                                                        <>
                                                          {/* //fixed right fixed value 2door// */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              1.6
                                                            }px`}
                                                            dy="-5.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.boxfixedheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              1.6
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.boxfixedwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                1.6 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            - 1
                                                          </tspan>
                                                          {/* //fixed jsquare value // */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              4.2
                                                            }px`}
                                                            dy="-0.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.jsquareboxheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              4.2
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                4.2 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      ) : (
                                                        <>
                                                          {/* // glass door else // */}
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            dy="-0.5em"
                                                            style={{
                                                              textDecoration:
                                                                "underline",
                                                            }}
                                                          >
                                                            {
                                                              details.jsquareboxheight
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            dy="1.1em"
                                                          >
                                                            {
                                                              details.jsquareboxwidth
                                                            }
                                                          </tspan>
                                                          <tspan
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 +
                                                              30
                                                            }px`}
                                                            dy="-0.7em"
                                                          >
                                                            -{" "}
                                                            {
                                                              details.jsquareboxquantity
                                                            }
                                                          </tspan>
                                                        </>
                                                      )}
                                                    </text>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* //single door top left right bottom // */}
                                            {details.boxdoor_selectcat ==
                                            "Single" ? (
                                              <>
                                                {details.singledoorposition ==
                                                  "top" && (
                                                  <>
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                          25 +
                                                        10
                                                      }`}
                                                      cy={`${10}`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                        80
                                                      }`}
                                                      cy={`${10}`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                  </>
                                                )}
                                                {details.singledoorposition ==
                                                  "bottom" && (
                                                  <>
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                          25 +
                                                        10
                                                      }`}
                                                      cy={`${
                                                        details.updatedboxheightcat +
                                                        10
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                        80
                                                      }`}
                                                      cy={`${
                                                        details.updatedboxheightcat +
                                                        10
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                  </>
                                                )}
                                                {details.singledoorposition ==
                                                  "right" && (
                                                  <>
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        details.updatedboxwidthcat +
                                                        10
                                                      }`}
                                                      cy={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        25
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        details.updatedboxwidthcat +
                                                        10
                                                      }`}
                                                      cy={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        80
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                  </>
                                                )}
                                                {details.singledoorposition ==
                                                  "left" && (
                                                  <>
                                                    <circle
                                                      r="8"
                                                      cx={`${10}`}
                                                      cy={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        25
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                    <circle
                                                      r="8"
                                                      cx={`${10}`}
                                                      cy={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        80
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* fixed position left right// */}
                                            {details.boxfixed == "1" &&
                                              details.boxfixed_position ==
                                                "left" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      (details.updatedboxwidthcat /
                                                        100) *
                                                        25 -
                                                      10
                                                    }`}
                                                    y={`${
                                                      details.updatedboxheightcat -
                                                      65
                                                    }`}
                                                    fill="black"
                                                    font-size="16"
                                                  >
                                                    Fixed
                                                  </text>
                                                </>
                                              )}
                                            {details.boxfixed == "1" &&
                                              details.boxfixed_position ==
                                                "right" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      (details.updatedboxwidthcat /
                                                        100) *
                                                        80 -
                                                      5
                                                    }`}
                                                    y={`${
                                                      details.updatedboxheightcat -
                                                      65
                                                    }`}
                                                    fill="black"
                                                    font-size="16"
                                                  >
                                                    Fixed
                                                  </text>
                                                </>
                                              )}
                                            {details.boxdoor_typecat ==
                                              "Glass Door" &&
                                              details.boxdoor_selectcat ==
                                                "Single" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      (details.updatedboxwidthcat /
                                                        100) *
                                                        25 +
                                                      25
                                                    }`}
                                                    y={`${
                                                      details.updatedboxheightcat -
                                                      15
                                                    }`}
                                                    fill="black"
                                                    font-size="16"
                                                  >
                                                    GDR
                                                  </text>
                                                </>
                                              )}
                                            {details.boxdoor_typecat ==
                                              "Glass Door" &&
                                              details.boxdoor_selectcat ==
                                                "Double" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      (details.updatedboxwidthcat /
                                                        100) *
                                                        25 +
                                                      25
                                                    }`}
                                                    y={`${
                                                      details.updatedboxheightcat -
                                                      15
                                                    }`}
                                                    fill="black"
                                                    font-size="16"
                                                  >
                                                    GDR
                                                  </text>
                                                  <text
                                                    x={`${
                                                      (details.updatedboxwidthcat /
                                                        100) *
                                                        80 -
                                                      35
                                                    }`}
                                                    y={`${
                                                      details.updatedboxheightcat -
                                                      15
                                                    }`}
                                                    fill="black"
                                                    font-size="16"
                                                  >
                                                    GDR
                                                  </text>
                                                </>
                                              )}
                                            {/* //g poisition top left // */}
                                            {details.boxg_valuecat == "1" ? (
                                              <>
                                                {details.boxgposition ==
                                                  "top" && (
                                                  <>
                                                    <text
                                                      x={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                          25 +
                                                        25
                                                      }`}
                                                      y="60"
                                                      fill="black"
                                                      font-size="18"
                                                    >
                                                      G
                                                    </text>
                                                    {details.boxdoor_selectcat ==
                                                      "Double" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedboxwidthcat /
                                                              100) *
                                                              80 -
                                                            35
                                                          }`}
                                                          y="60"
                                                          fill="black"
                                                          font-size="18"
                                                        >
                                                          G
                                                        </text>
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                                {details.boxgposition ==
                                                  "bottom" && (
                                                  <>
                                                    <text
                                                      x={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                          25 +
                                                        25
                                                      }`}
                                                      y={`${
                                                        details.updatedboxheightcat -
                                                        45
                                                      }`}
                                                      fill="black"
                                                      font-size="18"
                                                    >
                                                      G
                                                    </text>
                                                    {details.boxdoor_selectcat ==
                                                      "Double" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedboxwidthcat /
                                                              100) *
                                                              80 -
                                                            35
                                                          }`}
                                                          y={`${
                                                            details.updatedboxheightcat -
                                                            45
                                                          }`}
                                                          fill="black"
                                                          font-size="18"
                                                        >
                                                          G
                                                        </text>
                                                      </>
                                                    )}
                                                    {details.boxdoor_typecat ==
                                                      "Glass Door" &&
                                                      details.boxdoor_selectcat ==
                                                        "Single" && (
                                                        <>
                                                          <text
                                                            x={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                                25 +
                                                              25
                                                            }`}
                                                            y={`${
                                                              details.updatedboxheightcat -
                                                              15
                                                            }`}
                                                            fill="black"
                                                            font-size="16"
                                                          >
                                                            GDR
                                                          </text>
                                                        </>
                                                      )}
                                                    {details.boxdoor_typecat ==
                                                      "Glass Door" &&
                                                      details.boxdoor_selectcat ==
                                                        "Double" && (
                                                        <>
                                                          <text
                                                            x={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                                25 +
                                                              25
                                                            }`}
                                                            y={`${
                                                              details.updatedboxheightcat -
                                                              15
                                                            }`}
                                                            fill="black"
                                                            font-size="16"
                                                          >
                                                            GDR
                                                          </text>
                                                          <text
                                                            x={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                                80 -
                                                              35
                                                            }`}
                                                            y={`${
                                                              details.updatedboxheightcat -
                                                              15
                                                            }`}
                                                            fill="black"
                                                            font-size="16"
                                                          >
                                                            GDR
                                                          </text>
                                                        </>
                                                      )}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* //remarks in single // */}
                                            {(details.boxdoor_typecat &&
                                              details.boxdoor_selectcat ==
                                                "Single") ||
                                              (details.boxdoor_selectcat ==
                                                "" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      details.updatedboxwidthcat /
                                                        2 -
                                                      20
                                                    }px`}
                                                    y={`${
                                                      details.updatedboxheightcat -
                                                      28
                                                    }px`}
                                                    fill="black"
                                                    font-size="14"
                                                  >
                                                    {details.BoxRemark}
                                                  </text>
                                                </>
                                              ))}
                                            {details.boxdoor_typecat &&
                                              details.boxdoor_selectcat ==
                                                "Single" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      details.updatedboxwidthcat /
                                                        2 -
                                                      20
                                                    }px`}
                                                    y={`${
                                                      details.updatedboxheightcat -
                                                      28
                                                    }px`}
                                                    fill="black"
                                                    font-size="14"
                                                  >
                                                    {details.BoxRemark}
                                                  </text>
                                                </>
                                              )}
                                            {/* //remarks in double // */}
                                            {details.boxdoor_typecat &&
                                              details.boxdoor_selectcat ==
                                                "Double" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      (details.updatedboxwidthcat /
                                                        100) *
                                                        25 -
                                                      10
                                                    }`}
                                                    y={`${
                                                      details.updatedboxheightcat -
                                                      28
                                                    }`}
                                                    fill="black"
                                                    font-size="14"
                                                  >
                                                    {details.BoxRemark}
                                                  </text>
                                                  <text
                                                    x={`${
                                                      (details.updatedboxwidthcat /
                                                        100) *
                                                        80 -
                                                      35
                                                    }`}
                                                    y={`${
                                                      details.updatedboxheightcat -
                                                      28
                                                    }`}
                                                    fill="black"
                                                    font-size="14"
                                                  >
                                                    {details.BoxRightremark}
                                                  </text>
                                                </>
                                              )}
                                            {/* //remarks in facia // */}
                                            {details.boxdoor_typecat ==
                                            "Facia" ? (
                                              <>
                                                {details.boxdoor_selectcat ==
                                                  "2 drawer" && (
                                                  <>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        25
                                                      }`}
                                                      y={
                                                        details.updatedboxheightcat /
                                                          3 -
                                                        5
                                                      }
                                                      fill="black"
                                                      font-size="14"
                                                    >
                                                      {details.BoxRemark}
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        25
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          3.5) *
                                                          3 -
                                                        8
                                                      }
                                                      fill="black"
                                                      font-size="14"
                                                    >
                                                      {details.BoxRightremark}
                                                    </text>
                                                  </>
                                                )}
                                                {details.boxdoor_selectcat ==
                                                  "3 drawer" && (
                                                  <>
                                                    {/* //double line facia 3 drawer //     */}
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        25
                                                      }`}
                                                      y={
                                                        details.updatedboxheightcat /
                                                          4 -
                                                        5
                                                      }
                                                      fill="black"
                                                      font-size="14"
                                                    >
                                                      {details.BoxRemark}
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        25
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          5.3) *
                                                          3 -
                                                        5
                                                      }
                                                      fill="black"
                                                      font-size="14"
                                                    >
                                                      {details.BoxRightremark}
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        25
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          3.3) *
                                                          3 -
                                                        5
                                                      }
                                                      fill="black"
                                                      font-size="14"
                                                    >
                                                      {details.BoxThirdremark}
                                                    </text>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* //remarks in facia 1:1 2:1// */}
                                            {details.boxdoor_typecat ==
                                            "Facia" ? (
                                              <>
                                                {details.boxdoor_selectcat ==
                                                  "2 drawer 2:1" && (
                                                  <>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        25
                                                      }`}
                                                      y={
                                                        details.updatedboxheightcat /
                                                          3 -
                                                        5
                                                      }
                                                      fill="black"
                                                      font-size="14"
                                                    >
                                                      {details.BoxRemark}
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        25
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          3.5) *
                                                          3 -
                                                        8
                                                      }
                                                      fill="black"
                                                      font-size="14"
                                                    >
                                                      {details.BoxRightremark}
                                                    </text>
                                                  </>
                                                )}
                                                {details.boxdoor_selectcat ==
                                                  "3 drawer 2:1" && (
                                                  <>
                                                    {/* //double line facia 3 drawer //     */}
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        25
                                                      }`}
                                                      y={
                                                        details.updatedboxheightcat /
                                                          4 -
                                                        5
                                                      }
                                                      fill="black"
                                                      font-size="14"
                                                    >
                                                      {details.BoxRemark}
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        25
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          5.3) *
                                                          3 -
                                                        5
                                                      }
                                                      fill="black"
                                                      font-size="14"
                                                    >
                                                      {details.BoxRightremark}
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        25
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          3.3) *
                                                          3 -
                                                        5
                                                      }
                                                      fill="black"
                                                      font-size="14"
                                                    >
                                                      {details.BoxThirdremark}
                                                    </text>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* //single line facia 2 drwer// */}
                                            {details.boxdoor_typecat ==
                                            "Facia" ? (
                                              <>
                                                {details.boxdoor_selectcat ==
                                                  "2 drawer" && (
                                                  <>
                                                    <line
                                                      x1="10"
                                                      y1={
                                                        details.updatedboxheightcat /
                                                        2
                                                      }
                                                      x2={
                                                        details.updatedboxwidthcat +
                                                        10
                                                      }
                                                      y2={
                                                        details.updatedboxheightcat /
                                                        2
                                                      }
                                                      stroke="black"
                                                      strokeWidth="1"
                                                    />
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        18
                                                      }`}
                                                      y={
                                                        details.updatedboxheightcat /
                                                        3
                                                      }
                                                      fill="black"
                                                      font-size="80"
                                                    >
                                                      -
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        18
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          3.5) *
                                                        3
                                                      }
                                                      fill="black"
                                                      font-size="80"
                                                    >
                                                      -
                                                    </text>
                                                  </>
                                                )}
                                                {details.boxdoor_selectcat ==
                                                  "3 drawer" && (
                                                  <>
                                                    {/* //double line facia 3 drawer //     */}
                                                    <line
                                                      x1="10"
                                                      y1={
                                                        details.updatedboxheightcat /
                                                        3
                                                      }
                                                      x2={
                                                        details.updatedboxwidthcat +
                                                        10
                                                      }
                                                      y2={
                                                        details.updatedboxheightcat /
                                                        3
                                                      }
                                                      stroke="black"
                                                      strokeWidth="1"
                                                    />
                                                    <line
                                                      x1="10"
                                                      y1={
                                                        (details.updatedboxheightcat /
                                                          3) *
                                                        2
                                                      }
                                                      x2={
                                                        details.updatedboxwidthcat +
                                                        10
                                                      }
                                                      y2={
                                                        (details.updatedboxheightcat /
                                                          3) *
                                                        2
                                                      }
                                                      stroke="black"
                                                      strokeWidth="1"
                                                    />
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        18
                                                      }`}
                                                      y={
                                                        details.updatedboxheightcat /
                                                        4
                                                      }
                                                      fill="black"
                                                      font-size="80"
                                                    >
                                                      -
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        18
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          5.3) *
                                                        3
                                                      }
                                                      fill="black"
                                                      font-size="80"
                                                    >
                                                      -
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        18
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          3.3) *
                                                        3
                                                      }
                                                      fill="black"
                                                      font-size="80"
                                                    >
                                                      -
                                                    </text>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* //single line facia 1:1 2:1 drwer// */}
                                            {details.boxdoor_typecat ==
                                            "Facia" ? (
                                              <>
                                                {details.boxdoor_selectcat ==
                                                  "2 drawer 2:1" && (
                                                  <>
                                                    <line
                                                      x1="10"
                                                      y1={
                                                        details.updatedboxheightcat /
                                                        2
                                                      }
                                                      x2={
                                                        details.updatedboxwidthcat +
                                                        10
                                                      }
                                                      y2={
                                                        details.updatedboxheightcat /
                                                        2
                                                      }
                                                      stroke="black"
                                                      strokeWidth="1"
                                                    />
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        18
                                                      }`}
                                                      y={
                                                        details.updatedboxheightcat /
                                                        3
                                                      }
                                                      fill="black"
                                                      font-size="80"
                                                    >
                                                      -
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        18
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          3.5) *
                                                        3
                                                      }
                                                      fill="black"
                                                      font-size="80"
                                                    >
                                                      -
                                                    </text>
                                                  </>
                                                )}
                                                {details.boxdoor_selectcat ==
                                                  "3 drawer 2:1" && (
                                                  <>
                                                    {/* //double line facia 3 drawer //     */}
                                                    <line
                                                      x1="10"
                                                      y1={
                                                        details.updatedboxheightcat /
                                                        3
                                                      }
                                                      x2={
                                                        details.updatedboxwidthcat +
                                                        10
                                                      }
                                                      y2={
                                                        details.updatedboxheightcat /
                                                        3
                                                      }
                                                      stroke="black"
                                                      strokeWidth="1"
                                                    />
                                                    <line
                                                      x1="10"
                                                      y1={
                                                        (details.updatedboxheightcat /
                                                          3) *
                                                        2
                                                      }
                                                      x2={
                                                        details.updatedboxwidthcat +
                                                        10
                                                      }
                                                      y2={
                                                        (details.updatedboxheightcat /
                                                          3) *
                                                        2
                                                      }
                                                      stroke="black"
                                                      strokeWidth="1"
                                                    />
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        18
                                                      }`}
                                                      y={
                                                        details.updatedboxheightcat /
                                                        4
                                                      }
                                                      fill="black"
                                                      font-size="80"
                                                    >
                                                      -
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        18
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          5.3) *
                                                        3
                                                      }
                                                      fill="black"
                                                      font-size="80"
                                                    >
                                                      -
                                                    </text>
                                                    <text
                                                      x={`${
                                                        details.updatedboxwidthcat /
                                                          2 -
                                                        18
                                                      }`}
                                                      y={
                                                        (details.updatedboxheightcat /
                                                          3.3) *
                                                        3
                                                      }
                                                      fill="black"
                                                      font-size="80"
                                                    >
                                                      -
                                                    </text>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* //intdummy left right // */}
                                            {details.boxint_dummy_valuecat ==
                                            "1" ? (
                                              <>
                                                {details.boxintposition ==
                                                  "left" && (
                                                  <>
                                                    <rect
                                                      width={`10px`}
                                                      height={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        90
                                                      }px`}
                                                      fill="#ffffff"
                                                      stroke="black"
                                                      x={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                        7
                                                      }`}
                                                      y={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        10
                                                      }`}
                                                      style={{
                                                        border:
                                                          "3px solid #000000",
                                                      }}
                                                    />
                                                  </>
                                                )}
                                                {details.boxintposition ==
                                                  "right" && (
                                                  <>
                                                    <rect
                                                      width={`10px`}
                                                      height={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        90
                                                      }px`}
                                                      fill="#ffffff"
                                                      stroke="black"
                                                      x={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                        95
                                                      }`}
                                                      y={`${
                                                        (details.updatedboxheightcat /
                                                          100) *
                                                        10
                                                      }`}
                                                      style={{
                                                        border:
                                                          "3px solid #000000",
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </svg>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <span>{details.boxwidthcat}</span>
                                        </div>
                                        <div
                                          style={{
                                            textAlign: "center",
                                            fontSize: "18px",
                                            fontWeight: "900",
                                          }}
                                        >
                                           {details.Wardropestatus == "1" ? (
                                            <>
                                          <span>No-{imagesCounter++}-Wardrobe</span>
                                            </>
                                           ):(
                                            <>
                                          <span>No-{imagesCounter++}</span>
                                            </>
                                           )}
                                        </div>
                                      </>
                                    )}
                                    {/* frameimage// */}
                                    {details.idcat && (
                                      <>
                                        <p className="svgletter">
                                          {" "}
                                          {details.frametypecat === "1.25" ? (
                                            <span className="svgtext">ர</span>
                                          ) : details.frametypecat ===
                                            "1.25 lip" ? (
                                            <span className="svgtext">ரி</span>
                                          ) : details.frametypecat === "ES" ? (
                                            <span className="svgtext">ES</span>
                                          ) : details.frametypecat === "EP" ? (
                                            <span className="svgtext">EP</span>
                                          ) : (
                                            <span className="svgtext">வ</span>
                                          )}
                                        </p>
                                        <div className="square-box">
                                          <div
                                            style={{
                                              transform: "rotate(272deg)",
                                            }}
                                          >
                                            <span>
                                              {details.frameheightcat}
                                            </span>
                                          </div>
                                          <svg
                                            className="shape rectangle"
                                            width={`${
                                              details.updatedframewidthcat + 20
                                            }px`}
                                            height={`${
                                              details.updatedframeheightcat + 20
                                            }px`}
                                          >
                                            {/* frame svg square line 1x1 1.25 Pa Box// */}
                                            {details.frametypecat ==
                                              "1 x 1 Pa Frame" ||
                                            details.frametypecat ===
                                              "1.25 Pa Frame" ? (
                                              <>
                                                <svg
                                                  className="shape rectangle"
                                                  width={`${
                                                    details.updatedframewidthcat +
                                                    35
                                                  }px`}
                                                  height={`${
                                                    details.updatedframeheightcat +
                                                    7
                                                  }px`}
                                                >
                                                  <rect
                                                    width={`${details.updatedframewidthcat}px`}
                                                    height={`${details.updatedframeheightcat}px`}
                                                    fill="#ffffff"
                                                    stroke="black"
                                                    x="10"
                                                    y="10"
                                                  />
                                                </svg>
                                              </>
                                            ) : (
                                              <>
                                                <rect
                                                  width={`${details.updatedframewidthcat}px`}
                                                  height={`${details.updatedframeheightcat}px`}
                                                  fill="#ffffff"
                                                  stroke="black"
                                                  x="10"
                                                  y="10"
                                                />
                                              </>
                                            )}
                                            {/* //frame fixed box left right // */}
                                            {/* //Fixed Posistion left right // */}
                                            {details.framefixed == "1" ? (
                                              <>
                                                {details.framefixedposition ==
                                                  "left" && (
                                                  <>
                                                    <rect
                                                      width={`${
                                                        details.updatedframewidthcat /
                                                          2 +
                                                        5
                                                      }px`}
                                                      height={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        90
                                                      }px`}
                                                      fill="#ffffff"
                                                      stroke="black"
                                                      x={`${
                                                        (details.updatedframewidthcat /
                                                          100) *
                                                          7 +
                                                        15
                                                      }`}
                                                      y={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        8
                                                      }`}
                                                      style={{
                                                        border:
                                                          "3px solid #000000",
                                                      }}
                                                    />
                                                  </>
                                                )}
                                                {details.framefixedposition ==
                                                  "right" && (
                                                  <>
                                                    <rect
                                                      width={`${
                                                        details.updatedframewidthcat /
                                                          2 +
                                                        5
                                                      }px`}
                                                      height={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        90
                                                      }px`}
                                                      fill="#ffffff"
                                                      stroke="black"
                                                      x={`${
                                                        details.updatedframewidthcat /
                                                        2.5
                                                      }`}
                                                      y={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        10
                                                      }`}
                                                      style={{
                                                        border:
                                                          "3px solid #000000",
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* //square box in frame // */}
                                            {details.frametypecat === "EP" ||
                                            details.frametypecat === "ES" ||
                                            details.frametypecat ===
                                              "1.25 lip" ? (
                                              ""
                                            ) : (
                                              <rect
                                                fill="#ffffff"
                                                stroke="black"
                                                strokeWidth="2"
                                                x={`${
                                                  (details.updatedframewidthcat /
                                                    100) *
                                                  9
                                                }`}
                                                y="1"
                                                width="18"
                                                height="18 "
                                              />
                                            )}
                                            {/* //1x1 1.25 pa box // */}
                                            {(details.frametypecat ==
                                              "1 x 1 Pa Frame" ||
                                              details.frametypecat ===
                                                "1.25 Pa Frame") && (
                                              <>
                                                <svg
                                                  width="30px"
                                                  height="20px"
                                                  x={`${
                                                    details.updatedframewidthcat -
                                                    20
                                                  }`}
                                                >
                                                  <rect
                                                    fill="#ffffff"
                                                    stroke="black"
                                                    strokeWidth="2"
                                                    y="1"
                                                    width="20"
                                                    height="20"
                                                  />
                                                </svg>
                                              </>
                                            )}
                                            {details.frametypecat == "1.25" && (
                                              <>
                                                <text
                                                  x={`${
                                                    (details.updatedframewidthcat /
                                                      100) *
                                                      9 +
                                                    15
                                                  }`}
                                                  y="40"
                                                  fill="black"
                                                  font-size="16"
                                                >
                                                  1.25
                                                </text>
                                              </>
                                            )}
                                            {details.frametypecat ==
                                              "1 x 1 Pa Frame" && (
                                              <>
                                                <text
                                                  x={`${
                                                    (details.updatedframewidthcat /
                                                      100) *
                                                      9 +
                                                    15
                                                  }`}
                                                  y="40"
                                                  fill="black"
                                                  font-size="16"
                                                >
                                                  1 x 1 Pa Frame
                                                </text>
                                              </>
                                            )}
                                            {details.frametypecat ==
                                              "1.25 Pa Frame" && (
                                              <>
                                                <text
                                                  x={`${
                                                    (details.updatedframewidthcat /
                                                      100) *
                                                      9 +
                                                    15
                                                  }`}
                                                  y="40"
                                                  fill="black"
                                                  font-size="16"
                                                >
                                                  1.25 Pa Frame
                                                </text>
                                              </>
                                            )}
                                            {details.frametypecat ==
                                              "1 x 1" && (
                                              <>
                                                <text
                                                  x={`${
                                                    (details.updatedframewidthcat /
                                                      100) *
                                                      9 +
                                                    15
                                                  }`}
                                                  y="40"
                                                  fill="black"
                                                  font-size="16"
                                                >
                                                  1x1
                                                </text>
                                              </>
                                            )}
                                            {details.frametypecat ==
                                              "1.25 lip" && (
                                              <>
                                                <text
                                                  x={`${
                                                    (details.updatedframewidthcat /
                                                      100) *
                                                      9 +
                                                    15
                                                  }`}
                                                  y="40"
                                                  fill="black"
                                                  font-size="16"
                                                >
                                                  1.25 lip
                                                </text>
                                              </>
                                            )}
                                            {/* //remarks in single // */}
                                            {(details.framedoortypecat &&
                                              details.framedoortypeselectcat ==
                                                "Single") ||
                                              (details.framedoortypeselectcat ==
                                                "" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      details.updatedframewidthcat /
                                                        2 -
                                                      20
                                                    }px`}
                                                    y={`${
                                                      details.updatedframeheightcat -
                                                      30
                                                    }px`}
                                                    fill="black"
                                                    font-size="14"
                                                  >
                                                    {details.FrameRemark}
                                                  </text>
                                                </>
                                              ))}
                                            {details.framedoortypecat &&
                                              details.framedoortypeselectcat ==
                                                "Single" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      details.updatedframewidthcat /
                                                        2 -
                                                      20
                                                    }px`}
                                                    y={`${
                                                      details.updatedframeheightcat -
                                                      30
                                                    }px`}
                                                    fill="black"
                                                    font-size="14"
                                                  >
                                                    {details.FrameRemark}
                                                  </text>
                                                </>
                                              )}
                                            {/* //remarks in double // */}
                                            {details.framedoortypecat &&
                                              details.framedoortypeselectcat ==
                                                "Double" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      (details.updatedframewidthcat /
                                                        100) *
                                                        25 -
                                                      10
                                                    }`}
                                                    y={`${
                                                      details.updatedframeheightcat -
                                                      30
                                                    }`}
                                                    fill="black"
                                                    font-size="14"
                                                  >
                                                    {details.FrameRemark}
                                                  </text>
                                                  <text
                                                    x={`${
                                                      (details.updatedframewidthcat /
                                                        100) *
                                                        80 -
                                                      35
                                                    }`}
                                                    y={`${
                                                      details.updatedframeheightcat -
                                                      30
                                                    }`}
                                                    fill="black"
                                                    font-size="14"
                                                  >
                                                    {details.FrameRightremark}
                                                  </text>
                                                </>
                                              )}
                                            {/* //frame single door top left bottom right // */}
                                            {details.framedoortypeselectcat ==
                                            "Single" ? (
                                              <>
                                                {details.framesingledrposition ==
                                                  "top" && (
                                                  <>
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        (details.updatedframewidthcat /
                                                          100) *
                                                          25 +
                                                        10
                                                      }`}
                                                      cy={`${10}`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        (details.updatedframewidthcat /
                                                          100) *
                                                        80
                                                      }`}
                                                      cy={`${10}`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                  </>
                                                )}
                                                {details.framesingledrposition ==
                                                  "bottom" && (
                                                  <>
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        (details.updatedframewidthcat /
                                                          100) *
                                                          25 +
                                                        10
                                                      }`}
                                                      cy={`${
                                                        details.updatedframeheightcat +
                                                        10
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        (details.updatedframewidthcat /
                                                          100) *
                                                        80
                                                      }`}
                                                      cy={`${
                                                        details.updatedframeheightcat +
                                                        10
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                  </>
                                                )}
                                                {details.framesingledrposition ==
                                                  "right" && (
                                                  <>
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        details.updatedframewidthcat +
                                                        10
                                                      }`}
                                                      cy={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        25
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                    <circle
                                                      r="8"
                                                      cx={`${
                                                        details.updatedframewidthcat +
                                                        10
                                                      }`}
                                                      cy={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        80
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                  </>
                                                )}
                                                {details.framesingledrposition ==
                                                  "left" && (
                                                  <>
                                                    <circle
                                                      r="8"
                                                      cx={`${10}`}
                                                      cy={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        25
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                    <circle
                                                      r="8"
                                                      cx={`${10}`}
                                                      cy={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        80
                                                      }`}
                                                      stroke="black"
                                                      stroke-width="3"
                                                      fill="white"
                                                    />
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {details.framedoortypecat ==
                                              "Glass Door" &&
                                              details.framedoortypeselectcat ==
                                                "Single" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      (details.updatedframewidthcat /
                                                        100) *
                                                        25 +
                                                      25
                                                    }`}
                                                    y={`${
                                                      details.updatedframeheightcat -
                                                      15
                                                    }`}
                                                    fill="black"
                                                    font-size="16"
                                                  >
                                                    GDR
                                                  </text>
                                                </>
                                              )}
                                            {details.framedoortypecat ==
                                              "Glass Door" &&
                                              details.framedoortypeselectcat ==
                                                "Double" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      (details.updatedframewidthcat /
                                                        100) *
                                                        25 +
                                                      25
                                                    }`}
                                                    y={`${
                                                      details.updatedframeheightcat -
                                                      15
                                                    }`}
                                                    fill="black"
                                                    font-size="16"
                                                  >
                                                    GDR
                                                  </text>
                                                  <text
                                                    x={`${
                                                      (details.updatedframewidthcat /
                                                        100) *
                                                        80 -
                                                      35
                                                    }`}
                                                    y={`${
                                                      details.updatedframeheightcat -
                                                      15
                                                    }`}
                                                    fill="black"
                                                    font-size="16"
                                                  >
                                                    GDR
                                                  </text>
                                                </>
                                              )}
                                            {/* framefixed position left right// */}
                                            {details.framefixed == "1" &&
                                              details.framefixedposition ==
                                                "left" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      (details.updatedframewidthcat /
                                                        100) *
                                                        25 -
                                                      10
                                                    }`}
                                                    y={`${
                                                      details.updatedframeheightcat -
                                                      65
                                                    }`}
                                                    fill="black"
                                                    font-size="16"
                                                  >
                                                    Fixed
                                                  </text>
                                                </>
                                              )}
                                            {details.framefixed == "1" &&
                                              details.framefixedposition ==
                                                "right" && (
                                                <>
                                                  <text
                                                    x={`${
                                                      (details.updatedframewidthcat /
                                                        100) *
                                                        80 -
                                                      5
                                                    }`}
                                                    y={`${
                                                      details.updatedframeheightcat -
                                                      65
                                                    }`}
                                                    fill="black"
                                                    font-size="16"
                                                  >
                                                    Fixed
                                                  </text>
                                                </>
                                              )}
                                            {details.jsquareframewidth != "" &&
                                            details.jsquareframeheight != "" ? (
                                              <>
                                                {details.frametypecat ===
                                                  "EP" ||
                                                details.frametypecat === "ES" ||
                                                details.frametypecat ===
                                                  "1.25 lip" ? (
                                                  ""
                                                ) : (
                                                  <text
                                                    x={`${
                                                      details.updatedframewidthcat /
                                                      2
                                                    }px`}
                                                    y={`${
                                                      details.updatedframeheightcat /
                                                      2
                                                    }px`}
                                                    textAnchor="middle"
                                                    fill="#000000"
                                                    dominantBaseline="middle"
                                                    fontSize="16px"
                                                  >
                                                    {details.framedoortypecat ===
                                                      "Glass Door" &&
                                                    details.framedoortypeselectcat ===
                                                      "Double" ? (
                                                      <>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            2
                                                          }px`}
                                                          dy="-0.5em"
                                                          style={{
                                                            textDecoration:
                                                              "underline",
                                                          }}
                                                        >
                                                          {parseInt(
                                                            details.jsquareframeheight
                                                          )}
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            2
                                                          }px`}
                                                          dy="1.1em"
                                                        >
                                                          {parseInt(
                                                            details.jsquareframewidth
                                                          ) + "G"}
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                              2 +
                                                            30
                                                          }px`}
                                                          dy="-0.7em"
                                                        >
                                                          -{" "}
                                                          {
                                                            details.jsquareframequantity
                                                          }
                                                        </tspan>
                                                      </>
                                                    ) : details.framefixed ==
                                                        "1" &&
                                                      details.framefixedposition ==
                                                        "left" ? (
                                                      <>
                                                        {/* //fixed left fixed value // */}
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            2.4
                                                          }px`}
                                                          dy="-5.5em"
                                                          style={{
                                                            textDecoration:
                                                              "underline",
                                                          }}
                                                        >
                                                          {
                                                            details.framefixedheight
                                                          }
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            2.4
                                                          }px`}
                                                          dy="1.1em"
                                                        >
                                                          {
                                                            details.framefixedwidth
                                                          }
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                              2.4 +
                                                            30
                                                          }px`}
                                                          dy="-0.7em"
                                                        >
                                                          - 1
                                                        </tspan>
                                                        {/* //fixed jsquare value // */}
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            1.2
                                                          }px`}
                                                          dy="-0.5em"
                                                          style={{
                                                            textDecoration:
                                                              "underline",
                                                          }}
                                                        >
                                                          {
                                                            details.jsquareframeheight
                                                          }
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            1.2
                                                          }px`}
                                                          dy="1.1em"
                                                        >
                                                          {
                                                            details.jsquareframewidth
                                                          }
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                              1.2 +
                                                            30
                                                          }px`}
                                                          dy="-0.7em"
                                                        >
                                                          -{" "}
                                                          {
                                                            details.jsquareframequantity
                                                          }
                                                        </tspan>
                                                      </>
                                                    ) : details.framefixed ==
                                                        "1" &&
                                                      details.framefixedposition ==
                                                        "right" ? (
                                                      <>
                                                        {/* //fixed right fixed value // */}
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            1.6
                                                          }px`}
                                                          dy="-5.5em"
                                                          style={{
                                                            textDecoration:
                                                              "underline",
                                                          }}
                                                        >
                                                          {
                                                            details.framefixedheight
                                                          }
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            1.6
                                                          }px`}
                                                          dy="1.1em"
                                                        >
                                                          {
                                                            details.framefixedwidth
                                                          }
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                              1.6 +
                                                            30
                                                          }px`}
                                                          dy="-0.7em"
                                                        >
                                                          - 1
                                                        </tspan>
                                                        {/* //fixed jsquare value // */}
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            4.2
                                                          }px`}
                                                          dy="-0.5em"
                                                          style={{
                                                            textDecoration:
                                                              "underline",
                                                          }}
                                                        >
                                                          {
                                                            details.jsquareframeheight
                                                          }
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            4.2
                                                          }px`}
                                                          dy="1.1em"
                                                        >
                                                          {
                                                            details.jsquareframewidth
                                                          }
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                              4.2 +
                                                            30
                                                          }px`}
                                                          dy="-0.7em"
                                                        >
                                                          -{" "}
                                                          {
                                                            details.jsquareframequantity
                                                          }
                                                        </tspan>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            2
                                                          }px`}
                                                          dy="-0.5em"
                                                          style={{
                                                            textDecoration:
                                                              "underline",
                                                          }}
                                                        >
                                                          {
                                                            details.jsquareframeheight
                                                          }
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            2
                                                          }px`}
                                                          dy="1.1em"
                                                        >
                                                          {
                                                            details.jsquareframewidth
                                                          }
                                                        </tspan>
                                                        <tspan
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                              2 +
                                                            30
                                                          }px`}
                                                          dy="-0.7em"
                                                        >
                                                          -{" "}
                                                          {
                                                            details.jsquareframequantity
                                                          }
                                                        </tspan>
                                                      </>
                                                    )}
                                                  </text>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* //frame single g and grd//         */}
                                            {details.framegvaluecat == "1" ? (
                                              <>
                                                {details.framegposition ==
                                                  "top" && (
                                                  <>
                                                    <text
                                                      x={`${
                                                        (details.updatedframewidthcat /
                                                          100) *
                                                          25 +
                                                        25
                                                      }`}
                                                      y="80"
                                                      fill="black"
                                                      font-size="18"
                                                    >
                                                      G
                                                    </text>
                                                    {details.framedoortypeselectcat ==
                                                      "Double" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedframewidthcat /
                                                              100) *
                                                              80 -
                                                            35
                                                          }`}
                                                          y="80"
                                                          fill="black"
                                                          font-size="18"
                                                        >
                                                          G
                                                        </text>
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                                {details.framegposition ==
                                                  "bottom" && (
                                                  <>
                                                    <text
                                                      x={`${
                                                        (details.updatedframewidthcat /
                                                          100) *
                                                          25 +
                                                        25
                                                      }`}
                                                      y={`${
                                                        details.updatedframeheightcat -
                                                        45
                                                      }`}
                                                      fill="black"
                                                      font-size="18"
                                                    >
                                                      G
                                                    </text>
                                                    {details.framedoortypeselectcat ==
                                                      "Double" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedframewidthcat /
                                                              100) *
                                                              80 -
                                                            35
                                                          }`}
                                                          y={`${
                                                            details.updatedframeheightcat -
                                                            45
                                                          }`}
                                                          fill="black"
                                                          font-size="18"
                                                        >
                                                          G
                                                        </text>
                                                      </>
                                                    )}
                                                    {details.framedoortypecat ==
                                                      "Glass Door" &&
                                                      details.framedoortypeselectcat ==
                                                        "Single" && (
                                                        <>
                                                          <text
                                                            x={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                                25 +
                                                              25
                                                            }`}
                                                            y={`${
                                                              details.updatedframeheightcat -
                                                              15
                                                            }`}
                                                            fill="black"
                                                            font-size="16"
                                                          >
                                                            GDR
                                                          </text>
                                                        </>
                                                      )}
                                                    {details.framedoortypecat ==
                                                      "Glass Door" &&
                                                      details.framedoortypeselectcat ==
                                                        "Double" && (
                                                        <>
                                                          <text
                                                            x={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                                25 +
                                                              25
                                                            }`}
                                                            y={`${
                                                              details.updatedframeheightcat -
                                                              15
                                                            }`}
                                                            fill="black"
                                                            font-size="16"
                                                          >
                                                            GDR
                                                          </text>
                                                          <text
                                                            x={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                                80 -
                                                              35
                                                            }`}
                                                            y={`${
                                                              details.updatedframeheightcat -
                                                              15
                                                            }`}
                                                            fill="black"
                                                            font-size="16"
                                                          >
                                                            GDR
                                                          </text>
                                                        </>
                                                      )}
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {/* //intdummy left right // */}
                                            {details.frameintdummyvaluecat ==
                                            "1" ? (
                                              <>
                                                {details.frameintposition ==
                                                  "left" && (
                                                  <>
                                                    <rect
                                                      width={`10px`}
                                                      height={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        90
                                                      }px`}
                                                      fill="#ffffff"
                                                      stroke="black"
                                                      x={`${
                                                        (details.updatedframewidthcat /
                                                          100) *
                                                        10
                                                      }`}
                                                      y={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        10
                                                      }`}
                                                      style={{
                                                        border:
                                                          "3px solid #000000",
                                                      }}
                                                    />
                                                  </>
                                                )}
                                                {details.frameintposition ==
                                                  "right" && (
                                                  <>
                                                    <rect
                                                      width={`10px`}
                                                      height={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        90
                                                      }px`}
                                                      fill="#ffffff"
                                                      stroke="black"
                                                      x={`${
                                                        (details.updatedframewidthcat /
                                                          100) *
                                                        95
                                                      }`}
                                                      y={`${
                                                        (details.updatedframeheightcat /
                                                          100) *
                                                        10
                                                      }`}
                                                      style={{
                                                        border:
                                                          "3px solid #000000",
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </svg>
                                        </div>
                                        <div style={{ textAlign: "center" }}>
                                          <span>{details.framewidthcat}</span>
                                        </div>
                                        <div
                                          style={{
                                            textAlign: "center",
                                            fontSize: "18px",
                                            fontWeight: "900",
                                          }}
                                        >
                                          <span>No-{imagesCounter++}</span>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </Col>
                              )
                            )}
                          </Row>
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </Row>
              <br />
            </div>
            <br />
          </div>
          {/* //cutlistsection// */}
          {showSections && (
            <>
              <Row>
                <Col lg="2" md="2" sm="2"></Col>
                <Col lg="8" md="8" sm="8">
                  <div className="mblviewcutlist">
                    <div className="cutlistcontainer" style={{ padding: "1%" }}>
                      <button
                        onClick={() =>
                          handleExportAllcutlist(
                            faciatandemdrawer,
                            jsquare,
                            formulatabledetails,
                            formulaframedetails,
                            glassList,
                            faciammdrawer,
                            faciainternaldrawer,
                            doorsheet,
                            IvorySheet,
                            state.prjName
                          )
                        }
                        className="all2ddwnlbutton"
                      >
                        <FontAwesomeIcon icon={faDownload} /> Download All
                        Cutlist
                      </button>
                      <style>{`th {background-color: burlywood !important;}`}</style>
                      {/*Jsquare cutlist Table*/}
                      {Object.keys(jsquare).map((category: string) => (
                        <div key={category}>
                          {Object.keys(jsquare[category]).map(
                            (color: string) => (
                              <div key={color}>
                                <div style={{ textAlign: "end" }}>
                                  <ReactHTMLTableToExcel
                                    id={`test-table-xls-button-${category}-${color}`}
                                    className="download-table-xls-button"
                                    table={`table-to-xls-${category}-${color}`}
                                    filename={`${state.prjName}-${category}-${color}`}
                                    sheet={`tablexls-${category}-${color}`}
                                    buttonText={
                                      <FontAwesomeIcon icon={faDownload} />
                                    }
                                  />
                                </div>
                                <h4
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                    backgroundColor: "yellow",
                                    padding: "5px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {category.charAt(0).toUpperCase() +
                                    category.slice(1)}{" "}
                                  {color.charAt(0).toUpperCase() +
                                    color.slice(1)}{" "}
                                  NOs{" "}
                                  {jsquare[category][color][0].jsquaretotalnos}
                                </h4>
                                {Array.isArray(jsquare[category][color]) && ( // Check if it's an array
                                  <>
                                    <Table
                                      responsive="md"
                                      striped
                                      bordered
                                      hover
                                      id={`table-to-xls-${category}-${color}`}
                                    >
                                      <thead>
                                        <tr style={{ textAlign: "center" }}>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Height
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Width
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Quantity
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Label
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Note
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.values(
                                          jsquare[category][color].reduce(
                                            (acc: any, item: any) => {
                                              const key = `${item.formulaupdatedboxheightcat}-${item.formulaupdatedboxwidthcat}-${item.frametypecat}-${item.Boxtype}`;
                                              if (!acc[key]) {
                                                acc[key] = {
                                                  ...item,
                                                  quantitySum: 0,
                                                };
                                              }
                                              acc[key].quantitySum +=
                                                item.frametypecat === "EP"
                                                  ? 1
                                                  : item.boxdoor_selectcat ===
                                                    "3 drawer"
                                                  ? 3
                                                  : item.boxdoor_selectcat ===
                                                    "2 drawer"
                                                  ? 2
                                                  : item.boxdoor_selectcat ===
                                                    "3 drawer 2:1"
                                                  ? 1
                                                  : item.boxdoor_selectcat ===
                                                    "2 drawer 2:1"
                                                  ? 1
                                                  : item.boxdoor_selectcat ===
                                                    "Single Drawer"
                                                  ? 2
                                                  : item.boxdoor_selectcat ===
                                                    "Double Drawer"
                                                  ? 2
                                                  : item.boxdoor_selectcat ===
                                                      "Double" ||
                                                    item.framedoortypeselectcat ===
                                                      "Double"
                                                  ? 2
                                                  : 1;
                                              return acc;
                                            },
                                            {}
                                          )
                                        ).map((item: any, index: number) => (
                                          <tr
                                            key={index}
                                            style={{ textAlign: "center" }}
                                          >
                                            <td>
                                              {item.formulaupdatedboxheightcat}
                                            </td>
                                            <td>
                                              {item.formulaupdatedboxwidthcat}
                                            </td>
                                            <td>{item.quantitySum}</td>
                                            <td>
                                              {item.frametypecat === "EP"
                                                ? "EP"
                                                : item.boxdoor_typecat ===
                                                    "Glass Door" ||
                                                  item.framedoortypecat ===
                                                    "Glass Door"
                                                ? "Glass DR"
                                                : item.boxdoor_typecat ===
                                                  "Facia"
                                                ? "FA"
                                                : item.frametypecat ===
                                                    "1.25 lip" ||
                                                  item.frametypecat ===
                                                    "1.25" ||
                                                  item.frametypecat === "1x1" ||
                                                  item.frametypecat === "EP"
                                                ? "DR"
                                                : item.boxdoor_typecat ===
                                                    "Sheet Door" ||
                                                  item.framedoortypecat ===
                                                    "Sheet Door"
                                                ? "DR"
                                                : item.frametypecat === "ES"
                                                ? "ES"
                                                : ""}
                                            </td>
                                            <td>
                                              {item.Boxtype === "Regular Box"
                                                ? ""
                                                : item.Boxtype === "Open Box"
                                                ? "Open Box"
                                                : item.Boxtype === "Pa Box"
                                                ? "Pa Box"
                                                : item.frametypecat ===
                                                  "1 x 1 Pa Frame"
                                                ? "1 x 1 Pa Frame"
                                                : item.frametypecat ===
                                                  "1.25 Pa Frame"
                                                ? "1.25 Pa Frame"
                                                : ""}
                                            </td>
                                          </tr>
                                        ))}
                                        <tr
                                          style={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {
                                              jsquare[category][color][0]
                                                .totalsquarequantity
                                            }
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      ))}

                      {/*Box Cutlist Table*/}
                      {Object.keys(formulatabledetails)
                        .filter((category) => category !== "J Square")
                        .map((category: string) => (
                          <div key={category}>
                            {Object.keys(formulatabledetails[category]).map(
                              (color: string) => (
                                <div key={`${category}-${color}`}>
                                  {formulatabledetails[category][color][0]
                                    .totalquantity !== 0 && (
                                    <div>
                                      <div style={{ textAlign: "end" }}>
                                        <ReactHTMLTableToExcel
                                          id={`test-table-xls-button-${category}-${color}`}
                                          className="download-table-xls-button"
                                          table={`table-to-xls-${category}-${color}`}
                                          filename={`${state.prjName}-${category}-${color}`}
                                          sheet={`tablexls-${category}-${color}`}
                                          buttonText={
                                            <FontAwesomeIcon
                                              icon={faDownload}
                                            />
                                          }
                                        />
                                      </div>
                                      <h4
                                        style={{
                                          textAlign: "center",
                                          borderRadius: "5px",
                                          backgroundColor: "yellow",
                                          padding: "5px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {category.charAt(0).toUpperCase() +
                                          category.slice(1)}{" "}
                                        {color.charAt(0).toUpperCase() +
                                          color.slice(1)}{" "}
                                        NOs{" "}
                                        {category === "depth"
                                          ? formulatabledetails[category][
                                              color
                                            ][0].totalnos
                                          : formulatabledetails[category][
                                              color
                                            ][0].updatetotalnos}
                                      </h4>

                                      <Table
                                        responsive="md"
                                        striped
                                        bordered
                                        hover
                                        id={`table-to-xls-${category}-${color}`}
                                      >
                                        <thead className="colorexcel">
                                          <tr style={{ textAlign: "center" }}>
                                            {category === "depth" ? (
                                              <>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Depth
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Quantity
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Label
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Note
                                                </th>
                                              </>
                                            ) : category === "shelf" ? (
                                              <>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Depth
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Width
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Quantity
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Label
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Note
                                                </th>
                                              </>
                                            ) : (
                                              <>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Height
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Width
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Quantity
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Label
                                                </th>
                                                <th
                                                  style={{
                                                    backgroundColor:
                                                      "rgb(132, 206, 132)",
                                                  }}
                                                >
                                                  Note
                                                </th>
                                              </>
                                            )}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {Object.values(
                                            formulatabledetails[category][
                                              color
                                            ].reduce(
                                              (
                                                acc: any,
                                                item: any,
                                                index: number
                                              ) => {
                                                const key = `${
                                                  category === "depth"
                                                    ? Math.floor(
                                                        item.formulaupdatedboxdepthcat
                                                      )
                                                    : category === "shelf"
                                                    ? Math.floor(
                                                        item.formulaupdatedboxdepthcat
                                                      )
                                                    : Math.floor(
                                                        item.formulaupdatedboxheightcat
                                                      )
                                                }-${
                                                  category === "depth"
                                                    ? Math.floor(
                                                        item.formulaupdatedboxdepthcat
                                                      )
                                                    : category === "shelf"
                                                    ? Math.floor(
                                                        item.formulaupdatedboxwidthcat
                                                      )
                                                    : Math.floor(
                                                        item.formulaupdatedboxwidthcat
                                                      )
                                                }-${item.Label}-${
                                                  item.Boxtype
                                                }`;
                                                if (!acc[key]) {
                                                  acc[key] = {
                                                    ...item,
                                                    quantitySum:
                                                      category === "depth"
                                                        ? 4
                                                        : category === "shelf"
                                                        ? item.boxshelf_countcat !==
                                                          ""
                                                          ? parseInt(
                                                              item.boxshelf_countcat
                                                            )
                                                          : 1
                                                        : 1,
                                                  };
                                                } else {
                                                  acc[key].quantitySum +=
                                                    category === "depth"
                                                      ? 4
                                                      : category === "shelf"
                                                      ? item.boxshelf_countcat !==
                                                        ""
                                                        ? parseInt(
                                                            item.boxshelf_countcat
                                                          )
                                                        : 1
                                                      : 1;
                                                }
                                                return acc;
                                              },
                                              {}
                                            )
                                          ).map((item: any, index: number) => (
                                            <tr
                                              key={index}
                                              style={{ textAlign: "center" }}
                                            >
                                              {category === "depth" ? (
                                                <>
                                                  <td>
                                                    {Math.floor(
                                                      item.formulaupdatedboxdepthcat
                                                    )}
                                                  </td>
                                                  <td>{item.quantitySum}</td>
                                                  <td>{item.Label}</td>
                                                  <td>
                                                    {item.Boxtype ===
                                                    "Regular Box"
                                                      ? ""
                                                      : item.Boxtype ===
                                                        "Open Box"
                                                      ? "Open Box"
                                                      : item.Boxtype ===
                                                        "Pa Box"
                                                      ? "Pa Box"
                                                      : ""}
                                                  </td>
                                                </>
                                              ) : category === "shelf" ? (
                                                <>
                                                  <td>
                                                    {Math.floor(
                                                      item.formulaupdatedboxdepthcat
                                                    )}
                                                  </td>
                                                  <td>
                                                    {Math.floor(
                                                      item.formulaupdatedboxwidthcat
                                                    )}
                                                  </td>
                                                  <td>{item.quantitySum}</td>
                                                  <td>SF</td>
                                                  <td>
                                                    {item.Boxtype ===
                                                    "Regular Box"
                                                      ? ""
                                                      : item.Boxtype ===
                                                        "Open Box"
                                                      ? "Open Box"
                                                      : item.Boxtype ===
                                                        "Pa Box"
                                                      ? "Pa Box"
                                                      : ""}
                                                  </td>
                                                </>
                                              ) : (
                                                <>
                                                  <td>
                                                    {Math.floor(
                                                      item.formulaupdatedboxheightcat
                                                    )}
                                                  </td>
                                                  <td>
                                                    {Math.floor(
                                                      item.formulaupdatedboxwidthcat
                                                    )}
                                                  </td>
                                                  <td>{item.quantitySum}</td>
                                                  <td>{item.Label}</td>
                                                  <td>
                                                    {item.Boxtype ===
                                                    "Regular Box"
                                                      ? ""
                                                      : item.Boxtype ===
                                                        "Open Box"
                                                      ? "Open Box"
                                                      : item.Boxtype ===
                                                        "Pa Box"
                                                      ? "Pa Box"
                                                      : ""}
                                                  </td>
                                                </>
                                              )}
                                            </tr>
                                          ))}
                                          <tr
                                            style={{
                                              textAlign: "center",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {category === "depth" ? (
                                              <>
                                                <td colSpan={1}></td>
                                                <td>
                                                  {" "}
                                                  {
                                                    formulatabledetails[
                                                      category
                                                    ][color][0].totalquantity
                                                  }
                                                </td>
                                              </>
                                            ) : (
                                              <>
                                                <td colSpan={2}></td>
                                                <td>
                                                  {" "}
                                                  {
                                                    formulatabledetails[
                                                      category
                                                    ][color][0].totalquantity
                                                  }
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      {/*Frame cutlist table*/}
                      {Object.keys(formulaframedetails)
                        .filter((category) => category !== "EP")
                        .map((category: string) => (
                          <div key={category}>
                            {Object.keys(formulaframedetails[category]).map(
                              (color: string) => (
                                <div key={color}>
                                  {category !== "ES" && (
                                    <div style={{ textAlign: "end" }}>
                                      <ReactHTMLTableToExcel
                                        id={`test-table-xls-button-${category}-${color}`}
                                        className="download-table-xls-button"
                                        table={`table-to-xls-${category}-${color}`}
                                        filename={`${state.prjName}-${category}-${color}`}
                                        sheet={`tablexls-${category}-${color}`}
                                        buttonText={
                                          <FontAwesomeIcon icon={faDownload} />
                                        }
                                      />
                                    </div>
                                  )}
                                  {category !== "ES" && (
                                    <h4
                                      style={{
                                        textAlign: "center",
                                        borderRadius: "5px",
                                        backgroundColor: "yellow",
                                        padding: "5px",
                                        marginBottom: "0px",
                                      }}
                                    >
                                      {category == "ES" ? (
                                        <>
                                          {category.charAt(0).toUpperCase() +
                                            category.slice(1)}{" "}
                                        </>
                                      ) : (
                                        <>
                                          {category.charAt(0).toUpperCase() +
                                            category.slice(1)}{" "}
                                          {color.charAt(0).toUpperCase() +
                                            color.slice(1)}{" "}
                                          NOs{" "}
                                          {
                                            formulaframedetails[category][
                                              color
                                            ][0].updatetotalnos
                                          }
                                        </>
                                      )}
                                    </h4>
                                  )}
                                  {category !== "ES" && (
                                    <Table
                                      responsive="md"
                                      striped
                                      bordered
                                      hover
                                      id={`table-to-xls-${category}-${color}`}
                                    >
                                      <thead>
                                        <tr style={{ textAlign: "center" }}>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Height
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Width
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Quantity
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Label
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Note
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.values(
                                          formulaframedetails[category][
                                            color
                                          ].reduce((acc: any, item: any) => {
                                            const key = `${Math.floor(
                                              item.updatedframeheightcat
                                            )}-${Math.floor(
                                              item.updatedframewidthcat
                                            )}-${item.Label}`;
                                            if (!acc[key]) {
                                              acc[key] = {
                                                ...item,
                                                quantitySum: 0,
                                              };
                                            }
                                            acc[key].quantitySum +=
                                              item.boxdoor_selectcat ===
                                              "Double"
                                                ? 2
                                                : 1;
                                            return acc;
                                          }, {})
                                        ).map((item: any, index: number) => (
                                          <tr
                                            key={index}
                                            style={{ textAlign: "center" }}
                                          >
                                            <td>
                                              {Math.floor(
                                                item.updatedframeheightcat
                                              )}
                                            </td>
                                            <td>
                                              {Math.floor(
                                                item.updatedframewidthcat
                                              )}
                                            </td>
                                            <td>{item.quantitySum}</td>
                                            <td>{item.Label}</td>
                                          </tr>
                                        ))}
                                        <tr
                                          style={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {
                                              formulaframedetails[category][
                                                color
                                              ][0].totalframequantity
                                            }
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  )}
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      {/* glass cutlist table // */}
                      {Object.keys(glassList).map((category: string) => (
                        <div key={category}>
                          {Object.keys(glassList[category]).map(
                            (color: string) => (
                              <div key={color}>
                                <div style={{ textAlign: "end" }}>
                                  <ReactHTMLTableToExcel
                                    id={`test-table-xls-button-${category}-${color}`}
                                    className="download-table-xls-button"
                                    table={`table-to-xls-${category}-${color}`}
                                    filename={`${state.prjName}-${category}-${color}`}
                                    sheet={`tablexls-${category}-${color}`}
                                    buttonText={
                                      <FontAwesomeIcon icon={faDownload} />
                                    }
                                  />
                                </div>
                                <h4
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                    backgroundColor: "yellow",
                                    padding: "5px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {category.charAt(0).toUpperCase() +
                                    category.slice(1)}{" "}
                                </h4>
                                {Array.isArray(glassList[category][color]) && (
                                  <>
                                    <Table
                                      responsive="md"
                                      striped
                                      bordered
                                      hover
                                      id={`table-to-xls-${category}-${color}`}
                                    >
                                      <thead>
                                        <tr style={{ textAlign: "center" }}>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Height
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Width
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Quantity
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Label
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            SFT
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.values(
                                          glassList[category][color].reduce(
                                            (acc: any, item: any) => {
                                              const key = `${item.formulaupdatedboxheightcat}-${item.formulaupdatedboxwidthcat}-${item.frametypecat}-${item.SFTValue}`;
                                              if (!acc[key]) {
                                                acc[key] = {
                                                  ...item,
                                                  quantitySum: 0,
                                                  SftSum: 0,
                                                }; // Initialize SftSum
                                              }
                                              acc[key].quantitySum +=
                                                item.frametypecat === "EP"
                                                  ? 1
                                                  : item.boxdoor_selectcat ===
                                                    "3 drawer"
                                                  ? 3
                                                  : item.boxdoor_selectcat ===
                                                    "2 drawer"
                                                  ? 2
                                                  : item.boxdoor_selectcat ===
                                                      "Double" ||
                                                    item.framedoortypeselectcat ===
                                                      "Double"
                                                  ? 2
                                                  : 1;
                                              acc[key].SftSum += parseFloat(
                                                item.SFTValue
                                              );
                                              return acc;
                                            },
                                            {}
                                          )
                                        ).map((item: any, index: number) => (
                                          <tr
                                            key={index}
                                            style={{ textAlign: "center" }}
                                          >
                                            <td>
                                              {item.formulaupdatedboxheightcat}
                                            </td>
                                            <td>
                                              {item.formulaupdatedboxwidthcat}
                                            </td>
                                            <td>{item.quantitySum}</td>
                                            <td></td>
                                            <td>{item.SftSum}</td>
                                          </tr>
                                        ))}
                                        <tr
                                          style={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {
                                              glassList[category][color][0]
                                                .totalGlasslistquantity
                                            }
                                          </td>
                                          <td></td>
                                          {/* <td>{glassList[category][color][0].totalGlasslistSFTquantity.toFixed(1)}</td> */}

                                          <td>
                                            {glassList[category]?.[
                                              color
                                            ]?.[0]?.totalGlasslistSFTquantity?.toFixed(
                                              1
                                            ) ?? "N/A"}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      ))}
                      {/* facia 100 drawer cutlist table // */}
                      {Object.keys(faciammdrawer).map((category: string) => (
                        <div key={category}>
                          {Object.keys(faciammdrawer[category]).map(
                            (color: string) => (
                              <div key={color}>
                                <div style={{ textAlign: "end" }}>
                                  <ReactHTMLTableToExcel
                                    id={`test-table-xls-button-${category}-${color}`}
                                    className="download-table-xls-button"
                                    table={`table-to-xls-${category}-${color}`}
                                    filename={`${state.prjName}-${category}-${color}`}
                                    sheet={`tablexls-${category}-${color}`}
                                    buttonText={
                                      <FontAwesomeIcon icon={faDownload} />
                                    }
                                  />
                                </div>
                                <h4
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                    backgroundColor: "yellow",
                                    padding: "5px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {category.charAt(0).toUpperCase() +
                                    category.slice(1)}{" "}
                                  NOs{" "}
                                  {
                                    faciammdrawer[category][color][0]
                                      .Facia100mmDrawertotalnos
                                  }
                                </h4>
                                {Array.isArray(
                                  faciammdrawer[category][color]
                                ) && (
                                  <>
                                    <Table
                                      responsive="md"
                                      striped
                                      bordered
                                      hover
                                      id={`table-to-xls-${category}-${color}`}
                                    >
                                      <thead>
                                        <tr style={{ textAlign: "center" }}>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Depth
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Width
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Quantity
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Label
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Note
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.values(
                                          faciammdrawer[category][color].reduce(
                                            (acc: any, item: any) => {
                                              const key = `${item.formulaupdatedboxdepthcat}-${item.formulaupdatedboxwidthcat}-${item.frametypecat}-${item.Boxtype}`;
                                              if (!acc[key]) {
                                                acc[key] = {
                                                  ...item,
                                                  quantitySum: 0,
                                                };
                                              }
                                              acc[key].quantitySum +=
                                                item.frametypecat === "EP"
                                                  ? 1
                                                  : item.boxdoor_selectcat ===
                                                    "3 drawer"
                                                  ? 3
                                                  : item.boxdoor_selectcat ===
                                                    "2 drawer"
                                                  ? 2
                                                  : item.boxdoor_selectcat ===
                                                    "3 drawer 2:1"
                                                  ? 3
                                                  : item.boxdoor_selectcat ===
                                                    "2 drawer 2:1"
                                                  ? 2
                                                  : item.boxdoor_selectcat ===
                                                      "Double" ||
                                                    item.framedoortypeselectcat ===
                                                      "Double"
                                                  ? 2
                                                  : 1;
                                              return acc;
                                            },
                                            {}
                                          )
                                        ).map((item: any, index: number) => (
                                          <tr
                                            key={index}
                                            style={{ textAlign: "center" }}
                                          >
                                            <td>
                                              {item.formulaupdatedboxdepthcat}
                                            </td>
                                            <td>
                                              {item.formulaupdatedboxwidthcat}
                                            </td>
                                            <td>{item.quantitySum}</td>
                                            <td>
                                              {item.frametypecat === "EP"
                                                ? "EP"
                                                : item.boxdoor_typecat ===
                                                    "Glass Door" ||
                                                  item.framedoortypecat ===
                                                    "Glass Door"
                                                ? "Glass DR"
                                                : item.boxdoor_typecat ===
                                                  "Facia"
                                                ? "FA"
                                                : item.frametypecat ===
                                                    "1.25 lip" ||
                                                  item.frametypecat ===
                                                    "1.25" ||
                                                  item.frametypecat === "1x1" ||
                                                  item.frametypecat === "EP"
                                                ? "DR"
                                                : item.boxdoor_typecat ===
                                                    "Sheet Door" ||
                                                  item.framedoortypecat ===
                                                    "Sheet Door"
                                                ? "DR"
                                                : item.frametypecat === "ES"
                                                ? "ES"
                                                : ""}
                                            </td>
                                            <td>
                                              {item.Boxtype === "Regular Box"
                                                ? ""
                                                : item.Boxtype === "Open Box"
                                                ? "Open Box"
                                                : item.Boxtype === "Pa Box"
                                                ? "Pa Box"
                                                : ""}
                                            </td>
                                          </tr>
                                        ))}
                                        <tr
                                          style={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {
                                              faciammdrawer[category][color][0]
                                                .totalFaciaDrawerquantity
                                            }
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      ))}
                      {/* facia tendemdrawer cutlist table // */}
                      {Object.keys(faciatandemdrawer).map((category) => (
                        <div key={category}>
                          {Object.keys(faciatandemdrawer[category]).map(
                            (color) => (
                              <div key={color}>
                                <div style={{ textAlign: "end" }}>
                                  <ReactHTMLTableToExcel
                                    id={`test-table-xls-button-${category}-${color}`}
                                    className="download-table-xls-button"
                                    table={`table-to-xls-${category}-${color}`}
                                    filename={`${state.prjName}-${category}-${color}`}
                                    sheet={`tablexls-${category}-${color}`}
                                    buttonText={
                                      <FontAwesomeIcon icon={faDownload} />
                                    }
                                  />
                                </div>
                                <div className="tabletableborder">
                                  <h4
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      backgroundColor: "yellow",
                                      padding: "5px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {category.charAt(0).toUpperCase() +
                                      category.slice(1)}
                                  </h4>
                                  <Table
                                    responsive="md"
                                    striped
                                    bordered
                                    hover
                                    id={`table-to-xls-${category}-${color}`}
                                  >
                                    {["bottom panel", "back panel"].map(
                                      (detailType) =>
                                        Array.isArray(
                                          faciatandemdrawer[category][color][
                                            detailType
                                          ]
                                        ) && (
                                          <tbody
                                            key={`${category}-${color}-${detailType}`}
                                          >
                                            <tr>
                                              <td
                                                colSpan={6}
                                                style={{
                                                  backgroundColor:
                                                    "rgb(132, 206, 132)",
                                                }}
                                              >
                                                <h4
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {detailType
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    detailType.slice(1)}
                                                </h4>
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              {detailType === "bottom panel" ? (
                                                <>
                                                  <th>Depth</th>
                                                  <th>Width</th>
                                                  <th>Quantity</th>
                                                  <th>Label</th>
                                                  <th>SFT</th>
                                                </>
                                              ) : detailType ===
                                                "back panel" ? (
                                                <>
                                                  <th>Height</th>
                                                  <th>Width</th>
                                                  <th>Quantity</th>
                                                  <th>Label</th>
                                                  <th>SFT</th>
                                                </>
                                              ) : null}
                                            </tr>
                                            {Object.values(
                                              faciatandemdrawer[category][
                                                color
                                              ][detailType].reduce(
                                                (acc: any, item: any) => {
                                                  const key = `${item.formulaupdatedboxdepthcat}-${item.formulaupdatedboxwidthcat}-${item.TandemSftValue}-${item.frametypecat}-${item.Boxtype}`;
                                                  if (!acc[key]) {
                                                    acc[key] = {
                                                      ...item,
                                                      quantitySum: 0,
                                                      FaciaSft: 0,
                                                    };
                                                  }
                                                  acc[key].quantitySum +=
                                                    item.frametypecat === "EP"
                                                      ? 1
                                                      : item.boxdoor_selectcat ===
                                                        "3 drawer"
                                                      ? 3
                                                      : item.boxdoor_selectcat ===
                                                        "2 drawer"
                                                      ? 2
                                                      : item.boxdoor_selectcat ===
                                                        "3 drawer 2:1"
                                                      ? 3
                                                      : item.boxdoor_selectcat ===
                                                        "2 drawer 2:1"
                                                      ? 2
                                                      : item.boxdoor_selectcat ===
                                                          "Double" ||
                                                        item.framedoortypeselectcat ===
                                                          "Double"
                                                      ? 2
                                                      : 1;
                                                  acc[key].FaciaSft +=
                                                    parseFloat(
                                                      item.TandemSftValue
                                                    );
                                                  return acc;
                                                },
                                                {}
                                              )
                                            ).map((item: any, index: any) => (
                                              <tr
                                                key={index}
                                                style={{ textAlign: "center" }}
                                              >
                                                {detailType ===
                                                "bottom panel" ? (
                                                  <>
                                                    <td>
                                                      {Math.floor(
                                                        item.formulaupdatedboxdepthcat
                                                      )}
                                                    </td>
                                                    <td>
                                                      {Math.floor(
                                                        item.formulaupdatedboxwidthcat
                                                      )}
                                                    </td>
                                                    <td>{item.quantitySum}</td>
                                                    <td></td>

                                                    <td>{item.FaciaSft}</td>
                                                  </>
                                                ) : detailType ===
                                                  "back panel" ? (
                                                  <>
                                                    <td>
                                                      {Math.floor(
                                                        item.formulaupdatedboxheightcat
                                                      )}
                                                    </td>
                                                    <td>
                                                      {Math.floor(
                                                        item.formulaupdatedboxwidthcat
                                                      )}
                                                    </td>
                                                    <td>{item.quantitySum}</td>
                                                    <td></td>
                                                    <td>{item.FaciaSft}</td>
                                                  </>
                                                ) : null}
                                              </tr>
                                            ))}
                                            <tr
                                              style={{
                                                textAlign: "center",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              <td></td>
                                              <td></td>
                                              <td>
                                                {
                                                  faciatandemdrawer[category][
                                                    color
                                                  ][detailType][0]
                                                    .totalFaciaTandemDrawerQuantity
                                                }
                                              </td>
                                              <td></td>
                                              <td>
                                                {faciatandemdrawer[category]?.[
                                                  color
                                                ]?.[
                                                  detailType
                                                ]?.[0]?.totalFaciaSft?.toFixed(
                                                  1
                                                ) ?? "N/A"}
                                              </td>
                                            </tr>
                                          </tbody>
                                        )
                                    )}
                                  </Table>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ))}
                      {/* facia internaldrawer single double cutlist table // */}
                      {Object.keys(faciainternaldrawer).map((category) => (
                        <div key={category}>
                          {Object.keys(faciainternaldrawer[category]).map(
                            (color) => (
                              <div key={color}>
                                <div style={{ textAlign: "end" }}>
                                  <ReactHTMLTableToExcel
                                    id={`test-table-xls-button-${category}-${color}`}
                                    className="download-table-xls-button"
                                    table={`table-to-xls-${category}-${color}`}
                                    filename={`${state.prjName}-${category}-${color}`}
                                    sheet={`tablexls-${category}-${color}`}
                                    buttonText={
                                      <FontAwesomeIcon icon={faDownload} />
                                    }
                                  />
                                </div>
                                <div className="tabletableborder">
                                  <h4
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      backgroundColor: "yellow",
                                      padding: "5px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {category.charAt(0).toUpperCase() +
                                      category.slice(1)}
                                  </h4>
                                  <Table
                                    responsive="md"
                                    striped
                                    bordered
                                    hover
                                    id={`table-to-xls-${category}-${color}`}
                                  >
                                    {[
                                      "Single lip ivory",
                                      "100mm drawer ivory",
                                      "Internal Facia steel",
                                    ].map(
                                      (detailType) =>
                                        Array.isArray(
                                          faciainternaldrawer[category][color][
                                            detailType
                                          ]
                                        ) && (
                                          <tbody
                                            key={`${category}-${color}-${detailType}`}
                                          >
                                            <tr>
                                              <td
                                                colSpan={6}
                                                style={{
                                                  backgroundColor:
                                                    "rgb(132, 206, 132)",
                                                }}
                                              >
                                                <h4
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {detailType
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    detailType.slice(1)}{" "}
                                                  NOs{" "}
                                                  {
                                                    faciainternaldrawer[
                                                      category
                                                    ][color][detailType][0]
                                                      .FaciaInDrawertotalnos
                                                  }
                                                </h4>
                                              </td>
                                            </tr>
                                            <tr style={{ textAlign: "center" }}>
                                              {detailType ===
                                              "Single lip ivory" ? (
                                                <>
                                                  <th>Height</th>
                                                  <th>Width</th>
                                                  <th>Quantity</th>
                                                  <th>Label</th>
                                                  <th>Note</th>
                                                </>
                                              ) : detailType ===
                                                "100mm drawer ivory" ? (
                                                <>
                                                  <th>Depth</th>
                                                  <th>Width</th>
                                                  <th>Quantity</th>
                                                  <th>Label</th>
                                                  <th>Note</th>
                                                </>
                                              ) : detailType ===
                                                "Internal Facia steel" ? (
                                                <>
                                                  <th>Height</th>
                                                  <th>Width</th>
                                                  <th>Quantity</th>
                                                  <th>Label</th>
                                                  <th>Note</th>
                                                </>
                                              ) : null}
                                            </tr>
                                            {Object.values(
                                              faciainternaldrawer[category][
                                                color
                                              ][detailType].reduce(
                                                (acc: any, item: any) => {
                                                  const key = `${item.formulaupdatedboxdepthcat}-${item.formulaupdatedboxwidthcat}-${item.frametypecat}-${item.Boxtype}`;
                                                  if (!acc[key]) {
                                                    acc[key] = {
                                                      ...item,
                                                      quantitySum: 0,
                                                    };
                                                  }
                                                  acc[key].quantitySum +=
                                                    item.frametypecat === "EP"
                                                      ? 1
                                                      : item.boxdoor_selectcat ===
                                                        "3 drawer"
                                                      ? 3
                                                      : item.boxdoor_selectcat ===
                                                        "2 drawer"
                                                      ? 2
                                                      : item.boxdoor_selectcat ===
                                                        "3 drawer 2:1"
                                                      ? 3
                                                      : item.boxdoor_selectcat ===
                                                        "2 drawer 2:1"
                                                      ? 2
                                                      : item.boxdoor_selectcat ===
                                                        "Single Drawer"
                                                      ? 2
                                                      : item.boxdoor_selectcat ===
                                                        "Double Drawer"
                                                      ? 2
                                                      : item.boxdoor_selectcat ===
                                                          "Double" ||
                                                        item.framedoortypeselectcat ===
                                                          "Double"
                                                      ? 2
                                                      : 1;
                                                  return acc;
                                                },
                                                {}
                                              )
                                            ).map((item: any, index: any) => (
                                              <tr
                                                key={index}
                                                style={{ textAlign: "center" }}
                                              >
                                                {detailType ===
                                                "Single lip ivory" ? (
                                                  <>
                                                    <td>
                                                      {Math.floor(
                                                        item.formulaupdatedboxheightcat
                                                      )}
                                                    </td>
                                                    <td>
                                                      {Math.floor(
                                                        item.formulaupdatedboxwidthcat
                                                      )}
                                                    </td>
                                                    <td>{item.quantitySum}</td>
                                                    <td></td>
                                                    <td>
                                                      {item.Boxtype ===
                                                      "Regular Box"
                                                        ? ""
                                                        : item.Boxtype ===
                                                          "Open Box"
                                                        ? "Open Box"
                                                        : item.Boxtype ===
                                                          "Pa Box"
                                                        ? "Pa Box"
                                                        : ""}
                                                    </td>
                                                  </>
                                                ) : detailType ===
                                                  "100mm drawer ivory" ? (
                                                  <>
                                                    <td>
                                                      {Math.floor(
                                                        item.formulaupdatedboxdepthcat
                                                      )}
                                                    </td>
                                                    <td>
                                                      {Math.floor(
                                                        item.formulaupdatedboxwidthcat
                                                      )}
                                                    </td>
                                                    <td>{item.quantitySum}</td>
                                                    <td></td>
                                                    <td>
                                                      {item.Boxtype ===
                                                      "Regular Box"
                                                        ? ""
                                                        : item.Boxtype ===
                                                          "Open Box"
                                                        ? "Open Box"
                                                        : item.Boxtype ===
                                                          "Pa Box"
                                                        ? "Pa Box"
                                                        : ""}
                                                    </td>
                                                  </>
                                                ) : detailType ===
                                                  "Internal Facia steel" ? (
                                                  <>
                                                    <td>
                                                      {Math.floor(
                                                        item.formulaupdatedboxheightcat
                                                      )}
                                                    </td>
                                                    <td>
                                                      {Math.floor(
                                                        item.formulaupdatedboxwidthcat
                                                      )}
                                                    </td>
                                                    <td>{item.quantitySum}</td>
                                                    <td></td>
                                                    <td>
                                                      {item.Boxtype ===
                                                      "Regular Box"
                                                        ? ""
                                                        : item.Boxtype ===
                                                          "Open Box"
                                                        ? "Open Box"
                                                        : item.Boxtype ===
                                                          "Pa Box"
                                                        ? "Pa Box"
                                                        : ""}
                                                    </td>
                                                  </>
                                                ) : null}
                                              </tr>
                                            ))}
                                            <tr
                                              style={{
                                                textAlign: "center",
                                                fontWeight: "bold",
                                              }}
                                            >
                                              <td></td>
                                              <td></td>
                                              <td>
                                                {
                                                  faciainternaldrawer[category][
                                                    color
                                                  ][detailType][0]
                                                    .totalFaciaInternalDrawerQuantity
                                                }
                                              </td>
                                            </tr>
                                          </tbody>
                                        )
                                    )}
                                  </Table>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      ))}
                      {/* //frame es// */}
                      {Object.keys(formulaframedetails)
                        .filter((category) => category === "ES") // Only keep the "ES" category
                        .map((category: string) => (
                          <div key={category}>
                            {Object.keys(formulaframedetails[category]).map(
                              (color: string) => (
                                <div key={color}>
                                  <div style={{ textAlign: "end" }}>
                                    <ReactHTMLTableToExcel
                                      id={`test-table-xls-button-${category}-${color}`}
                                      className="download-table-xls-button"
                                      table={`table-to-xls-${category}-${color}`}
                                      filename={`${state.prjName}-${category}-${color}`}
                                      sheet={`tablexls-${category}-${color}`}
                                      buttonText={
                                        <FontAwesomeIcon icon={faDownload} />
                                      }
                                    />
                                  </div>
                                  <h4
                                    style={{
                                      textAlign: "center",
                                      borderRadius: "5px",
                                      backgroundColor: "yellow",
                                      padding: "5px",
                                      marginBottom: "0px",
                                    }}
                                  >
                                    {category.charAt(0).toUpperCase() +
                                      category.slice(1)}
                                  </h4>

                                  <Table
                                    responsive="md"
                                    striped
                                    bordered
                                    hover
                                    id={`table-to-xls-${category}-${color}`}
                                  >
                                    <thead>
                                      <tr style={{ textAlign: "center" }}>
                                        <th
                                          style={{
                                            backgroundColor:
                                              "rgb(132, 206, 132)",
                                          }}
                                        >
                                          Height
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor:
                                              "rgb(132, 206, 132)",
                                          }}
                                        >
                                          Width
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor:
                                              "rgb(132, 206, 132)",
                                          }}
                                        >
                                          Quantity
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor:
                                              "rgb(132, 206, 132)",
                                          }}
                                        >
                                          Label
                                        </th>
                                        <th
                                          style={{
                                            backgroundColor:
                                              "rgb(132, 206, 132)",
                                          }}
                                        >
                                          Note
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Object.values(
                                        formulaframedetails[category][
                                          color
                                        ].reduce((acc: any, item: any) => {
                                          const key = `${Math.floor(
                                            item.updatedframeheightcat
                                          )}-${Math.floor(
                                            item.updatedframewidthcat
                                          )}-${item.Label}`;
                                          if (!acc[key]) {
                                            acc[key] = {
                                              ...item,
                                              quantitySum: 0,
                                            };
                                          }
                                          acc[key].quantitySum +=
                                            item.boxdoor_selectcat === "Double"
                                              ? 2
                                              : 1;
                                          return acc;
                                        }, {})
                                      ).map((item: any, index: number) => (
                                        <tr
                                          key={index}
                                          style={{ textAlign: "center" }}
                                        >
                                          <td>
                                            {Math.floor(
                                              item.updatedframeheightcat
                                            )}
                                          </td>
                                          <td>
                                            {Math.floor(
                                              item.updatedframewidthcat
                                            )}
                                          </td>
                                          <td>{item.quantitySum}</td>
                                          <td>ES</td>
                                        </tr>
                                      ))}
                                      <tr
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <td></td>
                                        <td></td>
                                        <td>
                                          {
                                            formulaframedetails[category][
                                              color
                                            ][0].totalframequantity
                                          }
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              )
                            )}
                          </div>
                        ))}
                      {/* //ivorysheet cutlist Table/ */}

                      <Row className="scrollRow">
                        {IvorySheet.Ivory_Sheet &&
                          IvorySheet.Ivory_Sheet.length > 0 && (
                            <>
                              <Col lg="12" md="12" className="">
                                <div style={{ textAlign: "end" }}>
                                  <ReactHTMLTableToExcel
                                    id="test-table-xls-button"
                                    className="download-table-xls-button"
                                    table="table-to-xls"
                                    filename={`${state.prjName}-ivorysheet`}
                                    sheet={`tablexls`}
                                    buttonText={
                                      <FontAwesomeIcon icon={faDownload} />
                                    }
                                  />
                                </div>
                                <h4
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                    backgroundColor: "yellow",
                                    padding: "5px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  Ivory Sheet Report
                                </h4>
                                <Table
                                  responsive="md lg"
                                  striped
                                  bordered
                                  hover
                                  id="table-to-xls"
                                  style={{ width: "100% !important" }}
                                >
                                  <thead>
                                    <tr>
                                      <th
                                        style={{
                                          backgroundColor: "rgb(132, 206, 132)",
                                        }}
                                      >
                                        Height
                                      </th>
                                      <th
                                        style={{
                                          backgroundColor: "rgb(132, 206, 132)",
                                        }}
                                      >
                                        Width
                                      </th>
                                      <th
                                        style={{
                                          backgroundColor: "rgb(132, 206, 132)",
                                        }}
                                      >
                                        Qty
                                      </th>
                                      <th
                                        style={{
                                          backgroundColor: "rgb(132, 206, 132)",
                                        }}
                                      >
                                        Label
                                      </th>
                                      <th
                                        style={{
                                          backgroundColor: "rgb(132, 206, 132)",
                                        }}
                                      >
                                        Note
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {IvorySheet.Ivory_Sheet &&
                                      IvorySheet.Ivory_Sheet.length > 0 &&
                                      IvorySheet.Ivory_Sheet.map(
                                        (details: any, index: any) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{details.height}</td>
                                                <td>{details.width}</td>
                                                <td>{details.qty}</td>
                                                <td>{details.Label}</td>
                                                <td>{details.Note}</td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                    {IvorySheet.shelf &&
                                      IvorySheet.shelf.length > 0 &&
                                      IvorySheet.shelf.map(
                                        (details: any, index: any) => {
                                          return (
                                            <>
                                              <tr>
                                                <td>{details.depth}</td>
                                                <td>{details.width}</td>
                                                <td>{details.qty}</td>
                                                <td>{details.Label}</td>
                                                <td>{details.Note}</td>
                                              </tr>
                                            </>
                                          );
                                        }
                                      )}
                                  </tbody>
                                </Table>
                              </Col>
                            </>
                          )}
                      </Row>
                      {/*Doorsheet cutlist Table*/}
                      {Object.keys(doorsheet).map((category: string) => (
                        <div key={category}>
                          {Object.keys(doorsheet[category]).map(
                            (color: string) => (
                              <div key={color}>
                                <div style={{ textAlign: "end" }}>
                                  <ReactHTMLTableToExcel
                                    id={`test-table-xls-button-${category}-${color}`}
                                    className="download-table-xls-button"
                                    table={`table-to-xls-${category}-${color}`}
                                    filename={`${state.prjName}-${category}-${color}`}
                                    sheet={`tablexls-${category}-${color}`}
                                    buttonText={
                                      <FontAwesomeIcon icon={faDownload} />
                                    }
                                  />
                                </div>
                                <h4
                                  style={{
                                    textAlign: "center",
                                    borderRadius: "5px",
                                    backgroundColor: "yellow",
                                    padding: "5px",
                                    marginBottom: "0px",
                                  }}
                                >
                                  {category.charAt(0).toUpperCase() +
                                    category.slice(1)}{" "}
                                </h4>
                                {Array.isArray(doorsheet[category][color]) && ( // Check if it's an array
                                  <>
                                    <Table
                                      responsive="md"
                                      striped
                                      bordered
                                      hover
                                      id={`table-to-xls-${category}-${color}`}
                                    >
                                      <thead>
                                        <tr style={{ textAlign: "center" }}>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Height
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Width
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Quantity
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Label
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            LeftRemark
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            RightRemark
                                          </th>
                                          <th
                                            style={{
                                              backgroundColor:
                                                "rgb(132, 206, 132)",
                                            }}
                                          >
                                            Note
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {Object.values(
                                          doorsheet[category][color].reduce(
                                            (acc: any, item: any) => {
                                              const key = `${item.formulaupdatedboxheightcat}-${item.formulaupdatedboxwidthcat}-${item.frametypecat}-${item.Boxtype}`;
                                              if (!acc[key]) {
                                                acc[key] = {
                                                  ...item,
                                                  quantitySum: 0,
                                                };
                                              }
                                              acc[key].quantitySum +=
                                                item.frametypecat === "EP"
                                                  ? 1
                                                  : item.boxdoor_selectcat ===
                                                    "3 drawer"
                                                  ? 3
                                                  : item.boxdoor_selectcat ===
                                                    "2 drawer"
                                                  ? 2
                                                  : item.boxdoor_selectcat ===
                                                    "3 drawer 2:1"
                                                  ? 1
                                                  : item.boxdoor_selectcat ===
                                                    "2 drawer 2:1"
                                                  ? 1
                                                  : item.boxdoor_selectcat ===
                                                    "Single Drawer"
                                                  ? 2
                                                  : item.boxdoor_selectcat ===
                                                    "Double Drawer"
                                                  ? 2
                                                  : item.boxdoor_selectcat ===
                                                      "Double" ||
                                                    item.framedoortypeselectcat ===
                                                      "Double"
                                                  ? 2
                                                  : 1;
                                              return acc;
                                            },
                                            {}
                                          )
                                        ).map((item: any, index: number) => (
                                          <tr
                                            key={index}
                                            style={{ textAlign: "center" }}
                                          >
                                            <td>
                                              {item.formulaupdatedboxheightcat}
                                            </td>
                                            <td>
                                              {item.formulaupdatedboxwidthcat}
                                            </td>
                                            <td>{item.quantitySum}</td>

                                            <td>
                                              {item.frametypecat === "EP"
                                                ? "EP"
                                                : item.boxdoor_typecat ===
                                                    "Glass Door" ||
                                                  item.framedoortypecat ===
                                                    "Glass Door"
                                                ? "Glass DR"
                                                : item.boxdoor_typecat ===
                                                  "Facia"
                                                ? "FA"
                                                : item.frametypecat ===
                                                    "1.25 lip" ||
                                                  item.frametypecat ===
                                                    "1.25" ||
                                                  item.frametypecat === "1x1" ||
                                                  item.frametypecat === "EP"
                                                ? "DR"
                                                : item.boxdoor_typecat ===
                                                    "Sheet Door" ||
                                                  item.framedoortypecat ===
                                                    "Sheet Door"
                                                ? "DR"
                                                : item.frametypecat === "ES"
                                                ? "ES"
                                                : ""}
                                            </td>
                                            <td>{item.LeftRk}</td>
                                            <td>{item.RightRk}</td>
                                            <td>
                                              {item.Boxtype === "Regular Box"
                                                ? ""
                                                : item.Boxtype === "Open Box"
                                                ? "Open Box"
                                                : item.Boxtype === "Pa Box"
                                                ? "Pa Box"
                                                : item.frametypecat ===
                                                  "1 x 1 Pa Frame"
                                                ? "1 x 1 Pa Frame"
                                                : item.frametypecat ===
                                                  "1.25 Pa Frame"
                                                ? "1.25 Pa Frame"
                                                : ""}
                                            </td>
                                          </tr>
                                        ))}
                                        <tr
                                          style={{
                                            textAlign: "center",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          <td></td>
                                          <td></td>
                                          <td>
                                            {
                                              doorsheet[category][color][0]
                                                .totaldoorsheetquantity
                                            }
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </>
                                )}
                              </div>
                            )
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col lg="2" md="2" sm="2"></Col>
              </Row>
            </>
          )}
          <br />
          <br />
          {/* //footer // */}
          <header>
            <div className="">
              <h1 className="heading" style={{ fontSize: "25px" }}>
                Delete
                <FontAwesomeIcon
                  icon={faTrash}
                  className="icon "
                  onClick={confirmDeleteAndDownloadPDF}
                />
              </h1>
            </div>
          </header>
          {/* //edit box modal // */}
          <Modal show={state.modelshow} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Update Box</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <Row className="mb-3">
                  <Col lg="3" md="3" sm="3" xs="3" className="boxcardcol ">
                    <label className="label"> Categery</label>
                  </Col>
                  {state.updateCatstatus == "0" && (
                    <>
                      <Col
                        lg="5"
                        md="5"
                        sm="5"
                        xs="5"
                        className="flexbtn mblviewgap"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          value={state.updateboxcategery}
                          onChange={updatecategerychange}
                        >
                          <option value="">Select Categery</option>
                          {state.categery.map((categery) => (
                            <option key={categery.id} value={categery.categery}>
                              {categery.categery}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </>
                  )}
                  {state.updateCatstatus == "1" && (
                    <>
                      <Col
                        lg="4"
                        md="4"
                        sm="4"
                        xs="4"
                        className="flexbtn mblviewgap"
                      >
                        <input
                          type="text"
                          className="form-control colorinput"
                          placeholder="Enter categery"
                          minLength={5}
                          maxLength={50}
                          value={state.Updateboxtypecategery}
                          onChange={BoxUpdatecategerytype}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Row className="mb-3">
                  <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                    <label>Height</label>
                    <input
                      type="number"
                      name="boxheight"
                      value={state.updateboxheight}
                      onChange={updateheightchange}
                      className="form-control"
                      placeholder=""
                      minLength={5}
                      maxLength={50}
                      required
                    />
                  </Col>
                  <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                    <label>Width</label>
                    <input
                      type="number"
                      name="boxwidth"
                      value={state.updateboxwidth}
                      onChange={updatewidthchange}
                      className="form-control"
                      placeholder=""
                      minLength={5}
                      maxLength={50}
                      required
                    />
                  </Col>
                  <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                    <label>Depth</label>
                    <input
                      type="number"
                      name="boxdepth"
                      value={state.updateboxdepth}
                      onChange={updatedepthchange}
                      className="form-control"
                      placeholder=""
                      minLength={5}
                      maxLength={50}
                      required
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                    <label className="">Box</label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={UpdateBoxChange}
                      value={state.UpdateBoxType}
                      required
                    >
                      <option value="Regular Box">Regular Box</option>
                      <option value="Open Box">Open Box</option>
                      <option value="Pa Box">Pa Box </option>
                    </Form.Select>
                  </Col>

                  {(state.UpdateBoxType === "Regular Box" ||
                    state.UpdateBoxType === "Pa Box") && (
                    <>
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        <label className="">Door Type</label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={updatedoortypechange}
                          value={state.updateboxdoortype}
                          required
                        >
                          <option value="No Door">No Door</option>
                          <option value="Sheet Door">Sheet Door</option>
                          <option value="Glass Door">Glass Door </option>
                          <option value="Facia">Facia</option>
                        </Form.Select>
                      </Col>

                      {(state.updateboxdoortype === "Sheet Door" ||
                        state.updateboxdoortype === "Glass Door") && (
                        <>
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="mblviewgap"
                          >
                            <label className="">Quantity</label>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={updatedoorselectchange}
                              value={state.updateboxdoorselect}
                            >
                              <option value="Single">Single Door</option>
                              <option value="Double">Double Door</option>
                            </Form.Select>
                          </Col>
                        </>
                      )}

                      {state.updateboxdoortype === "Facia" && (
                        <>
                          <Col
                            lg="4"
                            md="4"
                            sm="4"
                            xs="4"
                            className="mblviewgap"
                          >
                            <label className=" whitetxt">type</label>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={UpdatedFaciavalueChange}
                              value={state.UpdateFaciaValue}
                            >
                              <option value="0">BPO drawer</option>
                              <option value="1">100mm drawer</option>
                              <option value="2">Tandem drawer</option>
                              <option value="3">Internal drawer</option>
                            </Form.Select>
                          </Col>
                          {(state.UpdateFaciaValue === "1" ||
                            state.UpdateFaciaValue === "2") && (
                            <>
                              <Col
                                lg="4"
                                md="4"
                                sm="4"
                                xs="4"
                                className="mblviewgap"
                              >
                                <label className="label"></label>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={updatedoorselectchange}
                                  value={state.updateboxdoorselect}
                                >
                                  <option value="2 drawer">2 Drawer 1:1</option>
                                  <option value="2 drawer 2:1">
                                    2 Drawer 2:1
                                  </option>
                                  <option value="3 drawer">
                                    3 Drawer 1:1:1
                                  </option>
                                  <option value="3 drawer 2:1">
                                    3 Drawer 2:1
                                  </option>
                                </Form.Select>
                              </Col>
                            </>
                          )}
                          {state.UpdateFaciaValue == "3" && (
                            <>
                              <Col
                                lg="4"
                                md="4"
                                sm="4"
                                xs="4"
                                className="mblviewgap"
                              >
                                <label className="label"></label>
                                <Form.Select
                                  aria-label="Default select example"
                                  onChange={updatedoorselectchange}
                                  value={state.updateboxdoorselect}
                                >
                                  <option value="Single Drawer">
                                    Single Drawer
                                  </option>
                                  <option value="Double Drawer">
                                    Double Drawer
                                  </option>
                                </Form.Select>
                              </Col>
                            </>
                          )}

                          {state.UpdateFaciaValue == "3" &&
                            (state.updateboxdoorselect == "Single Drawer" ||
                              state.updateboxdoorselect == "Double Drawer") && (
                              <>
                                <Col
                                  lg="4"
                                  md="4"
                                  sm="4"
                                  xs="4"
                                  className="nodoorcheck"
                                >
                                  <label className="label">No Door </label>
                                  <input
                                    name="#value"
                                    type="checkbox"
                                    className="form-check-input"
                                    id="exampleCheck1"
                                    checked={state.UpdateFaciaInnodoor}
                                    onChange={UpdateFaciaInternalnodoor}
                                  />
                                </Col>
                              </>
                            )}
                        </>
                      )}

                      {state.updateboxdoorselect === "Single" && (
                        <>
                          <Row className="mb-3">
                            <Col
                              lg="6"
                              md="6"
                              sm="6"
                              xs="6"
                              className="mblviewgap"
                            >
                              <label className=""> Door Side</label>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={updatedoorselectpositionchange}
                                value={state.updateboxdoorposition}
                              >
                                <option value="top">Top</option>
                                <option value="bottom">Bottom</option>
                                <option value="left">Left</option>
                                <option value="right">Right</option>
                              </Form.Select>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </Row>
                {(state.updateboxdoortype === "Sheet Door" ||
                  state.updateboxdoortype === "Glass Door" ||
                  state.updateboxdoortype === "No Door") && (
                  <>
                    <Row className="mb-3">
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        <label className="">Shelf</label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={updateshelfchange}
                          value={state.updateshelf}
                        >
                          <option value="Regular self">Regular Self</option>
                          <option value="Neck to Neck">Neck to Neck </option>
                          <option value="No Self">No Self </option>
                        </Form.Select>
                      </Col>
                      {(state.updateshelf === "Regular self" ||
                        state.updateshelf === "Neck to Neck") && (
                        <>
                          <Col
                            lg="2"
                            md="2"
                            sm="2"
                            xs="2"
                            className="mblviewgap"
                          >
                            <label className=" whitetxt">type</label>
                            <input
                              type="number"
                              name="shelf_count"
                              onChange={updateshelfcountchange}
                              value={state.updateshelfcount}
                              className="form-control"
                              placeholder=""
                              minLength={5}
                              maxLength={50}
                              required
                            />
                          </Col>
                        </>
                      )}
                      {(state.updateshelf === "Regular self" ||
                        state.updateshelf === "Neck to Neck") && (
                        <>
                          {state.updateSelfClrStatus == "0" && (
                            <>
                              <Col
                                lg="3"
                                md="3"
                                sm="3"
                                xs="3"
                                className="mblviewgap"
                              >
                                <label className="">Self Color</label>
                                <Form.Select
                                  aria-label="Default select example"
                                  value={state.updateshelfcolor}
                                  onChange={updateshelfcolorchange}
                                >
                                  {state.colors.map((color) => (
                                    <option
                                      key={color.id}
                                      value={color.colorname}
                                    >
                                      {color.colorname}
                                    </option>
                                  ))}
                                </Form.Select>
                              </Col>
                            </>
                          )}
                          {state.updateSelfClrStatus == "1" && (
                            <>
                              <Col
                                lg="3"
                                md="3"
                                sm="3"
                                xs="3"
                                className="mblviewgap"
                              >
                                <label className=" whitetxt">type</label>
                                <input
                                  type="text"
                                  className="form-control colorinput"
                                  placeholder="Enter Color"
                                  minLength={5}
                                  maxLength={50}
                                  value={state.updateSelfEnterclr}
                                  onChange={updateSelfEnter}
                                />
                              </Col>
                            </>
                          )}
                        </>
                      )}
                    </Row>
                  </>
                )}
                <Row>
                  <Col lg="2" md="2" sm="2" xs="2" className=" ">
                    <label>#</label>
                  </Col>
                  <Col lg="3" md="3" sm="3" xs="3" className="">
                    <label>G</label>
                  </Col>
                  <Col lg="4" md="4" sm="4" xs="4" className="">
                    <label>IntDummy</label>
                  </Col>
                  <Col lg="3" md="3" sm="3" xs="3" className=" ">
                    <label>Fixed</label>
                  </Col>
                </Row>
                <Row>
                  <Col lg="2" md="2" sm="2" xs="2" className=" ">
                    <input
                      name="#value"
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                      checked={state.Upasvalue}
                      onChange={UpdateasvalueOnchange}
                    />
                  </Col>
                  {(state.updateboxdoortype === "Sheet Door" ||
                    state.updateboxdoortype === "Glass Door" ||
                    state.updateboxdoortype === "Facia") && (
                    <>
                      <Col lg="3" md="3" sm="3" xs="3" className="">
                        <input
                          name="g_value"
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          checked={state.updateboxgvalue}
                          onChange={updategvaluechange}
                        />
                      </Col>
                      <Col lg="4" md="4" sm="4" xs="4" className="">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          id="exampleCheck1"
                          checked={state.updateintdummyvalue}
                          onChange={updateintdummyvaluechange}
                        />
                      </Col>
                      <Col lg="3" md="3" sm="3" xs="3" className="">
                        <input
                          type="checkbox"
                          className="form-check-input "
                          id="exampleCheck1"
                          checked={state.updateboxfixed}
                          onChange={updateboxfixedchange}
                        />
                      </Col>
                      {state.updateboxg && (
                        <>
                          <Col
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mblviewgap"
                          >
                            <label>G</label>
                            <input
                              className="form-control"
                              placeholder=""
                              required
                              name="g"
                              minLength={5}
                              maxLength={50}
                              type="number"
                              value={state.updateboxg}
                              onChange={updategchange}
                            />
                          </Col>
                        </>
                      )}
                      {state.updateboxgvalue && (
                        <>
                          <Col
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mblviewgap"
                          >
                            <label className=" whitetxt">type</label>
                            <Form.Select
                              aria-label="Default select example"
                              onChange={updategvaluepositionchange}
                              value={state.updateboxgposition}
                            >
                              <option value="top">Top</option>
                              <option value="bottom">Bottom</option>
                            </Form.Select>
                          </Col>
                        </>
                      )}
                      {state.updateintdummy && (
                        <>
                          <Col
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mblviewgap"
                          >
                            <label> Intdummy</label>
                            <input
                              className="form-control"
                              placeholder=""
                              required
                              name="boxID"
                              minLength={5}
                              maxLength={50}
                              type="number"
                              value={state.updateintdummy}
                              onChange={updateintdummychange}
                            />
                          </Col>
                        </>
                      )}

                      {state.updateintdummy && (
                        <>
                          <Col
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mblviewgap"
                          >
                            <label className=" whitetxt">type</label>

                            <Form.Select
                              aria-label="Default select example"
                              onChange={updateintdummypositionchange}
                              value={state.updateboxintdummyposition}
                            >
                              <option value="left">left</option>
                              <option value="right">right</option>
                            </Form.Select>
                          </Col>
                        </>
                      )}
                    </>
                  )}
                  {state.updateboxfixed && (
                    <>
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        <label> Fixed</label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={updateboxfixedpositionchange}
                          value={state.updateboxfixedposition}
                        >
                          <option value="left">left</option>
                          <option value="right">right</option>
                        </Form.Select>
                      </Col>
                    </>
                  )}

                  {(state.updateboxfixedposition === "left" ||
                    state.updateboxfixedposition === "right") && (
                    <>
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        <label className=" whitetxt">type</label>
                        <input
                          className="form-control"
                          placeholder=""
                          required
                          name="boxID"
                          minLength={5}
                          maxLength={50}
                          type="number"
                          value={parseInt(state.updateboxheight) - 51}
                          onChange={UpdateFixedheightonchange}
                          readOnly
                        />
                      </Col>
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        <label className=" whitetxt">type</label>
                        <input
                          className="form-control"
                          placeholder="Width"
                          required
                          name="boxID"
                          minLength={5}
                          maxLength={50}
                          type="number"
                          value={state.UpdateFixedWidth}
                          onChange={UpdateFixedwidthonchange}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <Row className="mb-3">
                  {state.updateboxdoortype !== "No Door" && (
                    <>
                      <Col lg="6" md="6" sm="6" xs="6" className="">
                        <label> J Square Color</label>
                      </Col>
                    </>
                  )}
                  <Col lg="6" md="6" sm="6" xs="6" className="">
                    <label className="">Single Lip Color</label>
                  </Col>
                </Row>

                <Row className="mb-3">
                  {(state.updateboxdoortype === "Sheet Door" ||
                    state.updateboxdoortype === "Glass Door" ||
                    state.updateboxdoortype === "Facia") && (
                    <>
                      {state.updateJsquareclrStatus == "0" && (
                        <>
                          <Col
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className=" mblviewgap"
                          >
                            <Form.Select
                              aria-label="Default select example"
                              value={state.updatejsquarecolor}
                              onChange={updatejsquarecolorchange}
                            >
                              {state.colors.map((color) => (
                                <option key={color.id} value={color.colorname}>
                                  {color.colorname}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </>
                      )}
                      {state.updateJsquareclrStatus == "1" && (
                        <>
                          <Col
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="flexbtn mblviewgap"
                          >
                            <input
                              type="text"
                              className="form-control colorinput"
                              placeholder="Enter Color"
                              minLength={5}
                              maxLength={50}
                              value={state.UpdateJsquareEntercolor}
                              onChange={UpdateJsquareEnterColor}
                            />
                          </Col>
                        </>
                      )}
                    </>
                  )}
                  {state.updateSingleclrSts == "0" && (
                    <>
                      <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                        <Form.Select
                          aria-label="Default select example"
                          value={state.updatesinglelipcolor}
                          onChange={updatesinglelipcolorchange}
                        >
                          {state.colors.map((color) => (
                            <option key={color.id} value={color.colorname}>
                              {color.colorname}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </>
                  )}
                  {state.updateSingleclrSts == "1" && (
                    <>
                      <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                        <input
                          type="text"
                          className="form-control colorinput"
                          placeholder="Enter Color"
                          minLength={5}
                          maxLength={50}
                          value={state.updateSingleclrenter}
                          onChange={updateSingleclrEnter}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Row className="mb-3">
                  <Col lg="6" md="6" sm="6" xs="6" className="">
                    <label>Bk Color</label>
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="6" className="">
                    <label className="">Depth Color</label>
                  </Col>
                </Row>
                <Row className="mb-3">
                  {state.updateBkClrStatus == "0" && (
                    <>
                      <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                        <Form.Select
                          aria-label="Default select example"
                          value={state.updatebkcolor}
                          onChange={updatebkcolorchange}
                        >
                          {state.colors.map((color) => (
                            <option key={color.id} value={color.colorname}>
                              {color.colorname}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </>
                  )}
                  {state.updateBkClrStatus == "1" && (
                    <>
                      <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                        <label className=" whitetxt">type</label>
                        <input
                          type="text"
                          className="form-control colorinput"
                          placeholder="Enter Color"
                          minLength={5}
                          maxLength={50}
                          value={state.updateBkclrenter}
                          onChange={updateBkEnter}
                        />
                      </Col>
                    </>
                  )}
                  {state.updateDepthClrstatus == "0" && (
                    <>
                      <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                        <Form.Select
                          aria-label="Default select example"
                          value={state.updatedepthcolor}
                          onChange={updatedepthcolorchange}
                        >
                          {state.colors.map((color) => (
                            <option key={color.id} value={color.colorname}>
                              {color.colorname}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </>
                  )}
                  {state.updateDepthClrstatus == "1" && (
                    <>
                      <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                        <label className=" whitetxt">type</label>
                        <input
                          type="text"
                          className="form-control colorinput"
                          placeholder="Enter Color"
                          minLength={5}
                          maxLength={50}
                          value={state.updateDepthclrenter}
                          onChange={updateDepthEnter}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <Row className="mb-3">
                  <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                    {state.updateboxdoorselect === "Single" ||
                    state.updateboxdoorselect === "Single Drawer" ||
                    state.updateboxdoorselect === "Double Drawer" ||
                    state.updateboxdoorselect === "" ? (
                      <>
                        <label className="">Remark</label>
                      </>
                    ) : (
                      <></>
                    )}
                    {state.updateboxdoorselect === "Double" && (
                      <>
                        <label className="">Left Remark</label>
                      </>
                    )}
                    {state.updateboxdoorselect === "2 drawer" ||
                    state.updateboxdoorselect === "2 drawer 2:1" ||
                    state.updateboxdoorselect === "3 drawer" ||
                    state.updateboxdoorselect === "3 drawer 2:1" ? (
                      <>
                        <label className="">First Remark</label>
                      </>
                    ) : (
                      <></>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Remark"
                      minLength={5}
                      maxLength={50}
                      value={state.UpdateBoxRemarks}
                      onChange={UpdateBoxRemarksonChange}
                    />
                  </Col>
                  {(state.updateboxdoorselect === "Double" ||
                    state.updateboxdoorselect === "2 drawer" ||
                    state.updateboxdoorselect === "2 drawer 2:1" ||
                    state.updateboxdoorselect === "3 drawer" ||
                    state.updateboxdoorselect === "3 drawer 2:1") && (
                    <>
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        {(state.updateboxdoorselect === "2 drawer" ||
                          state.updateboxdoorselect === "2 drawer 2:1" ||
                          state.updateboxdoorselect === "3 drawer" ||
                          state.updateboxdoorselect === "3 drawer 2:1") && (
                          <>
                            <label className="">Second Remark</label>
                          </>
                        )}
                        {state.updateboxdoorselect === "Double" && (
                          <>
                            <label className="">Right Remark</label>
                          </>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Remark"
                          minLength={5}
                          maxLength={50}
                          value={state.UpRightRemark}
                          onChange={UpdateRightRemarkonChange}
                        />
                      </Col>
                    </>
                  )}

                  {(state.updateboxdoorselect === "3 drawer" ||
                    state.updateboxdoorselect === "3 drawer 2:1") && (
                    <>
                      <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                        {(state.updateboxdoorselect === "3 drawer" ||
                          state.updateboxdoorselect === "3 drawer 2:1") && (
                          <>
                            <label className="">Third Remark</label>
                          </>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Remark"
                          minLength={5}
                          maxLength={50}
                          value={state.UpThirdRemark}
                          onChange={UpdateThirdRemarkonChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col lg="12" md="12" className="flexbtn mt-3">
                    <Button
                      variant="outline-primary subbutton"
                      onClick={updateboxhandelclick}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} className="icon" />{" "}
                      Update Box{" "}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
          {/* edit frame modal // */}
          <Modal show={state.framemodelshow} onHide={framehandleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Update Frame</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="loginform">
                <Row className="mb-3">
                  <Col
                    lg="3"
                    md="3"
                    sm="3"
                    xs="3"
                    className="boxcardcol mblviewgap"
                  >
                    <label className="label"> Categery</label>
                  </Col>
                  {state.updateFrameCatstatus == "0" && (
                    <>
                      <Col lg="5" md="5" sm="5" xs="5" className="flexbtn">
                        <Form.Select
                          aria-label="Default select example"
                          value={state.updateframecategery}
                          onChange={updateframecategerychange}
                        >
                          <option value="">Select Categery</option>
                          {state.categery.map((categery) => (
                            <option key={categery.id} value={categery.categery}>
                              {categery.categery}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </>
                  )}

                  {state.updateFrameCatstatus == "1" && (
                    <>
                      <Col lg="6" md="6" className="flexbtn">
                        <input
                          type="text"
                          className="form-control colorinput"
                          placeholder="Enter categery"
                          minLength={5}
                          maxLength={50}
                          value={state.UpdateFrametypecategery}
                          onChange={FrameUpdatecategerytype}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <Row className="mb-3">
                  <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                    <label>Height</label>
                    <input
                      type="number"
                      name="frameheight"
                      value={state.updateframeheight}
                      onChange={updateframeheightchange}
                      className="form-control"
                      placeholder=""
                      minLength={5}
                      maxLength={50}
                      required
                    />
                  </Col>
                  <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                    <label>Width</label>
                    <input
                      type="number"
                      name="framewidth"
                      value={state.updateframewidth}
                      onChange={updateframewidthchange}
                      className="form-control"
                      placeholder=""
                      minLength={5}
                      maxLength={50}
                      required
                    />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                    <label className="">Frame</label>
                    <Form.Select
                      aria-label="Default select example"
                      onChange={updateframetypechange}
                      value={state.updatetype}
                    >
                      <option value="1 x 1">1 x 1 </option>
                      <option value="1.25">1.25</option>
                      <option value="1.25 lip">1.25 lip</option>
                      <option value="EP">EP</option>
                      <option value="ES">ES</option>
                      <option value="1 x 1 Pa Frame">1 x 1 Pa Frame</option>
                      <option value="1.25 Pa Frame">1.25 Pa Frame</option>
                    </Form.Select>
                  </Col>

                  {state.updatetype !== "EP" &&
                    state.updatetype !== "ES" &&
                    state.updatetype !== "1.25 lip" && (
                      <>
                        <Col lg="4" md="4" sm="4" xs="4" className="mblviewgap">
                          <label className="">Door Type </label>
                          <Form.Select
                            aria-label="Default select example"
                            name="framedoor_type"
                            onChange={updateframedoortypechange}
                            value={state.updateframedoortype}
                          >
                            <option value="No Door">No Door</option>
                            <option value="Glass Door">Glass Door </option>
                            <option value="Sheet Door">Sheet Door</option>
                          </Form.Select>
                        </Col>
                        {state.updatetype !== "EP" &&
                          state.updatetype !== "ES" &&
                          state.updatetype !== "1.25 lip" && (
                            <>
                              {(state.updateframedoortype === "Glass Door" ||
                                state.updateframedoortype === "Sheet Door") && (
                                <>
                                  <Col
                                    lg="4"
                                    md="4"
                                    sm="4"
                                    xs="4"
                                    className="mblviewgap"
                                  >
                                    <label className="">Quantity</label>
                                    <Form.Select
                                      aria-label="Default select example"
                                      onChange={updateframedoorselectchange}
                                      value={state.updateframedoorselect}
                                    >
                                      <option value="Single">
                                        Single Door
                                      </option>
                                      <option value="Double">
                                        Double Door
                                      </option>
                                    </Form.Select>
                                  </Col>
                                </>
                              )}{" "}
                            </>
                          )}
                      </>
                    )}
                </Row>
                {state.updatetype !== "EP" &&
                  state.updatetype !== "ES" &&
                  state.updatetype !== "1.25 lip" && (
                    <>
                      {state.updateframedoorselect === "Single" && (
                        <>
                          <Row className="mb-3">
                            <Col
                              lg="6"
                              md="6"
                              sm="6"
                              xs="6"
                              className="mblviewgap"
                            >
                              <label className=" "> Door Side</label>
                              <Form.Select
                                aria-label="Default select example"
                                onChange={updateframeselectpositionchange}
                                value={state.updateframedoorposition}
                              >
                                <option value="left">Left</option>
                                <option value="right">Right</option>
                                <option value="top">Top</option>
                                <option value="bottom">Bottom</option>
                              </Form.Select>
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                <Row className="mb-3">
                  {state.updatetype !== "ES" && (
                    <>
                      <Col lg="6" md="6" sm="6" xs="6" className="">
                        <label>Frame Color</label>
                      </Col>
                    </>
                  )}
                  {state.updatetype !== "EP" &&
                    state.updatetype !== "ES" &&
                    state.updatetype !== "1.25 lip" &&
                    state.updateframedoortype !== "No Door" && (
                      <>
                        <Col lg="6" md="6" sm="6" xs="6" className="">
                          <label className="">J Square Color</label>
                        </Col>
                      </>
                    )}
                </Row>
                <Row>
                  {state.updatetype !== "ES" && (
                    <>
                      {state.UpFrameclrSts == "0" && (
                        <>
                          <Col
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mblviewgap"
                          >
                            <Form.Select
                              aria-label="Default select example"
                              value={state.updateframetypecolor}
                              onChange={updateframetypecolorchange}
                            >
                              {state.colors.map((color) => (
                                <option key={color.id} value={color.colorname}>
                                  {color.colorname}
                                </option>
                              ))}
                            </Form.Select>
                          </Col>
                        </>
                      )}
                      {state.UpFrameclrSts == "1" && (
                        <>
                          <Col
                            lg="6"
                            md="6"
                            sm="6"
                            xs="6"
                            className="mblviewgap"
                          >
                            <input
                              type="text"
                              className="form-control colorinput"
                              placeholder="Enter Color"
                              minLength={5}
                              maxLength={50}
                              value={state.UpdateFrameEntercolor}
                              onChange={UpdateFrameEnterColor}
                            />
                          </Col>
                        </>
                      )}
                    </>
                  )}
                  {state.updatetype !== "EP" &&
                    state.updatetype !== "ES" &&
                    state.updatetype !== "1.25 lip" && (
                      <>
                        {(state.updateframedoortype === "Glass Door" ||
                          state.updateframedoortype === "Sheet Door") && (
                          <>
                            {state.UpFrameJsclrSts == "0" && (
                              <>
                                <Col lg="6" md="6" sm="6" xs="6">
                                  <Form.Select
                                    aria-label="Default select example"
                                    value={state.updateframedoorcolor}
                                    onChange={updateframedoorcolorchange}
                                  >
                                    {state.colors.map((color) => (
                                      <option
                                        key={color.id}
                                        value={color.colorname}
                                      >
                                        {color.colorname}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </Col>
                              </>
                            )}
                            {state.UpFrameJsclrSts == "1" && (
                              <>
                                <Col
                                  lg="6"
                                  md="6"
                                  sm="6"
                                  xs="6"
                                  className="mblviewgap"
                                >
                                  <input
                                    type="text"
                                    className="form-control colorinput"
                                    placeholder="Enter Color"
                                    minLength={5}
                                    maxLength={50}
                                    value={state.UpdateFrameJsEntercolor}
                                    onChange={UpdateFrameJsEnter}
                                  />
                                </Col>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                </Row>

                {state.updatetype !== "EP" &&
                  state.updatetype !== "ES" &&
                  state.updatetype !== "1.25 lip" && (
                    <>
                      {(state.updateframedoortype === "Glass Door" ||
                        state.updateframedoortype === "Sheet Door") && (
                        <>
                          <Row className="mb-3">
                            <Col lg="4" md="4" sm="4" xs="4" className="">
                              <label>G</label>
                            </Col>
                            <Col lg="4" md="4" sm="4" xs="4" className="">
                              <label>IntDummy</label>
                            </Col>
                            <Col lg="4" md="4" sm="4" xs="4" className=" ">
                              <label>Fixed</label>
                            </Col>
                          </Row>
                          <Row className="mb-3 colorrow">
                            <Col lg="4" md="4" sm="4" xs="4" className=" ">
                              <input
                                type="checkbox"
                                className="form-check-input "
                                id="exampleCheck1"
                                onChange={updateframegvaluechange}
                                checked={state.updateframegvalue}
                              />
                            </Col>
                            <Col lg="4" md="4" sm="4" xs="4" className="">
                              <input
                                type="checkbox"
                                className="form-check-input "
                                id="exampleCheck1"
                                onChange={updateframeintdummyvaluechange}
                                checked={state.updateframeintdummyvalue}
                              />
                            </Col>
                            <Col lg="4" md="4" sm="4" xs="4" className="">
                              <input
                                type="checkbox"
                                className="form-check-input "
                                id="exampleCheck1"
                                checked={state.updateframefixed}
                                onChange={updateframefixedchange}
                              />
                            </Col>

                            {state.updateframeg && (
                              <>
                                <Col
                                  lg="6"
                                  md="6"
                                  sm="6"
                                  xs="6"
                                  className="mblviewgap"
                                >
                                  <label>G</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                    name="frameg"
                                    onChange={updateframegchange}
                                    value={state.updateframeg}
                                    minLength={5}
                                    maxLength={50}
                                    required
                                  />
                                </Col>
                              </>
                            )}
                            {state.updateframeg && (
                              <>
                                <Col
                                  lg="6"
                                  md="6"
                                  sm="6"
                                  xs="6"
                                  className="mblviewgap"
                                >
                                  <label className="whitetxt">type</label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    onChange={updateframegvaluepositionchange}
                                    value={state.updateframegposition}
                                  >
                                    <option value="top">Top</option>
                                    <option value="bottom">Bottom</option>
                                  </Form.Select>
                                </Col>
                              </>
                            )}
                            {state.updateframeintdummy && (
                              <>
                                <Col
                                  lg="6"
                                  md="6"
                                  sm="6"
                                  xs="6"
                                  className="mblviewgap"
                                >
                                  <label> Intdummy</label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder=""
                                    name="frameID"
                                    onChange={updateframeintdummychange}
                                    value={state.updateframeintdummy}
                                    minLength={5}
                                    maxLength={50}
                                    required
                                  />
                                </Col>
                              </>
                            )}
                            {state.updateframeintdummy && (
                              <>
                                <Col
                                  lg="6"
                                  md="6"
                                  sm="6"
                                  xs="6"
                                  className="mblviewgap"
                                >
                                  <label className="whitetxt">type</label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    onChange={updateframeintdummypositionchange}
                                    value={state.updateframeintdummyposition}
                                  >
                                    <option value="left">left</option>
                                    <option value="right">right</option>
                                  </Form.Select>
                                </Col>
                              </>
                            )}
                            {state.updateframefixed && (
                              <>
                                <Col
                                  lg="4"
                                  md="4"
                                  sm="4"
                                  xs="4"
                                  className="mblviewgap"
                                >
                                  <label> Fixed</label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    onChange={updateframefixedpositionchange}
                                    value={state.updateframefixedposition}
                                  >
                                    <option value="left">left</option>
                                    <option value="right">right</option>
                                  </Form.Select>
                                </Col>
                              </>
                            )}
                            {(state.updateframefixedposition === "left" ||
                              state.updateframefixedposition === "right") && (
                              <>
                                <Col
                                  lg="4"
                                  md="4"
                                  sm="4"
                                  xs="4"
                                  className="mblviewgap"
                                >
                                  <label className=" whitetxt">type</label>
                                  <input
                                    className="form-control"
                                    placeholder=""
                                    required
                                    name="boxID"
                                    minLength={5}
                                    maxLength={50}
                                    type="number"
                                    value={
                                      parseInt(state.updateframeheight) - 51
                                    }
                                    onChange={updateFrameFixedheightonchange}
                                    readOnly
                                  />
                                </Col>
                                <Col
                                  lg="4"
                                  md="4"
                                  sm="4"
                                  xs="4"
                                  className="mblviewgap"
                                >
                                  <label className="whitetxt">type</label>
                                  <input
                                    className="form-control"
                                    placeholder="Width"
                                    required
                                    name="boxID"
                                    minLength={5}
                                    maxLength={50}
                                    type="number"
                                    value={state.updateFrameFixedWidth}
                                    onChange={UpdateFrameFixedwidthonchange}
                                  />
                                </Col>
                              </>
                            )}
                          </Row>
                        </>
                      )}
                    </>
                  )}
                <Row className="mb-3">
                  <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                    {(state.updatetype === "1.25 lip" ||
                      state.updatetype === "EP" ||
                      state.updatetype === "ES" ||
                      state.updateframedoorselect === "Single" ||
                      state.updateframedoortype === "No Door") && (
                      <>
                        <label className="label">Remark</label>
                      </>
                    )}
                    {state.updateframedoorselect === "Double" && (
                      <>
                        <label className="label">Left Remark</label>
                      </>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Remark"
                      minLength={5}
                      maxLength={50}
                      value={state.UpdateFrameRemarks}
                      onChange={UpdateFrameRemarksonChange}
                    />
                  </Col>
                  {state.updateframedoorselect === "Double" && (
                    <>
                      <Col lg="6" md="6" sm="6" xs="6" className="mblviewgap">
                        {state.updateframedoorselect === "Double" && (
                          <>
                            <label className="label">Right Remark</label>
                          </>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Remark"
                          minLength={5}
                          maxLength={50}
                          value={state.UpdateRightFrameRemarks}
                          onChange={UpdateFrameRightRemark}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <Row>
                  <Col lg="12" md="12" className="flexbtn mt-3">
                    <Button
                      variant="outline-primary subbutton"
                      onClick={updateframehandelclick}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} className="icon" />{" "}
                      Update Frame{" "}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Modal.Body>
          </Modal>
          {/* //categorymodal// */}
          <Modal show={categorymodal} onHide={handleCategoryClose}>
            <Modal.Header closeButton>
              <Modal.Title>{selectedCategory}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="loginform">
                <Row className="mb-3">
                  <Col lg="3" md="3" sm="3" xs="3" className="boxcardcol">
                    <label className="label"> Category</label>
                  </Col>
                  {TypeselectedCategory == "" && (
                    <>
                      <Col
                        lg="5"
                        md="5"
                        sm="5"
                        xs="5"
                        className="flexbtn mblviewgap"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          value={EditselectedCategory}
                          onChange={EditCategorychange}
                        >
                          <option value="">Select Categery</option>
                          {state.categery.map((categery) => (
                            <option key={categery.id} value={categery.categery}>
                              {categery.categery}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </>
                  )}

                  {EditselectedCategory == "" && (
                    <>
                      <Col
                        lg="4"
                        md="4"
                        sm="4"
                        xs="4"
                        className="flexbtn mblviewgap"
                      >
                        <input
                          type="text"
                          className="form-control colorinput"
                          placeholder="Enter categery"
                          minLength={5}
                          maxLength={50}
                          value={TypeselectedCategory}
                          onChange={EditTypeCategorychange}
                        />
                      </Col>
                    </>
                  )}
                </Row>

                <div className="d-grid">
                  <button
                    className="btn btn-primary modalsubbutton"
                    onClick={() => updateCategory()}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          {/* //boxframepdf section// */}
          <div>
            <div>
              <div className="pdf-page">
                <div
                  className=""
                  style={{ paddingLeft: "2%", paddingRight: "2%" }}
                >
                  <Row className="print-row">
                    <div>
                      {Object.keys(updatedCategoryImages).map(
                        (category, index) => (
                          <React.Fragment key={index}>
                            <Row>
                              <Col lg="9" md="9">
                                <h2>{category}</h2>
                              </Col>
                            </Row>
                            <div className="category-images">
                              <Row className="print-row">
                                {updatedCategoryImages[
                                  category
                                ].diagramimages.map(
                                  (details: any, idx: number, arr: any[]) => (
                                    <>
                                      <Col md={4} key={idx}>
                                        <div
                                          key={idx}
                                          className="category-image"
                                        >
                                          {/* boximage// */}
                                          {details.id && (
                                            <>
                                              <p className="svgletter svgtext">
                                                டு
                                              </p>
                                              <div className="square-box">
                                                <div
                                                  style={{
                                                    transform: "rotate(272deg)",
                                                  }}
                                                >
                                                  <span>
                                                    {details.boxheightcat}
                                                  </span>
                                                </div>
                                                {details.AsSymbol && (
                                                  <>
                                                    <div
                                                      style={{
                                                        marginTop: `${
                                                          details.updatedboxheightcat +
                                                          12
                                                        }px`,
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: "20px",
                                                          fontWeight: "700",
                                                        }}
                                                      >
                                                        #
                                                      </span>
                                                    </div>
                                                  </>
                                                )}
                                                <svg
                                                  className="shape rectangle"
                                                  width={`${
                                                    details.updatedboxwidthcat +
                                                    35
                                                  }px`}
                                                  height={`${
                                                    details.updatedboxheightcat +
                                                    35
                                                  }px`}
                                                >
                                                  {/* box svg square line border & Pa Box// */}
                                                  {details.BoxType ==
                                                  "Pa Box" ? (
                                                    <>
                                                      <svg
                                                        className="shape rectangle"
                                                        width={`${
                                                          details.updatedboxwidthcat +
                                                          35
                                                        }px`}
                                                        height={`${
                                                          details.updatedboxheightcat +
                                                          7
                                                        }px`}
                                                      >
                                                        <rect
                                                          width={`${details.updatedboxwidthcat}px`}
                                                          height={`${details.updatedboxheightcat}px`}
                                                          fill="#ffffff"
                                                          stroke="black"
                                                          x="10"
                                                          y="10"
                                                        />
                                                      </svg>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <rect
                                                        width={`${details.updatedboxwidthcat}px`}
                                                        height={`${details.updatedboxheightcat}px`}
                                                        fill="#ffffff"
                                                        stroke="black"
                                                        x="10"
                                                        y="10"
                                                      />
                                                    </>
                                                  )}

                                                  {/* //Fixed Posistion left right // */}
                                                  {details.boxfixed == "1" ? (
                                                    <>
                                                      {details.boxfixed_position ==
                                                        "left" && (
                                                        <>
                                                          <rect
                                                            width={`${
                                                              details.updatedboxheightcat /
                                                                2 +
                                                              5
                                                            }px`}
                                                            height={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              90
                                                            }px`}
                                                            fill="#ffffff"
                                                            stroke="black"
                                                            x={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                                7 +
                                                              15
                                                            }`}
                                                            y={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              10
                                                            }`}
                                                            style={{
                                                              border:
                                                                "3px solid #000000",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                      {details.boxfixed_position ==
                                                        "right" && (
                                                        <>
                                                          <rect
                                                            width={`${
                                                              details.updatedboxheightcat /
                                                                2 +
                                                              5
                                                            }px`}
                                                            height={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              90
                                                            }px`}
                                                            fill="#ffffff"
                                                            stroke="black"
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2.5
                                                            }`}
                                                            y={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              10
                                                            }`}
                                                            style={{
                                                              border:
                                                                "3px solid #000000",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <svg>
                                                    {/* box top x border  */}
                                                    <rect
                                                      fill="#ffffff"
                                                      stroke="black"
                                                      strokeWidth="2"
                                                      x={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                        9
                                                      }`}
                                                      y="1"
                                                      width="20"
                                                      height="20"
                                                    />
                                                    <text
                                                      x={`${
                                                        (details.updatedboxwidthcat /
                                                          100) *
                                                          9 +
                                                        4
                                                      }`}
                                                      y="17"
                                                      fill="black"
                                                      font-size="25"
                                                    >
                                                      x
                                                    </text>
                                                  </svg>
                                                  {/* //open box circle // */}
                                                  {details.BoxType ==
                                                    "Open Box" && (
                                                    <>
                                                      <circle
                                                        r="8"
                                                        cx={`${
                                                          (details.updatedboxwidthcat /
                                                            100) *
                                                          102
                                                        }`}
                                                        cy={`${10}`}
                                                        stroke="black"
                                                        stroke-width="2"
                                                        fill="white"
                                                      />
                                                    </>
                                                  )}
                                                  {details.BoxType ==
                                                    "Pa Box" && (
                                                    <>
                                                      <svg
                                                        width="30px"
                                                        height="20px"
                                                        x={`${
                                                          details.updatedboxwidthcat -
                                                          20
                                                        }`}
                                                      >
                                                        <rect
                                                          fill="#ffffff"
                                                          stroke="black"
                                                          strokeWidth="2"
                                                          y="1"
                                                          width="20"
                                                          height="20"
                                                        />
                                                      </svg>
                                                    </>
                                                  )}

                                                  {/* //boxdepth // */}
                                                  {details.boxheightcat &&
                                                    state.staticboxdepth && (
                                                      <>
                                                        <line
                                                          x1="10"
                                                          y1={
                                                            Number(
                                                              details.updatedboxheightcat +
                                                                10
                                                            ) -
                                                            Number(
                                                              state.staticboxdepth
                                                            )
                                                          }
                                                          x2="35"
                                                          y2={
                                                            Number(
                                                              details.updatedboxheightcat
                                                            ) -
                                                            Number(
                                                              state.staticboxdepth
                                                            ) -
                                                            25
                                                          }
                                                          stroke="#000"
                                                          strokeWidth="4"
                                                        />
                                                        <text
                                                          x="35"
                                                          y={
                                                            Number(
                                                              details.updatedboxheightcat
                                                            ) -
                                                            Number(
                                                              state.staticboxdepth
                                                            ) -
                                                            30
                                                          }
                                                          fill="#000000"
                                                        >
                                                          {details.boxdepthcat}
                                                        </text>
                                                        {/* //shelfline// */}
                                                        {Array.from(
                                                          {
                                                            length:
                                                              details.boxshelf_countcat,
                                                          },
                                                          (_, i) => {
                                                            const lineCount =
                                                              Number(
                                                                details.boxshelf_countcat
                                                              ) + 1;
                                                            const lineGap =
                                                              Number(
                                                                details.updatedboxheightcat
                                                              ) / lineCount;
                                                            const y =
                                                              lineGap * (i + 1);
                                                            return (
                                                              <line
                                                                key={i}
                                                                x1="10"
                                                                y1={y}
                                                                x2={
                                                                  details.updatedboxwidthcat +
                                                                  10
                                                                }
                                                                y2={y}
                                                                stroke="#000"
                                                                strokeWidth="1"
                                                                strokeDasharray="3,3"
                                                              />
                                                            );
                                                          }
                                                        )}
                                                      </>
                                                    )}

                                                  {/* //facia internaldrawer 2d single // */}
                                                  {details.boxdoor_typecat ==
                                                    "Facia" &&
                                                    details.Drawerselect ==
                                                      "3" &&
                                                    details.boxdoor_selectcat ==
                                                      "Single Drawer" && (
                                                      <>
                                                        <line
                                                          x1="10"
                                                          y1="90"
                                                          x2={
                                                            details.updatedboxwidthcat +
                                                            10
                                                          }
                                                          y2="90"
                                                          stroke="#000"
                                                          strokeWidth="1"
                                                        />
                                                        <text
                                                          x={`${
                                                            details.updatedboxwidthcat /
                                                              2 -
                                                            10
                                                          }`}
                                                          y={
                                                            details.updatedboxheightcat /
                                                              4 -
                                                            5
                                                          }
                                                          fill="black"
                                                          font-size="60"
                                                        >
                                                          -
                                                        </text>
                                                      </>
                                                    )}
                                                  {/* //facia internaldrawer 2d double // */}
                                                  {details.boxdoor_typecat ==
                                                    "Facia" &&
                                                    details.Drawerselect ==
                                                      "3" &&
                                                    details.boxdoor_selectcat ==
                                                      "Double Drawer" && (
                                                      <>
                                                        <line
                                                          x1="10"
                                                          y1="90"
                                                          x2={
                                                            details.updatedboxwidthcat +
                                                            10
                                                          }
                                                          y2="90"
                                                          stroke="#000"
                                                          strokeWidth="1"
                                                        />
                                                        <line
                                                          x1={
                                                            details.updatedboxwidthcat /
                                                              2 +
                                                            5
                                                          }
                                                          y1="10"
                                                          x2={
                                                            details.updatedboxwidthcat /
                                                              2 +
                                                            5
                                                          }
                                                          y2="90"
                                                          stroke="#000"
                                                          strokeWidth="1"
                                                        />
                                                        <text
                                                          x={`${
                                                            details.updatedboxwidthcat /
                                                              3.5 -
                                                            10
                                                          }`}
                                                          y={
                                                            details.updatedboxheightcat /
                                                              4 -
                                                            5
                                                          }
                                                          fill="black"
                                                          font-size="60"
                                                        >
                                                          -
                                                        </text>
                                                        <text
                                                          x={`${
                                                            details.updatedboxwidthcat /
                                                              1.3 -
                                                            10
                                                          }`}
                                                          y={
                                                            details.updatedboxheightcat /
                                                              4 -
                                                            5
                                                          }
                                                          fill="black"
                                                          font-size="60"
                                                        >
                                                          -
                                                        </text>
                                                      </>
                                                    )}
                                                  {/* //jsquare box center height width // */}
                                                  {details.jsquareboxheight !=
                                                    "" &&
                                                  details.jsquareboxwidth !=
                                                    "" ? (
                                                    <>
                                                      {details.boxdoor_typecat ==
                                                        "Facia" &&
                                                      details.boxdoor_selectcat ==
                                                        "3 drawer" ? (
                                                        <>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            y={`${
                                                              details.updatedboxheightcat /
                                                                2 +
                                                              30
                                                            }px`}
                                                            textAnchor="middle"
                                                            fill="#000000"
                                                            dominantBaseline="middle"
                                                            fontSize="16px"
                                                          >
                                                            {details.boxdoor_typecat ===
                                                              "Glass Door" &&
                                                            details.boxdoor_selectcat ===
                                                              "Double" ? (
                                                              <>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    3
                                                                  }px`}
                                                                  dy="-0.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {details.updatedboxheightcat +
                                                                    2}
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    3
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {details.updatedboxwidthcat +
                                                                    3}
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 -
                                                                    18
                                                                  }px`}
                                                                  y={`${
                                                                    details.updatedboxheightcat /
                                                                      3 +
                                                                    40
                                                                  }px`}
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.jsquareboxheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 -
                                                                    18
                                                                  }px`}
                                                                  y={`${
                                                                    details.updatedboxheightcat /
                                                                      3 +
                                                                    55
                                                                  }px`}
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 +
                                                                    5
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            )}
                                                          </text>
                                                        </>
                                                      ) : (
                                                        //facia 2 drawer glass door if //
                                                        <>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                              2
                                                            }px`}
                                                            y={`${
                                                              details.updatedboxheightcat /
                                                                2 +
                                                              30
                                                            }px`}
                                                            textAnchor="middle"
                                                            fill="#000000"
                                                            dominantBaseline="middle"
                                                            fontSize="16px"
                                                          >
                                                            {details.boxdoor_typecat ===
                                                              "Glass Door" &&
                                                            details.boxdoor_selectcat ===
                                                              "Double" ? (
                                                              <>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2
                                                                  }px`}
                                                                  dy="-0.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {parseInt(
                                                                    details.jsquareboxheight
                                                                  ) + 2}
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {parseInt(
                                                                    details.jsquareboxwidth
                                                                  ) +
                                                                    3 +
                                                                    "G"}
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      2 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            ) : details.boxfixed ==
                                                                "1" &&
                                                              details.boxfixed_position ==
                                                                "left" &&
                                                              details.boxdoor_selectcat ===
                                                                "Single" ? (
                                                              <>
                                                                {/* //fixed left fixed value // */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2.7
                                                                  }px`}
                                                                  dy="-4.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.boxfixedheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2.7
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.boxfixedwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      2.7 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  - 1
                                                                </tspan>
                                                                {/* //fixed jsquare value // */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    1.2
                                                                  }px`}
                                                                  dy="-0.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.jsquareboxheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    1.2
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      1.2 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            ) : details.boxfixed ==
                                                                "1" &&
                                                              details.boxfixed_position ==
                                                                "right" &&
                                                              details.boxdoor_selectcat ===
                                                                "Single" ? (
                                                              <>
                                                                {/* //fixed right fixed value single door // */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    1.8
                                                                  }px`}
                                                                  dy="-4.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.boxfixedheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    1.8
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.boxfixedwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      1.8 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  - 1
                                                                </tspan>
                                                                {/* //fixed jsquare value // */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    4.2
                                                                  }px`}
                                                                  dy="-0.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.jsquareboxheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    4.2
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      4.2 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            ) : details.boxdoor_selectcat ===
                                                              "Single" ? (
                                                              <>
                                                                {/* // glass door else // */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2
                                                                  }px`}
                                                                  dy="-0.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {parseInt(
                                                                    details.jsquareboxheight
                                                                  )}
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {parseInt(
                                                                    details.jsquareboxwidth
                                                                  ) + "G"}
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      2 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            ) : details.boxdoor_typecat ==
                                                                "Facia" &&
                                                              details.boxdoor_selectcat ==
                                                                "2 drawer 2:1" ? (
                                                              <>
                                                                {/* //facia 2 drawer 2:1 1st value// */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2
                                                                  }px`}
                                                                  dy="-0.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.jsquareboxheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      2 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                                {/* //facia 2 drawer 2:1 2st value// */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2
                                                                  }px`}
                                                                  dy="-0.5em"
                                                                  y={`${
                                                                    details.updatedboxheightcat /
                                                                      3 +
                                                                    22
                                                                  }px`}
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {details.jsquareboxheight /
                                                                    2}
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2
                                                                  }px`}
                                                                  dy="1.1em"
                                                                  y={`${
                                                                    details.updatedboxheightcat /
                                                                      3 +
                                                                    15
                                                                  }px`}
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      2 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                  y={`${
                                                                    details.updatedboxheightcat /
                                                                      3 +
                                                                    30
                                                                  }px`}
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            ) : details.boxdoor_typecat ==
                                                                "Facia" &&
                                                              details.boxdoor_selectcat ==
                                                                "3 drawer 2:1" ? (
                                                              <>
                                                                {/* //facia 3 drawer 2:1 1st value// */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 -
                                                                    18
                                                                  }px`}
                                                                  dy="2.2em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.jsquareboxheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 -
                                                                    18
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 +
                                                                    10
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>

                                                                {/* //facia 3 drawer 2:1 2st value// */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 -
                                                                    18
                                                                  }px`}
                                                                  y={`${
                                                                    details.updatedboxheightcat /
                                                                      3 +
                                                                    40
                                                                  }px`}
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {details.jsquareboxheight /
                                                                    2}
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 -
                                                                    18
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 +
                                                                    10
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                                {/* //facia 3 drawer 2:1 3st value// */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 -
                                                                    18
                                                                  }px`}
                                                                  y={`${
                                                                    details.updatedboxheightcat /
                                                                      5 -
                                                                    10
                                                                  }px`}
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {details.jsquareboxheight /
                                                                    2}
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 -
                                                                    18
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      3 +
                                                                    10
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            ) : details.boxfixed ==
                                                                "1" &&
                                                              details.boxfixed_position ==
                                                                "left" ? (
                                                              <>
                                                                {/* //fixed left fixed value // */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2.4
                                                                  }px`}
                                                                  dy="-5.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.boxfixedheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2.4
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.boxfixedwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      2.4 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  - 1
                                                                </tspan>
                                                                {/* //fixed jsquare value // */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    1.2
                                                                  }px`}
                                                                  dy="-0.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.jsquareboxheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    1.2
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      1.2 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            ) : details.boxfixed ==
                                                                "1" &&
                                                              details.boxfixed_position ==
                                                                "right" ? (
                                                              <>
                                                                {/* //fixed right fixed value 2door// */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    1.6
                                                                  }px`}
                                                                  dy="-5.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.boxfixedheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    1.6
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.boxfixedwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      1.6 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  - 1
                                                                </tspan>
                                                                {/* //fixed jsquare value // */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    4.2
                                                                  }px`}
                                                                  dy="-0.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.jsquareboxheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    4.2
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      4.2 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {/* // glass door else // */}
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2
                                                                  }px`}
                                                                  dy="-0.5em"
                                                                  style={{
                                                                    textDecoration:
                                                                      "underline",
                                                                  }}
                                                                >
                                                                  {
                                                                    details.jsquareboxheight
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                    2
                                                                  }px`}
                                                                  dy="1.1em"
                                                                >
                                                                  {
                                                                    details.jsquareboxwidth
                                                                  }
                                                                </tspan>
                                                                <tspan
                                                                  x={`${
                                                                    details.updatedboxwidthcat /
                                                                      2 +
                                                                    30
                                                                  }px`}
                                                                  dy="-0.7em"
                                                                >
                                                                  -{" "}
                                                                  {
                                                                    details.jsquareboxquantity
                                                                  }
                                                                </tspan>
                                                              </>
                                                            )}
                                                          </text>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* //single door top left right bottom // */}
                                                  {details.boxdoor_selectcat ==
                                                  "Single" ? (
                                                    <>
                                                      {details.singledoorposition ==
                                                        "top" && (
                                                        <>
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                                25 +
                                                              10
                                                            }`}
                                                            cy={`${10}`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                              80
                                                            }`}
                                                            cy={`${10}`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                        </>
                                                      )}
                                                      {details.singledoorposition ==
                                                        "bottom" && (
                                                        <>
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                                25 +
                                                              10
                                                            }`}
                                                            cy={`${
                                                              details.updatedboxheightcat +
                                                              10
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                              80
                                                            }`}
                                                            cy={`${
                                                              details.updatedboxheightcat +
                                                              10
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                        </>
                                                      )}
                                                      {details.singledoorposition ==
                                                        "right" && (
                                                        <>
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              details.updatedboxwidthcat +
                                                              10
                                                            }`}
                                                            cy={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              25
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              details.updatedboxwidthcat +
                                                              10
                                                            }`}
                                                            cy={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              80
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                        </>
                                                      )}
                                                      {details.singledoorposition ==
                                                        "left" && (
                                                        <>
                                                          <circle
                                                            r="8"
                                                            cx={`${10}`}
                                                            cy={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              25
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                          <circle
                                                            r="8"
                                                            cx={`${10}`}
                                                            cy={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              80
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* fixed position left right// */}
                                                  {details.boxfixed == "1" &&
                                                    details.boxfixed_position ==
                                                      "left" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedboxwidthcat /
                                                              100) *
                                                              25 -
                                                            10
                                                          }`}
                                                          y={`${
                                                            details.updatedboxheightcat -
                                                            65
                                                          }`}
                                                          fill="black"
                                                          font-size="16"
                                                        >
                                                          Fixed
                                                        </text>
                                                      </>
                                                    )}
                                                  {details.boxfixed == "1" &&
                                                    details.boxfixed_position ==
                                                      "right" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedboxwidthcat /
                                                              100) *
                                                              80 -
                                                            5
                                                          }`}
                                                          y={`${
                                                            details.updatedboxheightcat -
                                                            65
                                                          }`}
                                                          fill="black"
                                                          font-size="16"
                                                        >
                                                          Fixed
                                                        </text>
                                                      </>
                                                    )}
                                                  {details.boxdoor_typecat ==
                                                    "Glass Door" &&
                                                    details.boxdoor_selectcat ==
                                                      "Single" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedboxwidthcat /
                                                              100) *
                                                              25 +
                                                            25
                                                          }`}
                                                          y={`${
                                                            details.updatedboxheightcat -
                                                            15
                                                          }`}
                                                          fill="black"
                                                          font-size="16"
                                                        >
                                                          GDR
                                                        </text>
                                                      </>
                                                    )}
                                                  {details.boxdoor_typecat ==
                                                    "Glass Door" &&
                                                    details.boxdoor_selectcat ==
                                                      "Double" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedboxwidthcat /
                                                              100) *
                                                              25 +
                                                            25
                                                          }`}
                                                          y={`${
                                                            details.updatedboxheightcat -
                                                            15
                                                          }`}
                                                          fill="black"
                                                          font-size="16"
                                                        >
                                                          GDR
                                                        </text>
                                                        <text
                                                          x={`${
                                                            (details.updatedboxwidthcat /
                                                              100) *
                                                              80 -
                                                            35
                                                          }`}
                                                          y={`${
                                                            details.updatedboxheightcat -
                                                            15
                                                          }`}
                                                          fill="black"
                                                          font-size="16"
                                                        >
                                                          GDR
                                                        </text>
                                                      </>
                                                    )}
                                                  {/* //g poisition top left // */}
                                                  {details.boxg_valuecat ==
                                                  "1" ? (
                                                    <>
                                                      {details.boxgposition ==
                                                        "top" && (
                                                        <>
                                                          <text
                                                            x={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                                25 +
                                                              25
                                                            }`}
                                                            y="60"
                                                            fill="black"
                                                            font-size="18"
                                                          >
                                                            G
                                                          </text>
                                                          {details.boxdoor_selectcat ==
                                                            "Double" && (
                                                            <>
                                                              <text
                                                                x={`${
                                                                  (details.updatedboxwidthcat /
                                                                    100) *
                                                                    80 -
                                                                  35
                                                                }`}
                                                                y="60"
                                                                fill="black"
                                                                font-size="18"
                                                              >
                                                                G
                                                              </text>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                      {details.boxgposition ==
                                                        "bottom" && (
                                                        <>
                                                          <text
                                                            x={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                                25 +
                                                              25
                                                            }`}
                                                            y={`${
                                                              details.updatedboxheightcat -
                                                              45
                                                            }`}
                                                            fill="black"
                                                            font-size="18"
                                                          >
                                                            G
                                                          </text>
                                                          {details.boxdoor_selectcat ==
                                                            "Double" && (
                                                            <>
                                                              <text
                                                                x={`${
                                                                  (details.updatedboxwidthcat /
                                                                    100) *
                                                                    80 -
                                                                  35
                                                                }`}
                                                                y={`${
                                                                  details.updatedboxheightcat -
                                                                  45
                                                                }`}
                                                                fill="black"
                                                                font-size="18"
                                                              >
                                                                G
                                                              </text>
                                                            </>
                                                          )}
                                                          {details.boxdoor_typecat ==
                                                            "Glass Door" &&
                                                            details.boxdoor_selectcat ==
                                                              "Single" && (
                                                              <>
                                                                <text
                                                                  x={`${
                                                                    (details.updatedboxwidthcat /
                                                                      100) *
                                                                      25 +
                                                                    25
                                                                  }`}
                                                                  y={`${
                                                                    details.updatedboxheightcat -
                                                                    15
                                                                  }`}
                                                                  fill="black"
                                                                  font-size="16"
                                                                >
                                                                  GDR
                                                                </text>
                                                              </>
                                                            )}
                                                          {details.boxdoor_typecat ==
                                                            "Glass Door" &&
                                                            details.boxdoor_selectcat ==
                                                              "Double" && (
                                                              <>
                                                                <text
                                                                  x={`${
                                                                    (details.updatedboxwidthcat /
                                                                      100) *
                                                                      25 +
                                                                    25
                                                                  }`}
                                                                  y={`${
                                                                    details.updatedboxheightcat -
                                                                    15
                                                                  }`}
                                                                  fill="black"
                                                                  font-size="16"
                                                                >
                                                                  GDR
                                                                </text>
                                                                <text
                                                                  x={`${
                                                                    (details.updatedboxwidthcat /
                                                                      100) *
                                                                      80 -
                                                                    35
                                                                  }`}
                                                                  y={`${
                                                                    details.updatedboxheightcat -
                                                                    15
                                                                  }`}
                                                                  fill="black"
                                                                  font-size="16"
                                                                >
                                                                  GDR
                                                                </text>
                                                              </>
                                                            )}
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* //remarks in single // */}
                                                  {(details.boxdoor_typecat &&
                                                    details.boxdoor_selectcat ==
                                                      "Single") ||
                                                    (details.boxdoor_selectcat ==
                                                      "" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            details.updatedboxwidthcat /
                                                              2 -
                                                            20
                                                          }px`}
                                                          y={`${
                                                            details.updatedboxheightcat -
                                                            28
                                                          }px`}
                                                          fill="black"
                                                          font-size="14"
                                                        >
                                                          {details.BoxRemark}
                                                        </text>
                                                      </>
                                                    ))}
                                                  {details.boxdoor_typecat &&
                                                    details.boxdoor_selectcat ==
                                                      "Single" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            details.updatedboxwidthcat /
                                                              2 -
                                                            20
                                                          }px`}
                                                          y={`${
                                                            details.updatedboxheightcat -
                                                            28
                                                          }px`}
                                                          fill="black"
                                                          font-size="14"
                                                        >
                                                          {details.BoxRemark}
                                                        </text>
                                                      </>
                                                    )}
                                                  {/* //remarks in double // */}
                                                  {details.boxdoor_typecat &&
                                                    details.boxdoor_selectcat ==
                                                      "Double" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedboxwidthcat /
                                                              100) *
                                                              25 -
                                                            10
                                                          }`}
                                                          y={`${
                                                            details.updatedboxheightcat -
                                                            28
                                                          }`}
                                                          fill="black"
                                                          font-size="14"
                                                        >
                                                          {details.BoxRemark}
                                                        </text>
                                                        <text
                                                          x={`${
                                                            (details.updatedboxwidthcat /
                                                              100) *
                                                              80 -
                                                            35
                                                          }`}
                                                          y={`${
                                                            details.updatedboxheightcat -
                                                            28
                                                          }`}
                                                          fill="black"
                                                          font-size="14"
                                                        >
                                                          {
                                                            details.BoxRightremark
                                                          }
                                                        </text>
                                                      </>
                                                    )}
                                                  {/* //remarks in facia // */}
                                                  {details.boxdoor_typecat ==
                                                  "Facia" ? (
                                                    <>
                                                      {details.boxdoor_selectcat ==
                                                        "2 drawer" && (
                                                        <>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              25
                                                            }`}
                                                            y={
                                                              details.updatedboxheightcat /
                                                                3 -
                                                              5
                                                            }
                                                            fill="black"
                                                            font-size="14"
                                                          >
                                                            {details.BoxRemark}
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              25
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                3.5) *
                                                                3 -
                                                              8
                                                            }
                                                            fill="black"
                                                            font-size="14"
                                                          >
                                                            {
                                                              details.BoxRightremark
                                                            }
                                                          </text>
                                                        </>
                                                      )}
                                                      {details.boxdoor_selectcat ==
                                                        "3 drawer" && (
                                                        <>
                                                          {/* //double line facia 3 drawer //     */}
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              25
                                                            }`}
                                                            y={
                                                              details.updatedboxheightcat /
                                                                4 -
                                                              5
                                                            }
                                                            fill="black"
                                                            font-size="14"
                                                          >
                                                            {details.BoxRemark}
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              25
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                5.3) *
                                                                3 -
                                                              5
                                                            }
                                                            fill="black"
                                                            font-size="14"
                                                          >
                                                            {
                                                              details.BoxRightremark
                                                            }
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              25
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                3.3) *
                                                                3 -
                                                              5
                                                            }
                                                            fill="black"
                                                            font-size="14"
                                                          >
                                                            {
                                                              details.BoxThirdremark
                                                            }
                                                          </text>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* //remarks in facia 1:1 2:1// */}
                                                  {details.boxdoor_typecat ==
                                                  "Facia" ? (
                                                    <>
                                                      {details.boxdoor_selectcat ==
                                                        "2 drawer 2:1" && (
                                                        <>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              25
                                                            }`}
                                                            y={
                                                              details.updatedboxheightcat /
                                                                3 -
                                                              5
                                                            }
                                                            fill="black"
                                                            font-size="14"
                                                          >
                                                            {details.BoxRemark}
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              25
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                3.5) *
                                                                3 -
                                                              8
                                                            }
                                                            fill="black"
                                                            font-size="14"
                                                          >
                                                            {
                                                              details.BoxRightremark
                                                            }
                                                          </text>
                                                        </>
                                                      )}
                                                      {details.boxdoor_selectcat ==
                                                        "3 drawer 2:1" && (
                                                        <>
                                                          {/* //double line facia 3 drawer //     */}
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              25
                                                            }`}
                                                            y={
                                                              details.updatedboxheightcat /
                                                                4 -
                                                              5
                                                            }
                                                            fill="black"
                                                            font-size="14"
                                                          >
                                                            {details.BoxRemark}
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              25
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                5.3) *
                                                                3 -
                                                              5
                                                            }
                                                            fill="black"
                                                            font-size="14"
                                                          >
                                                            {
                                                              details.BoxRightremark
                                                            }
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              25
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                3.3) *
                                                                3 -
                                                              5
                                                            }
                                                            fill="black"
                                                            font-size="14"
                                                          >
                                                            {
                                                              details.BoxThirdremark
                                                            }
                                                          </text>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* //single line facia 2 drwer// */}
                                                  {details.boxdoor_typecat ==
                                                  "Facia" ? (
                                                    <>
                                                      {details.boxdoor_selectcat ==
                                                        "2 drawer" && (
                                                        <>
                                                          <line
                                                            x1="10"
                                                            y1={
                                                              details.updatedboxheightcat /
                                                              2
                                                            }
                                                            x2={
                                                              details.updatedboxwidthcat +
                                                              10
                                                            }
                                                            y2={
                                                              details.updatedboxheightcat /
                                                              2
                                                            }
                                                            stroke="black"
                                                            strokeWidth="1"
                                                          />
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              18
                                                            }`}
                                                            y={
                                                              details.updatedboxheightcat /
                                                              3
                                                            }
                                                            fill="black"
                                                            font-size="80"
                                                          >
                                                            -
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              18
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                3.5) *
                                                              3
                                                            }
                                                            fill="black"
                                                            font-size="80"
                                                          >
                                                            -
                                                          </text>
                                                        </>
                                                      )}
                                                      {details.boxdoor_selectcat ==
                                                        "3 drawer" && (
                                                        <>
                                                          {/* //double line facia 3 drawer //     */}
                                                          <line
                                                            x1="10"
                                                            y1={
                                                              details.updatedboxheightcat /
                                                              3
                                                            }
                                                            x2={
                                                              details.updatedboxwidthcat +
                                                              10
                                                            }
                                                            y2={
                                                              details.updatedboxheightcat /
                                                              3
                                                            }
                                                            stroke="black"
                                                            strokeWidth="1"
                                                          />
                                                          <line
                                                            x1="10"
                                                            y1={
                                                              (details.updatedboxheightcat /
                                                                3) *
                                                              2
                                                            }
                                                            x2={
                                                              details.updatedboxwidthcat +
                                                              10
                                                            }
                                                            y2={
                                                              (details.updatedboxheightcat /
                                                                3) *
                                                              2
                                                            }
                                                            stroke="black"
                                                            strokeWidth="1"
                                                          />
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              18
                                                            }`}
                                                            y={
                                                              details.updatedboxheightcat /
                                                              4
                                                            }
                                                            fill="black"
                                                            font-size="80"
                                                          >
                                                            -
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              18
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                5.3) *
                                                              3
                                                            }
                                                            fill="black"
                                                            font-size="80"
                                                          >
                                                            -
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              18
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                3.3) *
                                                              3
                                                            }
                                                            fill="black"
                                                            font-size="80"
                                                          >
                                                            -
                                                          </text>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* //single line facia 1:1 2:1 drwer// */}
                                                  {details.boxdoor_typecat ==
                                                  "Facia" ? (
                                                    <>
                                                      {details.boxdoor_selectcat ==
                                                        "2 drawer 2:1" && (
                                                        <>
                                                          <line
                                                            x1="10"
                                                            y1={
                                                              details.updatedboxheightcat /
                                                              2
                                                            }
                                                            x2={
                                                              details.updatedboxwidthcat +
                                                              10
                                                            }
                                                            y2={
                                                              details.updatedboxheightcat /
                                                              2
                                                            }
                                                            stroke="black"
                                                            strokeWidth="1"
                                                          />
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              18
                                                            }`}
                                                            y={
                                                              details.updatedboxheightcat /
                                                              3
                                                            }
                                                            fill="black"
                                                            font-size="80"
                                                          >
                                                            -
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              18
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                3.5) *
                                                              3
                                                            }
                                                            fill="black"
                                                            font-size="80"
                                                          >
                                                            -
                                                          </text>
                                                        </>
                                                      )}
                                                      {details.boxdoor_selectcat ==
                                                        "3 drawer 2:1" && (
                                                        <>
                                                          {/* //double line facia 3 drawer //     */}
                                                          <line
                                                            x1="10"
                                                            y1={
                                                              details.updatedboxheightcat /
                                                              3
                                                            }
                                                            x2={
                                                              details.updatedboxwidthcat +
                                                              10
                                                            }
                                                            y2={
                                                              details.updatedboxheightcat /
                                                              3
                                                            }
                                                            stroke="black"
                                                            strokeWidth="1"
                                                          />
                                                          <line
                                                            x1="10"
                                                            y1={
                                                              (details.updatedboxheightcat /
                                                                3) *
                                                              2
                                                            }
                                                            x2={
                                                              details.updatedboxwidthcat +
                                                              10
                                                            }
                                                            y2={
                                                              (details.updatedboxheightcat /
                                                                3) *
                                                              2
                                                            }
                                                            stroke="black"
                                                            strokeWidth="1"
                                                          />
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              18
                                                            }`}
                                                            y={
                                                              details.updatedboxheightcat /
                                                              4
                                                            }
                                                            fill="black"
                                                            font-size="80"
                                                          >
                                                            -
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              18
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                5.3) *
                                                              3
                                                            }
                                                            fill="black"
                                                            font-size="80"
                                                          >
                                                            -
                                                          </text>
                                                          <text
                                                            x={`${
                                                              details.updatedboxwidthcat /
                                                                2 -
                                                              18
                                                            }`}
                                                            y={
                                                              (details.updatedboxheightcat /
                                                                3.3) *
                                                              3
                                                            }
                                                            fill="black"
                                                            font-size="80"
                                                          >
                                                            -
                                                          </text>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* //intdummy left right // */}
                                                  {details.boxint_dummy_valuecat ==
                                                  "1" ? (
                                                    <>
                                                      {details.boxintposition ==
                                                        "left" && (
                                                        <>
                                                          <rect
                                                            width={`10px`}
                                                            height={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              90
                                                            }px`}
                                                            fill="#ffffff"
                                                            stroke="black"
                                                            x={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                              7
                                                            }`}
                                                            y={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              10
                                                            }`}
                                                            style={{
                                                              border:
                                                                "3px solid #000000",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                      {details.boxintposition ==
                                                        "right" && (
                                                        <>
                                                          <rect
                                                            width={`10px`}
                                                            height={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              90
                                                            }px`}
                                                            fill="#ffffff"
                                                            stroke="black"
                                                            x={`${
                                                              (details.updatedboxwidthcat /
                                                                100) *
                                                              95
                                                            }`}
                                                            y={`${
                                                              (details.updatedboxheightcat /
                                                                100) *
                                                              10
                                                            }`}
                                                            style={{
                                                              border:
                                                                "3px solid #000000",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </svg>
                                              </div>
                                              <div
                                                style={{ textAlign: "center" }}
                                              >
                                                <span>
                                                  {details.boxwidthcat}
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "18px",
                                                  fontWeight: "900",
                                                }}
                                              >
                                                <span>
                                                  No-{pdfimagecounter++}
                                                </span>
                                              </div>
                                            </>
                                          )}
                                          {/* frameimage// */}
                                          {details.idcat && (
                                            <>
                                              <p className="svgletter">
                                                {" "}
                                                {details.frametypecat ===
                                                "1.25" ? (
                                                  <span className="svgtext">
                                                    ர
                                                  </span>
                                                ) : details.frametypecat ===
                                                  "1.25 lip" ? (
                                                  <span className="svgtext">
                                                    ரி
                                                  </span>
                                                ) : details.frametypecat ===
                                                  "ES" ? (
                                                  <span className="svgtext">
                                                    ES
                                                  </span>
                                                ) : details.frametypecat ===
                                                  "EP" ? (
                                                  <span className="svgtext">
                                                    EP
                                                  </span>
                                                ) : (
                                                  <span className="svgtext">
                                                    வ
                                                  </span>
                                                )}
                                              </p>
                                              <div className="square-box">
                                                <div
                                                  style={{
                                                    transform: "rotate(272deg)",
                                                  }}
                                                >
                                                  <span>
                                                    {details.frameheightcat}
                                                  </span>
                                                </div>
                                                <svg
                                                  className="shape rectangle"
                                                  width={`${
                                                    details.updatedframewidthcat +
                                                    20
                                                  }px`}
                                                  height={`${
                                                    details.updatedframeheightcat +
                                                    20
                                                  }px`}
                                                >
                                                  {/* frame svg square line 1x1 1.25 Pa Box// */}
                                                  {details.frametypecat ==
                                                    "1 x 1 Pa Frame" ||
                                                  details.frametypecat ===
                                                    "1.25 Pa Frame" ? (
                                                    <>
                                                      <svg
                                                        className="shape rectangle"
                                                        width={`${
                                                          details.updatedframewidthcat +
                                                          35
                                                        }px`}
                                                        height={`${
                                                          details.updatedframeheightcat +
                                                          7
                                                        }px`}
                                                      >
                                                        <rect
                                                          width={`${details.updatedframewidthcat}px`}
                                                          height={`${details.updatedframeheightcat}px`}
                                                          fill="#ffffff"
                                                          stroke="black"
                                                          x="10"
                                                          y="10"
                                                        />
                                                      </svg>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <rect
                                                        width={`${details.updatedframewidthcat}px`}
                                                        height={`${details.updatedframeheightcat}px`}
                                                        fill="#ffffff"
                                                        stroke="black"
                                                        x="10"
                                                        y="10"
                                                      />
                                                    </>
                                                  )}
                                                  {/* //frame fixed box left right // */}
                                                  {/* //Fixed Posistion left right // */}
                                                  {details.framefixed == "1" ? (
                                                    <>
                                                      {details.framefixedposition ==
                                                        "left" && (
                                                        <>
                                                          <rect
                                                            width={`${
                                                              details.updatedframewidthcat /
                                                                2 +
                                                              5
                                                            }px`}
                                                            height={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              90
                                                            }px`}
                                                            fill="#ffffff"
                                                            stroke="black"
                                                            x={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                                7 +
                                                              15
                                                            }`}
                                                            y={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              8
                                                            }`}
                                                            style={{
                                                              border:
                                                                "3px solid #000000",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                      {details.framefixedposition ==
                                                        "right" && (
                                                        <>
                                                          <rect
                                                            width={`${
                                                              details.updatedframewidthcat /
                                                                2 +
                                                              5
                                                            }px`}
                                                            height={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              90
                                                            }px`}
                                                            fill="#ffffff"
                                                            stroke="black"
                                                            x={`${
                                                              details.updatedframewidthcat /
                                                              2.5
                                                            }`}
                                                            y={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              10
                                                            }`}
                                                            style={{
                                                              border:
                                                                "3px solid #000000",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* //square box in frame // */}
                                                  {details.frametypecat ===
                                                    "EP" ||
                                                  details.frametypecat ===
                                                    "ES" ||
                                                  details.frametypecat ===
                                                    "1.25 lip" ? (
                                                    ""
                                                  ) : (
                                                    <rect
                                                      fill="#ffffff"
                                                      stroke="black"
                                                      strokeWidth="2"
                                                      x={`${
                                                        (details.updatedframewidthcat /
                                                          100) *
                                                        9
                                                      }`}
                                                      y="1"
                                                      width="18"
                                                      height="18 "
                                                    />
                                                  )}
                                                  {/* //1x1 1.25 pa box // */}
                                                  {(details.frametypecat ==
                                                    "1 x 1 Pa Frame" ||
                                                    details.frametypecat ===
                                                      "1.25 Pa Frame") && (
                                                    <>
                                                      <svg
                                                        width="30px"
                                                        height="20px"
                                                        x={`${
                                                          details.updatedframewidthcat -
                                                          20
                                                        }`}
                                                      >
                                                        <rect
                                                          fill="#ffffff"
                                                          stroke="black"
                                                          strokeWidth="2"
                                                          y="1"
                                                          width="20"
                                                          height="20"
                                                        />
                                                      </svg>
                                                    </>
                                                  )}
                                                  {details.frametypecat ==
                                                    "1.25" && (
                                                    <>
                                                      <text
                                                        x={`${
                                                          (details.updatedframewidthcat /
                                                            100) *
                                                            9 +
                                                          15
                                                        }`}
                                                        y="40"
                                                        fill="black"
                                                        font-size="16"
                                                      >
                                                        1.25
                                                      </text>
                                                    </>
                                                  )}
                                                  {details.frametypecat ==
                                                    "1 x 1 Pa Frame" && (
                                                    <>
                                                      <text
                                                        x={`${
                                                          (details.updatedframewidthcat /
                                                            100) *
                                                            9 +
                                                          15
                                                        }`}
                                                        y="40"
                                                        fill="black"
                                                        font-size="16"
                                                      >
                                                        1 x 1 Pa Frame
                                                      </text>
                                                    </>
                                                  )}
                                                  {details.frametypecat ==
                                                    "1.25 Pa Frame" && (
                                                    <>
                                                      <text
                                                        x={`${
                                                          (details.updatedframewidthcat /
                                                            100) *
                                                            9 +
                                                          15
                                                        }`}
                                                        y="40"
                                                        fill="black"
                                                        font-size="16"
                                                      >
                                                        1.25 Pa Frame
                                                      </text>
                                                    </>
                                                  )}
                                                  {details.frametypecat ==
                                                    "1 x 1" && (
                                                    <>
                                                      <text
                                                        x={`${
                                                          (details.updatedframewidthcat /
                                                            100) *
                                                            9 +
                                                          15
                                                        }`}
                                                        y="40"
                                                        fill="black"
                                                        font-size="16"
                                                      >
                                                        1x1
                                                      </text>
                                                    </>
                                                  )}
                                                  {details.frametypecat ==
                                                    "1.25 lip" && (
                                                    <>
                                                      <text
                                                        x={`${
                                                          (details.updatedframewidthcat /
                                                            100) *
                                                            9 +
                                                          15
                                                        }`}
                                                        y="40"
                                                        fill="black"
                                                        font-size="16"
                                                      >
                                                        1.25 lip
                                                      </text>
                                                    </>
                                                  )}
                                                  {/* //remarks in single // */}
                                                  {(details.framedoortypecat &&
                                                    details.framedoortypeselectcat ==
                                                      "Single") ||
                                                    (details.framedoortypeselectcat ==
                                                      "" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                              2 -
                                                            20
                                                          }px`}
                                                          y={`${
                                                            details.updatedframeheightcat -
                                                            30
                                                          }px`}
                                                          fill="black"
                                                          font-size="14"
                                                        >
                                                          {details.FrameRemark}
                                                        </text>
                                                      </>
                                                    ))}
                                                  {details.framedoortypecat &&
                                                    details.framedoortypeselectcat ==
                                                      "Single" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                              2 -
                                                            20
                                                          }px`}
                                                          y={`${
                                                            details.updatedframeheightcat -
                                                            30
                                                          }px`}
                                                          fill="black"
                                                          font-size="14"
                                                        >
                                                          {details.FrameRemark}
                                                        </text>
                                                      </>
                                                    )}
                                                  {/* //remarks in double // */}
                                                  {details.framedoortypecat &&
                                                    details.framedoortypeselectcat ==
                                                      "Double" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedframewidthcat /
                                                              100) *
                                                              25 -
                                                            10
                                                          }`}
                                                          y={`${
                                                            details.updatedframeheightcat -
                                                            30
                                                          }`}
                                                          fill="black"
                                                          font-size="14"
                                                        >
                                                          {details.FrameRemark}
                                                        </text>
                                                        <text
                                                          x={`${
                                                            (details.updatedframewidthcat /
                                                              100) *
                                                              80 -
                                                            35
                                                          }`}
                                                          y={`${
                                                            details.updatedframeheightcat -
                                                            30
                                                          }`}
                                                          fill="black"
                                                          font-size="14"
                                                        >
                                                          {
                                                            details.FrameRightremark
                                                          }
                                                        </text>
                                                      </>
                                                    )}
                                                  {/* //frame single door top left bottom right // */}
                                                  {details.framedoortypeselectcat ==
                                                  "Single" ? (
                                                    <>
                                                      {details.framesingledrposition ==
                                                        "top" && (
                                                        <>
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                                25 +
                                                              10
                                                            }`}
                                                            cy={`${10}`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                              80
                                                            }`}
                                                            cy={`${10}`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                        </>
                                                      )}
                                                      {details.framesingledrposition ==
                                                        "bottom" && (
                                                        <>
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                                25 +
                                                              10
                                                            }`}
                                                            cy={`${
                                                              details.updatedframeheightcat +
                                                              10
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                              80
                                                            }`}
                                                            cy={`${
                                                              details.updatedframeheightcat +
                                                              10
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                        </>
                                                      )}
                                                      {details.framesingledrposition ==
                                                        "right" && (
                                                        <>
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              details.updatedframewidthcat +
                                                              10
                                                            }`}
                                                            cy={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              25
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                          <circle
                                                            r="8"
                                                            cx={`${
                                                              details.updatedframewidthcat +
                                                              10
                                                            }`}
                                                            cy={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              80
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                        </>
                                                      )}
                                                      {details.framesingledrposition ==
                                                        "left" && (
                                                        <>
                                                          <circle
                                                            r="8"
                                                            cx={`${10}`}
                                                            cy={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              25
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                          <circle
                                                            r="8"
                                                            cx={`${10}`}
                                                            cy={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              80
                                                            }`}
                                                            stroke="black"
                                                            stroke-width="3"
                                                            fill="white"
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {details.framedoortypecat ==
                                                    "Glass Door" &&
                                                    details.framedoortypeselectcat ==
                                                      "Single" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedframewidthcat /
                                                              100) *
                                                              25 +
                                                            25
                                                          }`}
                                                          y={`${
                                                            details.updatedframeheightcat -
                                                            15
                                                          }`}
                                                          fill="black"
                                                          font-size="16"
                                                        >
                                                          GDR
                                                        </text>
                                                      </>
                                                    )}
                                                  {details.framedoortypecat ==
                                                    "Glass Door" &&
                                                    details.framedoortypeselectcat ==
                                                      "Double" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedframewidthcat /
                                                              100) *
                                                              25 +
                                                            25
                                                          }`}
                                                          y={`${
                                                            details.updatedframeheightcat -
                                                            15
                                                          }`}
                                                          fill="black"
                                                          font-size="16"
                                                        >
                                                          GDR
                                                        </text>
                                                        <text
                                                          x={`${
                                                            (details.updatedframewidthcat /
                                                              100) *
                                                              80 -
                                                            35
                                                          }`}
                                                          y={`${
                                                            details.updatedframeheightcat -
                                                            15
                                                          }`}
                                                          fill="black"
                                                          font-size="16"
                                                        >
                                                          GDR
                                                        </text>
                                                      </>
                                                    )}
                                                  {/* framefixed position left right// */}
                                                  {details.framefixed == "1" &&
                                                    details.framefixedposition ==
                                                      "left" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedframewidthcat /
                                                              100) *
                                                              25 -
                                                            10
                                                          }`}
                                                          y={`${
                                                            details.updatedframeheightcat -
                                                            65
                                                          }`}
                                                          fill="black"
                                                          font-size="16"
                                                        >
                                                          Fixed
                                                        </text>
                                                      </>
                                                    )}
                                                  {details.framefixed == "1" &&
                                                    details.framefixedposition ==
                                                      "right" && (
                                                      <>
                                                        <text
                                                          x={`${
                                                            (details.updatedframewidthcat /
                                                              100) *
                                                              80 -
                                                            5
                                                          }`}
                                                          y={`${
                                                            details.updatedframeheightcat -
                                                            65
                                                          }`}
                                                          fill="black"
                                                          font-size="16"
                                                        >
                                                          Fixed
                                                        </text>
                                                      </>
                                                    )}
                                                  {details.jsquareframewidth !=
                                                    "" &&
                                                  details.jsquareframeheight !=
                                                    "" ? (
                                                    <>
                                                      {details.frametypecat ===
                                                        "EP" ||
                                                      details.frametypecat ===
                                                        "ES" ||
                                                      details.frametypecat ===
                                                        "1.25 lip" ? (
                                                        ""
                                                      ) : (
                                                        <text
                                                          x={`${
                                                            details.updatedframewidthcat /
                                                            2
                                                          }px`}
                                                          y={`${
                                                            details.updatedframeheightcat /
                                                            2
                                                          }px`}
                                                          textAnchor="middle"
                                                          fill="#000000"
                                                          dominantBaseline="middle"
                                                          fontSize="16px"
                                                        >
                                                          {details.framedoortypecat ===
                                                            "Glass Door" &&
                                                          details.framedoortypeselectcat ===
                                                            "Double" ? (
                                                            <>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  2
                                                                }px`}
                                                                dy="-0.5em"
                                                                style={{
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                              >
                                                                {parseInt(
                                                                  details.jsquareframeheight
                                                                )}
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  2
                                                                }px`}
                                                                dy="1.1em"
                                                              >
                                                                {parseInt(
                                                                  details.jsquareframewidth
                                                                ) + "G"}
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                    2 +
                                                                  30
                                                                }px`}
                                                                dy="-0.7em"
                                                              >
                                                                -{" "}
                                                                {
                                                                  details.jsquareframequantity
                                                                }
                                                              </tspan>
                                                            </>
                                                          ) : details.framefixed ==
                                                              "1" &&
                                                            details.framefixedposition ==
                                                              "left" ? (
                                                            <>
                                                              {/* //fixed left fixed value // */}
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  2.4
                                                                }px`}
                                                                dy="-5.5em"
                                                                style={{
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                              >
                                                                {
                                                                  details.framefixedheight
                                                                }
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  2.4
                                                                }px`}
                                                                dy="1.1em"
                                                              >
                                                                {
                                                                  details.framefixedwidth
                                                                }
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                    2.4 +
                                                                  30
                                                                }px`}
                                                                dy="-0.7em"
                                                              >
                                                                - 1
                                                              </tspan>
                                                              {/* //fixed jsquare value // */}
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  1.2
                                                                }px`}
                                                                dy="-0.5em"
                                                                style={{
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                              >
                                                                {
                                                                  details.jsquareframeheight
                                                                }
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  1.2
                                                                }px`}
                                                                dy="1.1em"
                                                              >
                                                                {
                                                                  details.jsquareframewidth
                                                                }
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                    1.2 +
                                                                  30
                                                                }px`}
                                                                dy="-0.7em"
                                                              >
                                                                -{" "}
                                                                {
                                                                  details.jsquareframequantity
                                                                }
                                                              </tspan>
                                                            </>
                                                          ) : details.framefixed ==
                                                              "1" &&
                                                            details.framefixedposition ==
                                                              "right" ? (
                                                            <>
                                                              {/* //fixed right fixed value // */}
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  1.6
                                                                }px`}
                                                                dy="-5.5em"
                                                                style={{
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                              >
                                                                {
                                                                  details.framefixedheight
                                                                }
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  1.6
                                                                }px`}
                                                                dy="1.1em"
                                                              >
                                                                {
                                                                  details.framefixedwidth
                                                                }
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                    1.6 +
                                                                  30
                                                                }px`}
                                                                dy="-0.7em"
                                                              >
                                                                - 1
                                                              </tspan>
                                                              {/* //fixed jsquare value // */}
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  4.2
                                                                }px`}
                                                                dy="-0.5em"
                                                                style={{
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                              >
                                                                {
                                                                  details.jsquareframeheight
                                                                }
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  4.2
                                                                }px`}
                                                                dy="1.1em"
                                                              >
                                                                {
                                                                  details.jsquareframewidth
                                                                }
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                    4.2 +
                                                                  30
                                                                }px`}
                                                                dy="-0.7em"
                                                              >
                                                                -{" "}
                                                                {
                                                                  details.jsquareframequantity
                                                                }
                                                              </tspan>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  2
                                                                }px`}
                                                                dy="-0.5em"
                                                                style={{
                                                                  textDecoration:
                                                                    "underline",
                                                                }}
                                                              >
                                                                {
                                                                  details.jsquareframeheight
                                                                }
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                  2
                                                                }px`}
                                                                dy="1.1em"
                                                              >
                                                                {
                                                                  details.jsquareframewidth
                                                                }
                                                              </tspan>
                                                              <tspan
                                                                x={`${
                                                                  details.updatedframewidthcat /
                                                                    2 +
                                                                  30
                                                                }px`}
                                                                dy="-0.7em"
                                                              >
                                                                -{" "}
                                                                {
                                                                  details.jsquareframequantity
                                                                }
                                                              </tspan>
                                                            </>
                                                          )}
                                                        </text>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* //frame single g and grd//         */}
                                                  {details.framegvaluecat ==
                                                  "1" ? (
                                                    <>
                                                      {details.framegposition ==
                                                        "top" && (
                                                        <>
                                                          <text
                                                            x={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                                25 +
                                                              25
                                                            }`}
                                                            y="80"
                                                            fill="black"
                                                            font-size="18"
                                                          >
                                                            G
                                                          </text>
                                                          {details.framedoortypeselectcat ==
                                                            "Double" && (
                                                            <>
                                                              <text
                                                                x={`${
                                                                  (details.updatedframewidthcat /
                                                                    100) *
                                                                    80 -
                                                                  35
                                                                }`}
                                                                y="80"
                                                                fill="black"
                                                                font-size="18"
                                                              >
                                                                G
                                                              </text>
                                                            </>
                                                          )}
                                                        </>
                                                      )}
                                                      {details.framegposition ==
                                                        "bottom" && (
                                                        <>
                                                          <text
                                                            x={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                                25 +
                                                              25
                                                            }`}
                                                            y={`${
                                                              details.updatedframeheightcat -
                                                              45
                                                            }`}
                                                            fill="black"
                                                            font-size="18"
                                                          >
                                                            G
                                                          </text>
                                                          {details.framedoortypeselectcat ==
                                                            "Double" && (
                                                            <>
                                                              <text
                                                                x={`${
                                                                  (details.updatedframewidthcat /
                                                                    100) *
                                                                    80 -
                                                                  35
                                                                }`}
                                                                y={`${
                                                                  details.updatedframeheightcat -
                                                                  45
                                                                }`}
                                                                fill="black"
                                                                font-size="18"
                                                              >
                                                                G
                                                              </text>
                                                            </>
                                                          )}
                                                          {details.framedoortypecat ==
                                                            "Glass Door" &&
                                                            details.framedoortypeselectcat ==
                                                              "Single" && (
                                                              <>
                                                                <text
                                                                  x={`${
                                                                    (details.updatedframewidthcat /
                                                                      100) *
                                                                      25 +
                                                                    25
                                                                  }`}
                                                                  y={`${
                                                                    details.updatedframeheightcat -
                                                                    15
                                                                  }`}
                                                                  fill="black"
                                                                  font-size="16"
                                                                >
                                                                  GDR
                                                                </text>
                                                              </>
                                                            )}
                                                          {details.framedoortypecat ==
                                                            "Glass Door" &&
                                                            details.framedoortypeselectcat ==
                                                              "Double" && (
                                                              <>
                                                                <text
                                                                  x={`${
                                                                    (details.updatedframewidthcat /
                                                                      100) *
                                                                      25 +
                                                                    25
                                                                  }`}
                                                                  y={`${
                                                                    details.updatedframeheightcat -
                                                                    15
                                                                  }`}
                                                                  fill="black"
                                                                  font-size="16"
                                                                >
                                                                  GDR
                                                                </text>
                                                                <text
                                                                  x={`${
                                                                    (details.updatedframewidthcat /
                                                                      100) *
                                                                      80 -
                                                                    35
                                                                  }`}
                                                                  y={`${
                                                                    details.updatedframeheightcat -
                                                                    15
                                                                  }`}
                                                                  fill="black"
                                                                  font-size="16"
                                                                >
                                                                  GDR
                                                                </text>
                                                              </>
                                                            )}
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                  {/* //intdummy left right // */}
                                                  {details.frameintdummyvaluecat ==
                                                  "1" ? (
                                                    <>
                                                      {details.frameintposition ==
                                                        "left" && (
                                                        <>
                                                          <rect
                                                            width={`10px`}
                                                            height={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              90
                                                            }px`}
                                                            fill="#ffffff"
                                                            stroke="black"
                                                            x={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                              10
                                                            }`}
                                                            y={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              10
                                                            }`}
                                                            style={{
                                                              border:
                                                                "3px solid #000000",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                      {details.frameintposition ==
                                                        "right" && (
                                                        <>
                                                          <rect
                                                            width={`10px`}
                                                            height={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              90
                                                            }px`}
                                                            fill="#ffffff"
                                                            stroke="black"
                                                            x={`${
                                                              (details.updatedframewidthcat /
                                                                100) *
                                                              95
                                                            }`}
                                                            y={`${
                                                              (details.updatedframeheightcat /
                                                                100) *
                                                              10
                                                            }`}
                                                            style={{
                                                              border:
                                                                "3px solid #000000",
                                                            }}
                                                          />
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    ""
                                                  )}
                                                </svg>
                                              </div>
                                              <div
                                                style={{ textAlign: "center" }}
                                              >
                                                <span>
                                                  {details.framewidthcat}
                                                </span>
                                              </div>
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  fontSize: "18px",
                                                  fontWeight: "900",
                                                }}
                                              >
                                                <span>
                                                  No-{pdfimagecounter++}
                                                </span>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </Col>
                                      {(idx + 1) % 3 === 0 &&
                                        idx !== arr.length - 1 && (
                                          <div
                                            style={{
                                              flexBasis: "100%",
                                              height: "46px",
                                            }}
                                          ></div>
                                        )}
                                    </>
                                  )
                                )}
                              </Row>
                            </div>
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </Row>
                  <br />
                </div>
                <br />
              </div>
            </div>
          </div>
          {/* end// */}
          <br />
          <br />
        </main>
      )}
    </>
  );
};
export default Projectdetails;
